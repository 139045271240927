import {createFilterOptions} from "@mui/material/Autocomplete";

export const KEY_TOKEN = "frontFlow_authData";
export const KEY_PARAMETROS = "parametros";
export const KEY_EMPRESA = "empresa";
export const KEY_MODAL_CONFIG = "modalConfig";
export const KEY_MODAL_EMPRESA = "modalEmpresa";
export const KEY_WHITE_CONFIG = "configWhite";

export const PLACEHOLDER_IMAGEM =  'iVBORw0KGgoAAAANSUhEUgAAAJUAAAB0CAIAAAAD2Oc/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANYSURBVHhe7dfbbiIxEIThff+nI0FJCCtyuEEkF8AjLFJXpNESwG27bRdT31UyjD2HXx2RPwthpn7c1I+b+nFTP27qx039uKkfN/Xjpn7c1I+b+nFTP27qx039uKkfN/Xjpn7c1I+b+nFTP27qx039uKkfN/Xj1qLfw8PD8czn5yc+lgLh/ZDrgqenJ5wnWQL7rVYrVLoFC8QvsB/iJHh7e8MacYrqhzLJnp+fsVI8QvodDgdk8cBi8QjphyBOWCweA/Xb7XZYL8nq99tsNgjihy0kWf1+X19fqOGHLSRZ/X7b7RY1/LCFJKvf7/HxETX8sIUkG+j7i/6LzzBQPywWj5B+Hx8faOKBxeIR0u8ETZJhmThF9TtBmQRYIH6B/U7Q5yqcKlli+xmEOvPy8oIzJFeLfub19XW3231/f7+/v+OQFGvXTyKoHzf146Z+3NSPm/pxUz9u6sdN/bipHzf146Z+i/1+fzwe1+s1fqcy934Wz+AQlVn3m8YzdFM4337n8Qw+JjHTfpfiGaIpnGO/6/EMTh3e7PqlxDNYMLZ59UuPZ8b/Qzqjft54BotHNZd+efHMyFM4i34l8Qw2Gs/99yuPZ8acwjvvVyuewaYjued+deOZ0abwbvtFxDO4wBiG6LdcLuu+l7h4Zpwp7N/P4pnNZoOjBbBXsEES9u+H9/EDR3NFT97UCAl79ptO3lT2FLaMZ7on7NkP7+A3OMOjfTyDy3fSp9+lyZtyTWGveKbjFPbph+e+BWff0jeewa0017pfyuRN3ZzCEeKZLlPYuh+e1QMrfzNOPIPbaqhdP+/kTf06haPFM42nsF0/PF8u7PJjzHgGt9hEi34lkzeF7caOZ5pNYXi/WvHM6Q8pfhpem4Sx/erGo9MgYWC/mccz0QkD++EJZi80YUg/Td5/4hKG9MNdywReTW2V+2nyroiYwsr9cKdyAV5TPdX6afIS1Z3COv0Uz6Viwgr9FC9DrYSl/RQvW5WERf0Ur1B5wvx+ildFYcL8fri+FCtJmNNPk1dddsKcfrimVIWX6+Trp8kLlTGFjn6K14A3YWo/xWvGlTCpn+I1lp7wdj/F6yIx4e1+f6UTBLjK8f1FBqR+3NSPm/pxUz9u6sdN/bipHzf146Z+zBaLf0v0xE2IUDeCAAAAAElFTkSuQmCC';


export const PAGE_SIZE_SELECT = [5, 10, 20, 50, 100];

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// export const BASE_DOMINIOS = ["dashboard.frontflow.com.br", "homologacao.frontflow.com.br", "localhost"];
export const BASE_DOMINIOS = process.env.REACT_APP_BASE_DOMINIOS.split(",");

export const AUTH_TYPES = [
  { value: "NONE", label: "Sem Autenticação" },
  { value: "BASIC", label: "Basica" },
  { value: "JWT", label: "JWT" },
];

export const filterOptions = createFilterOptions({
  matchFrom: "any",
  ignoreCase: true,
  ignoreAccents: true,
});
