import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {useNavigate} from 'react-router';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import PersonIcon from '@mui/icons-material/Person';
import {useCallback, useEffect, useState} from 'react';
import {DashboardService} from 'services/DashboardService';
import Loader from 'components/loader';
import ReactApexChart from 'react-apexcharts';
import {toastError} from 'utils/toastError';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';
import {hasAnyRoles, useAuthState} from "store/auth";

const AdmDashboard = () => {
  const appSelector = useAppSelector(useAuthState)
  const navigate = useNavigate();
  const theme = useTheme();
  const config = useAppSelector(useConfigState);
  const [isLoading, setIsLoading] = useState(false);
  const [dadosAdm, setDadosAdm] = useState(null);
  const [modulosGrupo, setModulosGrupo] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [usuariosModulosGrupo, setUsuariosModulosGrupo] = useState([]);
  const [usuariosModulos, setUsuariosModulos] = useState([]);
  const [limitadoresModulosGrupo, setLimitadoresModulosGrupo] = useState([]);

  const md = useMediaQuery((t: Theme) => t.breakpoints.down("md"));

  const dadoCardsAdmin = useCallback(() => {
    if(!config.parametros) return;
    setIsLoading(true);
    DashboardService.dashboardAdm(config.parametros.codigoUnico)
      .then(res => {
        setDadosAdm(res.data);
      })
      .catch(err => {
        toastError(err, 'Erro ao carregar os dados!');
      })
      .finally(() => setIsLoading(false));
  }, [config.parametros]);

  useEffect(() => {
    dadoCardsAdmin();
  }, [dadoCardsAdmin]);

  const tratamentoDados = useCallback(() => {
    if (dadosAdm?.usuariosModulo) {
      // -- Cria um array com os dados de quantidade de usuários por modulo geral
      const usuariosPorModulo = dadosAdm.usuariosModulo.map(usuarios => usuarios.quantidade);
      setUsuariosModulos(usuariosPorModulo);

      // -- Cria um array com os nomes dos modulos que existem usuários
      const nomesModulos = dadosAdm.usuariosModulo.map(modulo => modulo.nome);
      const updatedItems = nomesModulos.map(item => item.replace(/_/g, ' '));
      setModulos(updatedItems);
    }

    if (dadosAdm?.limitadoresGrupo) {
      // -- Cria um array com os dados de quantidade de usuários por modulo de cada grupo de empresa
      const usuariosPorModuloGrupo = dadosAdm.limitadoresGrupo.map(usuarios => usuarios.quantidade);
      setUsuariosModulosGrupo(usuariosPorModuloGrupo);

      // -- Cria um array com os nomes dos modulos que existem usuários para cada grupo de empresa
      const nomesModulosGrupo = dadosAdm.limitadoresGrupo.map(nomes => nomes.nome);
      setModulosGrupo(nomesModulosGrupo);

      // -- Cria um array com os limitadores dos modulos que existem usuários para cada grupo de empresa
      const limitadoresPorModuloGrupo = dadosAdm.limitadoresGrupo.map(limitadores => limitadores.limite);
      setLimitadoresModulosGrupo(limitadoresPorModuloGrupo);
    }
  }, [dadosAdm]);

  useEffect(() => {
    tratamentoDados();
  }, [tratamentoDados]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {dadosAdm && (
        <Grid container spacing={4} sx={{ mt: 2, justifyContent: 'center' }}>
          {hasAnyRoles(appSelector, ['ROLE_ADMIN']) && (
            <Grid item xs={12} sm={12} md={4}>
              <Card
                elevation={4}
                sx={{ borderRadius: 1, cursor: 'pointer' }}
                onClick={() => navigate('/painel/grupos-empresas')}>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingY: 0.5,
                      paddingX: 1
                    }}>
                    <Box component={Paper} elevation={4} sx={{}}>
                      <Avatar
                        variant='rounded'
                        sx={{
                          backgroundColor: 'primary.main',
                          width: { xs: 30, md: 40, lg: 50 },
                          height: { xs: 30, md: 40, lg: 50 }
                        }}>
                        <BusinessIcon
                          sx={{
                            fontSize: { xs: 20, md: 30, lg: 40 }
                          }}
                        />
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'end',
                        flexDirection: 'column'
                      }}>
                      <Typography variant='h6' color='primary.main' sx={{ fontSize: { xs: 20, md: 15, lg: 20 } }}>
                        Grupos
                      </Typography>
                      <Typography variant='body1' fontWeight='bold' sx={{ fontSize: { xs: 30, md: 20, lg: 25 } }}>
                        {dadosAdm.usuariosGrupo.quantidade}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider variant='middle' sx={{ mb: 2 }} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end',
                      flexDirection: 'column'
                    }}>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: { xs: 10, md: 12.5, lg: 15 },
                        fontWeight: 'bold'
                      }}>
                      {dadosAdm.usuariosGrupo.ultimoMes >= 0 ? (
                        <span style={{ color: 'green' }}>+{dadosAdm.usuariosGrupo.ultimoMes}</span>
                      ) : (
                        <span style={{ color: 'red' }}>{dadosAdm.usuariosGrupo.ultimoMes}</span>
                      )}{' '}
                      <span style={{ opacity: 0.7 }}>
                        {dadosAdm.usuariosGrupo.ultimoMes > 1 || dadosAdm.usuariosGrupo.ultimoMes < -1
                          ? 'Grupos'
                          : 'Grupo'}{' '}
                        no último mês
                      </span>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
          {hasAnyRoles(appSelector,['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']) && (
            <Grid item xs={12} sm={12} md={hasAnyRoles(appSelector,['ROLE_GRUPO_EMPRESA']) ? 6 : 4}>
              <Card
                elevation={4}
                sx={{ borderRadius: 1, cursor: 'pointer' }}
                onClick={() => navigate('/painel/empresas')}>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingY: 0.5,
                      paddingX: 1
                    }}>
                    <Box component={Paper} elevation={4} sx={{}}>
                      <Avatar
                        variant='rounded'
                        sx={{
                          backgroundColor: 'primary.main',
                          width: { xs: 30, md: 40, lg: 50 },
                          height: { xs: 30, md: 40, lg: 50 }
                        }}>
                        <StoreIcon
                          sx={{
                            fontSize: { xs: 20, md: 30, lg: 40 }
                          }}
                        />
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'end',
                        flexDirection: 'column'
                      }}>
                      <Typography variant='h6' color='primary.main' sx={{ fontSize: { xs: 20, md: 15, lg: 20 } }}>
                        Empresas
                      </Typography>
                      <Typography variant='body1' fontWeight='bold' sx={{ fontSize: { xs: 30, md: 20, lg: 25 } }}>
                        {dadosAdm.usuariosEmpresa?.quantidade}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider variant='middle' sx={{ mb: 2 }} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end',
                      flexDirection: 'column'
                    }}>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: { xs: 10, md: 12.5, lg: 15 },
                        fontWeight: 'bold'
                      }}>
                      {dadosAdm.usuariosEmpresa?.ultimoMes >= 0 ? (
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                          +{dadosAdm.usuariosEmpresa?.ultimoMes}
                        </span>
                      ) : (
                        <span style={{ color: 'red', fontWeight: 'bold' }}>{dadosAdm.usuariosEmpresa?.ultimoMes}</span>
                      )}{' '}
                      <span style={{ opacity: 0.7 }}>
                        {dadosAdm.usuariosEmpresa?.ultimoMes > 1 || dadosAdm.usuariosEmpresa?.ultimoMes < -1
                          ? 'Empresas'
                          : 'Empresa'}{' '}
                        no último mês
                      </span>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
          {hasAnyRoles(appSelector,['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA', 'ROLE_EMPRESA']) && (
            <Grid item xs={12} sm={12} md={hasAnyRoles(appSelector,['ROLE_GRUPO_EMPRESA']) ? 6 : hasAnyRoles(appSelector,['ROLE_EMPRESA']) ? 12 : 4}>
              <Card
                elevation={4}
                sx={{ borderRadius: 1, cursor: 'pointer' }}
                onClick={() => navigate('/painel/colaboradores')}>
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingY: 0.5,
                      paddingX: 1
                    }}>
                    <Box component={Paper} elevation={4} sx={{}}>
                      <Avatar
                        variant='rounded'
                        sx={{
                          backgroundColor: 'primary.main',
                          width: { xs: 30, md: 40, lg: 50 },
                          height: { xs: 30, md: 40, lg: 50 }
                        }}>
                        <PersonIcon
                          sx={{
                            fontSize: { xs: 20, md: 30, lg: 40 }
                          }}
                        />
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'end',
                        flexDirection: 'column'
                      }}>
                      <Typography variant='h6' color='primary.main' sx={{ fontSize: { xs: 20, md: 15, lg: 20 } }}>
                        Colaboradores
                      </Typography>
                      <Typography variant='body1' fontWeight='bold' sx={{ fontSize: { xs: 30, md: 20, lg: 25 } }}>
                        {dadosAdm.usuariosColaboradores?.quantidade}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider variant='middle' sx={{ mb: 2 }} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end',
                      flexDirection: 'column'
                    }}>
                    <Typography
                      variant='body1'
                      sx={{
                        fontSize: { xs: 10, md: 12.5, lg: 15 },
                        fontWeight: 'bold'
                      }}>
                      {dadosAdm.usuariosColaboradores?.ultimoMes >= 0 ? (
                        <span style={{ color: 'green', fontWeight: 'bold' }}>
                          +{dadosAdm.usuariosColaboradores?.ultimoMes}
                        </span>
                      ) : (
                        <span style={{ color: 'red', fontWeight: 'bold' }}>
                          {dadosAdm.usuariosColaboradores?.ultimoMes}
                        </span>
                      )}{' '}
                      <span style={{ opacity: 0.7 }}>
                        {dadosAdm.usuariosColaboradores?.ultimoMes > 1 || dadosAdm.usuariosColaboradores?.ultimoMes < -1
                          ? 'Colaboradores'
                          : 'Colaborador'}{' '}
                        no último mês
                      </span>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
          {hasAnyRoles(appSelector,['ROLE_ADMIN']) && (
            <Grid item xs={12}>
              <Card elevation={4} sx={{ px: 2, borderRadius: 1, paddingTop: 2 }}>
                <ReactApexChart
                  options={{
                    title: {
                      text: 'USUÁRIOS POR MÓDULO (GERAL)',
                      floating: false,
                      offsetY: 0,
                      align: 'center',
                      style: {
                        color: theme.palette.primary.main,
                        fontSize:  md ? '12px' : '18px' 
                      }
                    },
                    chart: {
                      toolbar: {
                        show: true
                      },
                      stacked: false,
                      height: 'auto',
                      type: 'bar'
                    },
                    fill: {
                      colors: [theme.palette.primary.main]
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 5,
                        rangeBarOverlap: true,
                        dataLabels: {
                          position: 'top'
                        }
                      }
                    },
                    dataLabels: {
                      enabled: true,
                      textAnchor: 'middle',
                      distributed: true,
                      offsetY: 2,
                      style: {
                        fontSize: '12px',
                        colors: ['black']
                      }
                    },
                    stroke: {
                      show: false,
                      width: 0.5,
                      colors: ['#000000']
                    },
                    xaxis: {
                      categories: modulos,
                      position: 'bottom',
                      labels: {
                        style: {
                          fontSize: '8px',
                          fontWeight: 'bold'
                        }
                      }
                    },
                    yaxis: {
                      forceNiceScale: true,
                      logarithmic: false,
                      logBase: 10
                    }
                  }}
                  series={[
                    {
                      name: 'Usuários',
                      data: usuariosModulos
                    }
                  ]}
                  type='bar'
                  height={400}
                  width={'99%'}
                />
              </Card>
            </Grid>
          )}
          {hasAnyRoles(appSelector,['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA', 'ROLE_EMPRESA']) &&
            Object.keys(config.parametros).length !== 0 && (
              <Grid item xs={12}>
                <Card elevation={4} sx={{ px: 2, borderRadius: 1, paddingTop: 2 }}>
                  <ReactApexChart
                    options={{
                      title: {
                        text: `USUÁRIOS POR MÓDULO (${config.parametros.grupo})`,                      
                        floating: false,
                        offsetY: 0,
                        align: 'center',
                        style: {
                          color: theme.palette.primary.main,
                          fontSize: md ? '12px' : '18px' 
                        }
                      },
                      chart: {
                        events: {
                          dataPointSelection: (event, context, config) => {
                            navigate(`/painel/colaboradores?modulo=${modulosGrupo[config.dataPointIndex]}`);
                          },
                          dataPointMouseEnter: event => {
                            event.target.style.cursor = 'pointer';
                          },
                          dataPointMouseLeave: event => {
                            event.target.style.cursor = 'default';
                          }
                        },
                        toolbar: {
                          show: true
                        },
                        stacked: false,
                        height: 'auto',
                        type: 'bar'
                      },
                      fill: {
                        colors: [theme.palette.primary.main, '#00E396']
                      },
                      plotOptions: {
                        bar: {
                          borderRadius: 5,
                          rangeBarOverlap: true,
                          dataLabels: {
                            position: 'top'
                          }
                        }
                      },
                      dataLabels: {
                        enabled: true,
                        textAnchor: 'middle',
                        distributed: true,
                        offsetY: 2,
                        style: {
                          fontSize: '12px',
                          colors: ['black']
                        }
                      },
                      xaxis: {
                        categories: modulosGrupo.map(item => item.replace(/_/g, ' ')),
                        position: 'bottom',
                        labels: {
                          style: {
                            fontSize: '8px',
                            fontWeight: 'bold'
                          }
                        }
                      },
                      yaxis: {
                        forceNiceScale: true,
                        logarithmic: false,
                        logBase: 10
                      }
                    }}
                    series={[
                      {
                        name: 'Usuários',
                        data: usuariosModulosGrupo
                      },
                      {
                        name: 'Limitadores',
                        data: limitadoresModulosGrupo
                      }
                    ]}
                    type='bar'
                    height={400}
                    width={'99%'}
                  />
                </Card>
              </Grid>
            )}
        </Grid>
      )}
    </>
  );
};

export default AdmDashboard;
