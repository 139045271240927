import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/agendamento-entrega";
const DATAS = "/datas";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const AgendamentoService = {
  findEmpresas: (codigo: string, params?: AxiosRequestConfig) => {
      paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
      return API.get(`${URL}/empresas`, paramsGeral);
  },
  findAllDatas: (params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL+DATAS}`, paramsGeral);
  },
  createData: (data: any, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    return API.post(`${URL+DATAS}`, data, paramsGeral);
  },
  deleteData: (id: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    return API.delete(`${URL+DATAS}/${id}`, paramsGeral);
  },
  findAllPedidos: (codigo: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
    return API.get(`${URL}/pedidos`, paramsGeral);
  },
  findDatasLivres: (data:string[], codigo: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
    return API.post(`${URL}/datas-disponiveis`, data, paramsGeral);
  },
  findHorariosLivres: (data:string, codigo: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
    return API.post(`${URL}/horarios-disponiveis`, {data}, paramsGeral);
  },
  findConfig: (codigo: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
    return API.get(`${URL}/configuracoes`, paramsGeral);
  },
  // findById: (id: string, codigo: string, params?: AxiosRequestConfig) => {
  //   paramsGeral = { ...paramsGeral, ...params };
  //   paramsGeral.params = { codigo };
  //   return API.get(`${URL}/${id}`, paramsGeral);
  // },
  findAllAgendamentos: (codigo: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
    return API.get(`${URL}/entregas`, paramsGeral);
  },
  create: (data: any, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    return API.post(`${URL}/entregas`, data, paramsGeral);
  },
  // // update: (id: string, data: any, params?: AxiosRequestConfig) => {
  // //     paramsGeral = {...paramsGeral, ...params};
  // //     return API.put(`${URL}/${id}`, data, paramsGeral);
  // // },
  delete: (id: string, codigo: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
    return API.delete(`${URL}/entregas/${id}`, paramsGeral);
  },
  reenviarPedidos: (codigo: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = {...paramsGeral.params, codigo};
    return API.post(`${URL}/reenviar-pedidos`, {},paramsGeral);
  }
}