import MyCard from "components/card";
import {useCallback, useEffect, useState} from "react";
import {SpringPage} from "types/springpage";
import {useNavigate, useSearchParams} from "react-router-dom";
import ActionButtons from "components/buttons";
import {toast} from "react-toastify";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {Box, Grid, MenuItem, Pagination, Paper, TextField, Typography} from "@mui/material";
import {PAGE_SIZE_SELECT} from "utils/constants";
import {Data} from "types/agendamentos";
import {AgendamentoService} from "services/AgendamentoService";
import Swal from "sweetalert2";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";


const ListExcecoesDatas = () => {
  const [page, setPage] = useState<SpringPage<Data>>({} as SpringPage<Data>);
  const navigate = useNavigate();
  const config = useAppSelector(useConfigState);
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultPageSize = '10';
  const defaultPage = '1';
  const appDispatch = useAppDispatch();

  const handleDelete = (id: string) => {
    Swal.fire({
      title: `Deseja realmente excluir esta data?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Sim, deletar administrador!`,
      cancelButtonText: `Não`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then((result) => {
      if (result.isConfirmed) {
        appDispatch(configActions.setLoading({loading: true}))
        AgendamentoService.deleteData(id)
            .then((res) => {
              toast.success("Data deletada com sucesso!");
              loadDatas();
            })
            .catch((err) => {
              toast.error(err.response.data.message);
            })
            .finally(() => {
              appDispatch(configActions.setLoading({loading: false}))
            });
      }
    })
  }

  const loadDatas = useCallback(() => {
    if (!config.empresa) return;
    appDispatch(configActions.setLoading({loading: true}))
    const params: AxiosRequestConfig = {
      params: {
        page: parseInt(searchParams.get('page') || defaultPage)-1 ,
        size: searchParams.get('size') || defaultPageSize,
        codigo: config.empresa.empresaCodigo,
      }
    }
    AgendamentoService.findAllDatas(params)
      .then((res: AxiosResponse<SpringPage<Data>>) => {
        setPage(res.data);
      })
      .catch((err) => { toast.error(err); })
      .finally(() => { appDispatch(configActions.setLoading({loading: false})) });
  }, [appDispatch, config.empresa, searchParams]);

  useEffect(() => {
    loadDatas();
  }, [loadDatas]);

  return (
    <MyCard title={"Exceções de data"} action={() => navigate("/painel/agendamentos-de-entregas/excecoes-datas/add")} >
      <Grid item xs={12} sx={{mb:3}}>
        {page && page.content?.length > 0 && page.content.map(data => (
            <Box component={Paper} sx={{ width: "100%", mb:2, p:2, alignContent: "center" }} key={data.id}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} md={3.5} >
                    <Typography sx={{ fontSize: 16, p:0, m:0 }} color="text.secondary" gutterBottom>
                      <b>Data: </b> {data.data}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                    <Typography sx={{ fontSize: 16, p:0, m:0 }} color="text.secondary" gutterBottom>
                      <b>Descrição: </b> {data.descricao}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                    <Typography sx={{ fontSize: 16, p:0, m:0 }} color="text.secondary" gutterBottom>
                      <b>Empresa: </b> {data.empresa.nome}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1.5}>
                    <ActionButtons handleDelete={() => handleDelete(data.id)}/>
                  </Grid>
                </Grid>
            </Box>
        ))}
      </Grid>
      {page && page.totalElements > 0 &&
          <Grid container item xs={12}>
            <Grid item md={6} xs={6}>
              <Pagination
                  count={page?.totalPages}
                  defaultPage={parseInt(defaultPage)}
                  page={parseInt(searchParams.get('page') || defaultPage)}
                  onChange={(event, value) => {
                    setSearchParams({page: `${value}`, size: searchParams.get('size') || defaultPageSize})
                  }}
              />
            </Grid>
            <Grid item xs={6} md={6} sx={{ textAlign: "right"}}>
              <TextField
                  id="outlined-select-currency"
                  select
                  label="Registros por pagina"
                  onChange={(e:any) => {
                    setSearchParams({page: "1" , size: e.target.value})
                  }}
                  size="small"
                  value={searchParams.get('size') || defaultPageSize}
                  sx={{minWidth: 150}}
              >
                {PAGE_SIZE_SELECT.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
      }
    </MyCard>
  )
}


export default ListExcecoesDatas;