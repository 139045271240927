import React, {useCallback, useEffect, useReducer, useState} from "react";
import {PermissaoService} from "services/PermissaoService";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import MyCard from "components/card";
import {Modulo, Permissao} from "types/auth";
import {grey} from "@mui/material/colors";
import {useAppDispatch} from "store";
import {configActions} from "store/config";
import ActionButtons from "components/buttons";
import CloseIcon from "@mui/icons-material/Close";
import {TransitionProps} from "@mui/material/transitions";
import {useForm} from "react-hook-form";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

type Payload = {
    content?: Permissao[],
    prev?: Permissao,
    next?: Permissao
}

type Action = {
    type: 'SET' | 'ADD' | 'REMOVE' | 'UPDATE' | 'CLEAR',
    payload?: Payload
}


type FieldSearch = {nome:string, label: string}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='down' ref={ref} {...props} />;
});


const permissaoReducer = (state: any, action: Action) => {
    switch (action.type) {
        case 'SET':
            return action.payload.content;
        case 'ADD':
            return [...state.filter((item: Permissao) => item.nome !== action.payload.content[0].nome), {descricao: action.payload.content[0].descricao , nome: action.payload.content[0].nome.toUpperCase()}];
        case 'REMOVE':
            return state.filter((item: Permissao) => item.nome !== action.payload.content[0].nome);
        case 'UPDATE':
            if (!action.payload.next || !action.payload.prev) return state;
            return state.map((item: Permissao) => {
                if (item.nome === action.payload.prev.nome) {
                    return action.payload.next;
                }
                return item;
            });
        case 'CLEAR':
            return [];
        default:
            return state;
    }
}


const ListPermissoes = () => {
    const [modulos, setModulos] = useState<Modulo[]>([]);
    const appDispatch = useAppDispatch();
    const theme = useTheme();
    const downSm = useMediaQuery(theme.breakpoints.down('sm'));
    const [showModal, setShowModal] = useState(false);
    const [modulo, setModulo] = useState<Modulo>();
    const {handleSubmit, register, reset} = useForm();
    const [state, dispatch] = useReducer(permissaoReducer, []);
    const [search, setSearch] = useState<FieldSearch>();
    const [autComplete, setAutComplete] = useState<FieldSearch[]>([]);
    const [permissoes, setPermissoes] = useState<Permissao[]>([]);


    const getPermissoes = useCallback(() => {
        appDispatch(configActions.setLoading({loading: true}))
        PermissaoService.findAllPermissoes()
            .then((res: AxiosResponse<Permissao[]>) => {
                setPermissoes(res.data);
                let permissoes: {nome:string, label: string}[] = [];
                res.data.forEach((permissao) => {
                    permissoes.push({nome: permissao.nome, label: permissao.descricao ? permissao.descricao : permissao.nome });
                })
                setAutComplete(permissoes);
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                appDispatch(configActions.setLoading({loading: false}))
            });
    }, [appDispatch]);


    useEffect(() => {
        getPermissoes();
    }, [getPermissoes]);

    const getModulos = useCallback(() => {
        appDispatch(configActions.setLoading({loading: true}))
        PermissaoService.findAll({params: {page: 0, size: 100}})
            .then((res: AxiosResponse<Modulo[]>) => {
                setModulos(res.data);
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                appDispatch(configActions.setLoading({loading: false}))
            });
    }, [appDispatch]);

    useEffect(() => {
        getModulos();
    }, [getModulos]);


    const handleEdit = (modulo: Modulo) => {
        dispatch({type: 'CLEAR'});
        setShowModal(true);
        setModulo(modulo);
        reset({descricao: modulo.descricao, nome: modulo.nome});
        dispatch({type: 'SET', payload: {content: modulo.permissoes}});
    }

    const onSubmit = (data: any) => {
        let moduloTemp = {...data, permissoes: state, nome: data.nome.toUpperCase()};
        if(modulo) {
            appDispatch(configActions.setLoading({loading: true}))
            PermissaoService.update(moduloTemp)
                .then(() => {
                    toast.success('Permissão atualizada com sucesso');
                    handleClose();
                    getModulos();
                    getPermissoes();
                })
                .catch((err) => {
                    toast.error(err);
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                })
        }else{
            appDispatch(configActions.setLoading({loading: true}))
            PermissaoService.create(moduloTemp)
                .then(() => {
                    toast.success('Permissão atualizada com sucesso');
                    handleClose();
                    getModulos();
                    getPermissoes();
                })
                .catch((err) => {
                    toast.error(err);
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                })
        }
    }


    const handleClose = () => {
        setShowModal(false);
        setSearch(undefined);
        reset({descricao: '', nome: ''});
        setModulo(undefined);
        dispatch({type: 'CLEAR'});
    }

    return (
        <MyCard title={'Permissões'} action={() => setShowModal(true)} >
            <Box sx={{display: 'flex', width: '100%', color: grey[800]}}>
                <Grid container>
                    {!downSm &&
                        <Grid container item sx={{
                            border: 1,
                            borderColor: grey[300],
                            backgroundColor: grey[100],
                            p: 1,
                            fontWeight: 800
                        }}>
                            <Grid item xs sx={{textAlign: "center"}}>Modulo</Grid>
                            <Grid item xs sx={{textAlign: "center"}}>Tag</Grid>
                            <Grid item xs sx={{textAlign: "center"}}>Permissões</Grid>
                            <Grid item xs sx={{textAlign: "center"}}>Ações</Grid>
                        </Grid>
                    }
                    {modulos.map((modulo, index) => (
                        <Grid container item key={index} sx={{border: 1, borderColor: grey[300], p: 1, display: "flex", alignItems: "center", mb: {xs: 2, md: 0}}}>
                            <Grid item xs={12} md sx={{textAlign: "center"}}>{downSm && <b>Modulo: </b>}{modulo.descricao}</Grid>
                            <Grid item xs={12} md sx={{textAlign: "center"}}>{downSm && <b>Tag: </b>}{modulo.nome}</Grid>
                            <Grid item xs={12} md sx={{textAlign: "center", wordWrap: "break-word"}}>{downSm && <b>Permissões: </b>}{modulo.permissoes.map((pr, i) => `${pr.descricao ? pr.descricao : pr.nome} ${i === modulo.permissoes.length - 1 ? "" : ", "}`)}</Grid>
                            <Grid item xs={12} md sx={{textAlign: "center"}}>
                                {downSm && <b>Ações: </b>}<ActionButtons handleEdit={() => handleEdit(modulo)}/>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Dialog
                open={showModal}
                onClose={handleClose}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={'sm'}
                fullScreen={downSm}
                keepMounted
            >
                <DialogContent>
                    <Box sx={{mb: 3}}>
                        <Typography color='primary' sx={{fontSize: 16, fontWeight: 'bold'}}>
                            {modulo?.descricao}
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={() => handleClose()}
                            sx={{
                                position: 'absolute',
                                right: 15,
                                top: 10,
                                zIndex: 1,
                                color: theme => theme.palette.grey[500]
                            }}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Grid container component={"form"} spacing={2} onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12}>
                            <TextField
                                {...register('descricao')}
                                label={'Descrição'}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                {...register('nome')}
                                label={'Tag'}
                                inputProps={{readOnly: !!modulo}}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography color='primary' sx={{fontSize: 16, fontWeight: 'bold'}}>
                                Permissões
                            </Typography>
                            <Divider />
                        </Grid>
                        {
                            state.map((permissao: Permissao, index: number) => (
                                <Grid container item xs={12} key={index} spacing={.5}>
                                    <Grid item xs={5}>
                                        <TextField
                                            value={permissao.descricao || permissao.nome}
                                            fullWidth
                                            onChange={(e) => dispatch({type: "UPDATE", payload: {prev: permissao, next: {...permissao, descricao: e.target.value}}})}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            value={permissao.nome}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            fullWidth
                                            onChange={(e) => dispatch({type: "UPDATE", payload: {prev: permissao, next: {...permissao, nome: e.target.value.toUpperCase()}}})}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant={'outlined'}
                                            color={"inherit"}
                                            fullWidth
                                            sx={{
                                                height: "100%",
                                                borderColor: theme.palette.grey[400],
                                                color: theme.palette.error.main
                                            }}
                                            onClick={() => dispatch({type: "REMOVE", payload: {content: [permissao]}})}
                                        >
                                            <RemoveIcon/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))
                        }
                        <Grid container item xs={12} spacing={0.5}>
                            <Grid item xs={12}>
                                <Typography color='primary' sx={{fontSize: 16, fontWeight: 'bold'}}>
                                    Adicionar nova permissão
                                </Typography>
                                <Divider sx={{my:2}} />
                            </Grid>
                            <Grid item xs={5}>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    options={autComplete}
                                    value={search?.label || ''}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'object') setSearch(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            onChange={(event) => {
                                                setSearch(state => ({...state, label: event.target.value}))
                                                // setSearch(event.target.value);
                                            }}
                                            label="Descrição"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',

                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    options={autComplete}
                                    value={search?.nome || ''}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'object') setSearch(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            onChange={(event) => {
                                                setSearch(state => ({...state, nome: event.target.value}))
                                                // setSearch(event.target.value);
                                            }}
                                            label="Tag"
                                            InputProps={{
                                                ...params.InputProps,
                                                type: 'search',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    fullWidth
                                    sx={{height: "100%"}}
                                    onClick={() => {
                                        if (!search) {
                                            toast.warn('Informe uma permissão');
                                            return;
                                        }
                                        let per = permissoes.find((permissao) => permissao.nome === search.nome);
                                        if (!per) {
                                            dispatch({type: "ADD", payload: {content: [{nome: search.nome, descricao: search.label}]}});
                                        }else {
                                            dispatch({type: "ADD", payload: {content: [per]}});
                                        }
                                        setSearch(undefined);
                                    }}
                                >
                                    <AddIcon/>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type={'submit'} variant={'contained'} color={'primary'} fullWidth>
                                Salvar
                            </Button>
                        </Grid>

                    </Grid>

                </DialogContent>
            </Dialog>
        </MyCard>
    )
}

export default ListPermissoes;