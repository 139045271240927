import {Button, Card, Grid, Tooltip, Typography} from '@mui/material';
import {useState} from 'react';
import {SolicitacaoArmazem} from 'types/solicitacao-materiais';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Format from 'utils/formatadores';
import ModalDetalhesSolicitacaoArmazem from '../modal/detalhesArmazem';


type Props = {
    dadosSolicitacaoArmazem: SolicitacaoArmazem;
};

const CardSolicitacaoFinalizada = ( { dadosSolicitacaoArmazem }: Props ) => {
    const [showModal, setShowModal] = useState( false );

    const handleView = () => {
        setShowModal( true );
    };

    const handleClose = () => {
        setShowModal( false );
    };

    return (
        <Card variant='elevation' sx={{ p: 2 }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} spacing={1}>
                <Grid item xs={12} sm={4} lg={2}>
                    <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                        <b>Número:</b> {dadosSolicitacaoArmazem.numero}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={5} lg={3}>
                    <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                        <b>Data de Emissão:</b> {Format.data( dadosSolicitacaoArmazem.dataEmissao )}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3} lg={2}>
                    <Tooltip title='Ver' placement='top'>
                        <Button variant={'contained'} onClick={() => handleView()} fullWidth color={'info'}>
                            <VisibilityIcon />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            {showModal && (
                <ModalDetalhesSolicitacaoArmazem
                    onClose={() => handleClose()}
                    open={showModal}
                    dadosSolicitacaoArmazem={dadosSolicitacaoArmazem}
                />
            )}
        </Card>
    );
};

export default CardSolicitacaoFinalizada;
