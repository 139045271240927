import {Button, Tooltip} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';

type Props = {
    handlePrint: Function,
}

const ButtonPrint = ({handlePrint}: Props) => {
    return (
        <Tooltip title="Imprimir" placement="top">
            <Button variant={'contained'} onClick={() => handlePrint()} sx={{marginRight: 1,}} fullWidth>
                <PrintIcon/>
            </Button>
        </Tooltip>
    )
}

export default ButtonPrint;