import {Box, Container, GlobalStyles, useTheme} from '@mui/material';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';
import {Outlet} from 'react-router-dom';

const CadastroLayout = () => {
    const config = useAppSelector(useConfigState);
    
    const {palette} = useTheme();

    return (
        <>
        <GlobalStyles styles={{body: {backgroundColor: palette.grey[100]}}} />
            <Container component="main" maxWidth={false} sx={{my: 3, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Box sx={{py: 2, boxShadow: 4, backgroundColor: (t) => t.palette.background.paper, width: {xs: '100%', sm: '80%', md: '50%', lg: '33%' } }}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2}}>
                        <Box 
                            component={"img"}
                            src={config.configWhite.logo}
                            alt={config.configWhite.nome}
                            sx={{ width: 150, height: 'auto', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}
                        />
                    </Box>
                    <Box component='main' sx={{ mb: 2, px:4}}>
                        <Outlet/>
                    </Box>
                </Box>
            </Container>
        </>
         
    )
    
}

export default CadastroLayout