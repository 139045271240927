import {Box, Grid, Paper, TextField, Theme, Typography, useMediaQuery} from "@mui/material"
import {useState} from "react";
import {NumericFormat} from 'react-number-format';
import {ProdutoOrcamento} from "types/orcamento";
import Format from "utils/formatadores"
import numeral from "numeral";
import ButtonDelete from "components/buttons/delete";

type Props = {
    produto: ProdutoOrcamento;
    onChange: (produto: ProdutoOrcamento) => void;
    onDelete: (produto: ProdutoOrcamento) => void;
}

const styleText = { fontSize: 12, p:0, m: 0, textAlign: "center" }
const stylesHead = { fontSize: 14, p:0, m: 0, textAlign: "center" }

const CardPedido = ({produto, onChange, onDelete}: Props) => {
  const xs = useMediaQuery((t: Theme) => t.breakpoints.down("md"));
  const [quantidade, setQuantidade] = useState(produto.quantidade)
  const [valorVenda, setValorVenda] = useState(produto.valorVenda.replace(".", ","))

  const alteraQuant = (value: any) => {
    if (!value && value <= 0) return 1
    return value
  }

  
  return (
    <Box component={Paper} sx={{ width: "100%", mb:2, p: 2}}>
      <Grid container alignItems={"center"} spacing={2}>
          <Grid item xs={6} md>
              {xs && 
                <Typography sx={stylesHead} color="text.secondary" gutterBottom>
                  <b>Cód.</b>
                </Typography>
              }
              <Typography sx={styleText} color="text.secondary" gutterBottom>
                  {produto.codigo}
              </Typography>
          </Grid>
          <Grid item xs={6} md>
              {xs && 
                <Typography sx={stylesHead} color="text.secondary" gutterBottom>
                  <b>Descrição.</b>
                </Typography>
              }
              <Typography sx={styleText} color="text.secondary" gutterBottom>
                  {produto.descricao}
              </Typography>
          </Grid>
          <Grid item xs={6} md>
              {xs && 
                <Typography sx={stylesHead} color="text.secondary" gutterBottom>
                  <b>UM.</b>
                </Typography>
              }
              <Typography sx={styleText} color="text.secondary" gutterBottom>
                  {produto.unidadeMedida}
              </Typography>
          </Grid>
          <Grid item xs={6} md>
              {xs && 
                <Typography sx={stylesHead} color="text.secondary" gutterBottom>
                  <b>Prc. Tab.</b>
                </Typography>
              }
              <Typography sx={styleText} color="text.secondary" gutterBottom>
                  {numeral(produto.valor).format('$ 0,0.00')}
              </Typography>
          </Grid>
          <Grid item xs={12} md>
            {xs && 
              <Typography sx={stylesHead} color="text.secondary" gutterBottom>
                <b>Qtd.</b>
              </Typography>
            }
            <NumericFormat
              customInput={TextField}
              fullWidth
              decimalSeparator=","
              thousandSeparator="."
              InputProps={{
                style: { fontSize: 12 },
              }}
              allowNegative={false}
              min={1}
              variant="standard"
              value={quantidade}
              onChange={(e: any) => {
                let value = e.target.value
                if (!value) value = "1"
                setQuantidade(e.target.value)
                let quant = numeral(value).value();
                onChange({...produto, valorVenda: valorVenda, quantidade: quant.toString()})
              }}
            />
          </Grid>
          <Grid item xs={12} md>
            {xs && 
              <Typography sx={stylesHead} color="text.secondary" gutterBottom>
                <b>Prc. Venda.</b>
              </Typography>
            }
            <NumericFormat
              customInput={TextField}
              decimalScale={2}
              decimalSeparator=","
              fixedDecimalScale
              prefix="R$ "
              thousandSeparator="."
              fullWidth
              allowNegative={false}
              InputProps={{
                style: { fontSize: 12 }
              }}
              value={valorVenda}
              placeholder={'R$ 0,00'}
              variant="standard"
              onChange={(e: any) => {
                var value = e.target.value
                if (!value) value = "R$0,00"
                setValorVenda(e.target.value)
                var venda = numeral(value).value()
                onChange({...produto, valorVenda: venda.toString(), quantidade: quantidade})
              }}
            />
          </Grid>
          <Grid item xs={12} md>
            {xs && 
              <Typography sx={stylesHead} color="text.secondary" gutterBottom>
                <b>Valor Total.</b>
              </Typography>
            }
            <Typography sx={styleText} color="text.secondary" gutterBottom>
              <b>{Format.moeda(alteraQuant(numeral(quantidade).value()) * numeral(valorVenda).value())}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md>
            <ButtonDelete handleDelete={() => {
              onDelete(produto)
            }}/>
          </Grid>
      </Grid>
    </Box>
  )
}

export default CardPedido