import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ConfiguracoesEmpresa} from "types/configuracoes";
import {Controller, useForm} from "react-hook-form";
import {useEffect} from "react";
import SaveIcon from "@mui/icons-material/Save";
import {ConfiguracoesEmpresaService} from "services/ConfiguracoesEmpresaService";
import {toastError} from "utils/toastError";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {configActions, useConfigState} from "store/config";
import {useAppSelector} from "store";

type Props = {
    configuracao: ConfiguracoesEmpresa;
    update: () => void;
}

const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
const agendamentoParcial = [{value: "0", label: 'Não'}, {value: "1", label: 'Sim'}];

const ConfiguracaoEmpresa = ({configuracao, update}: Props) => {
    const {control, handleSubmit,formState: {errors}, setValue,register } = useForm({mode: 'all', reValidateMode: 'onSubmit'});
    const dispatch = useDispatch();
    const config = useAppSelector(useConfigState);

    const hasModuloPermission = (config: any, modulo: string) => {
        const moduloPermissao = config?.parametros?.permissoes?.find((permissao: string) => permissao === modulo);
        return !!moduloPermissao;
    }


    useEffect(() => {
        if (configuracao) {
            setValue('ativo', configuracao.agendamentoEntrega.ativo ? "1" : "0");
            setValue('horarioMin', configuracao.agendamentoEntrega.horarioMin);
            setValue('horarioMax', configuracao.agendamentoEntrega.horarioMax);
            setValue('intervaloHora', configuracao.agendamentoEntrega.intervaloHora);
            setValue('diasMin', configuracao.agendamentoEntrega.diasMin);
            setValue('diasMax', configuracao.agendamentoEntrega.diasMax);
            setValue('diasToCancel', configuracao.agendamentoEntrega.diasToCancel);
            setValue('agendamentoParcial', configuracao.agendamentoEntrega.agendamentoParcial ? "1" : "0");
        }
    }, [configuracao, setValue]);


    const onSubmit = (data: any) => {
        dispatch(configActions.setLoading({
            loading: true,
        }));
        data.ativo = data.ativo === "1";
        data.agendamentoParcial = data.agendamentoParcial === "1";
        ConfiguracoesEmpresaService
            .update(configuracao.empresaCodigo, {agendamentoEntrega: data})
            .then(() => {
                update();
                toast.success("Configurações atualizadas com sucesso!");
            })
            .catch((err) => {
                toastError(err, "Erro ao atualizar configurações!")
            })
            .finally(() => {
                dispatch(configActions.setLoading({
                    loading: false,
                }));
            })

    }

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                <Typography fontSize={16} fontWeight={"h6.fontWeight"}>{`Configurações ${configuracao.empresaNome}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {hasModuloPermission(config, 'AGENDAMENTO_ENTREGA') &&(
                    <>
                        <Box sx={{mb: 3}}>
                            <Typography>Configurações de agendamento de carga</Typography>
                            <Divider/>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"ativo"}
                                        render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    error={!!errors.ativo}
                                                    required
                                                    select
                                                    label="Status"
                                                    variant="outlined"
                                                    value={field.value || "0"}
                                                    fullWidth
                                                    helperText={errors.ativo?.message.toString()}
                                                >
                                                    {statusField.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>))}
                                                </TextField>
                                            )}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={"agendamentoParcial"}
                                        render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    error={!!errors.agendamentoParcial}
                                                    required
                                                    select
                                                    label="Agendamento Parcial"
                                                    variant="outlined"
                                                    value={field.value || "0"}
                                                    fullWidth
                                                    helperText={errors.agendamentoParcial?.message.toString()}
                                                >
                                                    {agendamentoParcial.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>))}
                                                </TextField>
                                            )}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        {...register('horarioMin')}
                                        required
                                        error={!!errors.horarioMin}
                                        type={'time'}
                                        label="Horario min."
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        helperText={errors.horarioMin?.message.toString()}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        {...register('horarioMax')}
                                        required
                                        error={!!errors.horarioMax}
                                        type={'time'}
                                        label="Horario max."
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        helperText={errors.horarioMax?.message.toString()}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        {...register('intervaloHora')}
                                        required
                                        error={!!errors.intervaloHora}
                                        type={'time'}
                                        label="Intervalo de Horas"
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        helperText={errors.intervaloHora?.message.toString()}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        {...register('diasMin')}
                                        required
                                        error={!!errors.diasMin}
                                        type={'number'}
                                        label="Dias min."
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        helperText={errors.diasMin?.message.toString()}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>

                                    <TextField
                                        {...register('diasMax')}
                                        required
                                        error={!!errors.diasMax}
                                        type={'number'}
                                        label="Dias max."
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        helperText={errors.diasMax?.message.toString()}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        {...register('diasToCancel')}
                                        required
                                        error={!!errors.diasToCancel}
                                        type={'number'}
                                        label="Dias para cancelamento"
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        helperText={errors.diasToCancel?.message.toString()}
                                        InputLabelProps={{shrink: true}}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{mt: 3, textAlign: 'right'}}>
                                <Button variant={'contained'} color={'primary'} type={'submit'}>
                                    <SaveIcon/> Salvar
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
            </AccordionDetails>
        </Accordion>
    )
}


export default ConfiguracaoEmpresa;