import {TabelaPrecoOrcamento} from "types/orcamento";
import {useForm} from "react-hook-form";
import React, {useCallback, useEffect, useState} from "react";
import {RestPage} from "types/prtoheuspage";
import {AxiosRequestConfig} from "axios";
import {OrcamentosService} from "services/OrcamentosService";
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Pagination,
    Slide,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Loader from "components/loader";
import {PAGE_SIZE_SELECT} from "utils/constants";
import {TransitionProps} from "@mui/material/transitions";
import {grey} from "@mui/material/colors";
import OrcamentoModalTabelaPrecoRow from "components/orcamento/modals/tabelaPreco/row";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";

type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    handleSelect: (data: TabelaPrecoOrcamento) => void;
    cgcCliente: string;
    codigoCliente: string;
};

type SearchProps = {
    nome: string;
    codigoTab: string;
};

const SendButton = styled(Button)({
    width: "100%",
    height: "100%",
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalTabelaPreco = ({ show,setShow, handleSelect,cgcCliente = "",codigoCliente = ""}: Props) => {
    const {
        handleSubmit,
        reset,
        register,
        formState: {errors},
    } = useForm<SearchProps>({defaultValues: {nome: "", codigoTab: ""}});
    const [page, setPage] = useState<RestPage<TabelaPrecoOrcamento>>();
    const config = useAppSelector(useConfigState);
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [codigoTab, setCodigoTab] = useState("");
    const [nomeTab, setNomeTab] = useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    const handleAction = (originalRow: TabelaPrecoOrcamento) => {
        handleSelect(originalRow);
        setPageCount(0);
        reset();
    };


    const getTabelas = useCallback(() => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            params: {
                nome: nomeTab,
                codigoTab: codigoTab,
                page: pageCount,
                size: pageSize,
                cgcCliente,
                codigoCliente,
            },
        };
        OrcamentosService.findTabelaProdutos(config.empresa.empresaCodigo, params)
            .then((res) => {
                setPage(res.data);
            })
            .catch((err) => {
                toast.error(
                    err.response.data.message || "Erro ao carregar os tabelas"
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [
        nomeTab,
        codigoTab,
        pageCount,
        cgcCliente,
        config.empresa.empresaCodigo,
        pageSize,
        codigoCliente,
    ]);

    useEffect(() => {
        getTabelas();
    }, [getTabelas]);

    const onSubmit = (data: SearchProps) => {
        setCodigoTab(data.codigoTab);
        setNomeTab(data.nome);
        setPageCount(0);
    };

    const handleChangePageSize = (e: any) => {
        setPageCount(0);
        setPageSize(e.target.value);
    }

    return (
        <div>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"xl"}
                fullScreen={fullScreen}
                keepMounted
                onClose={() => setShow(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShow(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>{"Tabelas de preço"}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{mt: 1}}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={5} xs={12}>
                                <TextField
                                    {...register('nome')}
                                    error={!!errors.nome}
                                    type={"text"}
                                    label="Nome"
                                    variant="outlined"
                                    sx={{width: "100%"}}
                                    helperText={errors.nome?.message}
                                />
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <TextField
                                    {...register('codigoTab')}
                                    error={!!errors.codigoTab}
                                    type={"text"}
                                    label="Codigo"
                                    variant="outlined"
                                    sx={{width: "100%"}}
                                    helperText={errors.codigoTab?.message}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <SendButton type={"submit"} variant="contained" size="large">
                                    Buscar
                                </SendButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{display: "flex", width: "100%", mt: 3}}>
                        {isLoading && <Loader/>}
                        {!isLoading && page &&
                            <Grid container>
                                {!xs  && <Grid item container sx={{
                                    border: 1,
                                    borderColor: grey[300],
                                    backgroundColor: grey[100],
                                    p: 1,
                                    fontWeight: 800
                                }}>
                                    <Grid item xs sx={{textAlign: "center"}}>Ações</Grid>
                                    <Grid item xs sx={{textAlign: "center"}}>Código</Grid>
                                    <Grid item xs sx={{textAlign: "center"}}>Descrição</Grid>
                                </Grid> }
                                {page.conteudo.map((row, index) => (
                                    <OrcamentoModalTabelaPrecoRow row={row} handleAction={handleAction} key={index} />
                                ))}
                                <Grid container item xs={12} sx={{pt: 2, display: "flex", alignItems: "center"}}>
                                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                                        <Pagination
                                            count={Math.ceil(parseInt(page?.quantidade) / pageSize)}
                                            defaultPage={1}
                                            page={pageCount + 1}
                                            onChange={(event, value) => {
                                                setPageCount(value - 1);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} sx={{textAlign: "right"}}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Registros por pagina"
                                            onChange={handleChangePageSize}
                                            size="small"
                                            value={pageSize}
                                            sx={{minWidth: 150}}
                                        >
                                            {PAGE_SIZE_SELECT.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalTabelaPreco;
