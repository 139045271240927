import axios from "axios";
import {ReactNode, useEffect} from "react";
import {toast} from "react-toastify";
import {BASE_URL} from "utils/constants";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "store";
import {authActions} from "store/auth";

const request = axios.create({baseURL: BASE_URL});

type Props = {
    children: ReactNode;
}

const AxiosInterceptor = ({ children }: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // const interceptor =
        request.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if(error.response.status === 451) {
                dispatch(authActions.signOut());
                toast.error(error.response.data.message, {autoClose: 2000});
                navigate("/auth/login");
                return;
            }
            return Promise.reject(error);
        });
        // return () => request.interceptors.response.eject(interceptor);
    }, [dispatch, navigate])
    return <>{children}</>;
}

export { AxiosInterceptor };
export default request;
