import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Card,
    CardActionArea,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Pagination,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {TransitionProps} from "@mui/material/transitions";
import {AxiosRequestConfig} from "axios";
import Loader from "components/loader";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {EpiService} from "services/Epi";
import {Funcionario} from "types/Epi";
import {RestPage} from "types/prtoheuspage";


type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    handleSelect: (data: Funcionario) => void;
};

type SearchProps = {
    codigoFuncionario: string;
    nome: string;
    matricula: string;
};

const SendButton = styled(Button)({
    width: "100%",
    height: "100%",
});

const TypoBigLetter = styled(Typography)({
    fontSize: 14,
    lineHeight: 2,
    color: 'black',
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalFuncionario = ({ show, setShow, handleSelect }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [funcionario, setFuncionario] = useState<RestPage<Funcionario>>();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const xs = useMediaQuery(theme.breakpoints.down('md'));
    const config = useAppSelector(useConfigState);
    const [pageCount, setPageCount] = useState(1);
    let pageSize = 10;
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<SearchProps>();

    const getFuncionario = useCallback((nome?: string, matricula?: string) => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            params: {
                page: pageCount,
                size: pageSize,
                codigoFunc: matricula,
                nome: nome
            }
        };
        EpiService.findAllFuncionarios(config.empresa.empresaCodigo, params)
            .then((res) => {
                setFuncionario(res.data);
            })
            .catch((err) => {
                toast.error(
                    err.response.data.message || "Erro ao carregar os Funcionários"
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [config.empresa.empresaCodigo, pageCount, pageSize]);

    useEffect(() => {
        getFuncionario();
    }, [getFuncionario]);

    const onSubmit = (data: SearchProps) => {
        getFuncionario(data.nome, data.matricula);
    };

    return (
        <div>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"xl"}
                fullScreen={fullScreen}
                keepMounted
                onClose={() => setShow(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShow(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>{"Funcionarios"}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{ mt: 2, }}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...register('matricula')}
                                    error={!!errors.matricula}
                                    type={"text"}
                                    label="Matricula"
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    helperText={errors.matricula?.message}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...register('nome')}
                                    error={!!errors.nome}
                                    type={"text"}
                                    label="Nome"
                                    variant="outlined"
                                    sx={{ width: "100%" }}
                                    helperText={errors.nome?.message}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <SendButton type={"submit"} variant="contained" size="large">
                                    Buscar
                                </SendButton>
                            </Grid>
                        </Grid>
                    </Box>
                    {!isLoading ? (
                        <>
                            <Grid item container xs={12}>
                                <>
                                    {(!xs && funcionario) &&
                                        <Grid item container component={Card} md={12} sx={{ mt: 1, p: 1 }}>
                                            <Grid item md={3}>
                                                <TypoBigLetter><b>Matricula</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={9}>
                                                <TypoBigLetter><b>Nome</b></TypoBigLetter>
                                            </Grid>
                                        </Grid>
                                    }
                                    {funcionario && funcionario.conteudo.map((p, i) => (
                                        <CardActionArea onClick={() => handleSelect(p)} key={p.matricula}>
                                            <Grid item
                                                container xs={12}
                                                component={Card}
                                                sx={{
                                                    mt: 1,
                                                    p: 1,
                                                    backgroundColor: i % 2 === 1 ? theme.palette.grey[300] : 'white'
                                                }}
                                            >
                                                <Grid item md={3} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Matricula</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{ my: 0.5 }} />}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.matricula}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={9} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Descrição</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{ my: 0.5 }} />}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.nome}</TypoBigLetter>
                                                </Grid>
                                            </Grid>
                                        </CardActionArea>
                                    ))}
                                </>
                            </Grid>
                            {funcionario &&
                                <Grid item xs={12}>
                                    <Pagination
                                        count={Math.ceil(parseInt(funcionario.quantidade) / pageSize)}
                                        defaultPage={1}
                                        page={pageCount}
                                        onChange={(event, value) => {
                                            setPageCount(value)
                                        }}
                                    />
                                </Grid>
                            }
                        </>
                    ) : (<Loader />)}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalFuncionario;
