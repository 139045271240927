import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Pagination,
    Slide,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import {TransitionProps} from '@mui/material/transitions';
import {AxiosRequestConfig} from 'axios';
import Loader from 'components/loader';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {OrcamentosService} from 'services/OrcamentosService';
import {ProdutoFilter, ProdutoOrcamento} from 'types/orcamento';
import {RestPage} from 'types/prtoheuspage';
import {PAGE_SIZE_SELECT} from 'utils/constants';
import PesquisaAvancada from 'components/pesquisaAvancada';
import * as base64js from 'base64-js';
import {grey} from "@mui/material/colors";
import OrcamentoModalTabelaPrecoRow from "components/orcamento/modals/produtos/row";
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';

type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    handleSelect: (data: ProdutoOrcamento) => void;
    codigotab: string;
    codigoCliente: string;
};

type SearchProps = {
    nome: string;
    codigo: string;
};

const CustomButton = styled(Button)({
    width: '100%',
    height: '100%',
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalProdutos = ({ show,setShow,handleSelect,codigotab,codigoCliente = '',}: Props) => {
    const {
        handleSubmit,
        reset,
        register,
        formState: {errors},
    } = useForm<SearchProps>({defaultValues: {nome: '', codigo: ''}});
    const [page, setPage] = useState<RestPage<ProdutoOrcamento>>();
    const config = useAppSelector(useConfigState);
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [nome, setNome] = useState('');
    const [codigoProd, setCodigoProd] = useState('');
    const [showPesquisaAvancada, setShowPesquisaAvancada] = useState(false);
    const [pesquisa, setPesquisa] = useState<ProdutoFilter[]>([]);
    const [stringPesquisa, setStringPesquisa] = useState<string>('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const xs = useMediaQuery(theme.breakpoints.down("md"));

    const setFilter = (filters: ProdutoFilter[]) => {
        setPesquisa(filters);
        let stringPesquisaTemp = '';
        filters.forEach((filter: ProdutoFilter, index) => {
            stringPesquisaTemp = `${stringPesquisaTemp} ${filter.campo} ${
                filter.condicao
            } '${
                filter.condicao === 'like' || filter.condicao === 'not like'
                    ? '%' + filter.valor.toUpperCase() + '%'
                    : filter.valor.toUpperCase()
            }' ${filter.juncao || ''}`;
        });
        setStringPesquisa(stringPesquisaTemp);
    };

    const handleAction = (originalRow: ProdutoOrcamento) => {
        if (originalRow.qtd === undefined || originalRow.qtd === null || originalRow.qtd === 0) {
            originalRow.qtd = 1;
        }
        handleSelect(originalRow);
        setPageCount(0);
        reset();
    };


    const getProdutos = useCallback(() => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            params: {
                nome,
                codigoProd,
                page: pageCount,
                size: pageSize,
                codigoCliente,
                pesquisa: base64js.fromByteArray(
                    new TextEncoder().encode(stringPesquisa)
                ),
            },
        };
        OrcamentosService.findProdutos(config.empresa.empresaCodigo, codigotab, params)
            .then((res) => {
                res.data.conteudo = res.data.conteudo.map((item: any) => {
                    item.qtd = 1;
                    return item;
                });
                setPage(res.data);
            })
            .catch((err) => {
                toast.error(
                    err.response.data.message || 'Erro ao carregar os produtos'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [
        nome,
        codigoProd,
        pageCount,
        pageSize,
        codigoCliente,
        stringPesquisa,
        config.empresa.empresaCodigo,
        codigotab,
    ]);

    useEffect(() => {
        getProdutos();
    }, [getProdutos]);

    const onSubmit = (data: SearchProps) => {
        setCodigoProd(data.codigo);
        setNome(data.nome);
        setPageCount(0);
    };

    const handleChangePageSize = (e: any) => {
        setPageCount(0);
        setPageSize(e.target.value);
    }

    return (
        <div>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={'xl'}
                fullScreen={fullScreen}
                keepMounted
                onClose={() => setShow(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShow(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>{'Produtos'}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{mt: 1}}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...register('nome')}
                                    error={!!errors.nome}
                                    type={'text'}
                                    label="Descrição"
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.nome?.message}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    {...register('codigo')}
                                    error={!!errors.codigo}
                                    type={'text'}
                                    label="Codigo"
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    helperText={errors.codigo?.message}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <CustomButton type={'submit'} variant="contained" size="large">
                                    Buscar
                                </CustomButton>
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <CustomButton
                                    type={'button'}
                                    variant="contained"
                                    size="large"
                                    onClick={() => setShowPesquisaAvancada(true)}
                                >
                                    Pesquisa Avançada
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{display: 'flex', width: '100%', mt: 3}}>
                        {isLoading && <Loader/>}
                        {!isLoading && page &&
                            <Grid container>
                                {!xs  && <Grid item container sx={{
                                    border: 1,
                                    borderColor: grey[300],
                                    backgroundColor: grey[100],
                                    p: 1,
                                    fontWeight: 800,
                                }}>
                                    <Grid item  xs={1.5} sx={{textAlign: "center"}}>Ações</Grid>
                                    <Grid item  xs={1.5} sx={{textAlign: "center"}}>Imagem</Grid>
                                    <Grid item  xs={1} sx={{textAlign: "center"}}>Qtd</Grid>
                                    <Grid item  xs={2} sx={{textAlign: "center"}}>Cod</Grid>
                                    <Grid item  xs={2} sx={{textAlign: "center"}}>Descrição</Grid>
                                    <Grid item  xs={1} sx={{textAlign: "center"}}>UM</Grid>
                                    <Grid item  xs={1.5} sx={{textAlign: "center"}}>Preço</Grid>
                                    <Grid item  xs={1.5} sx={{textAlign: "center"}}>Estoque</Grid>
                                </Grid> }
                                {page.conteudo.map((row, index) => (
                                    <OrcamentoModalTabelaPrecoRow row={row} handleAction={handleAction} key={index} />
                                ))}
                                <Grid container item xs={12} sx={{pt: 2, display: "flex", alignItems: "center"}}>
                                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                                        <Pagination
                                            count={Math.ceil(parseInt(page?.quantidade) / pageSize)}
                                            defaultPage={1}
                                            page={pageCount + 1}
                                            onChange={(event, value) => {
                                                setPageCount(value - 1);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} sx={{textAlign: "right"}}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Registros por pagina"
                                            onChange={handleChangePageSize}
                                            size="small"
                                            value={pageSize}
                                            sx={{minWidth: 150}}
                                        >
                                            {PAGE_SIZE_SELECT.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>

                            </Grid>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
            {showPesquisaAvancada && (
                <PesquisaAvancada
                    modulo={'ORCAMENTO'}
                    setShow={setShowPesquisaAvancada}
                    show={showPesquisaAvancada}
                    setFilters={setFilter}
                    filters={pesquisa}
                />
            )}
        </div>
    );
};

export default ModalProdutos;
