import {Button, Card, Grid, Tooltip, Typography} from "@mui/material";
import React from "react";
import {Cotacao} from "types/cotacao";

type Props = {
    cotacao: Cotacao;
    handlePreencher: Function
}

const CardCotacao = ({ cotacao, handlePreencher }: Props) => {


    return (
        <Grid item xs={12} sx={{ alignItems: "center" }}>
            <Card variant="elevation" sx={{ p: 2 }}>
                <Grid container alignItems="center">
                    <Grid item sm={5} xs={12}>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary">
                            <b>Numero: </b> {cotacao.numero}
                        </Typography>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary">
                            <b>Filial : </b> {cotacao.filial}
                        </Typography>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary">
                            <b>Proposta Nº: </b> {cotacao.numpro}
                        </Typography>
                        <Typography sx={{ fontSize: 16 }} color="text.secondary">
                            <b>Qtd. Produtos:</b> {cotacao.produtos.length}
                        </Typography>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Tooltip title="Preencher" placement="top">
                            <Button variant={'contained'} onClick={() => handlePreencher(cotacao)}
                                sx={{ width: "100%" }} color={'info'}>
                                Preencher
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}


export default CardCotacao;