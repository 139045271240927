import {AxiosRequestConfig} from "axios";
import {API} from "./Api";

const URL = "/solicitacao-compras";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const SolicitacaoMateriaisService = {
  findAllMateriais: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/produtos`, paramsGeral);
  },
  findAllSolicitacoesArmazem: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/solicitacoes`, paramsGeral);
  },
  saveSolicitacao: (codigo: string, data: any, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.post(`${URL}`, data, paramsGeral);
  },
  findAllSolicitacoes: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}`, paramsGeral);
  },
  findById: (codigo: string, id: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/${id}`, paramsGeral);
  },
  update: (
    codigo: string,
    id: string,
    data: any,
    params?: AxiosRequestConfig
  ) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.put(`${URL}/${id}`, data, paramsGeral);
  },
  findAllCamposFiltroProdutos(codigo: string, params?: AxiosRequestConfig) {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/filtros/produtos`, paramsGeral);
  },
  findCentroCustoFiltro(codigo: string, params?: AxiosRequestConfig) {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/filtros/centroCusto`, paramsGeral);
  },
  findFiliaisFiltro(codigo: string, params?: AxiosRequestConfig) {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/filtros/filiais`, paramsGeral);
  },
  delete: (codigo: string, id: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.delete(`${URL}/${id}`, paramsGeral);
  },
  aprovar: (codigo: string, id: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo };
    else params = { params: { codigo: codigo } };
    paramsGeral = { ...paramsGeral, ...params };
    return API.post(`${URL}/${id}/confirmar`, {}, paramsGeral);
  },
  getPdf: (codigo: string, id: string, params?: AxiosRequestConfig) => {
    if (params) {
      params = { ...params, responseType: "blob", withCredentials: true };
      params.params = { ...params?.params, codigo };
    } else {
      params = {
        params: { codigo },
        responseType: "blob",
        withCredentials: true,
      };
    }
    return API.get(`${URL}/${id}/pdf`, params);
  },
};
