import React, {useLayoutEffect, useRef} from "react";
import {
    Box,
    Collapse,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Slide,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from '@mui/material/transitions';
import './style.css';
import {sortModulos} from "utils/colaborador";
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {useAppSelector} from "store";
import {useAuthState} from "store/auth";

type Props = {
    showModal: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const ModalExemplos = ({showModal, onClose} : Props) => {
    const pre = useRef<HTMLPreElement>();
    const {tokenData} = useAppSelector(useAuthState)
    
    useLayoutEffect(() => {
        if (showModal && pre.current) {
            
            // Em seguida, obtenha o conteúdo de texto desse elemento
            var conteudo = pre.current.innerHTML;
            // Substitua <destaque> por <span class="destaque">
            conteudo = conteudo.replaceAll(/abertura/g, '<b class="destaque">');

            // Substitua </destaque> por </span>
            conteudo = conteudo.replaceAll(/fechamento/g, '</b>');

            // Finalmente, defina o novo conteúdo no elemento
            pre.current.innerHTML = conteudo;
        }
    }, [pre, showModal]);

  return (
    <Dialog
    open={showModal}
    onClose={onClose}
    TransitionComponent={Transition}
    fullWidth={true}
    maxWidth={'xl'}
    fullScreen={true}
    keepMounted={true}
>
    <DialogContent>
        <Box sx={{ mb: 3 }}>
            <Typography color='primary' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                Exemplo Json
            </Typography>
            <IconButton
                aria-label='close'
                onClick={() => onClose()}
                sx={{
                    position: 'absolute',
                    right: 30,
                    top: 10,
                    zIndex: 1,
                    color: theme => theme.palette.grey[500]
                }}>
                <CloseIcon />
            </IconButton>
        </Box>
        <Grid container gap={5} sx={{justifyContent: 'space-between'}}>
            <Grid item xs={12} lg={6.5} sx={{backgroundColor: '#1B1B1B'}}>
                <div>
                <code className="language-json">
                    <pre ref={pre} >
                        {`
    abertura Array de objetos fechamento
    [
        {
            "nome": "Nome do colaborador",abertura ---> Nome do colaborador fechamento
            "email": "emaildocolaborador@teste.com.br", abertura ---> Email do colaborador fechamento
            "cgc": "11122233344", abertura ---> CPF do colaborador fechamento
            "acessos": [
                {
                    "cnpj": "11222333444455", abertura ---> CNPJ da filial fechamento
                    "modulos": [
                        {
                            "nome": "FATURAMENTO", abertura ---> Nome do módulo fechamento
                            "permissoes": ["DANFES", "FATURAS"] abertura ---> Permissões do módulo fechamento
                        },
                        {
                            "nome": "ORCAMENTO", abertura ---> Nome do módulo fechamento
                            "permissoes": [] abertura ---> Permissões do módulo fechamento
                        }
                        
                    ]
                }
            ]
        },
        {
            "nome": "Nome do colaborador", abertura ---> Nome do colaborador fechamento
            "email": "emaildocolaborador@teste.com.br", abertura ---> Email do colaborador fechamento
            "cgc": "11122233344", abertura ---> CPF do colaborador fechamento
            "acessos": [
                {
                    "cnpj": "11222333444455", abertura ---> CNPJ da filial fechamento
                    "modulos": [
                        {
                            "nome": "FATURAMENTO", abertura ---> Nome do módulo fechamento
                            "permissoes": ["DANFES", "FATURAS"] abertura ---> Permissões do módulo fechamento
                        },
                        {
                            "nome": "ORCAMENTO", abertura ---> Nome do módulo fechamento
                            "permissoes": [] abertura ---> Permissões do módulo fechamento
                        }
                        
                    ]
                }
            ]
        }
    ]
                        `}
                    </pre>
                </code>
                </div>
            </Grid>
            <Grid item xs={12} lg={5} sx={{backgroundColor: '#1B1B1B'}}>
                <Typography color='primary' sx={{fontSize: 18, fontWeight: 'bold', p: 2}}>
                    Módulos e permissões disponíveis
                </Typography>
                <List>
                    {tokenData && tokenData.modulos.sort(sortModulos).map((mod) => (
                        <Box key={mod.nome}>
                            <ListItem>
                                <CircleIcon sx={{color: '#C4C7C5', fontSize: 10, mr: 1}} />
                                <Typography color='#C4C7C5' sx={{ fontSize: 14, fontWeight: 'bold'}}>
                                    {mod.nome}
                                </Typography>
                            </ListItem>
                            {mod.permissoes.map((permissao) => (
                                <Collapse key={permissao.nome} in={true} timeout='auto' unmountOnExit>
                                    <List component='div' disablePadding>
                                        <ListItem sx={{ pl: 4 }}>
                                            <KeyboardDoubleArrowRightIcon sx={{color: '#C4C7C5', fontSize: 15, mr: 1}}  />
                                            <Typography color='#C4C7C5' sx={{ fontSize: 12 }}>
                                                {permissao.descricao}
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </Collapse>
                            ))}
                            <Divider variant="middle" textAlign="left" sx={{my: 1, backgroundColor: '#C4C7C5'}} />
                        </Box>
                    ))}
                </List>
            </Grid>
        </Grid>    
       
    </DialogContent>
</Dialog>
  )
}

export default ModalExemplos