import MyCard from "components/card";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useCallback, useLayoutEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, MenuItem, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {AgendamentoService} from "services/AgendamentoService";
import moment from "moment";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

type FormState = {
    data: string;
    descricao: string;
}

const AddExcecoesDatas = () => {
    const {handleSubmit, formState: {errors}, register} = useForm<FormState>();
    const navigate = useNavigate();
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();    const [empresaData, setEmpresaData] = useState("TODOS");
    const [options, setOptions] = useState([]);

    const getEmpresas = useCallback(() => {
        if(!config.parametros) return;
        appDispatch(configActions.setLoading({
            loading: true,
        }));
        AgendamentoService.findEmpresas(config.parametros.codigoUnico)
            .then((response) => {
                setOptions(response.data)
            })
            .catch(() => toast.error("Erro ao buscar empresas !"))
            .finally(() => appDispatch(configActions.setLoading({
                loading: false,
            })));
    }, [appDispatch, config.parametros]);

    useLayoutEffect(() => {
        getEmpresas();
    }, [getEmpresas]);

    const onSubmit = (data: any) => {
        appDispatch(configActions.setLoading({
            loading: true,
        }));
        const configuracoes = {
            params: {
                codigo: config.parametros.codigoUnico,
                tipo: empresaData
            }
        }
        data = {
            ...data,
            data: moment(data.data).format("DD/MM/YYYY"),
        }
        AgendamentoService.createData(data, configuracoes)
            .then(() => {
                toast.success("Salvo com sucesso !");
                navigate("/painel/excecoes-datas")
            })
            .catch(() => toast.error("Erro ao salvar !"))
            .finally(() => appDispatch(configActions.setLoading({
                loading: false,
            })));
    }


    return (
        <MyCard title={"Nova exceção"}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                <TextField
                    {...register('data')}
                    required
                    error={!!errors.data}
                    label="Data"
                    type={"date"}
                    focused
                    variant="outlined"
                    sx={{width: '100%'}}
                    helperText={errors.data?.message}
                />
                <TextField
                    {...register('descricao')}
                    required
                    error={!!errors.descricao}
                    type={'text'}
                    label="Descrição"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.descricao?.message}
                />
                <TextField
                    required
                    type={'text'}
                    label="Empresa"
                    variant="outlined"
                    fullWidth
                    select
                    value={empresaData}
                    onChange={(e) => setEmpresaData(e.target.value)}
                    sx={{mt: 3}}
                >
                    <MenuItem value={"TODOS"}>Todos</MenuItem>
                    {options.map((item: any) => (
                        <MenuItem key={item.codigo} value={item.codigo}>{item.nome}</MenuItem>
                    ))}
                </TextField>
                <Box sx={{mt: 3, textAlign: 'right'}}>
                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                        <SaveIcon/> Salvar
                    </Button>
                </Box>
            </Box>
        </MyCard>
    );
}


export default AddExcecoesDatas;