import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Slide,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import {TransitionProps} from "@mui/material/transitions";
import {AxiosResponse} from "axios";
import Loader from "components/loader";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import {cnpj, cpf} from "cpf-cnpj-validator";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {CepService} from "services/CepService";
import {OrcamentosService} from "services/OrcamentosService";
import {ConsumidorOrcamento} from "types/orcamento";

type Props = {
  show: boolean;
  setShow: (show: boolean) => void;
  handleSelect: (data: ConsumidorOrcamento) => void;
};

type ClienteForms = {
  nome: string;
  nomeRed: string;
  tipo: string;
  inscr: string;
  mail: string;
  cgc: string;
  ddd: string;
  tel: string;
  telex: string;
  estado: string;
  end: string;
  numero: string;
  codMun: string;
  cidade: string;
  bairro: string;
  cep: string;
  complem: string;
  campo1: string;
  campo2: string;
  campo3: string;
  campo4: string;
};

type ClientSave = {
  nome: string;
  nomeRed: string;
  tipo: string;
  inscr: string;
  mail: string;
  cgc: string;
  ddd: string;
  tel: string;
  telex: string;
  endereco: string;
  estado: string;
  codMun: string;
  cidade: string;
  bairro: string;
  cep: string;
  complem: string;
  campo1: string;
  campo2: string;
  campo3: string;
  campo4: string;
};

type cepResponse = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalCadastrarConsumidor = ({ show, setShow, handleSelect }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<ClienteForms>({
    defaultValues: {
      nome: "",
      nomeRed: "",
      tipo: "",
      inscr: "",
      mail: "",
      cgc: "",
      ddd: "",
      tel: "",
      telex: "",
      estado: "",
      end: "",
      numero: "",
      codMun: "",
      cidade: "",
      bairro: "",
      cep: "",
      complem: "",
      campo1: "",
      campo2: "",
      campo3: "",
      campo4: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const config = useAppSelector(useConfigState);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const saveCliente = (data: ClienteForms) => {
    setIsLoading(true);
    const consumidor: ClientSave = {
      ...data,
      endereco: data.end + ", " + data.numero,
      cep: data.cep ? data.cep.replace(/\D/g, "") : "",
      tel: data.tel ? data.tel.replace(/\D/g, "") : "",
      telex: data.telex ? data.telex.replace(/\D/g, "") : "",
      cgc: data.cgc ? data.cgc.replace(/\D/g, "") : "",
      inscr: data.inscr ? data.inscr : "ISENTO",
    };
    OrcamentosService.createConsumidor(config.empresa.empresaCodigo, consumidor)
      .then((res) => {
        handleSelect(res.data);
        toast.success("Cliente adicionado com sucesso!");
        reset();
        setShow(false);
      })
      .catch((err) =>
        toast.error(
          err.response.data.message || "Erro ao cadastrar o consumidor"
        )
      )
      .finally(() => setIsLoading(false));
  };

  const onSubmit = (data: ClienteForms) => {
    let cgcTemp = data.cgc.replace(/\D/g, "");
    if (cgcTemp.length === 11) {
      data.tipo = "F";
    } else if (cgcTemp.length === 14) {
      data.tipo = "J";
    }

    if (verifyCGC(data.cgc)) {
      saveCliente(data);
    }
  };

  const onChangeCep = (value: string) => {
    value = value.replace(/\D/g, "");
    if (value.length === 8) {
      CepService.get(value).then((res: AxiosResponse<cepResponse>) => {
        if (res.data.ibge)
          setValue("codMun", res.data.ibge.slice(2, res.data.ibge.length));
        if (res.data.bairro) setValue("bairro", res.data.bairro);
        if (res.data.uf) setValue("estado", res.data.uf);
        if (res.data.localidade) setValue("cidade", res.data.localidade);
        if (res.data.logradouro) setValue("end", res.data.logradouro);
      });
    }
    if (value.length > 8) {
      toast.warn("CEP inválido");
    }
  };

  const verifyCGC = (value: string) => {
    const cgc = value.replace(/\D/g, "");
    if (cgc.length === 14) {
      if (!cnpj.isValid(cgc)) {
        toast.warn("CNPJ inválido!");
        return false;
      }
    }
    if (cgc.length === 11) {
      if (!cpf.isValid(cgc)) {
        toast.warn("CPF inválido!");
        return false;
      }
    }
    return true;
  };

  const handleCancel = () => {
    setShow(false);
    reset();
  };

  return (
    <div>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={fullScreen}
        keepMounted
        onClose={() => setShow(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          aria-label="close"
          onClick={() => setShow(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{"Cadastrar consumidor"}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Loader />
          ) : (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.nome}
                        label="Nome"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.nome?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="nomeRed"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.nomeRed}
                        label="Nome Fantasia"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.nomeRed?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="tipo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.tipo}
                        required
                        select
                        label="Tipo"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.tipo?.message}
                      >
                        <MenuItem value={"F"}>Fisica</MenuItem>
                        <MenuItem value={"J"}>Juridica</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="cgc"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.cgc}
                        label="CPF/CNPJ"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.cgc?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="mail"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.mail}
                        label="E-mail"
                        type={"email"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.mail?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="inscr"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.inscr}
                        label="Inscrição municipal"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.inscr?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="ddd"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.ddd}
                        label="DDD"
                        type={"number"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.ddd?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="tel"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.tel}
                        label="Telefone 1"
                        type={"number"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.tel?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="telex"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.telex}
                        type={"number"}
                        label="Telefone 2"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.telex?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="cep"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.cep}
                        label="CEP"
                        type={"number"}
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.cep?.message}
                        onChange={(e) => {
                          field.onChange(e);
                          onChangeCep(e.target.value);
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="end"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.end}
                        label="Endereço"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.end?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="numero"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.numero}
                        label="Numero"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.numero?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="complem"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.complem}
                        label="Complemento"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.complem?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="bairro"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.bairro}
                        label="Bairro"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.bairro?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="estado"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.estado}
                        label="Estado"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.estado?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="cidade"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        error={!!errors.cidade}
                        label="Municipio"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.cidade?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="campo1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.campo1}
                        label="Campo extra 1"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.campo1?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="campo2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.campo2}
                        label="Campo extra 2"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.campo2?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="campo3"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.campo3}
                        label="Campo extra 3"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.campo3?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="campo4"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.campo4}
                        label="Campo extra 4"
                        variant="outlined"
                        sx={{ width: "100%" }}
                        helperText={errors.campo4?.message}
                      />
                    )}
                  />
                </Grid>
                <input
                  type="hidden"
                  placeholder="Código Municipio"
                  {...register("codMun", { required: true })}
                />
                <Grid item xs={12} md={12} sx={{ textAlign: "right" }}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    type={"submit"}
                    sx={{ mr: 1 }}
                  >
                    <SaveIcon /> Salvar
                  </Button>
                  <Button
                    variant={"contained"}
                    color={"error"}
                    type={"button"}
                    onClick={handleCancel}
                  >
                    <CloseIcon /> Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalCadastrarConsumidor;
