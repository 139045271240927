import MyCard from "components/card";
import React, {useCallback, useLayoutEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {toast} from "react-toastify";
import {AxiosRequestConfig} from "axios";
import {RestPage} from "types/prtoheuspage";
import {styled} from "@mui/material/styles";
import Format from "utils/formatadores";
import download from "downloadjs";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from '@mui/icons-material/Download';
import {toastError} from "utils/toastError";
import {DanfesService} from "services/DanfesService";
import {Danfe} from "types/danfes";
import {PAGE_SIZE_SELECT} from "utils/constants";
import moment from "moment";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

const SendButton = styled( Button )( {
    width: "100%",
    height: "100%",
} );

const ListDanfes = () => {
    const { handleSubmit, register, reset } = useForm()
    const [page, setPage] = useState<RestPage<Danfe>>();
    const config = useAppSelector(useConfigState);
    const [pageSize, setPageSize] = useState( '10' );
    const [pageCount, setPageCount] = useState( '1' );
    const [dataini, setDataini] = useState( '' );
    const [datafin, setDatafin] = useState( '' );
    const appDispatch = useAppDispatch();

    const loadDanfes = useCallback( () => {
        if ( !dataini || !datafin ) {
            return;
        }
        const params: AxiosRequestConfig = {
            params: {
                page: pageCount,
                size: pageSize,
                codigo: config.empresa.empresaCodigo,
                dataini: dataini,
                datafim: datafin
            }
        }
        appDispatch(configActions.setLoading({loading: true}))
        DanfesService.findAll( config.parametros.codigoUnico, config.empresa.empresaCodigo, params )
            .then( res => {
                if ( res.data.status === "204" ) {
                    toast.warn( "Nenhum resultado encontrado" );
                    setPage( null )
                } else {
                    setPage( res.data );
                }
            } )
            .catch( err => {
                toastError( err, "Erro ao buscar os dados" );
            } )
            .finally( () => {
                appDispatch(configActions.setLoading({loading: false}));                
                setDataini( "" );
                setDatafin( "" );
            } )
    }, [appDispatch, config.empresa.empresaCodigo, config.parametros.codigoUnico, datafin, dataini, setDataini, setDatafin, pageCount, pageSize] );

    useLayoutEffect( () => {
        loadDanfes();
    }, [loadDanfes] );

    const onSubmit = ( data: any ) => {
        if ( !data.datainiField || !data.datafinField ) {
            toast.warning( "Informe o período para busca!" );
            return;
        }
        setDataini( moment( data.datainiField ).format( "YYYYMMDD" ) );
        setDatafin( moment( data.datafinField ).format( "YYYYMMDD" ) );
    }

    // const handlePrint = (chave: any) => {
    //     setIsLoading(true);
    //     DanfesService.getFile(chave, empresa.empresaCodigo, 1)
    //         .then(res => {
    //             const file = new Blob([res.data], {type: 'application/pdf'});
    //             const url = URL.createObjectURL(file);
    //             window.open(url, "_blank");
    //         })
    //         .catch((err) => {
    //             toast.error(err.response.data.message);
    //         })
    //         .finally(() => setIsLoading(false));
    // }

    const handleDownloadXml = ( chave: any ) => {
        appDispatch(configActions.setLoading({loading: true}))
        DanfesService.getFile( chave, config.empresa.empresaCodigo, 2 )
            .then( res => {
                const file = new Blob( [res.data], { type: 'application/xml' } );
                download( file, chave + ".xml", "application/xml" );
            } )
            .catch( ( err ) => {
                toast.error( err.response.data.message );
            } )
            .finally( () => {
                appDispatch(configActions.setLoading({loading: false}))
            } );
    }


    const loadPdf = ( chave: any ) => {
        appDispatch(configActions.setLoading({loading: true}))
        DanfesService.getFile( chave, config.empresa.empresaCodigo, 1 )
            .then( res => {
                const file = new Blob( [res.data], { type: 'application/pdf' } );
                if ( file ) download( file, chave + ".pdf", "application/pdf" );
            } )
            .catch( ( err ) => {
                toast.error( err.response.data.message );
            } )
            .finally( () => {
                appDispatch(configActions.setLoading({loading: false}))
            } );
    }

    const handleChangePageSize = ( e: any ) => {
        setPageCount( "1" );
        setPageSize( e.target.value );
    }


    return (
        <MyCard title={"Listar danfes"}>
            <Box
                sx={{ mt: 1 }}
                component="form"
                noValidate
                onSubmit={handleSubmit( onSubmit )}
            >
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register( "datainiField" )}
                            type={"date"}
                            label="Data de emissão inicial"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: "9999-12-31"
                            }}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register( "datafinField" )}
                            type={"date"}
                            label="Data de emissão final"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: "9999-12-31"
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <SendButton type={"submit"} variant="contained" size="large">
                            Buscar
                        </SendButton>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <SendButton
                            type={"button"}
                            variant="contained"
                            size="large"
                            color={"error"}
                            onClick={() => {
                                setDataini( "" );
                                setDatafin( "" );
                                setPageCount( "1" );
                                setPageSize( "10" );
                                reset();
                                setPage( undefined )
                            }}
                        >
                            Limpar
                        </SendButton>
                    </Grid>
                </Grid>
            </Box>
            <Grid container sx={{ mt: 2 }} spacing={2}>
                {page && (
                    page.conteudo.map( ( item: any ) => (
                        <Grid item xs={12} sx={{ alignItems: "center" }} key={item.chave}>
                            <Card variant="elevation" sx={{ p: 2 }}>
                                <Grid container spacing={.5}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                            <b>Documento:</b> {item.documento}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                            <b>Serie:</b> {item.serie}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                            <b>Emissão:</b> {Format.data( item.emissao )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                            <b>Chave:</b> {item.chave}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontSize: 15 }} color="text.secondary">
                                            <b>Valor:</b> {Format.moeda( item.valor )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Tooltip title="Imprimir" placement="top">
                                            <Button variant={'contained'} onClick={() => loadPdf( item.chave )}
                                                sx={{ marginRight: 1, }}>
                                                <PrintIcon sx={{ mr: .5 }} /> PDF
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Download XML" placement="top">
                                            <Button variant={'contained'} onClick={() => handleDownloadXml( item.chave )}
                                                sx={{ marginRight: 1, }}>
                                                <DownloadIcon sx={{ mr: .5 }} /> XML
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    ) )
                )}
                <Grid container item xs={12}>
                    <Grid item md={4} xs={12}>
                        {page && page.conteudo?.length > 0 &&
                            <Pagination
                                count={Math.ceil( parseInt( page.quantidade ) / parseInt( pageSize ) )}
                                defaultPage={1}
                                page={parseInt( pageCount )}
                                onChange={( event, value ) => {
                                    setPageCount( `${value}` );
                                }}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {page && parseInt( page.quantidade ) !== 0 &&
                            <Typography sx={{ textAlign: "center" }}>Total de registros: {page.quantidade}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ textAlign: { md: "right" } }}>
                        <FormControl sx={{ minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-label">Registros por pagina</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={pageSize}
                                label="Registros por pagina"
                                onChange={handleChangePageSize}
                                size={"small"}
                            >
                                {PAGE_SIZE_SELECT.map( ( option ) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ) )}
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>
            </Grid>
        </MyCard>
    );
}

export default ListDanfes;