import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/boletos";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const BoletoService = {
  findAll: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = { ...paramsGeral.params, codigo };
    return API.get(URL, paramsGeral);
  },
  getFile: (recno: string, codigo: string, params?: AxiosRequestConfig) => {
    if (params) {
      params = { ...params, responseType: 'blob', withCredentials: true }
      params.params = { ...params?.params, codigo }
    }
    else {
      params = { params: { codigo }, responseType: 'blob', withCredentials: true }
    }
    return API.get(`${URL}/file/${recno}`, params);
  },
}