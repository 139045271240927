import {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import HelpIcon from '@mui/icons-material/Help';
import {useForm} from 'react-hook-form';
import {Validadores} from 'utils/validadores';
import {useNavigate, useParams} from 'react-router-dom';
import {CadastroExternoService} from 'services/CadastroExternoService';
import {toast} from 'react-toastify';
import {useAppDispatch} from 'store';
import {configActions} from 'store/config';
import {LinkPersonalizado} from 'types/cadastroExterno';
import {toastError} from 'utils/toastError';
import Campos from "components/aprovacao-cadastro/campos";
import { AxiosError } from 'axios';

type Props = {
    handleNext: Function;
}

type UsuarioForm = {
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    telefone: string;
    status: string;
    cpf: string;
    razaoSocial: string;
    cnpj: string;
}

export const PrimeiraTela = ({handleNext}: Props) => {
    const [dados, setDados] = useState<LinkPersonalizado>();
    const navigate = useNavigate();
    const {id} = useParams();
    const appDispatch = useAppDispatch();

    const {handleSubmit, formState: {errors}, getValues, register, control} = useForm<UsuarioForm>({mode: 'all'});

    const onSubmit = (data: any) => {

        if(data.nome.trim().length === 0) {
            toast.error('O nome é obrigatório !');
            return;
        }

        if(data.telefone.trim().length === 0) {
            toast.error('O telefone é obrigatório !');
            return;
        }

        if(data.email.trim().length === 0) {
            toast.error('O e-mail é obrigatório !');
            return;
        }

        if(data.cpf.trim().length === 0) {
            toast.error('O CPF é obrigatório !');
            return;
        }

        if(data.password.trim().length === 0) {
            toast.error('A senha é obrigatória !');
            return;
        }

        if(data.confirm_senha.trim().length === 0) {
            toast.error('A confirmação de senha é obrigatória !');
            return;
        }

        if(data.password !== data.confirm_senha) {
            toast.error('As senhas não são iguais.');
            return;
        }

        const formData = {
            id: dados?.id,
            password: data.password,
            nome: data.nome,
            email: data.email,
            telefone: data.telefone.replace(/\D+/g,''),
            cpf: data.cpf.replace(/\D+/g,''),
            status: true,
            acessos: dados?.acessos,
            razaoSocial: data.razaoSocial,
            cnpj: data.cnpj.replace(/\D+/g,''),
            campos: dados?.campos.map((campo) => {
                return Object.assign(campo, { resposta: data[campo.campo]});
            })
        }

        appDispatch(configActions.setLoading({loading: true,}));
        CadastroExternoService.createCadastro(formData, {params: {linkId: dados.id}})
            .then((res) => {
                toast.success(`Cadastro enviado com sucesso !`);
                handleNext();
            })
            .catch((err: AxiosError<{error: string, message: string, status: number, time: string, path: string}>) => {
                if(err.response.status === 503){
                    toast.warn(`${err.response.data.message || 'Erro ao enviar email !'}`);
                    handleNext();
                }else{
                    toastError(err, "Erro ao salvar !")
                }
            })
            .finally(() => {
                appDispatch(configActions.setLoading({loading: false,}));
            });
       
    }

    useEffect(() => {
        if (!id) {
           navigate('/cadastro')
        }
    }, [id, navigate])

    const findById = useCallback((id:string) => {
        if (id) {
            appDispatch(configActions.setLoading({loading: true,}));
            CadastroExternoService.findByIdLink(id)
                .then(res => {
                    setDados(res.data)
                })
                .catch(err => {
                    navigate('/cadastro/nao-encontrada')
                    toast.error('Endereço não encontrado !');
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({
                        loading: false,
                    }));
                });
        }
    }, [appDispatch, navigate]);

    useEffect(() => {
        if (id) {
            findById(id)
        }
    }, [findById, id])

    return  (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography component="h3" variant="h5" textAlign={'center'} color={'primary'} sx={{ fontSize: 16, fontWeight: 'bold' }} >
                {dados ? dados.descricao : 'Cadastro de usuário'}
            </Typography>
            <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                <Grid item xs={12}>
                    <TextField
                        {...register("nome")}
                        required
                        size='small'
                        error={!!errors.nome}
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        helperText={errors.nome?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register("telefone")}
                        required
                        size='small'
                        error={!!errors.telefone}
                        label="Nº Telefone"
                        variant="outlined"
                        fullWidth
                        helperText={errors.telefone?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register("email",  {validate: (value) => Validadores.email(value)})}
                        required
                        size='small'
                        error={!!errors.email}
                        type={'email'}
                        label="E-mail"
                        variant="outlined"
                        fullWidth
                        helperText={errors.email?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register('cpf', {validate: (value) => Validadores.cgc(value),})}
                        error={!!errors.cpf}
                        required
                        size='small'
                        type={'text'}
                        label="CPF"
                        variant="outlined"
                        fullWidth
                        helperText={errors.cpf?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register("password", {validate: (value) => Validadores.senha(value)})}
                        error={!!errors.password}
                        required
                        size='small'
                        type={'password'}
                        label="Senha"
                        variant="outlined"
                        fullWidth
                        helperText={errors.password?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register("confirm_senha", {
                            validate: (value) => {
                                const {password} = getValues();
                                if (value !== password) return 'As senhas não são iguais.'
                            }
                        })}
                        error={!!errors.confirm_senha}
                        required
                        size='small'
                        type={'password'}
                        label="Confirmar senha"
                        variant="outlined"
                        fullWidth
                        helperText={errors.confirm_senha?.message}
                    />
                </Grid>
                {dados && dados.campos.length > 0 &&
                    <>
                        <Grid item xs={12}>
                            <Typography component="h3" variant="h5" textAlign={'center'} color={'primary'} sx={{ fontSize: 14, fontWeight: 'bold', mt: 2 }} >
                                Informações Adicionais
                            </Typography>
                        </Grid>
                        {dados.campos.map((campo) => (
                            <Campos campo={campo} register={register} errors={errors} key={campo.campo} control={control} />
                        ))}
                    </>
                }
                <Grid item xs={12}>
                    <Typography component="h3" variant="h5" textAlign={'center'} color={'primary'} sx={{ fontSize: 14, fontWeight: 'bold', mt: 2 }} >
                        Dados Corporativos
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register('razaoSocial')}
                        error={!!errors.razaoSocial}
                        required
                        size='small'
                        type={'text'}
                        label="Razão Social"
                        variant="outlined"
                        fullWidth
                        helperText={errors.razaoSocial?.message}
                        InputProps={{
                            endAdornment:
                                <Tooltip title="Nome oficial da empresa" placement="right">
                                    <IconButton aria-label="toggle password visibility" edge="end">
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register('cnpj', {validate: (value) => Validadores.cgc(value),})}
                        error={!!errors.cnpj}
                        required
                        size='small'
                        type={'text'}
                        label="CPF/CNPJ"
                        variant="outlined"
                        fullWidth
                        helperText={errors.cnpj?.message}
                        InputProps={{
                            endAdornment:
                                <Tooltip title="Se você não possui CNPJ corporativo, favor preencher com seu CPF" placement="right">
                                    <IconButton aria-label="toggle password visibility" edge="end">
                                        <HelpIcon />
                                    </IconButton>
                                </Tooltip>
                        }}
                    />
                </Grid> 
                <Grid item xs={12} sx={{mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Button variant={'contained'} color={'primary'} type={'submit'} fullWidth>
                        <SaveIcon sx={{mr: 1}}/> Enviar formulário
                    </Button>
                </Grid>
            </Grid>  
        </Box>
    )
}