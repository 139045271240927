import {Avatar, Box, Grid, Typography} from "@mui/material";
import LoginForm from "components/loginForm";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Copyright from "components/copyright";
import {Link} from "react-router-dom";

const Login = () => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "Center",
                alignItems: "center",
            }}
        >
            <Avatar sx={{m: 1, bgcolor: "primary.main"}}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">Log in</Typography>
            <LoginForm/>
            <Grid container>
                <Grid item xs>
                    <Link to="/auth/esqueci-a-senha">Esqueci a senha</Link>
                    <Copyright sx={{p: 5}}/>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Login;