import {Grid, useTheme} from "@mui/material";
import Atendimento from "components/crm/atendimentos";
import BaseCard from "components/crm/baseCard";
import Home from "components/crm/home";
import {Funil} from "components/crm/funil";
import AddOrcamentos from "components/crm/orcamento";
import {useCrm} from "contexts/CrmContext";
import {useAppSelector} from "store";
import {hasModuloPermission, useAuthState} from "store/auth";
import {useConfigState} from "store/config";

const CrmLeads = () => {
    const theme = useTheme();
    const {screen} = useCrm();
    const appSelector = useAppSelector(useAuthState)
    const config = useAppSelector(useConfigState)

    return (
        <BaseCard title={"CRM - " + screen}>
            <Grid container sx={{
                padding: theme.spacing(2),
                display: 'flex',
                flex: '1 1 auto',
                height: '100%'
            }}
                  spacing={3}
            >
                {screen === 'Início' && <Home/>}
                {screen === 'Atendimento' && <Atendimento/>}
                {screen === 'Funil' && <Funil/>}
                {screen === 'Orçamento' && hasModuloPermission(appSelector, config,"ORCAMENTO") && <AddOrcamentos/>}
            </Grid>
        </BaseCard>
    );
}

export default CrmLeads;