import {Backdrop, Box, Typography} from '@mui/material';
import loadingLogo from 'assets/imgs/loading.gif';
import CircularProgress from "@mui/material/CircularProgress";
import {ReactNode, useEffect, useState} from "react";
import {ConfigUtils} from "utils/config";
import {useConfigState} from 'store/config';
import {useAppSelector} from 'store';


const Loader = () => {
    const [loader, setLoader] = useState<ReactNode>();
    const  {loading} = useAppSelector(useConfigState);

    useEffect(() => {
        const host = window.location.hostname;
        if(!ConfigUtils.verifyDominio(host)) {
            setLoader(<CircularProgress color="inherit" size={125}/>)
        }else{
            setLoader(<img src={loadingLogo} alt="loading..." width={150} />)
        }
    }, [])

    return (
        <Backdrop
            sx={{ color: '#1976D2', zIndex: (theme) => theme.zIndex.drawer + 10000, backgroundColor: 'rgba(255,255,255,0.8)' }}
            open={loading}
        >
            <Box sx={{alignItems: "center", alignContent: "center", textAlign: "center", justifyContent: 'center'}}>
                {loader}
                <Typography fontSize={20}>Carregando...</Typography>
            </Box>
        </Backdrop>
    );
}


export default Loader;