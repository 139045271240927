import {CrmLead} from "types/crm";

const CrmUtils = {
  reducerLeads: (state: CrmLead[], action: any) => {
    switch (action.type) {
      case "add":
        return [...state, action.payload];
      case "getbyid":
        return state.filter((lead: CrmLead) => lead.codigo === action.payload.id);
      case "clear":
        return [];
      case "update":
        return state.map((item: CrmLead) => {
          if (item.codigo === action.payload.id) {
            item.status = action.payload.status;
            return item;
          }
          return item;
        });
      default:
        return state;
    }
  }
}

export default CrmUtils;