import {grey} from "@mui/material/colors";
import {Button, Grid, Theme, Typography, useMediaQuery} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import {CondicaoOrcamento} from "types/orcamento";

type Props = {
    row: CondicaoOrcamento;
    handleAction: (data: CondicaoOrcamento) => void;
}

const OrcamentoModalCondicoesRow = ({row, handleAction}: Props) => {
    const xs = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    if(xs){
        return (
            <Grid item container sx={{
                border: 1,
                borderColor: grey[400],
                p: 1,
                display: "flex",
                alignItems: "center",
                mb:2,
                borderRadius: 1
            }}>
                <Grid item xs={12} sx={{textAlign: "center"}}>
                    <Typography variant={"body1"} fontSize={14}><b>Código: </b> {row.codigo}</Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign: "center"}}>
                    <Typography variant={"body1"} fontSize={14}><b>Descrição: </b> {row.descricao}</Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign: "center"}}>
                    <Typography variant={"body1"} fontSize={14}><b>Forma: </b> {row.forma}</Typography>
                </Grid>
                <Grid item xs={12} sx={{textAlign: "center"}}>
                    <Button variant="contained" size={"small"} fullWidth={true} onClick={() => handleAction(row)}>
                        <AddIcon/>
                    </Button>
                </Grid>
            </Grid>
        );
    }else{
        return (
            <Grid item  container sx={{
                border: 1,
                borderColor: grey[300],
                p: 1,
                display: "flex",
                alignItems: "center"
            }}>
                <Grid item md sx={{textAlign: "center"}}>
                    <Button variant="contained" size={"small"}
                            onClick={() => handleAction(row)}>
                        <AddIcon/>
                    </Button>
                </Grid>
                <Grid item md sx={{textAlign: "center"}}>
                    {row.codigo}
                </Grid>
                <Grid item md sx={{textAlign: "center"}}>
                    {row.descricao}
                </Grid>
                <Grid item md sx={{textAlign: "center"}}>
                    {row.forma}
                </Grid>
            </Grid>
        );
    }

}


export default OrcamentoModalCondicoesRow;