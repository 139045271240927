import {Box, Button, Grid, MenuItem, Pagination, TextField, Typography} from "@mui/material";
import MyCard from "components/card"
import Loader from "components/loader";
import React, {useCallback, useEffect, useState} from "react";
import {RestPage} from "types/prtoheuspage";
import {toast} from "react-toastify";
import {BoletoService} from "services/BoletoService";
import CardBoleto from "components/boletos/card";
import {Boleto} from "types/boletos";
import {PAGE_SIZE_SELECT} from "utils/constants";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import {styled} from "@mui/material/styles";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

const SendButton = styled( Button )( {
    width: "100%",
    height: "100%",
} );

const ListBoletos = () => {
    const { handleSubmit, register, reset } = useForm()
    const [pageData, setPageData] = useState<RestPage<Boleto>>();
    const config = useAppSelector(useConfigState);
    const [pageSize, setPageSize] = useState( '10' );
    const [pageCount, setPageCount] = useState( '1' );
    const [dataini, setDataini] = useState( "" );
    const [datafin, setDatafin] = useState( "" );
    const appDispatch = useAppDispatch();

    const loadData = useCallback( () => {
        if ( !dataini || !datafin ) {
            return;
        }
        appDispatch(configActions.setLoading({loading: true}))
        let configuracoes = {
            params: {
                page: pageCount,
                size: pageSize,
                codigo: config.empresa.empresaCodigo,
                dataini: dataini,
                datafim: datafin
            }
        }
        BoletoService.findAll( config.empresa.empresaCodigo, configuracoes )
            .then( ( res ) => {
                setPageData( res.data )
            } )
            .catch( ( err ) => {
                toast.error( err.response.data.message );
            } )
            .finally( () => {
                appDispatch(configActions.setLoading({loading: false}))
                setDataini( "" );
                setDatafin( "" );
        });
    }, [appDispatch, config.empresa.empresaCodigo, datafin, dataini, setDataini, setDatafin, pageCount, pageSize] )

    useEffect( () => {
        loadData();
    }, [loadData] );

    const onSubmit = ( data: any ) => {
        if ( !data.datainiField || !data.datafinField ) {
            toast.warning( "Informe o período para busca!" );
            return;
        }
        setDataini( moment( data.datainiField ).format( "YYYYMMDD" ) );
        setDatafin( moment( data.datafinField ).format( "YYYYMMDD" ) );
    }

    const handleChangePageSize = ( e: any ) => {
        setPageCount( "1" );
        setPageSize( e.target.value );
    }

    return (
        <MyCard title={"Listar boletos"}>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit( onSubmit )}
                sx={{ mt: 1, mb: 2 }}
            >
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register( "datainiField" )}
                            type={"date"}
                            label="Data de vencimento inicial"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: "9999-12-31"
                            }}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register( "datafinField" )}
                            type={"date"}
                            label="Data de vencimento final"
                            variant="outlined"
                            sx={{ width: "100%" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                max: "9999-12-31"
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <SendButton type={"submit"} variant="contained" size="large">
                            Buscar
                        </SendButton>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <SendButton
                            type={"button"}
                            variant="contained"
                            size="large"
                            color={"error"}
                            onClick={() => {
                                setDataini( "" );
                                setDatafin( "" );
                                setPageCount( "1" );
                                setPageSize( "10" );
                                reset();
                                setPageData( undefined )
                            }}
                        >
                            Limpar
                        </SendButton>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={2}>
                {<Loader />}
                {pageData &&
                    <>
                        {pageData.conteudo.map( boleto => <CardBoleto key={boleto.recno} boleto={boleto}
                            setLoading={(loading) => appDispatch(configActions.setLoading({loading: loading}))} /> )}
                    </>
                }
                <Grid container item xs={12} sx={{ pt: 2 }}>
                    <Grid item md={4} xs={12}>
                        {pageData && pageData.conteudo?.length > 0 &&
                            <Pagination
                                count={Math.ceil( parseInt( pageData.quantidade ) / parseInt( pageSize ) )}
                                defaultPage={1}
                                page={parseInt( pageCount )}
                                onChange={( event, value ) => {
                                    setPageCount( `${value}` );
                                }}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {pageData && parseInt( pageData.quantidade ) !== 0 && <Typography sx={{ textAlign: "center" }}>Total de
                            registros: {pageData.quantidade}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ textAlign: { md: "right" } }}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Registros por pagina"
                            onChange={handleChangePageSize}
                            size="small"
                            value={pageSize}
                            sx={{ minWidth: 150 }}
                        >
                            {PAGE_SIZE_SELECT.map( ( option ) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ) )}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
        </MyCard>
    )
}

export default ListBoletos