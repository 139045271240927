import {AccordionSummary, Divider, Grid, Typography, useMediaQuery} from '@mui/material';
import {Cotacao, Documento, Valores} from 'types/documentos';
import {useAppDispatch, useAppSelector} from 'store';
import {configActions, useConfigState} from 'store/config';
import {toastError} from 'utils/toastError';
import {useCallback, useEffect, useState} from 'react';
import Format from 'utils/formatadores';
import {styled, useTheme} from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {AprovacaoDocumentoService} from 'services/AprovacaoDocumentosService';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';


type Props = {
    documento: Documento;
    tipoExt?: string;
}

const TypoBigLetter = styled(Typography)({
    fontSize: 14,
    lineHeight: 2,
    paddingRight: 10
});

const ComparaCotacao = ({ documento }: Props) => {
    const [cotas, setCotas] = useState<Cotacao[]>();
    const [bestOffer, setBestOffer] = useState<string[]>();
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('md'));


    const loadColumns = useCallback(() => {
        appDispatch(configActions.setLoading({
            loading: true,
        }));
        AprovacaoDocumentoService.lstCotas(config.empresa.empresaCodigo, documento.codDocumento)
            .then(res => {
                setCotas(res.data.conteudo);
                setBestOffer(thisIsDWinner(res.data.conteudo));
            })
            .catch((err) => toastError(err, "Erro ao carregar cotações"))
            .finally(() => appDispatch(configActions.setLoading({
                loading: false,
            })));
    }, [appDispatch, config.empresa.empresaCodigo, documento.codDocumento]);


    const thisIsDWinner = (data: Cotacao[]) => {
        let dBest: any[] = [];
        if (data) {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < data[i]['itens'].length; j++) {
                    let help: any[];
                    if (data[i]['itens'][j]['pedido'] && !/[A-Z]/.test(data[i]['itens'][j]['pedido'])) {
                        help = [data[i]['itens'][j]['itemPedido'], data[i]['itens'][j]['valor'], data[i]['itens'][j]['total'], data[i]['nomeEmpresa'], data[i]['itens'][j]['descricao'], data[i]['itens'][j]['produtor'], data[i]['itens'][j]['quantidade']];
                        dBest.push(help);
                    }
                }
            }
        }
        return dBest.sort((a, b) => a[0] < b[0] ? -1 : 1);
    }

    const testTrofeu = (c: Valores[]) => {
        let result: boolean = false;
        if (c) {
            for (let i = 0; i < c.length; i++) {
                if (c[i].pedido && !/[A-Z]/.test(c[i].pedido)) {
                    result = true;
                }
            }
        }
        return result;
    }


    useEffect(() => {
        loadColumns();
    }, [loadColumns]);

    return (
        <>
            {cotas ?
                <Grid container item spacing={2} xs={12} sx={{mb:2}}>
                    <Grid item xs={12}>
                        <Accordion elevation={2}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={'Melhor Oferta'}
                                id={'Melhor Oferta'}
                                sx={{backgroundColor: "#90FDCF"}}
                            >
                                <Typography fontWeight={800} fontSize={16} textTransform={"uppercase"}>Melhor Oferta</Typography>
                            </AccordionSummary>
                            {bestOffer && bestOffer.map((prod, i) => (
                                <>
                                    <Grid item container xs={12} key={i} p={2} spacing={2}>
                                        <Grid item md={1.5} xs={6}>
                                            {(i < 1 || xs) && <TypoBigLetter><b>Item/Cod.</b></TypoBigLetter>}
                                            <TypoBigLetter>{`${prod[0]} - ${prod[5]}`}</TypoBigLetter>
                                        </Grid>
                                        <Grid item md={3} xs={6}>
                                            {(i < 1 || xs) && <TypoBigLetter><b>Descrição</b></TypoBigLetter>}
                                            <TypoBigLetter>{prod[4]}</TypoBigLetter>
                                        </Grid>
                                        <Grid item md={3} xs={6}>
                                            {(i < 1 || xs) && <TypoBigLetter><b>Empresa</b></TypoBigLetter>}
                                            <TypoBigLetter>{prod[3]}</TypoBigLetter>
                                        </Grid>
                                        <Grid item md={1.5} xs={6}>
                                            {(i < 1 || xs) && <TypoBigLetter><b>Qtd.</b></TypoBigLetter>}
                                            <TypoBigLetter>{prod[6]}</TypoBigLetter>
                                        </Grid>
                                        <Grid item md={1.5} xs={6}>
                                            {(i < 1 || xs) && <TypoBigLetter><b>Valor Unitario</b></TypoBigLetter>}
                                            <TypoBigLetter>{Format.moeda(prod[1])}</TypoBigLetter>
                                        </Grid>
                                        <Grid item md={1.5} xs={6}>
                                            {(i < 1 || xs) && <TypoBigLetter><b>Valor Total</b></TypoBigLetter>}
                                            <TypoBigLetter>{Format.moeda(prod[2])}</TypoBigLetter>
                                        </Grid>
                                    </Grid>
                                    {xs && <Divider sx={{ my: 0.5 }} />}
                                </>
                            ))}
                        </Accordion>
                        {cotas && cotas.map((cota, index) => (
                            <Accordion key={index} elevation={2}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={cota.nomeEmpresa}
                                    id={cota.nomeEmpresa}
                                >
                                    <Grid item container spacing={0.5} alignItems={"center"}>
                                        <Grid item md={0.5} xs={2}>
                                            {testTrofeu(cota.itens) ? <EmojiEventsIcon sx={{color: (t) => t.palette.success.main}} /> : <DoDisturbAltIcon sx={{color: (t) => t.palette.error.main}}/>}
                                        </Grid>
                                        <Grid item md={11.5} xs={10}>
                                            <Typography fontSize={'18'} textTransform={"uppercase"}>{cota.nomeEmpresa}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item container xs={12}>
                                        {cota.itens.map((produto, i) => (
                                            <>
                                                <Grid container key={i} sx={{ backgroundColor: i % 2 === 1 ? theme.palette.grey[300] : 'white', padding: .5 }}>
                                                    <Grid item md={0.5} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>#</b></TypoBigLetter>}
                                                        {(produto.pedido || i < 1 ) && !/[A-Z]/.test(produto.pedido) ? <EmojiEventsIcon sx={{color: (t) => t.palette.success.main}}/> : <DoDisturbAltIcon sx={{color: (t) => t.palette.error.main}}/> }
                                                    </Grid>
                                                    <Grid item md={1.5} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>Item/Cod.</b></TypoBigLetter>}
                                                        <TypoBigLetter>{`${produto.item} - ${produto.produtor}`}</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={3.5} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>Descrição</b></TypoBigLetter>}
                                                        <TypoBigLetter>{produto.descricao}</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={0.5} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>Unid.</b></TypoBigLetter>}
                                                        <TypoBigLetter>{produto.unidades}</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={1} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>Qtd.</b></TypoBigLetter>}
                                                        <TypoBigLetter>{produto.quantidade}</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={1} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>ICMS Ret</b></TypoBigLetter>}
                                                        <TypoBigLetter>{Format.moeda(produto.icmsRet) }</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={1} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>ICMS</b></TypoBigLetter>}
                                                        <TypoBigLetter>{Format.moeda(produto.icms)}</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={1} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>Aliq. IPI</b></TypoBigLetter>}
                                                        <TypoBigLetter>{produto.aliIpi}</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={1} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>Valor Un.</b></TypoBigLetter>}
                                                        <TypoBigLetter>{Format.moeda(produto.valor)}</TypoBigLetter>
                                                    </Grid>
                                                    <Grid item md={1} xs={6}>
                                                        {(i < 1 || xs) && <TypoBigLetter><b>Total</b></TypoBigLetter>}
                                                        <TypoBigLetter>{Format.moeda(produto.total)}</TypoBigLetter>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ))}
                                    </Grid>
                                    <Divider sx={{ my: 0.5 }} />
                                    <Grid item container xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <TypoBigLetter><b>Total da Cotação:</b> {Format.moeda(cota.totalGeral)}</TypoBigLetter>
                                            <TypoBigLetter><b>IPI: </b>{Format.moeda(cota.ipi)}</TypoBigLetter>
                                            <TypoBigLetter><b>Valor Frete: </b>{Format.moeda(cota.frete)}</TypoBigLetter>
                                            <TypoBigLetter><b>Tipo Frete: </b>{cota.tpfrete}</TypoBigLetter>
                                            <TypoBigLetter><b>Ult. Ped: </b>{cota.ultPed}</TypoBigLetter>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TypoBigLetter><b>Impostos: </b>{Format.moeda(cota.impostos)}</TypoBigLetter>
                                            <TypoBigLetter><b>ICMS: </b>{Format.moeda(cota.icms)}</TypoBigLetter>
                                            <TypoBigLetter><b>Condição: </b>{cota.condicaoPag}</TypoBigLetter>
                                            <TypoBigLetter><b>ICMS Retido Total: </b>{cota.icmsRet}</TypoBigLetter>
                                            <TypoBigLetter><b>Contato: </b>{cota.contato}</TypoBigLetter>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid> : <TypoBigLetter>Não ha informações disponiveis</TypoBigLetter>
            }
        </>
    );
};

export default ComparaCotacao;