import {RootState} from "store/index";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Role, TokenData} from "types/auth";
import jwtDecode from "jwt-decode";
import {ConfigState} from "store/config";

export type AuthState = {
    isAuthenticated: boolean;
    userName: string;
    authData: any;
    tokenData: TokenData | undefined;
}

const initialState: AuthState = {
    isAuthenticated: false,
    userName: '',
    authData: null,
    tokenData: undefined
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signIn: (state, action: PayloadAction<{authData: any }>) => {
            state.isAuthenticated = true;
            state.authData = action.payload.authData;
            state.tokenData = getTokenData(action.payload.authData.access_token);
            if(state.tokenData.nome.split(" ").length > 2){
                state.userName = `${state.tokenData.nome.split(" ")[0]} ${state.tokenData.nome.split(" ")[state.tokenData.nome.split(" ").length - 1]}`;
            }else{
                state.userName = state.tokenData.nome;
            }
        },
        signOut: (state) => {
            state.isAuthenticated = false;
            state.userName = '';
            state.authData = null;
            state.tokenData = undefined;
        },
        updateName: (state, action: PayloadAction<{name: string}>) => {
            if(action.payload.name.split(" ").length > 2){
                state.userName = `${action.payload.name.split(" ")[0]} ${action.payload.name.split(" ")[action.payload.name.split(" ").length - 1]}`;
            }else{
                state.userName = action.payload.name;
            }
        },
    }
})

export default authSlice.reducer;

export const authActions = authSlice.actions;

export const useAuthState = (state: RootState) => state.auth;

export const hasAnyRoles = (state: AuthState ,roles:Role[]):boolean => {
    if(roles.length === 0 ) return false;
    if(state.tokenData){
        return roles.some(role => state.tokenData.authorities.includes(role));
    }
    return false;
}

export const hasModuloPermission = (state: AuthState, config: ConfigState , modulo?: string, permissao?: string) => {
    if(!state.tokenData) return false;
    if(!modulo) return false;
    let isAdmin = hasAnyRoles(state,["ROLE_ADMIN"]);
    let isEmpresa = hasAnyRoles(state, ["ROLE_EMPRESA"]);
    let isColaborador = hasAnyRoles(state,["ROLE_COLABORADOR"]);
    let isGrupoEmpresa = hasAnyRoles(state, ["ROLE_GRUPO_EMPRESA"]);

    if(isAdmin) return true;
    if(isGrupoEmpresa){
        // console.log("modulo", modulo, state.tokenData.modulos.some(md => md.nome === modulo));
        return state.tokenData.limites.some(md => md.nome === modulo);
    }
    if(isEmpresa){
        return state.tokenData.modulos.some(md => md.nome === modulo);
    }
    if(isColaborador){
        if(!config.empresa) return false;
        const acesso = state.tokenData.acessos.find(acs => acs.empresaCodigo === config.empresa.empresaCodigo);
        if(!acesso) return false;
        const moduloT = acesso.modulos.find(md => md.nome === modulo);
        if(!moduloT) return false;
        if(!permissao) return true;
        if(permissao && moduloT.permissoes.length === 0) return false;
        let permissoes = permissao ? permissao.split(",") : [];
        return permissoes.some(per => moduloT.permissoes.some(p => p.nome === per));
    }
    return false;
}

export const verifyAuthentication = (state: AuthState) => {
    if (state.tokenData) {
        return (state.tokenData.exp * 1000 > Date.now());
    }
    return false;
}


const getTokenData = (token: string) : TokenData | undefined => {
    try{
        return jwtDecode(token) as TokenData;
    }catch (error){
        return undefined;
    }
}

