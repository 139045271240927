import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    Pagination,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import CardSolicitacaoFinalizada from 'components/solicitacao-material/cardSoliticacaoFinalizada';
import {TransitionProps} from '@mui/material/transitions';
import Loader from 'components/loader';
import CloseIcon from '@mui/icons-material/Close';
import {SolicitacaoMateriaisService} from 'services/SolicitacaoMateriaisService';
import * as base64js from "base64-js";
import {AxiosRequestConfig} from 'axios';
import {toast} from 'react-toastify';
import {RestPage} from 'types/prtoheuspage';
import {SolicitacaoArmazem} from 'types/solicitacao-materiais';
import {PAGE_SIZE_SELECT} from 'utils/constants';
import {BarraDePesquisaSolicitacoes} from 'components/solicitacao-material/barraPesquisaSolicitacoes';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';

type Props = {
    open?: boolean;
    onClose: Function;
};

type SearchProps = {
    numeroSolicitacao: string;
    dataEmissao: string;
  };

const Transition = React.forwardRef( function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='down' ref={ref} {...props} />;
} );

const ModalHistoricoSolicitacoes = ( { onClose, open }: Props ) => {
    const [isLoading, setIsLoading] = useState( true );
    const [solicitacoesArmazem, setSolicitacoesArmazem] = useState<RestPage<SolicitacaoArmazem>>();
    const [pageCount, setPageCount] = useState( 0 ); // ESTADO DA PÁGINA ATUAL
    const [pageSize, setPageSize] = useState( 10 ); // QUANTIDADE DE CONTEÚDO POR PÁGINA
    const [stringPesquisa, setStringPesquisa] = useState<string>( "" );
    const [numeroSolicitacao, setNumeroSolicitacao] = useState( "" );
    const [dataEmissao, setDataEmissao] = useState( "" );
    const config = useAppSelector(useConfigState);
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );

    const onPesquisaAvançada = ( data: string ) => {
        setStringPesquisa( data );
    };

    const onPesquisa = ( data: SearchProps ) => {
        setDataEmissao( data.dataEmissao );
        setNumeroSolicitacao( data.numeroSolicitacao );
        setPageCount( 0 );
    };
    

    // BUSCA TODAS AS SOLICITAÇÕES
    const getSolicitacoes = useCallback( () => {
        setIsLoading( true );
        const params: AxiosRequestConfig = {
            params: {
                page: pageCount,
                size: pageSize,
                numeroSolicitacao: numeroSolicitacao,
                dataEmissao: dataEmissao,
                pesquisa: base64js.fromByteArray(
                    new TextEncoder().encode( stringPesquisa )
                ),
            },
        };

        SolicitacaoMateriaisService.findAllSolicitacoesArmazem( config.empresa.empresaCodigo, params )
            .then( ( res ) => {
                setSolicitacoesArmazem( res.data );
            } )
            .catch( ( ) => {
                toast.error( "Erro ao carregar as solicitações", { autoClose: 1000 } );
            } )
            .finally( ( ) => {
                setIsLoading( false );
            } );
    }, [dataEmissao, config.empresa.empresaCodigo, numeroSolicitacao, pageCount, pageSize, stringPesquisa] );

    useEffect( () => {
        getSolicitacoes();
    }, [getSolicitacoes] );

    const handlePageCount = ( page: number ) => {
        setPageCount( page - 1 );
    };

    return ( isLoading ? (
        <Loader />
    ) : (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose( false )}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={'xl'}
                fullScreen={fullScreen}
                keepMounted
                aria-describedby='alert-dialog-slide-description'>
                <DialogContent>

                    {/* Cabeçalho */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                        <Typography color='primary' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                            Histórico de Solicitações
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={() => onClose( false )}
                            sx={{ zIndex: 1, color: theme => theme.palette.grey[500] }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Cards */}
                    <Box>
                    <Grid container spacing={2} sx={{ mb: 5 }}>
                        <Grid item xs={12}>
                            <BarraDePesquisaSolicitacoes
                            onPesquisa={onPesquisa}
                            textoPesquisa="Busque número da solicitação"
                            onPesquisaAvançada={onPesquisaAvançada}
                            />
                        </Grid>
                    </Grid>
                        {solicitacoesArmazem &&
                            solicitacoesArmazem.conteudo.map( ( solicitacaoArmazem, i ) => (
                                <Grid item xs={12} sx={{ alignItems: 'center', py: 1, mb: 2 }} key={i}>
                                    <CardSolicitacaoFinalizada
                                        dadosSolicitacaoArmazem={solicitacaoArmazem}
                                    />
                                </Grid>
                            ) )}
                    </Box>

                    {/* Paginação */}
                    <Grid container spacing={2}  sx={{ mt: 2 }}>
                        <Grid item xs={10}>
                            <Pagination
                                count={solicitacoesArmazem &&
                                    Math.ceil( parseInt( solicitacoesArmazem?.quantidade ) / pageSize )} //Número total de solicitacoes / pageSize
                                defaultPage={0}
                                page={pageCount + 1}
                                onChange={( event, value ) => {
                                    handlePageCount( value );
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                label='Registros por página'
                                onChange={( e: any ) => {
                                    setPageSize( e.target.value );
                                    setPageCount( 0 );
                                }}
                                size='small'
                                value={pageSize}
                                fullWidth>
                                {PAGE_SIZE_SELECT.map( option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ) )}
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
    );
};

export default ModalHistoricoSolicitacoes;
