import {Card, CardContent, Grid, MenuItem, Pagination, TextField, Typography} from "@mui/material";
import {AxiosRequestConfig} from "axios";
import ActionButtons from "components/buttons";
import MyCard from "components/card";
import {useCallback, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {OrcamentosService} from "services/OrcamentosService";
import {Orcamento} from "types/orcamento";
import {SpringPage} from "types/springpage";
import {PAGE_SIZE_SELECT} from "utils/constants";
import Format from "utils/formatadores";
import OrcamentoUtils from "utils/orcamento";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "store";
import {configActions} from "store/config";

const ListOrcamentos = () => {
    const [page, setPage] = useState<SpringPage<Orcamento>>();
    const navigate = useNavigate();
    const config = useSelector((state: RootState) => state.config);
    const sweetScreen = withReactContent(Swal)
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPageSize = '10';
    const defaultPage = '1';
    const appDispatch = useAppDispatch();


    const handleEdit = (id: string) => {
        navigate(`/painel/orcamentos/edit/${id}`);
    }

    const handleDelete = (id: string) => {
        sweetScreen.fire({
            title: 'Deseja realmente excluir esta orcamento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, deletar orcamento!`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then((result) => {
            if (result.isConfirmed) {
                appDispatch(configActions.setLoading({loading: true}))
                OrcamentosService.delete(config.empresa.empresaCodigo,id)
                    .then((res) => {
                        toast.success("Orcamento deletado com sucesso!");
                        loadOrcamentos();
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    })
                    .finally(() => {
                        appDispatch(configActions.setLoading({loading: false}))
                    });
            }
        })
    }

    const handleConfirm = (id: string) => {
        sweetScreen.fire({
            title: 'Deseja realmente confirmar o orçamento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, confirmar orcamento!`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then((result) => {
            if (result.isConfirmed) {
                appDispatch(configActions.setLoading({loading: true}))
                OrcamentosService.confirmarPedido(config.empresa.empresaCodigo,id)
                    .then((res) => {
                        toast.success(<div>Orçamento confirmado com sucesso! <br /> Orçamento Nº #{res.data}</div>);
                        loadOrcamentos();
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    })
                    .finally(() => {
                        appDispatch(configActions.setLoading({loading: false}))
                    });
            }
        })
    }

    const handlePrint = (id:string) => {
        navigate(`/painel/orcamentos/imprimir/${id}`);
    }

    const loadOrcamentos = useCallback(() => {
        appDispatch(configActions.setLoading({loading: true}))
        const params : AxiosRequestConfig = {
            params: {
                page: parseInt(searchParams.get('page') || defaultPage)-1 ,
                size: searchParams.get('size') || defaultPageSize
            }
        }
        OrcamentosService.findAll(config.empresa.empresaCodigo, params)
            .then((res) => {
                setPage(res.data);
            })
            .catch((err) => { toast.error(err.response.data.message); })
            .finally(() => {appDispatch(configActions.setLoading({loading: false}))});
    }, [appDispatch, config.empresa.empresaCodigo, searchParams]);

    useEffect(() => {
        loadOrcamentos();
    }, [loadOrcamentos]);

    return(
        <MyCard title={'Lista de orçamentos'} action={() => navigate('/painel/orcamentos/add')}>
            <Grid item xs={12} sx={{mb:3}}>
                    {page && page.size > 0 && page.content?.length > 0 && page.content.map(orc => (
                        <Card sx={{ width: "100%", mb:2 }} key={orc.id}>
                            <CardContent>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} md={3} xl={2}>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Cód. : </b> {orc.codigoPedido}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Data : </b> {Format.data(orc.data)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4} xl={3}>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Nome : </b> {orc.nomeConsumidor}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom component={'div'}>
                                            <b>Tipo Pag. : </b> {orc.descricaoCondicao}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3} xl={3}>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Empresa :</b> {orc.empresa}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Valor Total : </b> {Format.moeda(OrcamentoUtils.valorTotal(orc.produtos, orc.valorFrete))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2} xl={4}>
                                        <ActionButtons handleEdit={() => handleEdit(orc.id)}
                                                       handleDelete={() => handleDelete(orc.id)}
                                                       handlePrint={() => handlePrint(orc.id)}
                                                       handleConfirm={() => handleConfirm(orc.id)}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            {page && page.totalElements > 0 &&
                <Grid container item xs={12}>
                    <Grid item md={6} xs={6}>
                        <Pagination
                            count={page?.totalPages}
                            defaultPage={parseInt(defaultPage)}
                            page={parseInt(searchParams.get('page') || defaultPage)}
                            onChange={(event, value) => {
                                setSearchParams({page: `${value}`, size: searchParams.get('size') || defaultPageSize})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "right"}}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Registros por pagina"
                            onChange={(e:any) => {
                                setSearchParams({page: "1" , size: e.target.value})
                            }}
                            size="small"
                            value={searchParams.get('size') || defaultPageSize}
                            sx={{minWidth: 150}}
                        >
                            {PAGE_SIZE_SELECT.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            }
        </MyCard>
    )
}


export default ListOrcamentos;