import AddIcon from '@mui/icons-material/Add';
import CallIcon from '@mui/icons-material/Call';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaidIcon from '@mui/icons-material/Paid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import {Box} from "@mui/material";
import Button from '@mui/material/Button';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {alpha, styled} from '@mui/material/styles';
import {useCrm} from 'contexts/CrmContext';
import * as React from 'react';
import {useState} from 'react';
import {CriaLead, CrmLead} from 'types/crm';
import ModalCadastrarLead from '../modalCadastrarLead';
import {useAppSelector} from "store";
import {hasModuloPermission, useAuthState} from "store/auth";
import {useConfigState} from "store/config";

const StyledMenu = styled( ( props: MenuProps ) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
) )( ( { theme } ) => ( {
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing( 1 ),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing( 1.5 ),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
} ) );

export default function MenuButton() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>( null );
  const open = Boolean( anchorEl );
  const [modalCadastrar, setModalCadastrar] = useState( false );
  const [crmLead, setCrmLead] = useState<CrmLead>( {} as CrmLead );
  const { setScreen, hideMenu } = useCrm();
  const appSelector = useAppSelector(useAuthState)
  const config = useAppSelector(useConfigState)

  const handleSelectConsumidor = ( data: CriaLead ) => {
    setCrmLead( {
      ...crmLead,
      codigo: data.codigo,
    } );
  }

  const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
    setAnchorEl( event.currentTarget );
  };

  const handleCloseMenu = () => {
    setAnchorEl( null );
  };

  const hadleCloseNewLead = () => {
    setAnchorEl( null );
    setModalCadastrar( true )
  }

  const handleHome = () => {
    setAnchorEl( null );
    setScreen( 'Início' );
  }

  const handleAtendimento = () => {
    setAnchorEl( null );
    setScreen( 'Atendimento' );
  }

  const handleOrcamento = () => {
    setAnchorEl( null );
    setScreen( 'Orçamento' );
  }

  const handleFunil = () => {
    setAnchorEl( null );
    setScreen( 'Funil' );
  }

  if ( modalCadastrar ) {
    return (
      <ModalCadastrarLead show={modalCadastrar} setShow={setModalCadastrar} handleSelect={handleSelectConsumidor} />
    );
  } else {
    return (
      <>
        {( !hideMenu ) &&
          <Box sx={{
            position: 'fixed',
            right: 65,
            top: [74, 74, 98]
          }}
          >
            <Button
              id="b1"
              aria-controls={open ? 'b1' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Menu CRM
            </Button>
            <StyledMenu
              id="m1"
              MenuListProps={{
                'aria-labelledby': 'm1',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleHome} disableRipple>
                <HomeIcon />
                Início
              </MenuItem>
              {hasModuloPermission(appSelector,config, "ORCAMENTO" ) &&
                <MenuItem onClick={hadleCloseNewLead} disableRipple>
                  <AddIcon />
                  Adicionar Cliente
                </MenuItem>
              }
              <MenuItem onClick={handleAtendimento} disableRipple>
                <CallIcon />
                Atendimentos
              </MenuItem>
              {hasModuloPermission(appSelector,config , "ORCAMENTO" ) &&
                <MenuItem onClick={handleOrcamento} disableRipple>
                  <PaidIcon />
                  Orçamentos
                </MenuItem>
              }
              <MenuItem onClick={handleFunil} disableRipple>
                <QueryStatsIcon />
                Funil de Vendas
              </MenuItem>
            </StyledMenu>
          </Box>
        }
      </>
    );
  }
}