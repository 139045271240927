import {AxiosRequestConfig} from "axios"
import {API} from "./Api";

const URL = "/epi";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const EpiService = {
  findFuncionario: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/lstFunc`, paramsGeral);
  },
  findAllFuncionarios: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/lstAllFunc`, paramsGeral);
  },
  findAllMotivos(codigo: string, params?: AxiosRequestConfig) {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/motivos`, paramsGeral);
  },
  findEpi: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/lstEpi`, paramsGeral);
  },
  criaPedidoEpi: (codigo: string, data: any, params?: AxiosRequestConfig) => {
    if (params) {
      params = { ...params, responseType: 'blob', withCredentials: true }
      params.params = { ...params?.params, codigo }
    }
    else {
      params = { params: { codigo }, responseType: 'blob', withCredentials: true }
    }
    return API.post(`${URL}/criaPedidoEpi`, data, params);
  }
}