import {Button, Tooltip} from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type Props = {
    handleDelete: Function,
}

const ButtonDelete = ({handleDelete}: Props) => {
    return (
        <Tooltip title="Apagar" placement="top">
            <Button variant={'contained'} onClick={() => handleDelete()} sx={{marginRight: 1,}} color={'error'} fullWidth>
                <DeleteOutlineIcon/>
            </Button>
        </Tooltip>
    )
}

export default ButtonDelete;