import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {Button, Card, CardContent, Grid, MenuItem, Pagination, TextField, Tooltip, Typography} from "@mui/material";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import ClipboardJS from "clipboard";
import ModalPermissoesLink from "components/cadastro-externo/modal/permissoesLink";
import MyCard from "components/card";
import {useCallback, useEffect, useState} from "react";
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from "react-toastify";
import {CadastroExternoService} from "services/CadastroExternoService";
import {useAppDispatch, useAppSelector} from 'store';
import {configActions, useConfigState} from 'store/config';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {LinkPersonalizado} from "types/cadastroExterno";
import {SpringPage} from "types/springpage";
import {PAGE_SIZE_SELECT} from "utils/constants";

type SearchProps = {
    descricao: string;
}

const Formularios = () => {
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState<SpringPage<LinkPersonalizado>>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showModal, setShowModal] = useState(false);
    const [linkSelecionado, setLinkSelecionado] = useState<LinkPersonalizado>();
    const defaultPageSize = '10';
    const defaultPage = '1';
    const sweetScreen = withReactContent(Swal);
    const {register, handleSubmit, formState: {errors}} = useForm<SearchProps>();

    const host = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/cadastro/';

    const findAllLinks = useCallback(() => {

        if (!config.parametros || !config.parametros.codigoUnico) {
            toast.warning('Grupo não encontrado, selecione o grupo!');
            return;
        }

        if (!config.empresa || !config.empresa.empresaCodigo) {
            toast.warning('Empresa não encontrada, selecione a empresa!');
            return;
        }

        appDispatch(configActions.setLoading({
            loading: true,
        }));
        const params: AxiosRequestConfig = {
            params: {
                codigo: config.empresa.empresaCodigo,
                codigoUsuario: searchParams.get('codigo') || '',
                page: parseInt(searchParams.get('page') || defaultPage) - 1,
                size: searchParams.get('size') || defaultPageSize,
                descricao: searchParams.get('descricao') || '',
            }
        };
        CadastroExternoService.findAllLink(params)
            .then((res: AxiosResponse<SpringPage<LinkPersonalizado>>) => {
                setPage(res.data);
            })
            .catch(err => {
                toast.error(err);
            })
            .finally(() => {
                appDispatch(configActions.setLoading({
                    loading: false,
                }));
            });
    }, [config.parametros, config.empresa, appDispatch, searchParams]);

    useEffect(() => {
        findAllLinks();
    }, [findAllLinks]);


    const handleDelete = (link: LinkPersonalizado) => {
        sweetScreen
            .fire({
                title: `Deseja excluir o link ?`,
                text: link.descricao,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Sim, deletar link!`,
                cancelButtonText: `Não`,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            })
            .then(result => {
                if (result.isConfirmed) {
                    appDispatch(configActions.setLoading({
                        loading: true,
                    }));
                    CadastroExternoService.deleteLink(link.id)
                        .then(res => {
                            toast.success('Link deletado com sucesso!');
                            findAllLinks();
                        })
                        .catch(err => {
                            toast.error(err.response.data.message);
                        })
                        .finally(() => {
                            appDispatch(configActions.setLoading({
                                loading: false,
                            }));
                        });
                }
            });
    };

    const copiarLink = (link: string) => {
        try {
            const element = document.getElementById(`copy-${link}`);
            ClipboardJS.copy(element);
            toast.success('Link copiado com sucesso !');
        } catch (err) {
            toast.error('Erro ao copiar link !');
        }

    };

    const handleOpen = (link: LinkPersonalizado) => {
        setLinkSelecionado(link);
        setShowModal(true);
    };


    const handleClose = () => {
        setLinkSelecionado(undefined);
        setShowModal(false);
    };

    const onSubmit = (d: any) => {
        setSearchParams({
            page: defaultPage,
            size: searchParams.get('size') || defaultPageSize,
            descricao: d.descricao,
        });
    };

    return (
        <MyCard title={'Cadastro Externo'} buttonText='Criar link' action={() => navigate('/painel/formularios/add')}>
            <>
                <Grid container spacing={2} sx={{pb: 2}}>
                    <Grid item xs={12} md={9}>
                        <TextField
                            {...register("descricao")}
                            label="Descrição"
                            variant="outlined"
                            fullWidth
                            error={!!errors.descricao}
                            helperText={errors.descricao?.message}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button
                            onClick={handleSubmit((e: any) => {
                                onSubmit(e);
                            })}
                            sx={{width: "100%", height: "100%"}}
                            variant="contained"
                            color="primary"
                        >
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{mb: 3}}>
                    {page &&
                        page.content.map(link => (
                            <Card sx={{width: '100%', mb: 2}} key={link.id}>
                                <CardContent>
                                    <Grid container alignItems='center' justifyContent='space-between' spacing={2}>
                                        {/* Status */}
                                        {/* <Grid item xs={12} md={3}>
                            <Typography sx={{ fontSize: 16 }} color='text.secondary' component={'div'}>
                                <b>Status: </b>{' '}
                                {link.ativo ? (
                                <Chip label='Ativo' color='success' size={'small'} sx={{ px: 1 }} />
                                ) : (
                                <Chip label='Bloqueado' color='error' size={'small'} sx={{ px: 1 }} />
                                )}
                            </Typography>
                            </Grid> */}

                                        {/* Descrição */}
                                        <Grid item xs={12} md={3.5}>
                                            <Typography sx={{fontSize: 16}} color='text.secondary'>
                                                <b>Descrição: </b> {link.descricao}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Typography sx={{fontSize: 16}} color='text.secondary'>
                                                <b>Auto Aprovação: </b> {link.autoAprove ? 'Sim' : 'Não'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3.5}>
                                            <Typography sx={{fontSize: 16}} color='text.secondary'>
                                                <b>Campos corporativos?
                                                    (cnpj) </b> {link.dadosCorporativos ? 'Sim' : 'Não'}
                                            </Typography>
                                        </Grid>

                                        {/* Ações */}
                                        <Grid item xs={12} md={2} sx={{display: 'flex', justifyContent: 'end'}}>
                                            <Tooltip title='Excuir' placement='top'>
                                                <Button
                                                    variant={'contained'}
                                                    fullWidth
                                                    color={'error'}
                                                    onClick={() => handleDelete(link)}
                                                >
                                                    <DeleteIcon/>
                                                </Button>
                                            </Tooltip>
                                        </Grid>

                                        {/* Link */}
                                        <Grid item xs={12} md={10} sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                                            <Typography sx={{fontSize: 16}} color='text.secondary' textAlign={'center'}>
                                                <b>Link:</b>
                                            </Typography>
                                            <Tooltip title='Abrir' placement='top'>
                                                <Button variant="contained" color="primary" href={host + link.id}
                                                        target="_blank">
                                                    <OpenInNewIcon/>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title='Copiar' placement='top'>
                                                <Button variant="contained" color="primary"
                                                        onClick={() => copiarLink(link.id)}>
                                                    <ContentCopyIcon/>
                                                </Button>
                                            </Tooltip>
                                            <input type="hidden" id={`copy-${link.id}`} value={`${host + link.id}`}/>
                                        </Grid>

                                        {/* Permissões */}
                                        <Grid item xs={12} md={2} sx={{display: 'flex', justifyContent: 'end'}}>
                                            <Button variant="contained" fullWidth onClick={() => handleOpen(link)}>
                                                Detalhes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                </Grid>
            </>
            {page && page.totalElements > 0 && (
                <Grid container item xs={12}>
                    <Grid item md={6} xs={6}>
                        <Pagination
                            count={page?.totalPages}
                            defaultPage={parseInt(defaultPage)}
                            page={parseInt(searchParams.get('page') || defaultPage)}
                            onChange={(event, value) => {
                                setSearchParams({page: `${value}`, size: searchParams.get('size') || defaultPageSize});
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: 'right'}}>
                        <TextField
                            id='outlined-select-currency'
                            select
                            label='Registros por pagina'
                            onChange={(e: any) => {
                                setSearchParams({page: '1', size: e.target.value});
                            }}
                            size='small'
                            value={searchParams.get('size') || defaultPageSize}
                            sx={{minWidth: 150}}>
                            {PAGE_SIZE_SELECT.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            )}

            {showModal && linkSelecionado &&
                <ModalPermissoesLink
                    onClose={handleClose}
                    link={linkSelecionado}
                    page={page}
                    showModal={showModal}
                />
            }
        </MyCard>

    )
}

export default Formularios