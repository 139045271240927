import {Button, Card, Grid, Tooltip, Typography} from '@mui/material';
import {useState} from 'react';
import {SolicitacaoMaterial} from 'types/solicitacao-materiais';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalDetalhesMateriais from '../modal/detalhes';
import Format from 'utils/formatadores';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import {SolicitacaoMateriaisService} from 'services/SolicitacaoMateriaisService';
import {toast} from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import PrintIcon from '@mui/icons-material/Print';
import {useNavigate} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';

type Props = {
  dadosSolicitacao: SolicitacaoMaterial;
  getSolicitacoes: Function;
  setSearchParams: Function;
  setIsLoading: Function;
};

const CardSolicitacaoMateriais = ( { dadosSolicitacao, getSolicitacoes, setSearchParams, setIsLoading }: Props ) => {
  const sweetScreen = withReactContent( Swal );
  const [showModal, setShowModal] = useState( false );
  const config = useAppSelector(useConfigState);
  const navigate = useNavigate();

  const handleView = () => {
    setShowModal( true );
  };

  const handleClose = () => {
    setShowModal( false );
  };

  const handlePrint = ( id: string ) => {
    navigate( `/painel/solicitacao-materiais/imprimir/${id}` );
  };

  const handleConfirmar = ( id: string, numero: string ) => {
    sweetScreen
      .fire( {
        title: `Deseja realmente aprovar a solicitação nº ${numero}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, aprovar a solicitação!`,
        cancelButtonText: `Não`,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      } )
      .then( result => {
        if ( result.isConfirmed ) {
          setIsLoading( true )
          SolicitacaoMateriaisService.aprovar( config.empresa.empresaCodigo, id )
            .then( ( res ) => {
              toast.success( 'Solicitação aprovada com sucesso!' );
              getSolicitacoes();
              setSearchParams( { page: "1" } );
            } )
            .catch( err => {
              toast.error( err.response.data.message );
            } ).finally( () => setIsLoading( false ) )
        }
      } );
  };

  const handleExcluir = ( id: string, numero: string ) => {
    sweetScreen
      .fire( {
        title: `Deseja realmente excluir a solicitação nº ${numero} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sim, excluir a solicitação!`,
        cancelButtonText: `Não`,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      } )
      .then( result => {
        if ( result.isConfirmed ) {
          SolicitacaoMateriaisService.delete( config.empresa.empresaCodigo, id )
            .then( ( res ) => {
              toast.success( 'Solicitação excluida com sucesso!' );
              getSolicitacoes();
              setSearchParams( { page: "1"} )
            } )
            .catch( err => {
              toast.error( err.response.data.message );
            } );
        }
      } );
  };

  const handleEditar = ( id: string ) => {
    navigate( `/painel/solicitacao-materiais/edit/${id}` );
  };

  return (
    <Card variant='elevation' sx={{ p: 2 }}>
      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} spacing={2}>
        <Grid item xs={12} sm={3} lg={2}>
          <Typography sx={{ fontSize: 16 }} color='text.secondary'>
            <b>Número:</b> {dadosSolicitacao.numero}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Typography sx={{ fontSize: 16 }} color='text.secondary'>
            <b>Data de Emissão:</b> {Format.data( dadosSolicitacao.dataEmissao )}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={2} lg={2}>
          <Typography sx={{ fontSize: 16 }} color='text.secondary'>
            <b>Status: </b>
            <Chip component={"span"} label={dadosSolicitacao.status} color="success" size={"small"} />
            {dadosSolicitacao.status === "CONFIRMADO" && <Chip component={"span"} label="Confirmado" color="success" size={"small"} />}
            {dadosSolicitacao.status === "PENDENTE" && <Chip label="Pendente" component={"span"} sx={{ backgroundColor: yellow[500] }} size={"small"} />}
            {dadosSolicitacao.status === "CANCELADO" && <Chip component={"span"} label="Cancelado" color="error" size={"small"} />}
          </Typography>
        </Grid> */}
        <Grid item container xs={12} sm={1.5} lg={1.5} spacing={1}>
          <Grid item xs={12} >
            <Tooltip title='Imprimir' placement='top'>
              <Button
                variant={'contained'}
                onClick={() => handlePrint( dadosSolicitacao.id )}
                fullWidth
                color={'info'}>
                <PrintIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}  >
            <Tooltip title='Editar' placement='top'>
              <Button
                variant={'contained'}
                onClick={() => handleEditar( dadosSolicitacao.id )}
                color={'warning'}
                fullWidth>
                <EditIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={1.5} lg={1.5} spacing={1}>
          <Grid item xs={12} >
            <Tooltip title='Confirmar' placement='top'>
              <Button
                variant={'contained'}
                onClick={() => handleConfirmar( dadosSolicitacao.id, dadosSolicitacao.numero )}
                color={'success'}
                fullWidth>
                <CheckIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title='Excluir' placement='top'>
              <Button
                variant={'contained'}
                color={'error'}
                onClick={() => handleExcluir( dadosSolicitacao.id, dadosSolicitacao.numero )}
                fullWidth>
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2} lg={2}>
          <Tooltip title='Ver' placement='top'>
            <Button variant={'contained'} onClick={() => handleView()} fullWidth color={'info'}>
              <VisibilityIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {showModal && (
        <ModalDetalhesMateriais
          onClose={() => handleClose()}
          open={showModal}
          dadosSolicitacao={dadosSolicitacao}
        />
      )}
    </Card>
  );
};

export default CardSolicitacaoMateriais;
