import {Alert, Box, Button, TextField} from "@mui/material";
import {useAuthContext} from "contexts/AuthContext";
import {configActions, useConfigState} from "store/config";
import {useAppDispatch, useAppSelector} from "store";
import {forwardRef, useEffect, useLayoutEffect, useState} from "react";
import {useForm} from "react-hook-form";
import InputMask from 'react-input-mask';
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

type FormState = {
    codigoUsuario: string;
    codigoEmpresa: string;
    senha: string;
}

const MyInputComponent = forwardRef((props:any, ref) => {
    const { component: Component, ...other } = props;
    return <Component {...other} inputRef={ref} />;
});

const LoginForm = () => {
    const [testEnvoirment, setTestEnvoirment] = useState(false);
    const navigate = useNavigate();
    const {signIn} = useAuthContext();
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const {handleSubmit, setValue, register, formState: {errors}} = useForm<FormState>();

    useLayoutEffect(() => {
        let host = window.location.hostname;
        let hostSplit = host.split(".");
        if (hostSplit.some((item) => item === "homologacao" || item === "localost")) {
            setTestEnvoirment(true);
        }
    }, []);

    useEffect(() => {
        if(config.configWhite.codigo) setValue('codigoEmpresa', config.configWhite.codigo);
    } , [config.configWhite, setValue]);

    const onSubmit = async ({codigoUsuario, codigoEmpresa, senha}: FormState) => {
        appDispatch(configActions.setLoading({
            loading: true,
        }));
        await signIn({username: codigoUsuario, empresa: codigoEmpresa, password: senha})
            .then(() => {
                navigate("/painel", {replace: true});
            })
            .catch((err) => {
                toast.error(err.data.error ? err.data.error : "Usuário ou senha inválidos!");
            }).finally(() => {
                appDispatch(configActions.setLoading({
                    loading: false,
                }));
            });
    };

    return (
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
            {testEnvoirment && <Alert  sx={{ width: '42%', position: "absolute", top: 8 }} severity="error">Ambiente de teste!</Alert> }
            <TextField
                {...register("codigoUsuario", {required: {value: true, message: "Código de usuário obrigatorio"}})}
                label={"Codigo do usuário *"}
                fullWidth
                sx={{ mb: 2}}
                error={!!errors.codigoUsuario}
                helperText={errors.codigoUsuario?.message}
                InputProps={{
                    inputComponent: MyInputComponent,
                    inputProps: {
                        mask: '999999',
                        component: InputMask,
                    },
                }}
                InputLabelProps={ {shrink: true} }
            />
            {
                !config.configWhite.codigo && (
                    <TextField
                        {...register("codigoEmpresa", {required: {value: true, message: "Código de empresa obrigatorio"}})}
                        label={"Codigo da empresa *"}
                        fullWidth
                        sx={{ mb: 2}}
                        error={!!errors.codigoEmpresa}
                        helperText={errors.codigoEmpresa?.message}
                        InputProps={{
                            inputComponent: MyInputComponent,
                            inputProps: {
                                mask: '9999',
                                component: InputMask,
                            },
                        }}
                        InputLabelProps={ {shrink: true} }
                    />
                )
            }
            <TextField
                {...register("senha", {required: {value: true, message: "Senha obrigatorio"}})}
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                id="senha"
                autoComplete="current-password"
                InputLabelProps={ {shrink: true} }
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Log in
            </Button>
        </Box>
    )
}

export default LoginForm;