import {useNavigate, useParams} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import MyCard from "components/card";
import {Grid} from "@mui/material";
import {DanfesService} from "services/DanfesService";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

const Embed = styled('embed')({
  width: "100%",
  height: "100vh",
})

const ImprimirDanfe = () => {
  const [pdf, setPdf] = useState<string>();
  // const [blob, setBlob] = useState<Blob>();
  const { id } = useParams();
  const navigate = useNavigate();
  const config = useAppSelector(useConfigState);
  const appDispatch = useAppDispatch();

  const loadPdf = useCallback(() => {
    if (id) {
      appDispatch(configActions.setLoading({loading: true}))
      DanfesService.getFile(id, config.empresa.empresaCodigo, 1)
        .then(res => {
          const file = new Blob([res.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          setPdf(fileURL);
          // setBlob(file)
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        })
        .finally(() => {
          appDispatch(configActions.setLoading({loading: false}))
        });
    } else {
      navigate('/painel/danfes');
    }
  }, [appDispatch, config.empresa.empresaCodigo, id, navigate]);

  // const handleDownload = () => {
  //     if (blob) download(blob, id + ".pdf", "application/pdf");
  // }

  useEffect(() => {
    loadPdf();
  }, [loadPdf, id, navigate]);

  return (
    <MyCard title={'Imprimir danfe'}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={12}>
            <Embed
              type="application/pdf"
              src={pdf}
            />
          </Grid>
        </Grid>
    </MyCard>
  );
}

export default ImprimirDanfe;