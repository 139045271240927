import {Navigate, Outlet} from 'react-router-dom';
import {
    Box,
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
    Zoom
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from 'components/menuItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MyMenu from 'components/menu';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import StoreIcon from '@mui/icons-material/Store';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CompanyMenu from 'components/companyMenu';
import ButtonTopBar from 'assets/styles/buttonTopBar';
import DrawerHeader from 'assets/styles/drawerHeader';
import AppBar from 'assets/styles/appBar';
import Drawer from 'assets/styles/drawer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LogoMenu from 'assets/imgs/logo-menu.png';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ShieldIcon from '@mui/icons-material/Shield';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ListIcon from '@mui/icons-material/List';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CategoryIcon from '@mui/icons-material/Category';
import AssessmentIcon from '@mui/icons-material/Assessment';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import {BASE_URL} from "utils/constants";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {toast} from "react-toastify";
import {toastError} from "utils/toastError";
import {UpdateService} from 'services/UpdateService';
import {useAppDispatch, useAppSelector} from 'store';
import {configActions, useConfigState} from 'store/config';
import {hasAnyRoles, useAuthState} from "store/auth";

const PainelLayout = () => {
    const appSelector = useAppSelector(useAuthState)
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("md"));
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const updateSystem = () => {
        appDispatch(configActions.setLoading({loading: true}));
        UpdateService.sistema()
            .then(() => {
                toast.success("Sistema atualizado com sucesso!");
            })
            .catch((err) => {
                toastError(err, "Erro ao atualizar o sistema");
            })
            .finally(() => {
                window.location.reload();
                appDispatch(configActions.setLoading({loading: false}));
            });
    }



    return !appSelector.isAuthenticated ? (
        <Navigate to={"/auth/login"}/>
    ) : (
        <Box sx={{display: "flex"}}>
            <AppBar position="fixed" open={config.openMenu}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => appDispatch(configActions.setOpenMenu({openMenu: !config.openMenu}))}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            // ...(openMenu && {display: "none"}),
                        }}
                    >
                        <MenuIcon sx={{color: (theme) => theme.palette.primary.contrastText,}}/>
                    </IconButton>
                    <Box sx={{display: md ? "block" : "none",}} onClick={() => {
                        if (md) appDispatch(configActions.setOpenMenu({openMenu: false}))
                    }}>
                        <CompanyMenu/>
                    </Box>
                    <Box sx={{display: md ? "none" : "block",}}>
                        <Box
                            sx={{
                                flexDirection: md ? "Column" : "row",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            {hasAnyRoles(appSelector, ["ROLE_ADMIN"]) && (
                                <ButtonTopBar
                                    variant={"contained"}
                                    color={"inherit"}
                                    onClick={() => {
                                        appDispatch(configActions.setModalConfig({modalConfig: true}));
                                    }}
                                    sx={{
                                        m: 1,
                                        width: {
                                            lg: 400,
                                        },
                                        height: {
                                            lg: 35,
                                        },
                                    }}
                                >
                                    <Typography sx={{whiteSpace: 'nowrap'}}>
                                        {(config.parametros?.codigoUnico) ?
                                            `${config.parametros?.codigoUnico} - ${config.parametros?.grupo}` : "Não selecionado"}
                                    </Typography>
                                </ButtonTopBar>
                            )}

                            {!hasAnyRoles(appSelector, ["ROLE_EMPRESA"]) &&
                                <ButtonTopBar
                                    variant={"contained"}
                                    color={"inherit"}
                                    onClick={() => appDispatch(configActions.setModalEmpresa({modalEmpresa: true}))}
                                    sx={{
                                        m: 1,
                                        width: {
                                            lg: 400,
                                        },
                                        height: {
                                            lg: 35,
                                        },
                                    }}
                                >
                                    <Typography sx={{whiteSpace: 'nowrap'}}>
                                        {config.empresa && config.empresa.empresaCodigo ? `${config.empresa.empresaCodigo} - ${config.empresa.empresaNome}` : "Não selecionado"}
                                    </Typography>
                                </ButtonTopBar>
                            }
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <MyMenu/>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent' open={config.openMenu}>
                <DrawerHeader/>
                <List>
                    <Collapse in={config.openMenu} unmountOnExit>
                        <Zoom in={config.openMenu} unmountOnExit>
                            <Box sx={{textAlign: 'center', paddingY: 1}}>
                                <img
                                    src={config.configWhite && config.configWhite.logo ? config.configWhite.logo : LogoMenu}
                                    alt='logo'
                                    // width={200}
                                    // height={"auto"}
                                    style={{maxWidth: 200, maxHeight: 200}}
                                />
                            </Box>
                        </Zoom>
                        <Divider/>
                    </Collapse>
                    <MenuItem
                        text={'Painel'}
                        icon={<DashboardIcon/>}
                        link={'/painel'}
                        role={['ROLE_ADMIN', 'ROLE_EMPRESA', 'ROLE_COLABORADOR', 'ROLE_GRUPO_EMPRESA']}
                    />
                    <MenuItem
                        text={'Formulario de Cadastro'}
                        icon={<ListIcon />}
                        link={'/painel/formularios'}
                        modulo='APROVACAO_CADASTRO'
                        role={['ROLE_GRUPO_EMPRESA']}
                    />
                    <MenuItem
                        text={'Aprovação Cadastro'}
                        icon={<PersonAddAlt1Icon/>}
                        link={'/painel/aprovacao-cadastro'}
                        modulo='APROVACAO_CADASTRO'
                        role={['ROLE_GRUPO_EMPRESA', 'ROLE_COLABORADOR']}
                    />
                    <MenuItem
                        text={'Administradores'}
                        icon={<AdminPanelSettingsIcon/>}
                        link={'/painel/admins'}
                        role={['ROLE_ADMIN']}
                    />
                    <MenuItem 
                        text={'Módulos'} 
                        icon={<ViewModuleIcon/>} 
                        link={'/painel/permissoes'}
                        role={['ROLE_ADMIN']}/>
                    <MenuItem
                        text={'Grupos de empresa'}
                        icon={<DomainAddIcon/>}
                        link={'/painel/grupos-empresas'}
                        role={['ROLE_ADMIN']}
                    />
                    <MenuItem
                        text={'Empresas'}
                        icon={<StoreIcon/>}
                        link={'/painel/empresas'}
                        role={['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']}
                    />
                    <MenuItem
                        text={'Colaboradores'}
                        icon={<PersonIcon/>}
                        link={'/painel/colaboradores'}
                        role={['ROLE_ADMIN', 'ROLE_EMPRESA', 'ROLE_GRUPO_EMPRESA']}
                    />
                    <MenuItem
                        text={'Configurações'}
                        icon={<SettingsIcon/>}
                        link={'/painel/configuracoes'}
                        role={['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']}
                    />
                    {/*<MenuItem*/}
                    {/*    text={'Limitadores'}*/}
                    {/*    icon={<EqualizerIcon/>}*/}
                    {/*    link={'/painel/limitadores'}*/}
                    {/*    role={['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']}*/}
                    {/*/>*/}
                    <MenuItem
                        text={'Relatorios'}
                        icon={<AssessmentIcon/>}
                        link={'/painel/relatorios'}
                        role={['ROLE_ADMIN']}
                    />
                    {!downSm && <MenuItem
                        text={'Importação'}
                        icon={<UploadFileIcon/>}
                        link={'/painel/importacao'}
                        role={['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']}
                    /> }                
                    <MenuItem
                        text={'Danfes'}
                        icon={<FileCopyIcon/>}
                        link={'/painel/danfes'}
                        role={['ROLE_COLABORADOR']}
                        modulo='FATURAMENTO'
                        permissao='DANFES'

                    />
                    <MenuItem
                        text={'Boletos'}
                        icon={<ReceiptIcon/>}
                        link={'/painel/boletos'}
                        role={['ROLE_COLABORADOR']}
                        modulo='FATURAMENTO'
                        permissao='FATURAS'
                    />
                    <MenuItem
                        text={'Agendamento de Entrega'}
                        icon={<LocalShippingIcon/>}
                        link={'/painel/agendamentos-de-entregas'}
                        role={['ROLE_COLABORADOR']}
                        modulo='AGENDAMENTO_ENTREGA'
                        permissao='COMPRAS, FORNECEDOR'
                    />
                    <MenuItem
                        text={'Exceções de Entrega'}
                        icon={<CalendarMonthIcon/>}
                        link={'/painel/agendamentos-de-entregas/excecoes-datas'}
                        role={['ROLE_GRUPO_EMPRESA', 'ROLE_EMPRESA']}
                        modulo='AGENDAMENTO_ENTREGA'
                    />
                    <MenuItem
                        text={'Cotações'}
                        icon={<CurrencyExchangeIcon/>}
                        link={'/painel/cotacoes'}
                        role={['ROLE_COLABORADOR']}
                        modulo='COTACAO'
                    />
                    <MenuItem
                        text={'Orçamento'}
                        icon={<MonetizationOnIcon/>}
                        link={'/painel/orcamentos'}
                        role={['ROLE_COLABORADOR']}
                        modulo='ORCAMENTO'
                    />
                    <MenuItem
                        text={'Aprovação de documentos'}
                        icon={<DocumentScannerIcon/>}
                        link={'/painel/aprovacao-documentos'}
                        role={['ROLE_COLABORADOR']}
                        modulo='APROVACAO_DOCUMENTO'
                    />
                    <MenuItem
                        text={'CRM'}
                        icon={<AssignmentIndIcon/>}
                        link={'/painel/crm'}
                        role={['ROLE_COLABORADOR']}
                        modulo='CRM'
                    />
                    <MenuItem
                        text={'Funcionário x EPI'}
                        icon={<ShieldIcon/>}
                        link={'/painel/funcionario-epi'}
                        role={['ROLE_COLABORADOR']}
                        modulo='FUNCIONARIO_EPI'
                        permissao='GERENTE,FUNCIONARIO'
                    />
                    <MenuItem
                        text={'Solicitação de Materiais'}
                        icon={<CategoryIcon/>}
                        link={'/painel/solicitacao-materiais'}
                        role={['ROLE_COLABORADOR']}
                        modulo='SOLICITACAO_COMPRA'
                    />
                    {
                        hasAnyRoles(appSelector,["ROLE_ADMIN"]) &&
                        <ListItem disablePadding sx={{display: "block", textDecoration: 'ImageListItem'}}>
                            <ListItemButton sx={{minHeight: 48,justifyContent: config.openMenu ? "initial" : "center",px: 2.5,}} onClick={updateSystem}>
                                <ListItemIcon sx={{minWidth: 0,mr: config.openMenu ? 3 : "auto",justifyContent: "center",}}>
                                    <AutorenewIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Atualizar banco"} sx={{opacity: config.openMenu ? 1 : 0}} />
                            </ListItemButton>
                        </ListItem>
                    }
                    {hasAnyRoles(appSelector,['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']) && (
                        <ListItem disablePadding sx={{display: 'block', textDecoration: 'ImageListItem'}}>
                            <a
                                target='_blank'
                                href={`${BASE_URL}/swagger-ui/index.html`}
                                style={{textDecoration: 'none', color: theme.palette.grey[900]}}
                                rel='noreferrer'>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: config.openMenu ? 'initial' : 'center',
                                        px: 2.5
                                    }}>
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: config.openMenu ? 3 : 'auto',
                                            justifyContent: 'center'
                                        }}>
                                        <IntegrationInstructionsIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={'Documentação API'} sx={{opacity: config.openMenu ? 1 : 0}}/>
                                </ListItemButton>
                            </a>
                        </ListItem>
                    )}
                </List>
            </Drawer>
            <Box component='main' sx={{flexGrow: 1, p: md ? 0 : 3}}>
                <DrawerHeader/>
                <Outlet/>
            </Box>
        </Box>
    );
};

export default PainelLayout;
