import {Box, Button, Grid, TextField} from '@mui/material';
import PesquisaAvancada from 'components/pesquisaAvancada';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {MaterialFilter} from 'types/solicitacao-materiais';

type BarraDePesquisaProps = {
  onPesquisa?: Function;
  textoPesquisa: string;
  onPesquisaAvançada?: Function;
};

export const BarraDePesquisaSolicitacoes = ( props: BarraDePesquisaProps ) => {
  const [showPesquisaAvancada, setShowPesquisaAvancada] = useState( false );
  const [pesquisa, setPesquisa] = useState<MaterialFilter[]>( [] );

  const setFilter = ( filters: MaterialFilter[] ) => {
    setPesquisa( filters );
    let stringPesquisaTemp = '';
    filters.forEach( ( filter: MaterialFilter, index ) => {
      stringPesquisaTemp = `${stringPesquisaTemp} ${filter.campo} ${filter.condicao} '${filter.condicao === 'like' || filter.condicao === 'not like'
          ? '%' + filter.valor.toUpperCase() + '%'
          : filter.valor.toUpperCase()
        }' ${filter.juncao || ''}`;
    } );
    props.onPesquisaAvançada( stringPesquisaTemp );
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<any>();

  const onSubmit = ( data: any ) => {
    data.dataEmissao = data.dataEmissao.replace( /-/g, '' )
    props.onPesquisa( data );
  };

  return (
    <div>
      <Box component='form' display='flex' alignItems='center' paddingY={2} onSubmit={handleSubmit( onSubmit )}>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={4.5} lg={4.5}>
            <TextField
              {...register( 'numeroSolicitacao' )}
              error={!!errors.numeroSolicitacao}
              fullWidth
              InputLabelProps={{shrink: true}}
              type={'text'}
              label='Número da solicitação'
              variant='outlined'
              helperText={errors.numeroSolicitacao?.message.toString()}
            />
          </Grid>
          <Grid item xs={12} md={4.5} lg={4.5}>
          <TextField
                type={"date"}
                label="Data de Emissão"
                variant="outlined"
                fullWidth
                InputLabelProps={{ shrink: true}}
                {...register( "dataEmissao")}
                error={!!errors.dataEmissao}
              />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Button type={'submit'} variant='contained' size='large' fullWidth>
              Buscar
            </Button>
          </Grid>
          {/* <Grid item xs={12} md={4} lg={3}>
            <Button
              type={'submit'}
              variant='contained'
              size='large'
              onClick={() => setShowPesquisaAvancada( true )}
              fullWidth>
              Pesquisa Avançada
            </Button>
          </Grid> */}
        </Grid>
      </Box>

      {showPesquisaAvancada && (
        <PesquisaAvancada
          modulo={'SOLICITACAO_COMPRA'}
          setShow={setShowPesquisaAvancada}
          show={showPesquisaAvancada}
          setFilters={setFilter}
          filters={pesquisa}
        />
      )}
    </div>
  );
};
