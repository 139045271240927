import {Box, Button, Typography} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import {useNavigate} from "react-router-dom";

const SegundaTela = () => {
    
    const navigate = useNavigate();

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: 3}}>
        
        <Typography component="h3" variant="h5" textAlign={'center'} color={'primary'} sx={{ fontSize: 16, fontWeight: 'bold' }} >
            Formulário de solicitação de cadastro enviado
        </Typography>

        <EmailIcon color="primary" sx={{fontSize: 50}}/>

        <Typography component="h3" variant="h5" textAlign={'center'} color={'primary'} sx={{ fontSize: 14, }} >
            Você receberá um email com o código de acesso assim que seu cadastro for aprovado.
        </Typography>

        <Button variant={'contained'} color={'primary'} onClick={() => navigate('/auth/login')}>
            Login
        </Button>
       
    </Box>

  )
}

export default SegundaTela