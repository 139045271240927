import {Box, Grid, Paper, useMediaQuery, useTheme} from "@mui/material";
import {ReactNode} from "react";

type Props = {
  children: ReactNode;
  title: string;
}

const BaseCard = ({ children, title }: Props) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      component={Paper}
      elevation={md ? 0 : 3}
      square
      sx={{
          backgroundColor: (t) => md ? t.palette.background.paper : t.palette.grey[50],
          borderRadius: 1,
          color: "primary.main",
          p: 2,
          minHeight: '100%',
          maxHeight: '100%'
      }}
    >
      <Grid container sx={{mb: 2}} alignContent={"space-between"}>
        <Grid item xs={12} sm={6} md={6}>
            <Box sx={{fontSize: 16, fontWeight: "h6.fontWeight"}}>
              {title}
            </Box>
        </Grid>
      </Grid>
      {children}
    </Box>
  )
}

export default BaseCard;