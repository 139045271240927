import MyCard from "components/card";
import {Box, Button, Grid, MenuItem, Pagination, TextField, Typography} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {RestPage} from "types/prtoheuspage";
import {Controller, useForm} from "react-hook-form";
import {styled} from "@mui/material/styles";
import {CampoFilter} from "types/orcamento";
import {toastError} from "utils/toastError";
import {Documento, ProdutoDocumento} from "types/documentos";
import {AprovacaoDocumentoService} from "services/AprovacaoDocumentosService";
import CardPedidoCompra from "components/aprovacao-documento/cards/pedido-compra";
import CardItemPedidoCompra from "components/aprovacao-documento/cards/item-pedido-compra";
import CardNotaFiscal from "components/aprovacao-documento/cards/nota-fiscal";
import CardSolicitacaoArmazem from "components/aprovacao-documento/cards/solicitacao-armazem";
import CardSolicitacaoCompra from "components/aprovacao-documento/cards/solicitacao-compra";
import ModalDetalhes from "components/aprovacao-documento/modal/detalhes";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";


type SearchProps = {
    dataini: string;
    datafin: string;
    filtro: string;
    cPed:string;
}


const SendButton = styled(Button)({
    width: "100%",
    height: "100%",
});


const ListDocumentos = () => {
    const {handleSubmit, control} = useForm<SearchProps>({
        defaultValues: {
            cPed: "",
            dataini: "",
            datafin: "",
            filtro: "SELECIONE"
        }
    });
    const [camposFilter, setCamposFilter] = useState<CampoFilter[]>([]);
    const [page, setPage] = useState<RestPage<Documento>>();
    const [showModal, setShowModal] = useState(false);
    const config = useAppSelector(useConfigState);
    const [documentoSelected, setDocumentoSelected] = useState<Documento>();
    const [pageCount, setPageCount] = useState(1);
    const [dataIniT, setDataIniT] = useState("")
    const [dataFinT, setDataFinT] = useState("")
    const [filtroT, setFiltroT] = useState("SELECIONE")
    const [cPed, setCped] = useState("")
    let pageSize = 10;
    const appDispatch = useAppDispatch();

    const loadData = (pageCount:number) => {
        const configuracoes = {
            params: {
                codigo: config.empresa.empresaCodigo,
                size: pageSize,
                page: pageCount,
                dataini: dataIniT.replaceAll("-", ""),
                datafim: dataFinT.replaceAll("-", ""),
                filtro: filtroT,
                cPed: cPed
            }
        }
        appDispatch(configActions.setLoading({loading: true}))
        AprovacaoDocumentoService.findAll(configuracoes)
            .then(response => {
                setPage(response.data);
            })
            .catch(error => {
                toastError(error, "Erro ao carregar lista de documentos");
                setPage(undefined);
            })
            .finally(() => {
                appDispatch(configActions.setLoading({loading: false}))
            });
    }

    // useEffect(() => {
    //     loadData();
    // }, [loadData]);

    const comparar = (a: ProdutoDocumento, b: ProdutoDocumento) => {
        if (a.numeroItem < b.numeroItem) {
            return -1;
        }
        if (a.numeroItem > b.numeroItem) {
            return 1;
        }
        return 0;
    }

    const handleView = (documento: Documento) => {
        documento.produtos.sort(comparar);
        setDocumentoSelected(documento);
        setShowModal(true);
    }

    const handleClose = () => {
        setDocumentoSelected(undefined);
        setShowModal(false);
    }

    const onSubmit = () => {
        loadData(1);
        setPageCount(1);
    }

    const loadCamposFilter = useCallback(() => {
        appDispatch(configActions.setLoading({loading: true}))
        AprovacaoDocumentoService.findAllFiltrosDoc(config.empresa.empresaCodigo)
            .then(res => {
                setCamposFilter(res.data)
            })
            .catch((err) => toastError(err,"Erro ao carregar campos de filtro"))
            .finally(() => appDispatch(configActions.setLoading({loading: false})));
    }, [appDispatch, config.empresa.empresaCodigo]);

    useEffect(() => {
        loadCamposFilter();
    }, [loadCamposFilter]);

    const handlePageCount = (page: number) => {
        setPageCount(page);
        loadData(page);
    }


    return (
        <MyCard title={"Lista de documentos"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{mb: 3}}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <Controller
                                    name="filtro"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Tipo de documento"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => {
                                                setPage(undefined)
                                                setFiltroT(e.target.value)
                                            }}
                                            value={filtroT}
                                        >
                                            <MenuItem value={"SELECIONE"} disabled>
                                                Selecione o Tipo
                                            </MenuItem>
                                            {camposFilter.map(option => (
                                                <MenuItem key={option.campo} value={option.campo}>
                                                    {option.descricao}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <Controller
                                    name="cPed"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            type={"number"}
                                            label="Nº Documento"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                min: "0"
                                            } }
                                            onChange={(e) => setCped(e.target.value)}
                                            value={cPed}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Controller
                                    name="dataini"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            type={"date"}
                                            label="Data de emissão inicial"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                max: "9999-12-31"
                                            } }
                                            onChange={(e) => setDataIniT(e.target.value)}
                                            value={dataIniT}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <Controller
                                    name="datafin"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            type={"date"}
                                            label="Data de emissão final"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                max: "9999-12-31"
                                            } }
                                            onChange={(e) => setDataFinT(e.target.value)}
                                            value={dataFinT}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={12}>
                                <SendButton type={"submit"} variant="contained" size="large">
                                    Buscar
                                </SendButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {page && (<>
                    {page.conteudo.map(dc =>
                        <Grid item xs={12} sx={{alignItems: "center", cursor: "pointer"}} key={`${dc.tipo}-${dc.codDocumento}`} onClick={() => handleView(dc)} >
                            {dc.tipo === "PC" && <CardPedidoCompra dc={dc} handleView={handleView} tipoExt={camposFilter.find(c => c.campo === dc.tipo)?.descricao}/>}
                            {dc.tipo === "IP" && <CardItemPedidoCompra dc={dc} handleView={handleView} tipoExt={camposFilter.find(c => c.campo === dc.tipo)?.descricao}/>}
                            {dc.tipo === "SA" && <CardSolicitacaoArmazem dc={dc} handleView={handleView} tipoExt={camposFilter.find(c => c.campo === dc.tipo)?.descricao}/>}
                            {dc.tipo === "SC" && <CardSolicitacaoCompra dc={dc} handleView={handleView} tipoExt={camposFilter.find(c => c.campo === dc.tipo)?.descricao}/>}
                            {dc.tipo === "NF" && <CardNotaFiscal dc={dc} handleView={handleView} tipoExt={camposFilter.find(c => c.campo === dc.tipo)?.descricao}/>}
                        </Grid>
                    )}
                    <Grid item xs={8}>
                        <Pagination
                            count={Math.ceil(parseInt(page.quantidade) / pageSize)}
                            defaultPage={1}
                            page={pageCount}
                            onChange={(event, value) => {
                                handlePageCount(value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {parseInt(page.quantidade) > 0 && <Typography sx={{textAlign: "right"}}>Total de registros: {page.quantidade}</Typography>}
                    </Grid>
                </>)}
            </Grid>
            {documentoSelected &&
                <ModalDetalhes onClose={() => handleClose()} open={showModal} documento={documentoSelected} tipoExt={camposFilter.find(c => c.campo === documentoSelected.tipo)?.descricao} loadData={loadData}/>
            }
        </MyCard>
    );
}

export default ListDocumentos;