import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    MenuItem,
    Pagination,
    Paper,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {PAGE_SIZE_SELECT} from "utils/constants";
import {useCallback, useEffect, useState} from "react";
import {SpringPage} from "types/springpage";
import {Empresa} from "types/user";
import {useNavigate, useSearchParams} from "react-router-dom";
import ActionButtons from "components/buttons";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {EmpresaService} from "services/EmpresaService";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useForm} from "react-hook-form";
import SeachForms from "components/searchForms";
import Format from "utils/formatadores";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {hasAnyRoles, useAuthState} from "store/auth";

const ListEmpresas = () => {
    const [page, setPage] = useState<SpringPage<Empresa>>();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPageSize = '10';
    const defaultPage = '1';
    const config = useAppSelector(useConfigState);
    const appSelector = useAppSelector(useAuthState)
    const sweetScreen = withReactContent(Swal)
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("md"));
    const forms = useForm({
        defaultValues: {
            nome: searchParams.get('nome') || '',
            codigo: searchParams.get('codigo') || '',
            cgc: searchParams.get('cgc') || ''
        },
    });
    const appDispatch = useAppDispatch();

    const loadEmpresas = useCallback( () => {
        if(!config.parametros || !config.parametros.codigoUnico){
            toast.warning("Parametros não encontrados, selecione o grupo!");
            return;
        }
        const params : AxiosRequestConfig = {
            params: {
                page: parseInt(searchParams.get('page') || defaultPage)-1 ,
                size: searchParams.get('size') || defaultPageSize,
                codigo: config.parametros.codigoUnico,
                codigoEmpresa: searchParams.get('codigo') || '',
                nome: searchParams.get('nome') || '',
                cgc: searchParams.get('cgc') || ''
            }
        }
        appDispatch(configActions.setLoading({loading: true}))
        EmpresaService.findAll(params)
            .then((res: AxiosResponse<SpringPage<Empresa>>) => {
                setPage(res.data);
            })
            .catch((err) => {toast.error(err);})
            .finally(() => {appDispatch(configActions.setLoading({loading: false}))});
    } , [config.parametros, searchParams, appDispatch]);

    useEffect(() => {
        loadEmpresas();
    }, [loadEmpresas]);

    const handleEdit = (id: string) => {
        navigate(`/painel/empresas/edit/${id}`);
    }

    const handleDelete = (id: string) => {
        sweetScreen.fire({
            title: `Deseja realmente excluir a empresa:\n ${id} - ${page.content.find(pr => pr.id === id).nome} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, deletar empresa!`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then((result) => {
            if (result.isConfirmed) {
                appDispatch(configActions.setLoading({loading: true}))
                EmpresaService.delete(id)
                    .then((res) => {
                        toast.success("Empresa deletado com sucesso!");
                        loadEmpresas();
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    })
                    .finally(() => {
                        appDispatch(configActions.setLoading({loading: false}))
                    });
            }
        })
    }

    const onSubmit = (e: any) => {
        setSearchParams({   
            page: defaultPage,
            size: searchParams.get('size') || defaultPageSize,
            nome: e.nome || '',
            codigo: e.codigo || '',
            cgc: e.cgc || ''
        })
        loadEmpresas();
    }

    const handleChangePage = () => {
        navigate('/painel/empresas/add');
    }

    return (
        <Box
            component={Paper}
            elevation={md ? 0 : 3}
            square
            sx={{
                backgroundColor: (t) => md ? t.palette.background.paper : t.palette.grey[50],
                borderRadius: 1,
                color: "primary.main",
                p: 2,
            }}
        >
            <Grid container sx={{mb: 3}}>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{fontSize: 16, fontWeight: "h6.fontWeight", mb: 3}}>Empresas</Box>
                </Grid>
                {hasAnyRoles(appSelector, ["ROLE_ADMIN"]) && (
                    <Grid item xs={12} sm={6} md={6} sx={{textAlign: {xs: "left", sm: "right", md: "right"}}}>
                        <Tooltip title={"Adicionar"} placement="top">
                            <Button variant={"contained"} onClick={() => handleChangePage()}>
                                {"Adicionar"}
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>


            <SeachForms forms={forms} onSubmit={onSubmit}/>
            <Grid item xs={12} sx={{mb:3}}>
                    {page && page.content.map(empresa => (
                        <Card sx={{ width: "100%", mb:2 }} key={empresa.id}>
                            <CardContent>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} md={hasAnyRoles(appSelector, ['ROLE_ADMIN']) ? 5 : 6} >
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Codigo: </b> {empresa.id.substring(4)}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Nome: </b> {empresa.nome}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Email: </b> {empresa.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={hasAnyRoles(appSelector, ['ROLE_ADMIN']) ? 5 : 6}>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>CNPJ: </b> {Format.cnpj(empresa.cgc)}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Grupo: </b> {empresa.id.substring(0,4)}
                                        </Typography>
                                     
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom component={'div'}>
                                            <b>Status: </b> {empresa.status ? <Chip label="Ativo" color="success" size={"small"} sx={{px:1}} /> :  <Chip label="Bloqueado" color="error" size={"small"} sx={{px:1}}/>}
                                        </Typography>
                                    </Grid>
                                    {hasAnyRoles(appSelector,['ROLE_ADMIN']) && (
                                        <Grid item xs={12} md={2}>
                                            <ActionButtons handleEdit={() => handleEdit(empresa.id)}
                                                           handleDelete={() => handleDelete(empresa.id)}/>
                                        </Grid>
                                    )}

                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            {page && page.totalElements > 0 &&
                <Grid container item xs={12}>
                    <Grid item md={6} xs={6}>
                        <Pagination
                            count={page?.totalPages}
                            defaultPage={parseInt(defaultPage)}
                            page={parseInt(searchParams.get('page') || defaultPage)}
                            onChange={(event, value) => {
                                setSearchParams({page: `${value}`, size: searchParams.get('size') || defaultPageSize})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "right"}}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Registros por pagina"
                            onChange={(e:any) => {
                                setSearchParams({page: "1" , size: e.target.value})
                            }}
                            size="small"
                            value={searchParams.get('size') || defaultPageSize}
                            sx={{minWidth: 150}}
                        >
                            {PAGE_SIZE_SELECT.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            }
        </Box>
  );
}


export default ListEmpresas;