import MyCard from 'components/card';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import download from 'downloadjs';
import {Button, Grid} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {styled} from '@mui/material/styles';
import {SolicitacaoMateriaisService} from 'services/SolicitacaoMateriaisService';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from 'store';
import {configActions} from "store/config";

const Embed = styled( 'embed' )( {
  width: '100%',
  height: '100vh',
} );

const ImprimirSolicitacaoMateriais = () => {
  const appDispatch = useAppDispatch();
  const [pdf, setPdf] = useState<string>();
  const [blob, setBlob] = useState<Blob>();
  const { id } = useParams();
  const navigate = useNavigate();
  const config = useSelector((state: RootState) => state.config);

  const loadPdf = useCallback( () => {
    if ( id ) {
      appDispatch(configActions.setLoading({loading: true}))
      SolicitacaoMateriaisService.getPdf( config.empresa.empresaCodigo, id )
        .then( ( res ) => {
          const file = new Blob( [res.data], { type: 'application/pdf' } );
          const fileURL = URL.createObjectURL( file );
          setPdf( fileURL );
          setBlob( file );
        } )
        .catch( ( err ) => {
          toast.error( err.response.data.message );
        } )
        .finally( () => {
          appDispatch(configActions.setLoading({loading: false}))
        } );
    } else {
      toast.error( 'Não foi possível carregar a solicitação' );
      navigate( '/painel/solicitacao-materiais' );
    }
  }, [appDispatch,config.empresa.empresaCodigo, id, navigate] );

  useEffect( () => {
    loadPdf();
  }, [loadPdf, id, navigate] );

  const handleDownload = () => {
    if ( blob )
      download(
        blob,
        Math.random().toString().replace( /\D/g, '' ) + '.pdf',
        'application/pdf'
      );
  };

  return (
    <MyCard title={'Imprimir solicitação'}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={() => handleDownload()}
            >
              <DownloadIcon />
              DOWNLOAD
            </Button>
          </Grid>
          <Grid item md={12} lg={12}>
            <Embed type="application/pdf" src={pdf} />
          </Grid>
        </Grid>
    </MyCard>
  );
};

export default ImprimirSolicitacaoMateriais;
