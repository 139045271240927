import {Button, Card, Grid, Typography} from "@mui/material";
import {LstAgenda} from "types/crm";
import Format from "utils/formatadores";
import {ModalDetails} from "../modalDetails";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useState} from "react";

type Props = {
    d: LstAgenda;
    loadDados: () => void;
}

const CardAtendimento = ({d, loadDados}: Props) => {
    const [modalDetail, setModalDetail] = useState(false);

    return (
        <Grid item xs={12} sx={{alignItems: "center"}}>
            <Card variant="elevation" sx={{p: 2}}>
                <Grid container alignItems="center">
                    <Grid item sm={4} xs={12}>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Nome de Cliente:</b> {d.nome}
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Data de Agendamento:</b> {Format.data(d.alerta)}
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Data de Contato:</b> {d.contato && Format.data(d.contato)}
                        </Typography>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Button onClick={() => setModalDetail(true)}
                            variant="contained"
                            color='info'
                            sx={{ width: '100%' }}
                            >
                            <VisibilityIcon />
                        </Button>
                        { modalDetail &&
                            <ModalDetails d={d} loadDados={loadDados} modalDetail={modalDetail} setModalDetail={setModalDetail} />
                        }
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        
    );
}

export default CardAtendimento;