import {Box, Button, Grid, Paper, useMediaQuery, useTheme} from "@mui/material";
import {useCrm} from "contexts/CrmContext";
import {ReactNode} from "react";
import MenuButton from "../menuButton";

type Props = {
  children: ReactNode;
  title: string;
}

const BaseCard = ( { children, title }: Props ) => {
  const theme = useTheme();
  const md = useMediaQuery( theme.breakpoints.down( "md" ) );
  const { scroll, screen, setScreen, hideMenu } = useCrm();

  return (
    <Box
      component={Paper}
      elevation={md ? 0 : 3}
      square
      sx={{
        backgroundColor: ( t ) => md ? t.palette.background.paper : t.palette.grey[50],
        borderRadius: 1,
        color: "primary.main",
        p: 2,
        minHeight: '100%',
        maxHeight: !scroll ? '77vh' : '100%'
      }}
    >
      <Grid container sx={{ mb: 2 }} alignContent={"space-between"}>
        <Grid item xs={12} sm={6} md={6}>
          <Box sx={{ fontSize: 16, fontWeight: "h6.fontWeight" }}>
            {title}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <MenuButton />
          {screen !== "Início" && !hideMenu &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => setScreen( "Início" )}
              sx={{
                position: 'fixed',
                right: 210,
                top: [74, 74, 98],
                elevation: 0
              }}
            >
              Voltar
            </Button>
          }
        </Grid>
      </Grid>
      {children}
    </Box>
  )
}

export default BaseCard;