export const inputsTypes = [
    {tipo: 'text', label: 'Texto'},
    {tipo: 'select', label: 'Combo'},
    {tipo: 'checkbox', label: 'Checkbox'},
    {tipo: 'radio', label: 'Radio'},
    {tipo: 'number', label: 'Numero'},
    {tipo: 'textarea', label: 'Texto grande'},
    {tipo: 'email', label: 'Email'},
    {tipo: 'range', label: 'Intervalo'},
    {tipo: 'url', label: 'Link'},
    {tipo: 'time', label: 'Hora'},
    {tipo: 'datetime-local', label: 'Data'},
]