import {useCallback, useEffect, useState} from "react";
import MyCard from "components/card";
import {SpringPage} from "types/springpage";
import {AdminService} from "services/AdminService";
import {toast} from "react-toastify";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {Card, CardContent, Chip, Grid, MenuItem, Pagination, TextField, Typography} from "@mui/material";
import {Administrador} from "types/user";
import {useNavigate, useSearchParams} from "react-router-dom";
import ActionButtons from "components/buttons";
import {PAGE_SIZE_SELECT} from "utils/constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useForm} from "react-hook-form";
import SeachForms from "components/searchForms";
import {useAppDispatch} from "store";
import {configActions} from "store/config";

const ListAdmins = () => {
    const [page, setPage] = useState<SpringPage<Administrador>>();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPageSize = '10';
    const defaultPage = '1';
    const sweetScreen = withReactContent(Swal)
    const forms = useForm({
        defaultValues: {
            nome: searchParams.get('nome') || '',
            codigo: searchParams.get('codigo') || ''
        },
      });
    const appDispatch = useAppDispatch();

    const handleEdit = (id: string) => {
        navigate(`/painel/admins/edit/${id}`);
    }

    const handleDelete = (id: string) => {
        sweetScreen.fire({
            title: `Deseja realmente excluir este administrador: \n ${id.substring(5)} - ${page.content.find(admin => admin.id === id).nome} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, deletar administrador!`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        }).then((result) => {
            if (result.isConfirmed) {
                appDispatch(configActions.setLoading({loading: true}))
                AdminService.delete(id)
                    .then((res) => {
                        toast.success("Administrador deletado com sucesso!");
                        loadAdmins();
                    })
                    .catch((err) => {
                        toast.error(err.response.data.message);
                    })
                    .finally(() => {
                        appDispatch(configActions.setLoading({loading: false}))
                    });
            }
        })
    }

    const loadAdmins = useCallback(() => {
        appDispatch(configActions.setLoading({loading: true}))
        const params: AxiosRequestConfig = {
            params: {
                page: parseInt(searchParams.get('page') || defaultPage)-1 ,
                size: searchParams.get('size') || defaultPageSize,
                nome: searchParams.get('nome') || '',
                codigo: searchParams.get('codigo')  || ''
            }
        }
        AdminService.findAll(params)
            .then((res: AxiosResponse<SpringPage<Administrador>>) => {
                setPage(res.data);
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                appDispatch(configActions.setLoading({loading: false}))
            });
    }, [searchParams, appDispatch]);

    useEffect(() => {
        loadAdmins();
    }, [loadAdmins]);

    const onSubmit = (e: any) => {
        setSearchParams({   page: defaultPage,
            size: searchParams.get('size') || defaultPageSize,
            nome: e.nome || '',
            codigo: e.codigo || ''
        })
        loadAdmins();
    }

    return (
        <MyCard title={'Administradores'} action={() => navigate('/painel/admins/add')}>
            <>
                <SeachForms forms={forms} onSubmit={onSubmit}/>
                <Grid item xs={12} sx={{ mb: 3 }}>
                        {page && page.content.map(admin => (
                            <Card sx={{ width: "100%", mb: 2 }} key={admin.id}>
                                <CardContent>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} md={5}>
                                            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                <b>ID: </b> {admin.id.substring(4)}
                                            </Typography>
                                            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                <b>Nome: </b> {admin.nome}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                                <b>Email: </b> {admin.email}
                                            </Typography>
                                            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom component={'div'}>
                                                <b>Status: </b> {admin.status ? <Chip label="Ativo" color="success" size={"small"} sx={{ px: 1 }} /> : <Chip label="Bloqueado" color="error" size={"small"} sx={{ px: 1 }} />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <ActionButtons handleEdit={() => handleEdit(admin.id)}
                                                handleDelete={() => handleDelete(admin.id)} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                </>
            {page && page.totalElements > 0 &&
                <Grid container item xs={12}>
                    <Grid item md={6} xs={6}>
                        <Pagination
                            count={page?.totalPages}
                            defaultPage={parseInt(defaultPage)}
                            page={parseInt(searchParams.get('page') || defaultPage)}
                            onChange={(event, value) => {
                                setSearchParams({page: `${value}`, size: searchParams.get('size') || defaultPageSize})
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign:  "right"}}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Registros por pagina"
                            onChange={(e:any) => {
                                setSearchParams({page: "1" , size: e.target.value})
                            }}
                            size="small"
                            value={searchParams.get('size') || defaultPageSize}
                            sx={{minWidth: 150}}
                        >
                            {PAGE_SIZE_SELECT.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            }

        </MyCard>
    )
}


export default ListAdmins;
