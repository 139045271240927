/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useReducer, useState} from "react";
import {Autocomplete, Button, Card, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useForm} from "react-hook-form";
import MyCard from "components/card";
import SaveIcon from "@mui/icons-material/Save";
import ButtonDelete from "components/buttons/delete";
import ModalAddMateriais from "components/solicitacao-material/modal/addMateriais";
import SolicitacaoMateriaisUtils from "utils/solicitacao-materiais";
import {toast} from "react-toastify";
import {CampoFilter, SolicitacaoMaterial} from "types/solicitacao-materiais";
import {SolicitacaoMateriaisService} from "services/SolicitacaoMateriaisService";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {filterOptions} from "utils/constants";
import {useAppDispatch, useAppSelector} from "store";
import {useAuthState} from "store/auth";
import {configActions, useConfigState} from "store/config";

type SolicitacaoMaterialForm = {
  solicitante: string;
  dataEmissao: string;
  filialEntrega: string;
};

const AddMateriais = () => {
  const [showModal, setShowModal] = useState( false );
  const [filiais, setFiliais] = useState<CampoFilter[]>();
  const [centroCusto, setCentroCusto] = useState<CampoFilter[]>();
  const [selecionadosReducer, dispatch] = useReducer(
    SolicitacaoMateriaisUtils.reducerSelecionados,
    []
  ); // Usado na lista de materiais cadastrados
  const config = useAppSelector(useConfigState);
  const { userName } = useAppSelector(useAuthState)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SolicitacaoMaterialForm>();
  const appDispatch = useAppDispatch();

  const handleView = () => {
    setShowModal( true );
  };

  const handleClose = () => {
    setShowModal( false );
  };

  const removeMaterial = ( material: any ) => {
    dispatch( { type: "remove", payload: material } );
  };

  const addMaterial = ( material: any ) => {
    material.qtdSolicitada = "1";
    material.necessidade = moment().format( "YYYY-MM-DD" );
    material.observacao = "Sem observação";
    dispatch( { type: "add", payload: material } );
    toast.success( `Produto ${material.descricao} adicionado com sucesso !`, {
      autoClose: 1000,
    } );
  };

  const addCentroCusto = ( centroCusto: any, codigo: string ) => {
    dispatch( {
      type: "updateCentroCusto",
      payload: {
        codigo,
        centroCusto,
      },
    } );
  };

  const addNecessidade = ( necessidade: string, codigo: string ) => {
    dispatch( {
      type: "updateNecessidade",
      payload: {
        codigo,
        necessidade,
      },
    } );
  };

  const addUnidades = ( qtdSolicitada: string, codigo: string ) => {
    dispatch( {
      type: "updateQtdSolicitada",
      payload: {
        codigo,
        qtdSolicitada,
      },
    } );
  };

  const addObservacao = ( observacao: string, codigo: string ) => {
    dispatch( {
      type: "updateObservacao",
      payload: {
        codigo,
        observacao,
      },
    } );
  };

  const buscarInfos = useCallback( async () => {
    try {
      appDispatch(configActions.setLoading({loading: true}))

      const centroCustoResponse = await SolicitacaoMateriaisService.findCentroCustoFiltro( config.empresa.empresaCodigo );
      setCentroCusto( centroCustoResponse.data.map(pr => {return {campo: pr.campo, descricao: pr.descricao}}));

      const filiaisResponse = await SolicitacaoMateriaisService.findFiliaisFiltro( config.empresa.empresaCodigo );
      setFiliais( filiaisResponse.data );

    } catch ( error ) {
      toast.error( 'Erro ao carregar campos de filtro' );
    } finally {
      appDispatch(configActions.setLoading({loading: false}))
    }
  }, [config.empresa.empresaCodigo] );

  useEffect( () => {
    buscarInfos();
  }, [] );


  // SALVAR A SOLICITAÇÃO
  const saveSolicitacao = ( solicitacao: any ) => {
    appDispatch(configActions.setLoading({loading: true}))
    SolicitacaoMateriaisService.saveSolicitacao( config.empresa.empresaCodigo, solicitacao )
      .then( ( res ) => {
        toast.success( `Solicitação nº ${res.data.numero} salva com sucesso!`, {
          autoClose: 1000,
        } );
        navigate( "/painel/solicitacao-materiais" );
      } )
      .catch( ( err ) =>
        toast.error( err.response.data.message, { autoClose: 1000 } )
      )
      .finally( () => appDispatch(configActions.setLoading({loading: false})) );
  };

  const salvarSolicitacao = ( data: SolicitacaoMaterial ) => {

    let solicitacao = {
      dataEmissao: data.dataEmissao.replace( /-/g, '' ),
      codComprador: data.codComprador,
      filialEntrega: data.filialEntrega,
      materiais: selecionadosReducer.map((e) => {e.necessidade = e.necessidade.replaceAll( "-", '' );return e;})
    };

    if ( selecionadosReducer.length === 0 ) {
      toast.error( "Adicione pelo menos um material" );
      return;
    }

    let valid = true;

    for ( let i = 0; i < selecionadosReducer.length; i++ ) {
      if ( selecionadosReducer[i].qtdSolicitada === "0" ) valid = false;
    }

    if ( !valid ) {
      toast.error( "O valor da quantidade deve ser maior que 0" );
      return;
    }

    saveSolicitacao( solicitacao );
  };

  return (
    <div>
      <MyCard title={"Adicionar solicitação de materiais"}>
        <Box component="form" onSubmit={handleSubmit( salvarSolicitacao )}>
          <Grid container spacing={2} sx={{ mb: 6 }}>
            <Grid item xs={4}>
              <TextField
                label="Solicitante"
                variant="outlined"
                fullWidth
                value={userName}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ readOnly: true }}
                error={!!errors.solicitante}
                helperText={errors.solicitante?.message}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type={"date"}
                label="Data de Emissão"
                variant="outlined"
                fullWidth
                inputProps={{
                  readOnly: true,
                }}
                defaultValue={moment().format( "YYYY-MM-DD" )}
                {...register( "dataEmissao", {
                  required: {
                    value: true,
                    message: "Date de Emissão obrigatória",
                  },
                } )}
                error={!!errors.dataEmissao}
                helperText={errors.dataEmissao?.message}
              />
            </Grid>
            {filiais ?     
            <Grid item xs={4}>
              <TextField
                {...register( 'filialEntrega', { required: {
                  value: true,
                  message: "Local de Entrega obrigatório",
                }})}
                select
                required
                label="Local de Entrega"
                defaultValue={''}
                variant="outlined"
                error={!!errors.filialEntrega}
                helperText={errors.filialEntrega?.message.toString()}
                sx={{ width: '100%' }}
              >
                <MenuItem value={''} disabled>
                  Selecione um local de entrega
                </MenuItem>
                {filiais && filiais.map( ( campo ) => (
                  <MenuItem key={campo.campo} value={campo.campo}>
                    {campo.descricao}
                  </MenuItem>
                ) )}
              </TextField>
            </Grid>
            :
            <Grid item xs={4}>
              <TextField
                label="Local de Entrega"
                variant="outlined"
                fullWidth
                defaultValue={''}
                {...register( "filialEntrega", {
                  required: {
                    value: true,
                    message: "Local de Entrega obrigatório",
                  },
                } )}
                error={!!errors.filialEntrega}
                helperText={errors.filialEntrega?.message}
              />
            </Grid>
            }

          </Grid>

          {/* Listagem dos materiais */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ fontSize: 14, fontWeight: "h6.fontWeight", mb: 3 }}>
                Materiais da solicitação
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ mb: 3, textAlign: "right" }}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => {
                    handleView();
                  }}
                >
                  Adicionar Materiais
                </Button>
              </Box>
            </Grid>

            {selecionadosReducer.map( ( material, i ) => (
              <Grid item xs={12} key={i}>
                <Card
                  variant="elevation"
                  sx={{ p: 2, backgroundColor: ( t ) => t.palette.grey[50] }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={6} md={4} lg={1.5}>
                      <Typography sx={{ fontWeight: "900" }}>Código</Typography>
                      <Typography>{material.codigo}</Typography>
                    </Grid>
                    <Grid item xs={6} md={2} lg={0.5}>
                      <Typography sx={{ fontWeight: "900" }}>UM</Typography>
                      <Typography>{"UM"}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6} lg={3}>
                      <Typography sx={{ fontWeight: "900" }}>
                        Descrição
                      </Typography>
                      <Typography>{material.descricao}</Typography>
                    </Grid>

                    <Grid item xs={6} md={4.5} lg={2.5}>
                      <Typography sx={{ fontWeight: "900" }}>
                        Centro Custo
                      </Typography>
                      {centroCusto ?
                      <Autocomplete
                        disablePortal
                        options={centroCusto}
                        fullWidth
                        size="small"
                        filterOptions={filterOptions}
                        getOptionLabel={(option: any) => option.descricao}
                        isOptionEqualToValue={(option:any, value:any) => option.campo === value.campo}
                        renderInput={(params) => 
                          <TextField 
                            {...params}
                            required 
                          />}
                        onChange={( e, newValue ) => {
                          if (newValue) {
                            addCentroCusto( newValue.campo, material.codigo );
                          }
                        }}                                  
                      /> 
                      :
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        inputProps={{
                          readOnly: false,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={( e ) => {
                          addCentroCusto( e.target.value, material.codigo );
                        }}
                      />
                      }
                      
                    </Grid>
                    <Grid item xs={6} md={4.5} lg={2.5}>
                      <Typography sx={{ fontWeight: "900" }}>
                        Necessidade
                      </Typography>
                      <TextField
                        type={"date"}
                        variant="outlined"
                        size="small"
                        required
                        value={moment( material.necessidade ).format(
                          "YYYY-MM-DD"
                        )}
                        fullWidth
                        inputProps={{
                          readOnly: false,
                          max: "9999-12-31",
                          min: moment().format( "YYYY-MM-DD" ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={( e ) => {
                          addNecessidade( e.target.value, material.codigo );
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} md={3} lg={2}>
                      <Typography sx={{ fontWeight: "900" }}>Qtd.</Typography>
                      <TextField
                        multiline
                        type={"number"}
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        inputProps={{
                          min: "0.00001",
                          step: "0.00001",
                        }}
                        value={material.qtdSolicitada}
                        onChange={( e ) => {
                          addUnidades( e.target.value, material.codigo );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      <Typography sx={{ fontWeight: "900" }}>
                        Observação
                      </Typography>
                      <TextField
                        multiline
                        rows={2}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={( e ) => {
                          addObservacao( e.target.value, material.codigo );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                      &nbsp;
                      <ButtonDelete
                        handleDelete={() => removeMaterial( material )}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ) )}
          </Grid>
          <Box sx={{ mt: 3, textAlign: "right" }}>
            <Button variant={"contained"} color={"primary"} type={"submit"}>
              <SaveIcon /> Salvar
            </Button>
          </Box>
        </Box>
      </MyCard>
      {showModal && (
        <ModalAddMateriais
          showModal={showModal}
          onClose={() => handleClose()}
          selecionadosReducer={selecionadosReducer}
          addMaterial={addMaterial}
        />
      )}
    </div>
  );
};

export default AddMateriais;
