import {grey} from "@mui/material/colors";
import {Button, Grid, Modal, TextField, Typography, useMediaQuery} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, {useState} from "react";
import {ProdutoOrcamento} from "types/orcamento";
import Formatadores from "utils/formatadores";
import {PLACEHOLDER_IMAGEM} from "utils/constants";

type Props = {
    row: ProdutoOrcamento;
    handleAction: (data: ProdutoOrcamento) => void;
}

const OrcamentoModalTabelaPrecoRow = ({row, handleAction}: Props) => {
    const [openModal, setOpenModal] = useState(false);
    const [expandedImage, setExpandedImage] = useState('');
    const md = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    const openImageModal = (imageUrl: string) => {
        setExpandedImage(`data:image/jpeg;base64,${imageUrl}`);
        setOpenModal(true);
    };
    

    if(md){
        return (<>
            <Grid item container sx={{
                    border: 1,
                    borderColor: grey[400],
                    p: 1,
                    display: "flex",
                    alignItems: "center",
                    mb:2,
                    borderRadius: 1
                }}>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <img
                            src={`data:image/jpeg;base64,${row.imagem ? row.imagem : PLACEHOLDER_IMAGEM}`}
                            alt={row.descricao}
                            width={'30%'}
                            height={'auto'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => openImageModal(row.imagem ? row.imagem : PLACEHOLDER_IMAGEM)}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Typography variant={"body1"} fontSize={14}><b>Cod: </b> {row.codigo}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Typography variant={"body1"} fontSize={14}><b>Descrição: </b> {row.descricao}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Typography variant={"body1"} fontSize={14}><b>UM: </b> {row.unidadeMedida}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Typography variant={"body1"} fontSize={14}><b>Preço: </b> {Formatadores.moeda(row.valor)}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Typography variant={"body1"} fontSize={14}><b>Estoque: </b> {row.quantidade}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center", my: 1}}>
                        <TextField
                            type={'number'}
                            size={"small"}
                            variant="outlined"
                            inputProps={{min: '0', step: '1'}}
                            defaultValue={1}
                            fullWidth
                            onChange={(e) => {
                                row.qtd = parseInt(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button variant="contained" size={"small"} fullWidth={true} onClick={() => handleAction(row)}>
                            <AddIcon/>
                        </Button>
                    </Grid>
            </Grid>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                src={expandedImage}
                alt='Imagem ampliada'
                style={{
                    width: '60%',
                    height: 'auto',
                    border: '1px solid #3b3b3b'
                }}
                />
            </Modal>
        </>
            
        );
    }else{
        return (<>
                <Grid item container sx={{
                    border: 1,
                    borderColor: grey[300],
                    p: 1,
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Grid item xs={1.5} sx={{textAlign: "center"}}>
                        <Button variant="contained" size={"small"}
                                onClick={() => handleAction(row)}>
                            <AddIcon/>
                        </Button>
                    </Grid>
                    <Grid item  xs={1.5} sx={{ textAlign: "center"}}>
                        <img
                            src={`data:image/jpeg;base64,${row.imagem ? row.imagem : PLACEHOLDER_IMAGEM}`}
                            alt={row.descricao}
                            width={80}
                            height={80}
                            style={{ cursor: 'pointer' }}
                            onClick={() => openImageModal(row.imagem ? row.imagem : PLACEHOLDER_IMAGEM)}
                        />
                    </Grid>
                    <Grid item  xs={1} sx={{textAlign: "center"}}>
                        <TextField
                            type={'number'}
                            size={"small"}
                            variant="outlined"
                            inputProps={{min: '0', step: '1'}}
                            defaultValue={1}
                            onChange={(e) => {
                                row.qtd = parseInt(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item  xs={2} sx={{textAlign: "center"}}>
                        {row.codigo}
                    </Grid>
                    <Grid item  xs={2} sx={{textAlign: "center"}}>
                        {row.descricao}
                    </Grid>
                    <Grid item  xs={1} sx={{textAlign: "center"}}>
                        {row.unidadeMedida}
                    </Grid>
                    <Grid item  xs={1.5} sx={{textAlign: "center"}}>
                        {Formatadores.moeda(row.valor)}
                    </Grid>
                    <Grid item  xs={1.5} sx={{textAlign: "center"}}>
                        {row.quantidade}
                    </Grid>
                </Grid>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                    src={expandedImage}
                    alt='Imagem ampliada'
                    style={{
                        width: '25%',
                        height: 'auto',
                        border: '1px solid #3b3b3b'
                    }}
                    />
                </Modal>
            </>
        );
    }

}


export default OrcamentoModalTabelaPrecoRow;