import {API} from "services/Api";
import {AxiosRequestConfig} from "axios";

const URL = "/aprovacao-documentos";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const AprovacaoDocumentoService =  {
    findAll: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(URL, paramsGeral);
    },
    send: (data:any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(URL, data, paramsGeral);
    },
    findAllFiltrosDoc(codigo: string, params?: AxiosRequestConfig) {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/filtros/documentos`, paramsGeral);
    },
    lstCotas: (codigo: string, cPed: string, params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, cPed, codigo: codigo}
        else params = {params: {codigo: codigo, cPed}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/lstCotas`, paramsGeral);
    },

}