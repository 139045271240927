import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Card,
    CardActionArea,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Pagination,
    Slide,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {TransitionProps} from "@mui/material/transitions";
import {AxiosRequestConfig} from "axios";
import Loader from "components/loader";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {RestPage} from "types/prtoheuspage";
import {EpiService} from 'services/Epi';
import {toast} from "react-toastify";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import {Epi} from "types/Epi";
import ButtonSelect from "components/buttons/select";
import {PLACEHOLDER_IMAGEM} from "utils/constants";

type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    handleAdd: (data: Epi) => void;
    matricula?: string;
};

type SearchProps = {
    codigoEpi: string;
    nome: string;
    numCa: string;
};

const SendButton = styled(Button)({
    width: "100%",
    height: "100%",
});

const TypoBigLetter = styled(Typography)({
    fontSize: 14,
    lineHeight: 2,
    color: 'black',
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalEpi = ({show, setShow, handleAdd, matricula}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const config = useAppSelector(useConfigState);
    const [pageCount, setPageCount] = useState(1);
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const xs = useMediaQuery(theme.breakpoints.down('md'));
    const [epis, setEpis] = useState<RestPage<Epi>>();
    const {
        handleSubmit,
        register,
        formState: {errors}
    } = useForm<SearchProps>();
    let pageSize = 10;

    const getEpi = useCallback((data?: SearchProps) => {
        setIsLoading(true);
        const params: AxiosRequestConfig = {
            params: {
                page: pageCount,
                size: pageSize,
                nome: data && data.nome ? data.nome : '',
                codigoEpi: data && data.codigoEpi ? data.codigoEpi : '',
                validade: data && data.numCa ? data.numCa : '',
                codigoFunc: matricula
            },
        };
        EpiService.findEpi(config.empresa.empresaCodigo, params)
            .then((res) => {
                setEpis(res.data);
            })
            .catch((err) => {
                toast.error(
                    err.response.data.message || "Erro ao carregar EPIs"
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [pageCount, pageSize, matricula, config.empresa.empresaCodigo]);

    useEffect(() => {
        getEpi();
    }, [getEpi, pageCount]);

    const onSubmit = (data: SearchProps) => {
        getEpi(data);
    };

    return (
        <div>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"xl"}
                fullScreen={fullScreen}
                keepMounted
                onClose={() => setShow(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShow(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>{"Funcionarios"}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{mt: 2,}}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={12}>
                                <TextField
                                    {...register('codigoEpi')}
                                    error={!!errors.codigoEpi}
                                    type={"text"}
                                    label="Código do EPI"
                                    variant="outlined"
                                    sx={{width: "100%"}}
                                    helperText={errors.codigoEpi?.message}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField
                                    {...register('nome')}
                                    error={!!errors.nome}
                                    type={"text"}
                                    label="Nome do EPI"
                                    variant="outlined"
                                    sx={{width: "100%"}}
                                    helperText={errors.nome?.message}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <TextField
                                    {...register('numCa')}
                                    error={!!errors.nome}
                                    type={"data"}
                                    label="Codigo CA"
                                    variant="outlined"
                                    sx={{width: "100%"}}
                                    helperText={errors.numCa?.message}
                                />
                            </Grid>
                            <Grid item md={3} xs={12}>
                                <SendButton type={"submit"} variant="contained" size="large">
                                    Buscar
                                </SendButton>
                            </Grid>
                        </Grid>
                    </Box>
                    {!isLoading ? (
                        <Grid item container xs={12}>
                            <>
                                {(!xs) &&
                                    <Grid item container component={Card} md={12} sx={{mt: 1, p: 1}}>
                                        <Grid item md={1}>
                                            <TypoBigLetter><b>Imagem</b></TypoBigLetter>
                                        </Grid>
                                        <Grid item md={2}>
                                            <TypoBigLetter><b>Numero</b></TypoBigLetter>
                                        </Grid>
                                        <Grid item md={4}>
                                            <TypoBigLetter><b>Descrição</b></TypoBigLetter>
                                        </Grid>
                                        <Grid item md={1}>
                                            <TypoBigLetter><b>Num C.A</b></TypoBigLetter>
                                        </Grid>
                                        <Grid item md={1}>
                                            <TypoBigLetter><b>Dt.Val</b></TypoBigLetter>
                                        </Grid>
                                        <Grid item md={2}>
                                            <TypoBigLetter><b>Fornece</b></TypoBigLetter>
                                        </Grid>
                                        <Grid item md={1} />
                                    </Grid>
                                }
                                {epis && epis.conteudo.map((p, i) => (
                                    <CardActionArea onClick={() => handleAdd(p)} key={p.numero + p.item}>
                                        <Grid item container xs={12} alignItems={"center"} component={Card} sx={{mt: 1,p: 1,backgroundColor: i % 2 === 1 ? theme.palette.grey[300] : 'white'}}>
                                            <>
                                                <Grid item md={1} xs={12} sx={{textAlign: ["center", "left"]}}>
                                                    {p.image && <img
                                                        src={`data:image/jpeg;base64,${p.image ? p.image : PLACEHOLDER_IMAGEM}`}
                                                        alt={p.descricao} width={xs ? 150 : 70}
                                                        height={xs ? 150 : 70}/>}
                                                    {xs && <Divider sx={{my: 0.5}}/>}
                                                </Grid>
                                                <Grid item md={2} xs={6}>
                                                    {xs && <TypoBigLetter><b>Numero</b></TypoBigLetter>}
                                                    <TypoBigLetter>{p.numero}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={4} xs={6}>
                                                    {xs && <TypoBigLetter><b>Descrição</b></TypoBigLetter>}
                                                    <TypoBigLetter>{p.descricao}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={1} xs={6}>
                                                    {xs && <TypoBigLetter><b>Num C.A</b></TypoBigLetter>}
                                                    <TypoBigLetter>{p.numCa}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={1} xs={6}>
                                                    {xs && <TypoBigLetter><b>Dt.Val</b></TypoBigLetter>}
                                                    <TypoBigLetter>{p.dataValidade}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={2} xs={6}>
                                                    {xs && <TypoBigLetter><b>Fornece</b></TypoBigLetter>}
                                                    <TypoBigLetter>{p.fornecEpi}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={1} xs={12}>
                                                    <TypoBigLetter><ButtonSelect handleSelect={() => {}} /></TypoBigLetter>
                                                </Grid>
                                            </>
                                        </Grid>
                                    </CardActionArea>
                                ))}
                                <Grid item xs={12} sx={{mt: 2}}>
                                    {epis &&
                                        <Pagination
                                            count={Math.ceil(parseInt(epis.quantidade) / pageSize)}
                                            defaultPage={1}
                                            page={pageCount}
                                            onChange={(event, value) => {
                                                setPageCount(value)
                                            }}
                                        />
                                    }
                                </Grid>
                            </>
                        </Grid>
                    ) : (<Loader/>)}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalEpi;
