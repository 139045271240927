import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Card,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    Modal,
    Pagination,
    Slide,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {Box} from "@mui/system";
import {SolicitacaoMateriaisService} from "services/SolicitacaoMateriaisService";
import {toast} from "react-toastify";
import {AxiosRequestConfig} from "axios";
import {RestPage} from "types/prtoheuspage";
import {Materiais} from "types/solicitacao-materiais";
import {BarraDePesquisaMateriais} from "components/solicitacao-material/barraPesquisaMateriais";
import {PAGE_SIZE_SELECT, PLACEHOLDER_IMAGEM} from "utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import Loader from "components/loader";
import * as base64js from "base64-js";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";

type SearchProps = {
  nome: string;
  codigoProd: string;
};

type Props = {
  showModal: boolean;
  onClose: () => void;
  selecionadosReducer?: any;
  addMaterial: Function;
};

const Transition = React.forwardRef( function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
} );

const ModalAddMateriais = ( props: Props ) => {
  const config = useAppSelector(useConfigState);
  const [isLoading, setIsLoading] = useState( true );
  const [pageCount, setPageCount] = useState( 0 ); // ESTADO DA PÁGINA ATUAL
  const [pageSize, setPageSize] = useState( 10 ); // QUANTIDADE DE CONTEÚDO POR PÁGINA
  const [materiais, setMateriais] = useState<RestPage<Materiais>>();
  const [codigoProd, setCodigoProd] = useState( "" );
  const [nomeTab, setNomeTab] = useState( "" );
  const [stringPesquisa, setStringPesquisa] = useState<string>( "" );
  const [openModal, setOpenModal] = useState( false );
  const [expandedImage, setExpandedImage] = useState( "" );
  const fullScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const md = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const openImageModal = ( imageUrl?: string ) => {
    if ( !md ) {
      if ( imageUrl ) {
        setExpandedImage( `data:image/jpeg;base64,${imageUrl}` );
      } else {
        setExpandedImage(`data:image/jpeg;base64,${PLACEHOLDER_IMAGEM}`);
      }
      setOpenModal( true );
    }
  };

  const onPesquisa = ( data: SearchProps ) => {
    setCodigoProd( data.codigoProd );
    setNomeTab( data.nome );
    setPageCount( 0 );
  };

  const onPesquisaAvançada = ( data: string ) => {
    setStringPesquisa( data );
  };

  const handlePageCount = ( page: number ) => {
    setPageCount( page - 1 );
  };

  // BUSCA TODOS OS PRODUTOS
  const getMateriais = useCallback( () => {
    setIsLoading( true );
    const params: AxiosRequestConfig = {
      params: {
        page: pageCount,
        size: pageSize,
        nome: nomeTab,
        codigoProd: codigoProd,
        pesquisa: base64js.fromByteArray(
          new TextEncoder().encode( stringPesquisa )
        ),
      },
    };
    SolicitacaoMateriaisService.findAllMateriais( config.empresa.empresaCodigo, params )
      .then( ( res ) => {
        setMateriais( res.data );
      } )
      .catch( ( err ) => {
        toast.error( "Erro ao carregar os materiais", { autoClose: 1000 } );
      } )
      .finally( () => {
        setIsLoading( false );
      } );
  }, [codigoProd, config.empresa.empresaCodigo, nomeTab,pageCount, pageSize, stringPesquisa] );

  useEffect( () => {
    getMateriais();
  }, [getMateriais] );

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      <Dialog
        open={props.showModal}
        onClose={props.onClose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={fullScreen}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography
              color="primary"
              sx={{ fontSize: 16, fontWeight: "bold" }}
            >
              Adicione um produto
            </Typography>
            <IconButton aria-label="close" onClick={props.onClose}sx={{position: "absolute",right: 30,top: 10,zIndex: 1,color: ( theme ) => theme.palette.grey[500]}}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2} sx={{ mb: 5 }}>
              <Grid item xs={12}>
                <BarraDePesquisaMateriais
                  onPesquisa={onPesquisa}
                  textoPesquisa="Busque o nome do produto"
                  onPesquisaAvançada={onPesquisaAvançada}
                />
              </Grid>
            </Grid>
            
            {/* LISTAGEM DE MATERIAIS / PRODUTOS */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {materiais &&
                materiais.conteudo.map( ( material, i ) => (
                  <Grid item xs={12} key={i}>
                    <Card
                      variant="elevation"
                      sx={{ p: 2, backgroundColor: ( t ) => t.palette.grey[50] }}
                    >
                      <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: "space-around" }}
                      >
                        <Grid
                          item
                          xs={12}
                          md={2}
                          lg={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={`data:image/jpeg;base64,${material.imagem ? material.imagem : PLACEHOLDER_IMAGEM}`}
                            alt={material.descricao}
                            width={md ? "100%" : 80}
                            height={md ? "auto" : 80}
                            style={{ cursor: md ? "" : "pointer" }}
                            onClick={() => openImageModal( material.imagem )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid container>
                            <Grid item xs={6} md={4} lg={3}>
                              <Typography sx={{ fontWeight: "900" }}>
                                Código
                              </Typography>
                              <Typography>{material.codigo}</Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={3}>
                              <Typography sx={{ fontWeight: "900" }}>
                                UM
                              </Typography>
                              <Typography>{material.unidadeMedida}</Typography>
                            </Grid>
                            <Grid item xs={6} md={4} lg={3}>
                              <Typography sx={{ fontWeight: "900" }}>
                                Descrição
                              </Typography>
                              <Typography>{material.descricao}</Typography>
                            </Grid>
                            <Grid item xs={6} md={2} lg={3}>
                              <Typography sx={{ fontWeight: "900" }}>
                                Estoque
                              </Typography>
                              <Typography>{material.quantidade}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                type="submit"
                                fullWidth
                                onClick={() => props.addMaterial( material )}
                              >
                                <AddIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ) )}
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                md={10}
                sx={{ display: "flex", justifyContent: "left" }}
              >
                <Pagination
                  count={
                    materiais &&
                    Math.ceil( parseInt( materiais?.quantidade ) / pageSize )
                  } //Número total de materiais / pageSize
                  defaultPage={0}
                  page={pageCount + 1}
                  onChange={( event, value ) => {
                    handlePageCount( value );
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={2}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <TextField
                  select
                  label="Registros por pagina"
                  onChange={( e: any ) => {
                    setPageSize( e.target.value );
                  }}
                  size="small"
                  value={pageSize}
                  sx={{ minWidth: 150 }}
                >
                  {PAGE_SIZE_SELECT.map( ( option ) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ) )}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Modal
        open={openModal}
        onClose={() => setOpenModal( false )}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img
          src={expandedImage}
          alt="Imagem ampliada"
          style={{
            width: "25%",
            height: "auto",
            border: "1px solid #3b3b3b",
          }}
        />
      </Modal>
    </div>
  );
};

export default ModalAddMateriais;
