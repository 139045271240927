import {Button, Tooltip} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

type Props = {
    handleView: Function,
}

const ButtonView = ({handleView}: Props) => {
    return (
        <Tooltip title="Ver" placement="top">
            <Button variant={'contained'} onClick={() => handleView()} sx={{marginRight: 1,}} color={'info'} fullWidth>
                <VisibilityIcon/>
            </Button>
        </Tooltip>
    )
}

export default ButtonView;