import {createContext, ReactNode, useContext, useEffect} from "react";
import {ConfigResposta, ConfigWhite} from "types/configuracoes";
import {ConfiguracoesService} from "services/ConfiguracoesService";
import {ConfigUtils} from "utils/config";
import {AxiosResponse} from "axios";
import LoginLogo from "assets/imgs/logo-login.png";
import {useAppDispatch, useAppSelector} from 'store';
import {configActions, useConfigState} from "store/config";
import {toast} from "react-toastify";


type Props = {
    children: ReactNode;
}

export type ConfigContext = {
    getConfig: (codigo: string) => Promise<void>;
}

const ConfigContextType = createContext<ConfigContext>({} as ConfigContext);


const ConfigProvider = ({children}: Props) => {
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();

    const getConfig = async (codigo: string) => {
        appDispatch(configActions.setLoading({loading: true}));
        // falta codigo
        ConfiguracoesService.findByCodigo(codigo)
            .then((response: AxiosResponse<ConfigResposta|null>) => {
                if (response.status === 200) {
                    appDispatch(configActions.setParametros({parametros: response.data}));
                } else {
                    toast.error('Empresa não encontrada ou codigo incorreto!');
                }
            })
            .catch(error => {
                toast.error('Erro ao buscar configurações, tente novamente mais tarde.');
            })
            .finally(() => {
                appDispatch(configActions.setLoading({loading: false}));
            })
    };

    useEffect(() => {
        const host = window.location.hostname;
        if(!ConfigUtils.verifyDominio(host) && !config.configWhite.codigo) {
                appDispatch(configActions.setLoading({
                    loading: true,
                }));
                ConfiguracoesService.findByDominio(host)
                    .then((res: AxiosResponse<ConfigWhite>) => {
                        appDispatch(configActions.setConfigWhite({
                            configWhite: res.data,
                        }));
                    })
                    .catch(error => {
                        appDispatch(configActions.setConfigWhite({
                            configWhite: {
                                logo: LoginLogo,
                                nome: "Front Flow",
                                codigo: null,
                                dominio: null
                            }
                        }));
                    })
                    .finally(() => {
                        appDispatch(configActions.setLoading({
                            loading: false,
                        }));
                    })
        }
    }, [config.configWhite.codigo, appDispatch])

    useEffect(() => {
        const favicon = document.getElementById("favicon");
        if(favicon && config.configWhite.logo) favicon.setAttribute("href", config.configWhite.logo);
        if(config.configWhite.nome) document.title = config.configWhite?.nome;
    } , [config.configWhite.logo, config.configWhite.nome]);


    return (
        <ConfigContextType.Provider value={{getConfig}}>
            {children}
        </ConfigContextType.Provider>
    )
}

export default ConfigProvider;

export const useConfigContext = () => useContext(ConfigContextType);
