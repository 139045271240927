import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Loader from 'components/loader';
import React, {useCallback, useEffect, useReducer, useState} from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {useForm} from 'react-hook-form';
import {styled} from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import {CampoFilter, ProdutoFilter} from 'types/orcamento';
import OrcamentoUtils from 'utils/orcamento';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {toast} from 'react-toastify';
import {OrcamentosService} from 'services/OrcamentosService';
import DeleteIcon from '@mui/icons-material/Delete';
import {SolicitacaoMateriaisService} from 'services/SolicitacaoMateriaisService';
import {useConfigState} from 'store/config';
import {useAppSelector} from 'store';

type Props = {
  show: boolean;
  setShow: ( show: boolean ) => void;
  setFilters: ( produtos: ProdutoFilter[] ) => void;
  filters: ProdutoFilter[];
  modulo: 'ORCAMENTO' | 'SOLICITACAO_COMPRA';
};

const Transition = React.forwardRef( function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
} );

const CustomButton = styled( Button )( {
  width: '100%',
  height: '100%',
} );

const condicoes = [
    {value: '=', label: 'Igual a'},
    {value: '!=', label: 'Diferente de'},
    {value: '>', label: 'Maior que'},
    {value: '<', label: 'Menor que'},
    {value: '>=', label: 'Maior que ou igual a'},
    {value: '<=', label: 'Menor que ou igual a'},
    {value: 'like', label: 'Contém a expressão'},
    {value: 'not like', label: 'Não contém'},
];

const PesquisaAvancada = ( {
  show,
  setShow,
  setFilters,
  filters,
  modulo,
}: Props ) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState( false );
  const [state, dispatch] = useReducer( OrcamentoUtils.reduceFilter, [] );
  const [camposFilter, setCamposFilter] = useState<CampoFilter[]>( [] );
  const config = useAppSelector(useConfigState);

  useEffect( () => {
    if ( filters.length > 0 ) {
      filters.forEach( ( filter ) => {
        dispatch( { type: 'add', payload: filter } );
      } );
    }
  }, [filters] );

  const loadCamposFilter = useCallback( () => {
    setIsLoading( true );
    if ( modulo === 'ORCAMENTO' ) {
      OrcamentosService.findAllCamposFiltroProdutos( config.empresa.empresaCodigo )
        .then( ( res ) => setCamposFilter( res.data ) )
        .catch( () => toast.error( 'Erro ao carregar campos de filtro' ) );
    }
    if ( modulo === 'SOLICITACAO_COMPRA' ) {
      SolicitacaoMateriaisService.findAllCamposFiltroProdutos( config.empresa.empresaCodigo )
        .then( ( res ) => setCamposFilter( res.data ) )
        .catch( () => toast.error( 'Erro ao carregar campos de filtro' ) );
    }
    setIsLoading( false );
  }, [config.empresa.empresaCodigo, modulo] );

  useEffect( () => {
    loadCamposFilter();
  }, [loadCamposFilter] );

  const onSubmit = ( data: any ) => {
    if ( state.length === 0 ) {
      dispatch( {
        type: 'add',
        payload: data,
      } );
      reset();
    } else {
      if ( state[state.length - 1].juncao ) {
        dispatch( {
          type: 'add',
          payload: data,
        } );
        reset();
      } else {
        toast.warn( 'Adicione o tipo de junção para continuar' );
      }
    }
  };

  return (
    <div>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={'xl'}
        keepMounted
        onClose={() => setShow( false )}
        aria-describedby="alert-dialog-slide-description"
      >
        <IconButton
          aria-label="close"
          onClick={() => setShow( false )}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: ( theme ) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>{'Pesquisa avançada de produtos'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', width: '100%', mt: 1 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Grid
                  container
                  spacing={2}
                  component="form"
                  noValidate
                  onSubmit={handleSubmit( onSubmit )}
                  sx={{ mt: 2, width: '100%' }}
                >
                  <Grid item md={3} xs={12}>
                    <TextField
                      {...register( 'campo', { required: true } )}
                      select
                      label="Campo"
                      defaultValue={''}
                      variant="outlined"
                      error={!!errors.campo}
                      helperText={errors.campo?.message.toString()}
                      sx={{ width: '100%' }}
                    >
                      <MenuItem value={''} disabled>
                        Selecione um campo
                      </MenuItem>
                      {camposFilter.map( ( campo ) => (
                        <MenuItem key={campo.campo} value={campo.campo}>
                          {campo.descricao}
                        </MenuItem>
                      ) )}
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      {...register( 'condicao', { required: true } )}
                      select
                      label="Condição"
                      defaultValue={''}
                      variant="outlined"
                      error={!!errors.condicao}
                      helperText={errors.condicao?.message.toString()}
                      sx={{ width: '100%' }}
                    >
                      <MenuItem value={''} disabled>
                        Selecione um condição
                      </MenuItem>
                      {condicoes &&
                        condicoes.map( ( option ) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ) )}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      {...register( 'valor' )}
                      type={'text'}
                      label="Valor"
                      variant="outlined"
                      error={!!errors.valor}
                      helperText={errors.valor?.message.toString()}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sx={{ mb: 2 }}>
                    <CustomButton
                      type={'submit'}
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      Adicionar
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                    <TableContainer
                      sx={{
                        maxWidth: '100%',
                        border: { xs: '1px solid #bcbcbc' },
                        mb: 2,
                      }}
                    >
                      <Table
                        sx={{ border: 1, borderColor: 'rgba(0, 0, 0, 0.28)' }}
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Campo</TableCell>
                            <TableCell>Condição</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Junção</TableCell>
                            <TableCell>Remover</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.map( ( row: ProdutoFilter, index: number ) => (
                            <TableRow key={index}>
                              <TableCell>
                                {
                                  camposFilter.find(
                                    ( campo ) => campo.campo === row.campo
                                  )?.descricao
                                }
                              </TableCell>
                              <TableCell>
                                {
                                  condicoes.find(
                                    ( condicao ) =>
                                      condicao.value === row.condicao
                                  )?.label
                                }
                              </TableCell>
                              <TableCell>{row.valor}</TableCell>
                              <TableCell>
                                <TextField
                                  select
                                  label="Junção"
                                  defaultValue={row.juncao || 'N'}
                                  variant="outlined"
                                  sx={{ width: 100 }}
                                  onChange={( e ) => {
                                    dispatch( {
                                      type: 'update',
                                      payload: {
                                        ...row,
                                        juncao:
                                          e.target.value === 'N'
                                            ? ''
                                            : e.target.value,
                                      },
                                    } );
                                  }}
                                >
                                  <MenuItem value={'N'}>Nenhuma</MenuItem>
                                  <MenuItem value={'AND'}>E</MenuItem>
                                  <MenuItem value={'OR'}>OU</MenuItem>
                                </TextField>
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() =>
                                    dispatch( { type: 'remove', payload: row } )
                                  }
                                  color={'error'}
                                  variant={'contained'}
                                >
                                  <RemoveCircleOutlineIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ) )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
          <Box>
            <Button
              variant={'contained'}
              color={'primary'}
              type={'submit'}
              sx={{ mr: 1 }}
              onClick={() => {
                setFilters( state );
                setShow( false );
              }}
            >
              <SaveIcon /> Salvar
            </Button>
            <Button
              variant={'contained'}
              color={'warning'}
              type={'submit'}
              sx={{ mr: 1 }}
              onClick={() => {
                dispatch( { type: 'clear', payload: [] } );
              }}
            >
              <DeleteIcon /> Limpar
            </Button>
            <Button
              variant={'contained'}
              color={'error'}
              type={'button'}
              onClick={() => setShow( false )}
            >
              <CloseIcon /> Cancelar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PesquisaAvancada;
