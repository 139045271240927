import {useCallback, useEffect, useReducer, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MyCard from "components/card"
import {Acesso, Modulo, Permissao} from 'types/auth';
import AcessoUtils, {sortModulos} from 'utils/colaborador';
import {toastError} from 'utils/toastError';
import {PermissaoService} from 'services/PermissaoService';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {CadastroExternoService} from 'services/CadastroExternoService';
import {useAppDispatch, useAppSelector} from 'store';
import {configActions, useConfigState} from 'store/config';
import {FCampo} from "types/formulariosCampos";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {inputsTypes} from "utils/aprovacao-cadastro";

type FormType = {
    status: string;
    autoAprove: string;
    dadosCorporativos: string;
    descricao: string;
}

type FormCamposType = {
    descricao: string;
    tipo: string;
    obrigatorio: string;
    valor: string;
}

export const formRequiredValue = ['select', 'checkbox', 'radio', 'range'];


const sortCampo = (a: FCampo, b: FCampo) => a.ordem - b.ordem;

const randonId = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

const proximoDaOrdem = (campos: FCampo[]) => {
    let ordem = 0;
    campos.forEach((campo) => {
        if (campo.ordem > ordem) {
            ordem = campo.ordem;
        }
    })
    return ordem + 1;
}

const isPermission = (state: Acesso[], acessoM: Acesso, mod: Modulo, per: Permissao): boolean => {
    let acesso = state.find((acesso: Acesso) => acesso.empresaCodigo === acessoM.empresaCodigo);
    if (acesso === undefined) return false;
    let modulo = acesso.modulos.find((modu: Modulo) => modu.nome === mod.nome);
    if (modulo === undefined) return false;
    let permissao = modulo.permissoes.find((perm: Permissao) => perm.nome === per.nome);
    return permissao !== undefined;
}

const camposReducer = (state: FCampo[], action: { type: string, payload: FCampo }) => {
    switch (action.type) {
        case 'add':
            //campo uuid
            action.payload.campo = randonId();
            return [...state, action.payload];
        case 'remove':
            return state.filter((campo: FCampo) => campo.campo !== action.payload.campo);
        case 'clear':
            return [];
        case 'sortUp':
            if (state.length > 1) {
                const index = state.findIndex((element) => element.ordem === action.payload.ordem);
                if (state[index].ordem > 1) {
                    state[index].ordem -= 1;
                    state[index - 1].ordem += 1;
                    state.sort(sortCampo);
                    return [...state];
                }
            }
            return state;
        case 'sortDown':
            if (state.length > 1) {
                const index = state.findIndex((element) => element.ordem === action.payload.ordem);
                if (state[index].ordem <= state.length) {
                    state[index].ordem += 1;
                    state[index + 1].ordem -= 1;
                    state.sort(sortCampo);
                    return [...state];
                }
            }
            return state;
        default:
            return state;
    }
}

const AddFormularios = () => {
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const [listAcessos, setListAcessos] = useState<Acesso[]>([])
    const [state, dispatch] = useReducer(AcessoUtils.reducerAcessos, []);
    const [stateCampos, dispatchCampos] = useReducer(camposReducer, []);
    const navigate = useNavigate();
    const autoAprove = [{value: "0", label: 'Não'}, {value: "1", label: 'Sim'}];
    const dadosCorporativos = [{value: "0", label: 'Não'}, {value: "1", label: 'Sim'}];
    const {handleSubmit, formState: {errors}, register} = useForm<FormType>({
        mode: 'all',
        defaultValues: {
            status: '1',
            descricao: ''
        }
    });
    const formCampos = useForm<FormCamposType>({mode: 'all'});
    const [tipo, setTipo] = useState<string>('text');

    const getAcessos = useCallback(() => {
        appDispatch(configActions.setLoading({
            loading: true,
        }));
        let params: AxiosRequestConfig = {
            params: {
                codigo: config.parametros.codigoUnico,
                full: true
            }
        };
        PermissaoService.findAllAcessos(params)
            .then((res: AxiosResponse<Acesso[]>) => {
                setListAcessos(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    dispatch({type: 'add', payload: {acs: {...res.data[i], modulos: []}}})
                }
            })
            .catch((err) => {
                toastError(err, "Erro ao buscar acessos !");
            })
            .finally(() => {
                appDispatch(configActions.setLoading({
                    loading: false,
                }));
            })
    }, [appDispatch, config.parametros.codigoUnico]);

    useEffect(() => {
        getAcessos();
    }, [getAcessos]);

    const handleChangeMod = (checked: any, acs: Acesso, mod: Modulo) => {
        if (checked) {
            dispatch({type: 'addMod', payload: {acs, mod}})
        } else {
            dispatch({type: 'removeMod', payload: {acs, mod}})
        }
    }

    const handleChangePerm = (checked: any, acs: Acesso, mod: Modulo, permissao: Permissao) => {
        if (checked) {
            dispatch({type: 'addPer', payload: {acs, mod, permissao}})
        } else {
            dispatch({type: 'removePer', payload: {acs, mod, permissao}})
        }
    }

    const onSubmit = (data: any) => {

        if (!config.empresa.empresaCodigo) {
            toast.error('É necessario selecionar uma empresa para continuar.')
            return;
        }

        appDispatch(configActions.setLoading({
            loading: true,
        }));
        const formData = {
            descricao: data.descricao,
            ativo: true,
            autoAprove: data.autoAprove === "1",
            dadosCorporativos: data.dadosCorporativos === "1",
            campos: stateCampos,
            acessos: state
        }
        CadastroExternoService.createLink(formData, {params: {codigo: config.empresa.empresaCodigo}})
            .then((res) => {
                toast.success(`Link criado com sucesso !`);
                navigate("/painel/formularios")
            })
            .catch((err) => toastError(err, "Erro ao salvar !"))
            .finally(() => appDispatch(configActions.setLoading({
                loading: false,
            })));

    }

    const handeleCamposSubmit = ({tipo, valor, obrigatorio, descricao}: FormCamposType) => {
        if(!tipo) {
            toast.warn("Você precisa selecionar um tipo de campo!");
            return;
        }
        if(!descricao) {
            toast.warn("Você precisa informar uma descrição para o campo!");
            return;
        }
        if(formRequiredValue.includes(tipo) && !valor) {
            toast.warn("Você precisa informar os valores para o campo!");
            return;
        }

        dispatchCampos({
            type: 'add',
            payload: {
                campo: '',
                tipo,
                ordem: proximoDaOrdem(stateCampos),
                obrigatorio: obrigatorio === "1",
                descricao,
                valor: valor
            }
        })
        formCampos.reset();
        setTipo('text');
    }

    return (
        <MyCard title={'Gerar link'}>
            <Grid container>
                <Grid item xs={12} md={6} sx={{p:2,borderRight: {md:1, xs: 0}, borderColor: (t) => t.palette.grey[200]}}>
                    <Box sx={{mb: 2}}>
                        <Typography>Configurações</Typography>
                        <Divider/>
                    </Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                        <TextField
                            {...register("descricao")}
                            required
                            error={!!errors.descricao}
                            label="Descrição"
                            variant="outlined"
                            fullWidth
                            helperText={errors.descricao?.message}
                        />

                        <TextField
                            {...register("autoAprove")}
                            error={!!errors.autoAprove}
                            required
                            select
                            defaultValue={"0"}
                            label="Auto Aprovação"
                            variant="outlined"
                            fullWidth
                            sx={{mt: 2}}
                            helperText={errors.autoAprove?.message}
                        >
                            {autoAprove.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>))}
                        </TextField>
                        <TextField
                            {...register("dadosCorporativos")}
                            error={!!errors.dadosCorporativos}
                            required
                            select
                            defaultValue={"0"}
                            label="Habilitar campos corporativos? (cnpj)"
                            variant="outlined"
                            fullWidth
                            sx={{mt: 2}}
                            helperText={errors.dadosCorporativos?.message}
                        >
                            {dadosCorporativos.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>))}
                        </TextField>
                        <Box sx={{mb: 2, mt: 3}}>
                            <Typography>Permissões do Colaborador</Typography>
                            <Divider/>
                        </Box>
                        {listAcessos.map((acessoM: Acesso) => (
                            <Accordion sx={{width: "100%"}} key={acessoM.empresaCodigo}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography sx={{fontSize: 16, fontWeight: 600}}>{acessoM.empresaNome}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12} sx={{mb: 2}}>
                                            <TextField
                                                required
                                                select
                                                size={'small'}
                                                defaultValue={state.find((acesso: Acesso) => acesso.empresaCodigo === acessoM.empresaCodigo).status ? "1" : "0"}
                                                onChange={(e) => {
                                                    let temp = acessoM;
                                                    temp.status = e.target.value === "1";
                                                    dispatch({type: 'updateStatus', payload: {acs: temp}})
                                                }}
                                                label="Status"
                                                variant="outlined"
                                                sx={{width: '100%', mt: 3}}
                                            >
                                                <MenuItem value={"1"}>Ativo</MenuItem>
                                                <MenuItem value={"0"}>Bloqueado</MenuItem>
                                            </TextField>
                                        </Grid>

                                        {acessoM.modulos.sort(sortModulos).map((mod) => (
                                            <Grid item xs={12} md={6} lg={4}
                                                  sx={{border: 1, borderColor: (t) => t.palette.grey[200], p: 2}}
                                                  key={mod.nome}>
                                                <FormControlLabel
                                                    key={mod.nome}
                                                    label={mod.descricao}
                                                    sx={{fontWeight: 600}}
                                                    control={
                                                        <Checkbox
                                                            name={mod.nome}
                                                            onChange={(e) => handleChangeMod(e.target.checked, acessoM, mod)}
                                                            checked={!!state.find((acesso: Acesso) => acesso.empresaCodigo === acessoM.empresaCodigo).modulos.find((modu: Modulo) => modu.nome === mod.nome)}
                                                        />
                                                    }
                                                />
                                                {mod.permissoes.length > 0 && (
                                                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                                        {mod.permissoes.map((per, i) => (
                                                            <FormControlLabel
                                                                key={i}
                                                                label={per.nome}
                                                                control={
                                                                    <Checkbox
                                                                        name={per.nome}
                                                                        onChange={(e) => handleChangePerm(e.target.checked, acessoM, mod, per)}
                                                                        checked={isPermission(state, acessoM, mod, per)}
                                                                    />
                                                                }
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        <Box sx={{mt: 3, textAlign: 'right'}}>
                            <Button variant={'contained'} color={'primary'} type={'submit'}>
                                <InsertLinkIcon sx={{mr: 1}}/> Gerar Link
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} component={"form"} onSubmit={formCampos.handleSubmit(handeleCamposSubmit)} sx={{p:2}} >
                    <Box sx={{mb: 2}}>
                        <Typography>Campos Personalizados</Typography>
                        <Divider/>
                    </Box>
                    <TextField
                        {...formCampos.register("descricao")}
                        label={"Descrição"}
                        variant="outlined"
                        type={'text'}
                        sx={{mb: 2}}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                    <TextField
                        {...formCampos.register("tipo")}
                        label={"Tipo"}
                        select
                        fullWidth
                        sx={{mb: 2}}
                        InputLabelProps={{shrink: true,}}
                        SelectProps={{native: true}}
                        onChange={(e) => setTipo(e.target.value)}
                    >
                        {inputsTypes.map(op => <option value={op.tipo} key={op.tipo}>{op.label}</option>)}
                    </TextField>
                    <TextField
                        {...formCampos.register("obrigatorio")}
                        label={"Obrigatório"}
                        select
                        fullWidth
                        sx={{mb: 2}}
                        InputLabelProps={{shrink: true,}}
                        SelectProps={{native: true}}
                    >
                        <option value={'1'}>
                            SIM
                        </option>
                        <option value={'0'}>
                            NÃO
                        </option>
                    </TextField>

                    {formRequiredValue.includes(tipo) &&
                        <TextField
                            {...formCampos.register("valor")}
                            label={"Valores"}
                            variant="outlined"
                            multiline
                            sx={{mb: 2}}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            helperText={"Valores separados por ;"}
                        />
                    }

                    <Button variant={'contained'} color={'primary'} type={"submit"}>
                        <AddBoxIcon/>{" "}Adicionar
                    </Button>
                    <Divider sx={{mt:2}}/>
                    {stateCampos.length > 0 && stateCampos.map((campo, i) => (
                        <Grid item container sx={{my: 2}} key={i}>
                            <Grid item md={1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <DoDisturbAltIcon onClick={() => dispatchCampos({type: 'remove', payload: campo})} sx={{cursor: "pointer"}}/>
                            </Grid>
                            <Grid item md={10} sx={{display: "flex", justifyContent: "center",  alignItems: "center"}}>
                                <TextField
                                    label={"Campo"}
                                    size={"small"}
                                    fullWidth
                                    value={campo.descricao}
                                    inputProps={{readOnly: true}}
                                    InputLabelProps={{shrink: true,}}
                                />
                            </Grid>
                            <Grid item md={1} sx={{display: "flex", flexDirection: "column", justifyContent: "center",  alignItems: "center"}}>
                                <ExpandLessIcon sx={{cursor: "pointer"}} onClick={() => dispatchCampos({type: 'sortUp', payload: campo})}/>
                                <ExpandMoreIcon sx={{cursor: "pointer"}} onClick={() => dispatchCampos({type: 'sortDown', payload: campo})}/>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

        </MyCard>
    )
}

export default AddFormularios