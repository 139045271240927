import {toast} from "react-toastify";


export const toastError = (err: any, defaultMessage: string):void => {
    if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.status+": "+ err.response.data.message);
    } else {
        if (err.status){
            toast.error(err.status+": "+defaultMessage);
        }else{
            toast.error(defaultMessage);
        }
    }
}