import {Grid, Paper} from "@mui/material";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import {useAuthState} from "store/auth";

type States = {
    from: {
        pathname: string;
        hash: string;
        search: string;
        key: string;
        state: any;
    };
}

const AuthLayout = () => {
    const config = useAppSelector(useConfigState);
    const location = useLocation();
    const state = location?.state as States;
    const fromLocation = state?.from?.pathname ? state.from.pathname : "/painel";
    const {isAuthenticated} = useAppSelector(useAuthState);

    if(isAuthenticated){
        return <Navigate to={fromLocation} state={{ from: location }} replace />;
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                component={"img"}
                src={config.configWhite.logo}
                alt={config.configWhite.nome}
                xs={12}
                md={6}
                sx={{
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.grey[100],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    p: { xs: 5, md: 10}
                }}
            />
            <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square
                  sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "Center",
                        alignItems: "center",
                        paddingX: { xs: 3, md: 10},
                        paddingTop: { xs: 4, md: 0}
                    }}
            >
                <Outlet />
            </Grid>
        </Grid>
    );
}

export default AuthLayout;