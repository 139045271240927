import React, {useCallback, useEffect, useReducer, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {GrupoEmpresaService} from "services/GrupoEmpresaService";
import {toast} from "react-toastify";
import {PermissaoService} from "services/PermissaoService";
import {AxiosResponse} from "axios";
import MyCard from "components/card";
import {Autocomplete, Button, Divider, Grid, MenuItem, TextField, Typography, useTheme} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {filterOptions} from "utils/constants";
import {Validadores} from "utils/validadores";
import {Modulo, ModuloLimites} from "types/auth";
import {useAppDispatch} from "store";
import {configActions} from "store/config";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

type UsuarioForm = {
    id: number;
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    status: string;
    cgc: string;
    permissoes: string[];
}

type Payload = {
    content?: ModuloLimites[],
    prev?: ModuloLimites,
    next?: ModuloLimites
}

type Action = {
    type: 'SET' | 'ADD' | 'REMOVE' | 'UPDATE' | 'CLEAR',
    payload?: Payload
}

const permissaoReducer = (state: any, action: Action) => {
    switch (action.type) {
        case 'SET':
            return action.payload.content;
        case 'ADD':
            return [...state.filter((item: ModuloLimites) => item.nome !== action.payload.content[0].nome), action.payload.content[0]];
        case 'REMOVE':
            return state.filter((item: ModuloLimites) => item.nome !== action.payload.content[0].nome);
        case 'UPDATE':
            if (!action.payload.next || !action.payload.prev) return state;
            return state.map((item: ModuloLimites) => {
                if (item.nome === action.payload.prev.nome) {
                    return action.payload.next;
                }
                return item;
            });
        case 'CLEAR':
            return [];
        default:
            return state;
    }
}

const EditGrupoEmpresa = () => {
    const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
    const {handleSubmit, formState: {errors}, getValues, reset, register, control} = useForm<UsuarioForm>({
        mode: 'all',
        defaultValues: {
            id: 0,
            nome: '',
            password: '',
            confirm_senha: '',
            email: '',
            status: '1',
        }
    });
    const [options, setOptions] = useState<{ id: string; label: string; }[]>([]);
    const [optionsSelected, setOptionsSelected] = useState<{ id: string; label: string; }>(null);
    const navigate = useNavigate();
    const {id} = useParams();
    const appDispatch = useAppDispatch();
    const [state, dispatch] = useReducer(permissaoReducer, []);
    const theme = useTheme();
    const [quantidade, setQuantidade] = useState<number>(0);
    const [modulos, setModulos] = useState<Modulo[]>([]);

    const loadUser = useCallback(() => {
        if (id) {
            appDispatch(configActions.setLoading({loading: true}))
            GrupoEmpresaService.findById(id as string)
                .then(res => {
                    res.data.status = res.data.status ? 1 : 0;
                    res.data.password = '';
                    dispatch({type: 'SET', payload: {content: res.data.limites}});
                    reset(res.data);
                })
                .catch(err => {
                    toast.error(err.message);
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                });
        }
    }, [id, reset, appDispatch]);

    useEffect(() => {
        loadUser();
    }, [loadUser]);


    const onSubmit = (data: any) => {
        if (id) {
            const formData = {
                ...data,
                status: parseInt(data.status),
                raizCnpj: data.cgc.replace(/\D/g, ''),
                limites: state,
            }
            appDispatch(configActions.setLoading({loading: true}))
            GrupoEmpresaService.update(id, formData)
                .then(() => {
                    toast.success("Salvo com sucesso !");
                    navigate("/painel/grupos-empresas")
                })
                .catch(() => toast.error("Erro ao salvar !"))
                .finally(() => appDispatch(configActions.setLoading({loading: false})));
        } else {
            navigate("/painel/grupos-empresas");
            toast.error("Id não encontrado !");
        }
    }

    const getPermissoes = useCallback(() => {
        PermissaoService.findAll()
            .then((res: AxiosResponse<Modulo[]>) => {
                setModulos(res.data);
                let temp = res.data.map(pr => {return {id: pr.nome, label: `${pr.descricao}`}});
                temp = temp.filter((item) => !state.find((item2: ModuloLimites) => item2.nome === item.id));
                setOptions(temp);
            })
            .catch((err) => {
                toast.error(err);
            })
    }, [state]);

    useEffect(() => {
        getPermissoes();
    }, [getPermissoes]);


    const handleChange = (e: any) => {
        setOptionsSelected(e);
    };

    return (
        <MyCard title={'Editar grupo de empresa'}>
            <Grid component="form" container onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off" spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        {...register('nome')}
                        InputLabelProps={{shrink: true}}
                        required
                        error={!!errors.nome}
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        helperText={errors.nome?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register('email')}
                        required
                        error={!!errors.email}
                        InputLabelProps={{shrink: true}}
                        type={'email'}
                        label="E-mail"
                        variant="outlined"
                        fullWidth
                        helperText={errors.email?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register("password", {validate: (value) => {
                                if (value) return Validadores.senha(value)
                            }})}
                        error={!!errors.password}
                        InputLabelProps={{shrink: true}}
                        type={'password'}
                        label="Senha"
                        variant="outlined"
                        fullWidth
                        helperText={errors.password?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register("confirm_senha", {validate: (value) => {
                                const {password} = getValues();
                                if (value && password && value !== password) return 'As senhas não são iguais.';
                            }})}
                        error={!!errors.confirm_senha}
                        type={'password'}
                        label="Confirmar senha"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{shrink: true}}
                        helperText={errors.confirm_senha?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...register('cgc', {validate: (value) => {
                                if (value.length !== 8) return 'A raiz do CNPJ precisa ter 8 digitos.'}
                        })}
                        error={!!errors.cgc}
                        required
                        type={'text'}
                        label="Raiz CNPJ"
                        InputLabelProps={{shrink: true}}
                        variant="outlined"
                        fullWidth
                        helperText={errors.cgc?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller 
                        name="status"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.status}
                                required
                                select
                                label="Status"
                                variant="outlined"
                                fullWidth
                                helperText={errors.status?.message}
                            >
                                {statusField.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>))}
                            </TextField>
                        )}

                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography color='primary' sx={{fontSize: 16, fontWeight: 'bold'}}>
                        Permissões
                    </Typography>
                    <Divider sx={{my:1}} />
                </Grid>
                { state.map((item: ModuloLimites, i) => (
                    <Grid item container  xs={12} spacing={.5} key={i}>
                        <Grid item xs={5}>
                            <TextField
                                value={item.descricao}
                                type={'text'}
                                label="Modulo"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                value={item.limite}
                                type={'number'}
                                label="Quantidade"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                    dispatch({type: 'UPDATE', payload: {prev: item, next: {...item, limite: parseInt(e.target.value)}}})
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant={'outlined'}
                                color={"inherit"}
                                fullWidth
                                sx={{
                                    height: "100%",
                                    borderColor: theme.palette.grey[400],
                                    color: theme.palette.error.main
                                }}
                                onClick={() => {
                                    dispatch({type: 'REMOVE', payload: {content: [item]}})
                                    setOptions(stateAnt => [...stateAnt, {id: item.nome, label: item.descricao}]);
                                }}
                            >
                                <RemoveIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Grid item container xs={12} spacing={.5}>
                    <Grid item xs={5}>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            value={optionsSelected}
                            filterOptions={filterOptions}
                            options={options}
                            fullWidth
                            isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Permissões" InputLabelProps={{shrink: true}} />}
                            onChange={(e, newValue) => {
                                handleChange(newValue);
                            }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            value={quantidade}
                            onChange={(e) => {
                                setQuantidade(parseInt(e.target.value));
                            }}
                            type={'number'}
                            inputProps={{
                                min:0,
                                step: 1
                            }}
                            label="Quantidade"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            fullWidth
                            sx={{height: "100%"}}
                            onClick={() => {
                                if(!optionsSelected) return toast.warn('Selecione um modulo');
                                let modulo = modulos.find((item: Modulo) => item.nome === optionsSelected.id);
                                dispatch({type: 'ADD', payload: {content: [{...modulo, limite: quantidade}]}})
                                setQuantidade(0)
                                setOptionsSelected(null)
                                setOptions(stateAnt => stateAnt.filter((item) => item.id !== modulo.nome));
                            }}
                        >
                            <AddIcon/>
                        </Button>
                    </Grid>
                </Grid>
            
                <Grid item xs={12}>
                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                        <SaveIcon/> Salvar
                    </Button>
                </Grid>
            </Grid>
        </MyCard>
    )
}

export default EditGrupoEmpresa;