import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/modulos";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const PermissaoService =  {
    findAll: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(URL, paramsGeral);
    },
    findAllPermissoes: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/permissoes`, paramsGeral);
    },
    findAllAcessos: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/acessos`, paramsGeral);
    },
    findByGrupo: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/grupos`, paramsGeral);
    },
    findById: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/${id}`, paramsGeral);
    },
    create: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(URL, data, paramsGeral);
    },
    update: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.put(`${URL}`, data, paramsGeral);
    },
    delete: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.delete(`${URL}/${id}`, paramsGeral);
    }
}