import {Acesso, Modulo, Permissao} from 'types/auth';


type AcessoAction = {
    type: 'add'| 'addMod' | 'addPer' |'update' | 'updateStatus' | 'remove'| 'removeMod' | 'removePer' | 'clear',
    payload: {
        acs: Acesso,
        mod?: Modulo,
        permissao?: Permissao
    },

}

export function sortModulos(a: Modulo, b: Modulo) {
  if (a.nome < b.nome) {
    return -1;
  }
  if (a.nome > b.nome) {
    return 1;
  }
  return 0;
}


const AcessoUtils = {
    reducerAcessos: (state: Acesso[], action: AcessoAction) => {
        switch (action.type) {
            case "add":
                action.payload.acs.modulos = action.payload.acs.modulos.sort(sortModulos);
                if(state.find((item: Acesso) => item.empresaCodigo === action.payload.acs.empresaCodigo)) {
                    return [...state];
                }
                return [...state, action.payload.acs];
            case "addMod":
                let toAdd= action.payload.mod;
                let acs = action.payload.acs;
                let find = state.find((item: Acesso) => item.empresaCodigo === acs.empresaCodigo);
                if(!find) {
                    acs.modulos = [toAdd].sort(sortModulos);
                    return [...state, acs];
                }else{
                    return [...state.map((item: Acesso) => {
                        if (item.empresaCodigo === acs.empresaCodigo) {
                            if(!item.modulos.find((mod: Modulo) => mod.nome === toAdd?.nome)){
                                item.modulos = [...item.modulos, toAdd].sort(sortModulos);
                            }
                        }
                        return item;
                    })];
                }
            case "addPer":
                return [...state.map((item: Acesso) => {
                    if (item.empresaCodigo === action.payload.acs.empresaCodigo) {
                        if(item.modulos.find((mod: Modulo) => mod.nome === action.payload.mod?.nome)){
                            let temp = item.modulos.find((mod: Modulo) => mod.nome === action.payload.mod?.nome);
                            if(temp){
                                if(!temp.permissoes.find((perm: Permissao) => perm.nome === action.payload.permissao.nome)){
                                    temp.permissoes = [...temp.permissoes, action.payload.permissao];
                                }
                            }
                            item.modulos = [...item.modulos.filter((mod: Modulo) => mod.nome !== action.payload.mod?.nome), temp].sort(sortModulos);
                        }
                    }
                    return item;
                })];

            case "update":
                return [...state.map((item: Acesso) => {
                    if (item.empresaCodigo === action.payload.acs.empresaCodigo) {
                        return action.payload.acs;
                    }
                    return item;
                }
                )];

            case "updateStatus":
                return [...state.map((item: Acesso) => {
                    if (item.empresaCodigo === action.payload.acs.empresaCodigo) {
                        item.status = action.payload.acs.status;
                    }
                   return item;
                })]
            case "remove":
                return [...state.filter((item: Acesso) => item.empresaCodigo !== action.payload.acs.empresaCodigo)];
            case "removeMod":      
                let temp1 =  state.map((item: Acesso) => {
                    if (item.empresaCodigo === action.payload.acs.empresaCodigo) {
                        return {
                            ...item,
                            modulos: item.modulos.filter((mod: Modulo) => mod.nome !== action.payload.mod?.nome).sort(sortModulos),
                        }
                    }
                    return item;
                });
                return temp1;
            case "removePer":
                let temp2 = state.map((item: Acesso) => {
                    if (item.empresaCodigo === action.payload.acs.empresaCodigo) {
                    const modulosAtualizados = item.modulos.map((mod: Modulo) => {
                        if (mod.nome === action.payload.mod?.nome) {
                        return {
                            ...mod,
                            permissoes: mod.permissoes.filter((perm: Permissao) => perm.nome !== action.payload.permissao.nome),
                        };
                        }
                        return mod;
                    });

                    return {
                        ...item,
                        modulos: modulosAtualizados,
                    };
                    }
                    return item;
                });
                return temp2;


            case "clear":
                return [];
            default:
                return state;
        }
    }
}

export default AcessoUtils;