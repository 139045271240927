import {Button, Card, Grid, Tooltip, Typography} from "@mui/material";
import Format from "utils/formatadores";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import {Documento} from "types/documentos";

type Props = {
    dc: Documento;
    handleView: (dc: Documento) => void;
    tipoExt?: string;
}


const CardItemPedidoCompra = ({dc, handleView, tipoExt} : Props) => {
    return (
        <Grid item xs={12} sx={{alignItems: "center"}}>
            <Card variant="elevation" sx={{p: 2}}>
                <Grid container alignItems="center">
                    <Grid item sm={2} xs={12}>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Documento:</b> {dc.codDocumento}
                        </Typography>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Item:</b> {dc.produtos[0].numeroItem}
                        </Typography>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Data:</b> {Format.data(dc.dataCria)}
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Tipo:</b> {tipoExt ? tipoExt : dc.tipo}
                        </Typography>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Valor total:</b> {Format.moeda(dc.valTotal)}
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Produto:</b> {dc.produtos[0].descricao}
                        </Typography>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Cond. Pag.:</b> {dc.condiPag}
                        </Typography>
                        <Typography sx={{fontSize: 16}} color="text.secondary">
                            <b>Fornecedor:</b> {dc.fornecedor}
                        </Typography>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Tooltip title="Ver" placement="top">
                            <Button variant={'contained'} onClick={() => handleView(dc)}
                                    sx={{width: "100%"}} color={'info'}>
                                <VisibilityIcon/>
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}

export default CardItemPedidoCompra;