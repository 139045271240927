import {Button, Tooltip} from "@mui/material";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

type Props = {
    handleCancel: Function,
}

const ButtonCancel = ({handleCancel}: Props) => {
    return (
        <Tooltip title="Cancelar" placement="top">
            <Button variant={'contained'} onClick={() => handleCancel()} sx={{marginRight: 1,}} color={'error'} fullWidth>
                <DoDisturbIcon/>
            </Button>
        </Tooltip>
    )
}

export default ButtonCancel;