import {AxiosRequestConfig} from "axios";
import {API} from "services/Api";

const URL = "/configuracoes-empresas";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const ConfiguracoesEmpresaService =  {
    findAll: (codigo: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        paramsGeral.params = {...paramsGeral.params, codigo};
        return API.get(`${URL}`, paramsGeral);
    },
    findByEmpresa: (codigo: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        paramsGeral.params = {...paramsGeral.params, codigo};
        return API.get(`${URL}/empresa`, paramsGeral);
    },
    update: (codigo: string, data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        paramsGeral.params = {...paramsGeral.params, codigo};
        return API.put(`${URL}`, data, paramsGeral);
    },
}