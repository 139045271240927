import {useState} from 'react';
import {Stepper} from "@mui/material";
import {PrimeiraTela} from 'components/cadastro-externo/stepper/primeiraTela';
import SegundaTela from 'components/cadastro-externo/stepper/segundaTela';
import {Navigate} from 'react-router-dom';
import {ConfigUtils} from 'utils/config';
import {useSelector} from 'react-redux';
import {RootState} from 'store';

// type Props = {
//     handleNext?: Function;
//     // handleBack?: Function;
// };

const TelaCadastroExterno = () => {
    const [activeStep, setActiveStep] = useState(0);
    const config = useSelector((state: RootState) => state.config);


    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    if (!ConfigUtils.verifyDominio(window.location.hostname)) {
        if (window.location.hostname !== config.configWhite.dominio) {
            return <Navigate to="/cadastro/nao-encontrada" replace />
        }
    }

    // const handleBack = () => {
    //     setActiveStep(activeStep - 1);
    // };

    
    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <PrimeiraTela handleNext={handleNext}/>;
            case 1:
                return <SegundaTela />;
            default:
                throw new Error('Unknown step');
        }
    }

    return  (
        <div>
             <Stepper activeStep={activeStep} sx={{mb: 2}}/>
            <>
                {getStepContent(activeStep)}
            </>
        </div>
    )
}

export default TelaCadastroExterno