import React, {useEffect, useState} from 'react'
import {Board} from '../board'
import {Divider, Grid, Paper} from "@mui/material";
import {CrmLead} from 'types/crm'
import {BoardHeader} from '../boardHeader';
import Loader from 'components/loader';
import {useCrm} from 'contexts/CrmContext';
import moment from 'moment';
import {useDrop} from 'react-dnd';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';
import {AxiosRequestConfig} from 'axios';
import {CrmService} from 'services/CrmService';

type Props = {
  dt: CrmLead[];
  id: string;
}

type CrmStatus = {
  codigoCliente: string;
  codigoVendedor: string;
  status: any;
  alerta: string;
}

const oneGrid = {
  display: 'flex',
  paddingTop: '10px',
  paddingBottom: '10px',
  minHeight: '100%',
}

const onePaper = {
  width: '380px',
  display: 'flex',
  maxHeight: '100%',
  marginLeft: '8px',
  marginRight: '8px',
  flexDirection: 'column'
}

export const BoardsList = React.memo(({ dt, id }: Props) => {
  const [data, setData] = useState<CrmLead[]>([]);
  const { vendedor, dispatch, isLoading, setIsLoading } = useCrm();
  const config = useAppSelector(useConfigState);
  const [, drop] = useDrop(() => ({
    accept: 'card',
    drop(item: any, monitor: any) {
      const isOver = monitor.isOver({ shallow: true });
      const canDrop = monitor.canDrop();
      if (!canDrop || !isOver) {
        return;
      }
      const dragIndex = item.status;
      const hoverIndex = id;
      if (dragIndex === hoverIndex) {
        return;
      }
      setIsLoading(true);
      onDragEnd(item.codigo, hoverIndex);
      setIsLoading(false);
    },
  }));

  const onDragEnd = (idCard: string, idHover: string ) => {
    const dados: CrmStatus = {
      alerta: "",
      codigoCliente: idCard,
      codigoVendedor: vendedor,
      status: idHover
    };
    const params : AxiosRequestConfig = {
      params: {
          codigo: config.empresa.empresaCodigo,
      }
    };
    if (!idHover) {
      return;
    } else {
      dispatch({
        type: 'update',
        payload: {id: idCard ,status: idHover}
      });
      CrmService.createStatus( config.empresa.empresaCodigo, idCard, dados, params )
    } 
  };

  const sort = (a: CrmLead, b: CrmLead) => {
    if (moment(a.alerta).format('YYYY-MM-DD') < moment(b.alerta).format('YYYY-MM-DD')) {
      return -1;
    } else if (moment(a.alerta).format('YYYY-MM-DD') > moment(b.alerta).format('YYYY-MM-DD')) {
      return 1;
    } else {
      return 0;
    }
  }
  
  useEffect(() => {
    const sorted = [...dt].sort(sort);
    setData(sorted);
  } ,[dt]);

  return (
    <Grid sx={oneGrid} 
          ref={drop} 
          role={'hold'}
    >
      <Paper elevation={3} sx={onePaper}>
        <BoardHeader status={id}/>
        <Divider />
        {isLoading && <Loader/>}
        {!isLoading &&
          <Grid sx={{
              height: '100%',
            }}
          >
            {data.map((d) => (
              <Board dt={d} key={d.codigo} />
            ))}
          </Grid>
        }
      </Paper>
    </Grid>
  )
});