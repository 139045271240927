import {AxiosRequestConfig} from "axios";
import {API} from "services/Api";

const URL = "/usuarios";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const DashboardService = {
  dashboardAdm: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = { ...paramsGeral.params, codigo };
    return API.get(URL + "/dashboard", paramsGeral);
  },
};
