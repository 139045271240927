import {AxiosRequestConfig} from "axios"
import {API} from "./Api";

const URL = "/crm";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const CrmService = {
  listLeads: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/loadLeads`, paramsGeral);
  },
  criaLead: (codigo: string, data: any, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.post(`${URL}/createLead`, data, paramsGeral);
  },
  createStatus: (codigo: string, codigoCliente: string, dados: any, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params.params, codigo: codigo, codigoCliente: codigoCliente }
    else params = { params: { dados: dados, codigo: codigo, codigoCliente: codigoCliente } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.post(`${URL}/createStatus`, dados, paramsGeral);
  },
  findHistorico: (codigo: string, codigoCliente: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo, codigoCliente: codigoCliente }
    else params = { params: { codigo: codigo, codigoCliente: codigoCliente } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/listHist`, paramsGeral);
  },
  findAgenda: (codigo: string, pageCount: number, pageSize: Number, codigoCliente: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo, pageCount: pageCount, pageSize: pageSize, codigoCliente: codigoCliente }
    else params = { params: { codigo: codigo, pageCount: pageCount, pageSize: pageSize, codigoCliente: codigoCliente } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/listAgenda`, paramsGeral);
  },
  createAgenda: (codigo: string, id: string, codigoCliente: string, codigoVendedor: string, dados: any, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params.params, codigo: codigo, id: id, codigoCliente: codigoCliente, codigoVendedor: codigoVendedor }
    else params = { params: { codigo: codigo, id: id, codigoCliente: codigoCliente, codigoVendedor: codigoVendedor } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.post(`${URL}/createAgenda`, dados, paramsGeral);
  },
  findCloseAg: (codigo: string, codigoCliente: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo, codigoCliente: codigoCliente }
    else params = { params: { codigo: codigo, codigoCliente: codigoCliente } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/findCloseAg`, paramsGeral);
  },
  findFunil: (codigoVendedor: string, codigo: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo, codigoVendedor: codigoVendedor }
    else params = { params: { codigo: codigo, codigoVendedor: codigoVendedor } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/findFunil`, paramsGeral);
  },
  findAllColumns(codigo: string, params?: AxiosRequestConfig) {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/filtros`, paramsGeral);
  },
  lstAllAgendamentos: (codigo: string, codigoVendedor: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params?.params, codigo: codigo, codigoVendedor: codigoVendedor }
    else params = { params: { codigo: codigo, codigoVendedor: codigoVendedor } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/findAllAgenda`, paramsGeral);
  },
  deleteAgenda: (codigo: string, id: string, params?: AxiosRequestConfig) => {
    if (params) params.params = { ...params.params, codigo: codigo, id: id }
    else params = { params: { codigo: codigo, id: id } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.delete(`${URL}/deleteAgenda`, paramsGeral);
  },
}   