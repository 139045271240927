import {Grid, Stack, Typography, useTheme} from "@mui/material";

type Props = {
  status: string;
}

export const BoardHeader = ({ status }: Props) => {
  const theme = useTheme();

  return (
    <Grid container sx={{
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row">
        <Grid item xs={12} >
          <Typography component='h5' variant='h5' fontWeight={900}>
            { status === "1" && "Contato" }
            { status === "2" && "Proposta" }
            { status === "3" && "Negociação" }
            { status === "4" && "Fechamento" }
            { status === "5" && "Ganhas" }
            { status === "6" && "Perdas" }
          </Typography>
        </Grid>
      </Stack>
    </Grid>
  );
}