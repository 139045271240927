import {Box, Grid, Paper, Typography} from "@mui/material"

const styles = { fontSize: 14, p:0, m: 0, textAlign: "center" }
    

const CardPedidoHeader = () => {
  return (
    <Box component={Paper} sx={{ width: "100%", mb:2, p: 2}}>
        <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={12} md>
                <Typography sx={styles} color="text.secondary" gutterBottom>
                    <b>Cód.</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography sx={styles} color="text.secondary" gutterBottom>
                    <b>Descrição.</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography sx={styles} color="text.secondary" gutterBottom>
                    <b>UM.</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography sx={styles} color="text.secondary" gutterBottom>
                    <b>Prc. Tab.</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography sx={styles} color="text.secondary" gutterBottom>
                    <b>Qtd.</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography sx={styles} color="text.secondary" gutterBottom>
                    <b>Prc. Venda.</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md>
                <Typography sx={styles} color="text.secondary" gutterBottom>
                    <b>Valor Total.</b>
                </Typography>
            </Grid>
            <Grid item xs={12} md>
            <Typography sx={styles} color="text.secondary" gutterBottom>
                <b>Deletar.</b>
            </Typography>
            </Grid>
        </Grid>
    </Box>
  )
}

export default CardPedidoHeader