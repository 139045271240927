import {useCallback, useEffect, useReducer, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {cnpj, cpf} from "cpf-cnpj-validator";
import {toast} from "react-toastify";
import {ColaboradorService} from "services/ColaboradorService";
import MyCard from "components/card";
import {Box, Button, Divider, FormControlLabel, Grid, MenuItem, TextField, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {Acesso, Modulo, Permissao} from "types/auth";
import AcessoUtils, {sortModulos} from "utils/colaborador";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {toastError} from "utils/toastError";
import {PermissaoService} from "services/PermissaoService";
import Checkbox from '@mui/material/Checkbox';
import {Validadores} from "utils/validadores";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

type UsuarioForm = {
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    status: string;
    cgc: string;
}

const isPermission = (state: Acesso[], acessoM: Acesso, mod: Modulo, per: Permissao): boolean => {
    let acesso = state.find((acesso: Acesso) => acesso.empresaCodigo === acessoM.empresaCodigo);
    if (acesso === undefined) return false;
    let modulo = acesso.modulos.find((modu: Modulo) => modu.nome === mod.nome);
    if (modulo === undefined) return false;
    let permissao = modulo.permissoes.find((perm: Permissao) => perm.nome === per.nome);
    return permissao !== undefined;
}


const AddColaboradores = () => {
    const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
    const {handleSubmit, formState: {errors}, getValues, register} = useForm<UsuarioForm>({
        mode: 'all',
        defaultValues: {
            nome: '',
            password: '',
            cgc: '',
            confirm_senha: '',
            email: '',
            status: '1',
        }
    });
    const navigate = useNavigate();
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const [listAcessos, setListAcessos] = useState<Acesso[]>([])
    const [state, dispatch] = useReducer(AcessoUtils.reducerAcessos, []);


    const onSubmit = (data: any) => {
        if (!config.empresa.empresaCodigo) {
            toast.error('É necessario selecionar uma empresa para continuar.')
            return;
        }
        data.cgc = data.cgc.replace(/\D/g, '');
        if (data.cgc.length === 14 && !cnpj.isValid(data.cgc)) {
            toast.error('CNPJ inválido');
            return;
        } else if (data.cgc.length === 11 && !cpf.isValid(data.cgc)) {
            toast.error('CPF inválido');
            return;
        } else {
            appDispatch(configActions.setLoading({
                loading: true,
            }));
            const formData = {
                password: data.password,
                nome: data.nome,
                email: data.email,
                cgc: data.cgc,
                status: data.status === "1" ? true : false,
                acessos: state,
            }
            ColaboradorService.create(formData, {params: {codigo: config.empresa.empresaCodigo, full: false}})
                .then((res) => {
                    toast.success(`Usuario criado com sucesso !\n Código: ${res.data.id}`);
                    navigate("/painel/colaboradores")
                })
                .catch((err) => toastError(err, "Erro ao salvar !"))
                .finally(() => appDispatch(configActions.setLoading({
                    loading: false,
                })));
        }
    }


    const getAcessos = useCallback(() => {
        let params: AxiosRequestConfig = {
            params: {
                codigo: config.parametros.codigoUnico,
                full: true
            }
        };
        PermissaoService.findAllAcessos(params)
            .then((res: AxiosResponse<Acesso[]>) => {
                setListAcessos(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    dispatch({type: 'add', payload: {acs: res.data[i]}})
                }
            })
            .catch((err) => {
                toastError(err, "Erro ao buscar acessos !");
            });
    }, [config.parametros.codigoUnico]);

    useEffect(() => {
        getAcessos();
    }, [getAcessos]);


    const handleChangeMod = (checked: any, acs: Acesso, mod: Modulo) => {
        if (checked) {
            dispatch({type: 'addMod', payload: {acs, mod}})
        } else {
            dispatch({type: 'removeMod', payload: {acs, mod}})
        }
    }

    const handleChangePerm = (checked: any, acs: Acesso, mod: Modulo, permissao: Permissao) => {
        if (checked) {
            dispatch({type: 'addPer', payload: {acs, mod, permissao}})
        } else {
            dispatch({type: 'removePer', payload: {acs, mod, permissao}})
        }
    }


    return (
        <MyCard title={'Adicionar colaborador'}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                <TextField
                    {...register("nome")}
                    required
                    error={!!errors.nome}
                    label="Nome"
                    variant="outlined"
                    fullWidth
                    helperText={errors.nome?.message}
                />
                <TextField
                    {...register("email")}
                    required
                    error={!!errors.email}
                    type={'email'}
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    sx={{mt: 3}}
                    helperText={errors.email?.message}
                />
                <TextField
                    {...register("password", {validate: (value) => Validadores.senha(value)})}
                    error={!!errors.password}
                    required
                    type={'password'}
                    label="Senha"
                    variant="outlined"
                    fullWidth
                    sx={{mt: 3}}
                    helperText={errors.password?.message}
                />
                <TextField
                    {...register("confirm_senha", {
                        validate: (value) => {
                            const {password} = getValues();
                            if (value !== password) return 'As senhas não são iguais.'
                        }
                    })}
                    error={!!errors.confirm_senha}
                    required
                    type={'password'}
                    label="Confirmar senha"
                    variant="outlined"
                    fullWidth
                    sx={{mt: 3}}
                    helperText={errors.confirm_senha?.message}
                />
                <TextField
                    {...register('cgc', {validate: (value) => Validadores.cgc(value),})}
                    error={!!errors.cgc}
                    required
                    type={'text'}
                    label="CPF/CNPJ"
                    variant="outlined"
                    fullWidth
                    sx={{mt: 3}}
                    helperText={errors.cgc?.message}
                />
                <TextField
                    {...register("status")}
                    error={!!errors.status}
                    required
                    select
                    defaultValue={"1"}
                    label="Status"
                    variant="outlined"
                    fullWidth
                    sx={{mt: 3}}
                    helperText={errors.status?.message}
                >
                    {statusField.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>))}
                </TextField>
                <Box sx={{mb: 2, mt: 3}}>
                    <Typography>Permissões do Colaborador</Typography>
                    <Divider/>
                </Box>
                {listAcessos.map((acessoM: Acesso) => (
                    <Accordion sx={{width: "100%"}} key={acessoM.empresaCodigo}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography sx={{fontSize: 16, fontWeight: 600}}>{acessoM.empresaNome}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} sx={{mb: 2}}>
                                    <TextField
                                        required
                                        select
                                        size={'small'}
                                        value={acessoM.status ? "1" : "0"}
                                        onChange={(e) => {
                                            let temp = acessoM;
                                            temp.status = e.target.value === "1" ? true : false;
                                            dispatch({type: 'updateStatus', payload: {acs: temp}})
                                        }}
                                        label="Status"
                                        variant="outlined"
                                        sx={{width: '100%', mt: 3}}
                                    >
                                        <MenuItem value={"1"}>Ativo</MenuItem>
                                        <MenuItem value={"0"}>Bloqueado</MenuItem>
                                    </TextField>
                                </Grid>

                                {acessoM.modulos.sort(sortModulos).map((mod) => (
                                    <Grid item xs={12} md={6} lg={4}
                                          sx={{border: 1, borderColor: (t) => t.palette.grey[200], p: 2}}
                                          key={mod.nome}>
                                        <FormControlLabel
                                            key={mod.nome}
                                            label={mod.descricao}
                                            sx={{fontWeight: 600}}
                                            control={
                                                <Checkbox
                                                    name={mod.nome}
                                                    onChange={(e) => handleChangeMod(e.target.checked, acessoM, mod)}
                                                    checked={!!state.find((acesso: Acesso) => acesso.empresaCodigo === acessoM.empresaCodigo).modulos.find((modu: Modulo) => modu.nome === mod.nome)}
                                                />
                                            }
                                        />
                                        {mod.permissoes.length > 0 && (
                                            <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                                {mod.permissoes.map((per) => (
                                                    <FormControlLabel
                                                        key={per.nome}
                                                        label={per.descricao ? per.descricao : per.nome}
                                                        control={
                                                            <Checkbox
                                                                name={per.descricao ? per.descricao : per.nome}
                                                                onChange={(e) => handleChangePerm(e.target.checked, acessoM, mod, per)}
                                                                checked={isPermission(state, acessoM, mod, per)}
                                                            />
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Box sx={{mt: 3, textAlign: 'right'}}>
                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                        <SaveIcon/> Salvar
                    </Button>
                </Box>
            </Box>
        </MyCard>
    )
}

export default AddColaboradores;