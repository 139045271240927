import {useCallback, useEffect, useReducer, useState} from "react";
import {
    Box,
    Button,
    Card,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    styled,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useForm} from "react-hook-form";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {AxiosRequestConfig} from "axios";
import {toast} from "react-toastify";
import {EpiService} from "services/Epi";
import {toastError} from "utils/toastError";
import moment from "moment/moment";
import download from "downloadjs";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import BaseCard from "components/funcionario-epi/base-card";
import ModalEpi from "components/funcionario-epi/modalEpi";
import ModalFuncionario from "components/funcionario-epi/modalFuncionario";
import {Epi, FiltroMotivo, Funcionario} from "types/Epi";
import EpiUtils from "utils/Epi";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {useAuthState} from "store/auth";

const TypoBigLetter = styled(Typography)({
    fontSize: 14,
    lineHeight: 2,
    color: 'black',
});

const FuncionarioEpi = () => {
    const [modalFuncionario, setModalFuncionario] = useState(false);
    const [modalEpi, setModalEpi] = useState(false);
    const [gestor, setGestor] = useState(false);
    const theme = useTheme();
    const config = useAppSelector(useConfigState);
    // const [modalPrint, setModalPrint] = useState(false);
    const xs = useMediaQuery(theme.breakpoints.down('md'));
    const [funcionario, setFuncionario] = useState<Funcionario>();
    const [motivos, setMotivos] = useState<FiltroMotivo[]>();
    const [epis,] = useState<Epi[]>();
    const [state, dispatch] = useReducer(EpiUtils.reducerEpis, epis || []);
    const [item, setItem] = useState(1);
    const {handleSubmit, formState: {errors}, setValue, getValues, register, reset} = useForm();
    const sweetScreen = withReactContent(Swal)
    const appDispatch = useAppDispatch();
    const {tokenData} = useAppSelector(useAuthState);
    const {empresa} = useAppSelector(useConfigState);

    const criaPedEpi = useCallback(() => {
        const params: AxiosRequestConfig = {
            params: {}
        };
        if (epis?.length === 0 || !epis) {
            toast.error('Nenhum EPI adicionado, não é possível salvar o pedido.');
        } else {
            appDispatch(configActions.setLoading({loading: true}))
            EpiService.criaPedidoEpi(config.empresa.empresaCodigo, epis, params)
                .then((res) => {
                    setFuncionario(res.data);
                })
                .catch((err) => {
                    toast.error(
                        err.response.data.message || "Erro ao carregar os Funcionário"
                    );
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                });
        }
    }, [epis, appDispatch, config.empresa.empresaCodigo]);

    const onSubmit = () => {
        criaPedEpi();
    }

    useEffect(() => {
        if (funcionario) {
            reset({
                matricula: funcionario.matricula,
                nome: funcionario.nome,
                centroCusto: funcionario.centroCusto,
                dataPedido: funcionario.dataPedido,
            });
        }
    }, [funcionario, reset]);

    const loadCamposMotivo = useCallback(() => {
        appDispatch(configActions.setLoading({loading: true}))
        EpiService.findAllMotivos(config.empresa.empresaCodigo)
            .then(res => {
                setMotivos(res.data)
            })
            .catch((err) => toastError(err, "Erro ao carregar motivos de requisição"))
            .finally(() => appDispatch(configActions.setLoading({loading: false})));
    }, [appDispatch, config.empresa.empresaCodigo]);

    useEffect(() => {
        loadCamposMotivo();
    }, [loadCamposMotivo]);

    const getFuncionario = useCallback(() => {
        appDispatch(configActions.setLoading({loading: true}))
        const params: AxiosRequestConfig = {
            params: {}
        };
        EpiService.findFuncionario(config.empresa.empresaCodigo, params)
            .then((res) => {
                setFuncionario(res.data.conteudo);
                if (res.data.conteudo) {
                    setValue("matricula", res.data.conteudo[0].matricula);
                    setValue("nome", res.data.conteudo[0].nome);
                    setValue("centroCusto", res.data.conteudo[0].centroCusto);
                    setValue("dataPedido", res.data.conteudo[0].dataPedido);
                }
            })
            .catch((err) => {
                toast.error(
                    err.response.data.message || "Erro ao carregar os Funcionário"
                );
            })
            .finally(() => {
                appDispatch(configActions.setLoading({loading: false}))
            });
    }, [appDispatch, config.empresa.empresaCodigo, setValue]);

    const isGerente = useCallback(() => {
        let empresaLogada = empresa.empresaCodigo;
        let empresaData = tokenData?.acessos?.filter((acesso) => acesso.empresaCodigo === empresaLogada)[0];
        let modulo = empresaData?.modulos.filter((acesso) => acesso.nome === 'FUNCIONARIO_EPI')[0];
        let isPermission = modulo?.permissoes.filter((acesso) => acesso.nome === 'GERENTE')[0];
        if (isPermission) {
            setGestor(true);
        } else {
            setGestor(false);
        }
    }, [empresa, tokenData]);

    useEffect(() => {
        isGerente();
    }, [isGerente]);

    useEffect(() => {
        if (!gestor) {
            getFuncionario();
        }
    }, [getFuncionario, gestor]);

    const handleSelectFuncionario = (data: Funcionario) => {
        if (state.length > 0) {
            dispatch({type: 'clear'});
        }
        if (funcionario) {
            setFuncionario({
                ...funcionario,
                matricula: data.matricula,
                nome: data.nome,
                centroCusto: data.centroCusto,
                dataPedido: data.dataPedido
            });
        }
        setValue("matricula", data.matricula);
        setValue("nome", data.nome);
        setValue("centroCusto", data.centroCusto);
        setValue("dataPedido", moment(new Date()).format('DD-MM-YYYY'));
        setModalFuncionario(false);
    }

    const handleAddEpi = (data: Epi) => {
        if (state.filter((item) => item.numero === data.numero).length === 0) {
            dispatch({
                type: 'add',
                payload: {
                    item: item.toString(),
                    numero: data.numero,
                    descricao: data.descricao,
                    tipo: data.tipo,
                    quantidade: "1",
                    motivo: "0",
                    numCa: data.numCa ? data.numCa : 'Sem Certificado',
                    dataValidade: data.dataValidade ? data.dataValidade : 'Indeterminado',
                    fornecEpi: data.fornecEpi ? data.fornecEpi : 'Sem Fornecedor',
                    lojaFornec: data.lojaFornec,
                    funcao: data.funcao ? data.funcao : 'Sem Função',
                    image: data.image ? data.image : 'Sem Imagem',
                }
            });
            setItem(item + 1);
            toast.success(`Epi ${data.descricao} adicionado com sucesso !`, {autoClose: 600,});
        } else {
            toast.error(`Epi ${data.descricao} já adicionado !`, {autoClose: 600,});
        }
    }

    const handleDeleteEpi = (data: Epi) => {
        dispatch({
            type: "remove",
            payload: data,
        });
        if (state.length === 1) {
            setItem(1);
        }
        toast.success(`Epi ${data.descricao} removido com sucesso !`, {autoClose: 600,});
    }

    const handleChangeMotivo = (event: any, p: Epi) => {
        p.motivo = event.target.value;
    }

    const handleChangeQtd = (event: any, p: Epi) => {
        p.quantidade = event.target.value;
    }

    const handleModalEpi = () => {
        if (getValues('matricula')) {
            setModalEpi(true);
        } else {
            toast.error('Selecione um funcionário para adicionar um EPI');
        }
    }

    const handlePrintSend = (data: Epi[]) => {
        var checkMotivos = true;
        data.forEach((item) => {
            if ((item.motivo === '0' || item.motivo === "") && checkMotivos) {
                checkMotivos = false;
            } else {
                item.motivo = motivos.find((motivo) => motivo.campo === item.motivo)?.descricao;
            }
        });
        if (data.length > 0 && checkMotivos) {
            sweetScreen.fire({
                title: 'Você deseja criar o pedido de EPI?',
                text: "Não e possivel cancelar apos aceitar!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, criar pedido!'
            }).then((result) => {
                if (result.isConfirmed) {
                    let checkReq = false;
                    for (let i = 0; i < data.length; i++) {
                        checkReq = !!(data[i].motivo && parseInt(data[i].quantidade) > 0);
                    }
                    if (checkReq) {
                        // setModalPrint(true);
                        appDispatch(configActions.setLoading({loading: true}))
                        const params: AxiosRequestConfig = {
                            params: {
                                matricula: funcionario?.matricula
                            }
                        };
                        const rData = {
                            matricula: funcionario?.matricula,
                            signature: "",
                            epis: data
                        };
                        EpiService.criaPedidoEpi(config.empresa.empresaCodigo, rData, params)
                            .then(res => {
                                const file = new Blob([res.data], {type: 'application/pdf'});
                                dispatch({type: 'clear'});
                                reset({
                                    matricula: "",
                                    nome: "",
                                    centroCusto: "",
                                    dataPedido: ""
                                });
                                sweetScreen.fire({
                                    title: 'Você deseja fazer download do pedido?',
                                    text: 'O pedido tambem sera enviado para o seu email!',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sim'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        download(file, Math.random().toString().replaceAll(/\D/g, '') + ".pdf", "application/pdf");
                                    }
                                    toast.success('Pedido de EPI gerado com sucesso!');
                                });
                            })
                            .catch((err) => {
                                toast.error(err.response.data.message);
                            })
                            .finally(() => {
                                appDispatch(configActions.setLoading({loading: false}))
                            });
                    } else {
                        toast.error('Preencha todos os campos obrigatórios.');
                    }
                }
            })
        } else {
            if (!checkMotivos) {
                toast.error('Preencha todos os campos obrigatórios.');
            } else {
                toast.error('Nenhum EPI adicionado, não é possível criar o pedido.');
            }
        }
    }

    return (
        <BaseCard title={"Adicionar pedido de EPI"}>
            <>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1, p: 1}} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={12}>
                            <TextField
                                {...register("matricula")}
                                required
                                error={!!errors.matricula}
                                label="Mat do Func"
                                variant="outlined"
                                helperText={errors.matricula?.message.toString()}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment:
                                        gestor && (
                                            <InputAdornment position="start" onClick={() => setModalFuncionario(true)}>
                                                <SearchIcon/>
                                            </InputAdornment>
                                        )
                                }}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                {...register("nome")}
                                error={!!errors.nome}
                                label="Nome"
                                variant="outlined"
                                inputProps={{readOnly: true}}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                helperText={errors.nome?.message.toString()}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <TextField
                                {...register("centroCusto")}
                                error={!!errors.centroCusto}
                                label="CC"
                                variant="outlined"
                                inputProps={{readOnly: true}}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                helperText={errors.centroCusto?.message.toString()}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                {...register("dataPedido")}
                                error={!!errors.dataPedido}
                                label="Data do Pedido"
                                variant="outlined"
                                inputProps={{readOnly: true}}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                                helperText={errors.dataPedido?.message.toString()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={() => {
                                handleModalEpi()
                            }}>
                                <AddIcon/> Adicionar EPI
                            </Button>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item container xs={12}>
                                <>
                                    {(!xs) &&
                                        <Grid item container component={Card} md={12} sx={{mt: 1, p: 1}}>
                                            <Grid item md={0.5}>
                                                <TypoBigLetter><b>Item</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={1.5}>
                                                <TypoBigLetter><b>Numero</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={3}>
                                                <TypoBigLetter><b>Descrição</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={1}>
                                                <TypoBigLetter><b>Num C.A</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={1}>
                                                <TypoBigLetter><b>Validade</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={2}>
                                                <TypoBigLetter><b>Fornece</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={1}>
                                                <TypoBigLetter><b>Quantidade</b></TypoBigLetter>
                                            </Grid>
                                            <Grid item md={1.5}>
                                                <TypoBigLetter><b>Motivo</b></TypoBigLetter>
                                            </Grid>
                                        </Grid>
                                    }
                                    {state && state.map((p, i) => (
                                        <Grid item
                                              container xs={12}
                                              component={Card}
                                              key={p.numero + p.item}
                                              sx={{
                                                  mt: 1,
                                                  p: 1,
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  backgroundColor: i % 2 === 1 ? theme.palette.grey[300] : 'white'
                                              }}
                                        >
                                            <>
                                                <Grid item md={0.5} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Item</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.item}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={1.5} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Numero</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.numero}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={3} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Descrição</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.descricao}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={1} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Num C.A</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.numCa}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={1} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Validade</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.dataValidade}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={2} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Fornece</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <TypoBigLetter>{p.fornecEpi}</TypoBigLetter>
                                                </Grid>
                                                <Grid item md={1} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Quantidade</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <TextField
                                                        required
                                                        size="small"
                                                        id="quantidade"
                                                        onChange={(e: any) => handleChangeQtd(e, p)}
                                                        defaultValue="1"
                                                        sx={{width: '80%'}}
                                                    />
                                                </Grid>
                                                <Grid item md={1.5} xs={6}>
                                                    {(xs) &&
                                                        <>
                                                            <TypoBigLetter><b>Motivo</b></TypoBigLetter>
                                                            {!xs && <Divider sx={{my: 0.5}}/>}
                                                        </>
                                                    }
                                                    <FormControl size="small" sx={{width: '80%'}}>
                                                        <Select
                                                            labelId="motivo-droplist"
                                                            id="motivo-droplist"
                                                            required
                                                            defaultValue="0"
                                                            onChange={(e: any) => handleChangeMotivo(e, p)}
                                                        >
                                                            <MenuItem value="0"></MenuItem>
                                                            {motivos && motivos.map((m) => (
                                                                <MenuItem value={m.campo}
                                                                          key={m.campo}>{m.descricao}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={0.5} xs={6} sx={{
                                                    display: {xs: 'flex'},
                                                    justifyContent: {xs: 'center'},
                                                    flexDirection: {xs: 'column'}
                                                }}>
                                                    <IconButton onClick={() => handleDeleteEpi(p)}>
                                                        <ClearIcon color="error"/> {xs &&
                                                        <TypoBigLetter>Excluir</TypoBigLetter>}
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        </Grid>
                                    ))}
                                </>
                            </Grid>
                        </Grid>
                    </Grid>
                    {state &&
                        <>
                            <Box sx={{mt: 3, textAlign: 'right'}}>
                                <Button variant={'contained'} color={'primary'} onClick={() => handlePrintSend(state)}>
                                    <SaveIcon/> Enviar pedido
                                </Button>
                            </Box>
                        </>
                    }
                </Box>
            </>
            {modalFuncionario && <ModalFuncionario show={modalFuncionario} setShow={setModalFuncionario}
                                                   handleSelect={handleSelectFuncionario}/>}
            {modalEpi && <ModalEpi show={modalEpi} setShow={setModalEpi} handleAdd={handleAddEpi}
                                   matricula={getValues("matricula")}/>}
        </BaseCard>
    );
}

export default FuncionarioEpi