import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/usuarios/empresas";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const EmpresaService =  {
    findAll: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}`, paramsGeral);
    },
    findByGrupo: (codigo: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/grupo/${codigo}`, paramsGeral);
    },
    findById: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/${id}`, paramsGeral);
    },
    create: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}`, data, paramsGeral);
    },
    update: (id: string, data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.put(`${URL}/${id}`, data, paramsGeral);
    },
    delete: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.delete(`${URL}/${id}`, paramsGeral);
    }
}