import {AxiosRequestConfig} from "axios";
import {API} from "services/Api";

const URL = "/relatorios";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};


export const RelatoriosService =  {
    findLogs: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/log`, paramsGeral);
    },
    findUsuario: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/usuarios`, paramsGeral);
    }
}