import React from "react";
import {Box, Button, Checkbox, Grid, Paper, Theme, Typography, useMediaQuery} from "@mui/material";
import {AgendamentoPedido} from "types/agendamentos";
import {styled} from "@mui/material/styles";
import moment from "moment";

type Props = {
    itens: AgendamentoPedido[],
    pedidosSelecionados: AgendamentoPedido[],
    handleNext?: () => void,
    handleAddPedido?: (pedido: AgendamentoPedido) => void
    handleRemovePedido?: (pedido: AgendamentoPedido) => void
}

const CustomHeader = styled(Typography)(({theme}) => ({
    fontWeight: "bold",
    fontSize: ".9rem",
    margin:0,
    padding:0
}));

const TableListItens = ({itens, handleNext, handleAddPedido, handleRemovePedido, pedidosSelecionados}: Props) => {

    const md = useMediaQuery((t: Theme) => t.breakpoints.down("md"));

    return (
        <Box>
            {!md && <Grid component={Paper} container sx={{mb:2, px: 1, py: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Grid item container xs={1} md={1}>
                </Grid>
                <Grid item container xs={11} md={11} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Grid item md={2}>
                        <CustomHeader>Nº Pedido</CustomHeader>
                    </Grid>
                    <Grid item md={2}>
                        <CustomHeader>Data Necessidade</CustomHeader>
                    </Grid>
                    <Grid item md={2}>
                        <CustomHeader>Produto</CustomHeader>
                    </Grid>
                    <Grid item md={4}>
                        <CustomHeader>Descrição</CustomHeader>
                    </Grid>
                    <Grid item md={1}>
                        <CustomHeader>Qtd. Pedida</CustomHeader>
                    </Grid>
                    <Grid item md={1}>
                        <CustomHeader>Un. Medida</CustomHeader>
                    </Grid>
                </Grid>
                
            </Grid>}
            {itens.map((item, index) => (
                <Grid key={index} component={Paper} container sx={{mb:2, p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Grid item container xs={2} sm={1}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                            <Checkbox size={"small"} checked={pedidosSelecionados.filter(pd => pd.id === item.id).length > 0} onChange={(e) => {
                                if (e.target.checked) {
                                    handleAddPedido && handleAddPedido(item);
                                } else {
                                    handleRemovePedido && handleRemovePedido(item);
                                }
                            }} />
                        </Grid>
                    </Grid>
                    <Grid item container xs={10} sm={11} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Grid item xs={6} sm={6} md={2} sx={{mb: {xs: 1, md: 0}}}>
                            <Typography variant="body1">{md && <b>Nº Pedido:</b>} {item.pedido}</Typography>
                         </Grid>
                        <Grid item xs={6} sm={6} md={2} sx={{mb: {xs: 1, md: 0}}}>
                            <Typography variant="body1">{md && <b>Data Necessidade:</b>} {moment(item.dataNecessidade).format("DD/MM/YYYY")}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2} sx={{mb: {xs: 1, md: 0}}}>
                            <Typography variant="body1">{md && <b>Produto:</b>} {item.produto}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} sx={{mb: {xs: 1, md: 0}}}>
                            <Typography variant="body1">{md && <b>Descrição:</b>} {item.descricao}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1} sx={{mb: {xs: 1, md: 0}}}>
                            <Typography variant="body1">{md && <b>Qtd. Pedida:</b>} {item.quantidadeDisponivel}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1} sx={{mb: {xs: 1, md: 0}}}>
                            <Typography variant="body1">{md && <b>Un. Medida:</b>} {item.unidadeMedida}</Typography>
                        </Grid>
                    </Grid>                 
                </Grid>
            ))}
            <Grid container sx={{mt:2}}>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'right'}}>
                    <Button onClick={() => handleNext()} variant="contained" >
                        Avançar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default TableListItens;