import {Box, Button, Grid, InputAdornment, TextField, Theme, useMediaQuery} from "@mui/material";
import Loader from "components/loader";
import {Controller, useForm} from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
    CondicaoOrcamento,
    ConsumidorOrcamento,
    Orcamento,
    ProdutoOrcamento,
    TransportadoraOrcamento,
} from "types/orcamento";
import Format from "utils/formatadores";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import SaveIcon from "@mui/icons-material/Save";
import ModalConsumidor from "components/orcamento/modals/consumidor";
import ModalTabelaPreco from "components/orcamento/modals/tabelaPreco";
import ModalTransportadora from "components/orcamento/modals/transportadora";
import ModalCondicao from "components/orcamento/modals/condicoes";
import ModalProdutos from "components/orcamento/modals/produtos";
import ModalCadastrarConsumidor from "components/orcamento/modals/cadastrarConsumidor";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import OrcamentoUtils from "utils/orcamento";
import {OrcamentosService} from "services/OrcamentosService";
import {toast} from "react-toastify";
import ModalHistorico from "components/orcamento/modals/historico";
import {useCrm} from "contexts/CrmContext";
import numeral from "numeral";
import CardPedidoHeader from "components/orcamento/cardPedidoHeader";
import CardPedido from "components/orcamento/cardPedido";

const AddOrcamentos = () => {
    const [modalConsumidor, setModalConsumidor] = useState(false);
    const [modalTransportadora, setModalTransportadora] = useState(false);
    const [modalCondicao, setModalCondicao] = useState(false);
    const [modalProdutos, setModalProdutos] = useState(false);
    const [modalCadastrar, setModalCadastrar] = useState(false);
    const [modalTabelaPreco, setModalTabelaPreco] = useState(false);
    const [modalHistorico, setModalHistorico] = useState(false);
    const xs = useMediaQuery((t: Theme) => t.breakpoints.down("md"));
    const {
        handleSubmit,
        formState: {errors},
        setValue,
        control,
    } = useForm({
        defaultValues: {
            consumidor: "",
            tabelaPreco: "",
            transportadora: "",
            condicao: "",
        },
    });
    const [orcamento, setOrcamento] = useState<Orcamento>({} as Orcamento);
    const navigate = useNavigate();
    const config = useAppSelector(useConfigState);
    const {dadosPedido, setScroll, isLoading, setIsLoading} = useCrm(); //----------------------------------------------------------------------------------------------
    const [state, dispatch] = useReducer(OrcamentoUtils.reducerProduto, orcamento.produtos || []);

    useEffect(() => {
        setScroll(true);
    }, [setScroll]);

    const handleSelectConsumidor = useCallback((data: ConsumidorOrcamento) => {
        setOrcamento({
            ...orcamento,
            codigoConsumidor: data.codigo,
            nomeConsumidor: data.nome,
            cgcConsumidor: data.cgc,
            enderecoConsumidor: data.endereco,
            bairroConsumidor: data.bairro,
            municipioConsumidor: data.municipio,
            estadoConsumidor: data.estado,
        });
        setValue("consumidor", data.nome);
        setModalConsumidor(false);
    }, [orcamento, setValue]);

    useEffect(() => {
        if (dadosPedido) {
            setValue("consumidor", dadosPedido.nome);
            handleSelectConsumidor(dadosPedido);
        }
    }, [dadosPedido, handleSelectConsumidor, setValue]);

    const onSubmit = () => {
        let isValid = true;
        state.forEach((item: ProdutoOrcamento, index: any) => {
            if (item.quantidade === "0") {
                isValid = false;
            }
            if (item.valorVenda === "0.00") {
                isValid = false;
            }
        });
        if (isValid) {
            let orcamentoTemp = {...orcamento, produtos: state};
            saveData(orcamentoTemp);
        } else {
            toast.warn("A quantidade ou valor dos produtos não pode ser 0!");
        }
    };

    const saveData = (data: any) => {
        setIsLoading(true);
        OrcamentosService.create(config.empresa.empresaCodigo, data)
            .then((res) => {
                toast.success(
                    <div>
                        Salvo com sucesso ! <br/> Código: {res.data.codigoPedido}
                    </div>
                );
                navigate("/painel/orcamentos");
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => setIsLoading(false));
    };

    const handleSelectTabelaPreco = (data: any) => {
        setOrcamento({
            ...orcamento,
            codigoTab: data.codigotab,
            descricaoTab: data.descricao,
        });
        setValue("tabelaPreco", data.descricao);
        setModalTabelaPreco(false);
    };

    const handleSelectTransportador = (data: TransportadoraOrcamento) => {
        setOrcamento({
            ...orcamento,
            codigoTransportadora: data.codigo,
            nomeTransportadora: data.nome,
        });
        setValue("transportadora", data.nome);
        setModalTransportadora(false);
    };

    const handleSelectCondicao = (data: CondicaoOrcamento) => {
        setOrcamento({
            ...orcamento,
            codigoCondicao: data.codigo,
            descricaoCondicao: data.descricao,
            formaCondicao: data.forma,
        });
        setValue("condicao", data.forma + " - " + data.descricao);
        setModalCondicao(false);
    };

    const handleSelectOrcamento = (data: Orcamento) => {
        setOrcamento(data);
        setValue("condicao", data.formaCondicao + " - " + data.descricaoCondicao);
        setValue("transportadora", data.nomeTransportadora);
        setValue("tabelaPreco", data.descricaoTab);
        setValue("consumidor", data.nomeConsumidor);
        data.produtos.forEach((item: ProdutoOrcamento, index: any) => {
            dispatch({type: "add", payload: {...item, valorVenda: parseFloat(item.valorVenda).toFixed(2)}});
        });
        setModalHistorico(false);
    };

    const handleAddProduto = (data: any) => {
        dispatch({
            type: "add",
            payload: {
                ...data,
                quantidade: data.qtd,
                valorFinal: parseFloat(data.qtd) * parseFloat(data.valor),
                valorVenda: parseFloat(data.valor).toFixed(2),
            },
        });
        toast.success(`Produto ${data.descricao} adicionado com sucesso !`, {autoClose: 1000});
    };

    const removeProduto = (produto: any) => {
        dispatch({
            type: "remove",
            payload: produto,
        });
    };

    const onChangeCardData = (produto: ProdutoOrcamento) => {
        dispatch({
            type: 'update',
            payload: produto
        })
    }


    return (
        <Box sx={{width: "100%", mt: 2}}>
            {isLoading ? (
                <Loader/>
            ) : (
                <>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Controller
                                    name="consumidor"
                                    control={control}
                                    render={({field}) =>
                                        <TextField
                                            {...field}
                                            required
                                            error={!!errors.consumidor}
                                            fullWidth={true}
                                            label="Consumidor"
                                            variant="outlined"
                                            disabled={true}
                                            helperText={errors.consumidor?.message}
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment position="start" onClick={() => setModalConsumidor(true)}>
                                                        <SearchIcon/>
                                                    </InputAdornment>,
                                                endAdornment:
                                                    <InputAdornment position="end" onClick={() => setModalCadastrar(true)}>
                                                        <AddIcon/>
                                                    </InputAdornment>
                                            }}
                                        />}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="tabelaPreco"
                                    control={control}
                                    render={({field}) =>
                                        <TextField
                                            {...field}
                                            required
                                            error={!!errors.tabelaPreco}
                                            label="Tabela de preço"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth={true}
                                            helperText={errors.tabelaPreco?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" onClick={() => setModalTabelaPreco(true)}>
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="transportadora"
                                    control={control}
                                    render={({field}) =>
                                        <TextField
                                            {...field}
                                            required
                                            error={!!errors.transportadora}
                                            label="Transportadora"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth={true}
                                            helperText={errors.transportadora?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start" onClick={() => setModalTransportadora(true)}>
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />}
                                />
                            </Grid>
                            <Grid item>
                                <Controller
                                    name="condicao"
                                    control={control}
                                    render={({field}) =>
                                        <TextField
                                            {...field}
                                            required
                                            error={!!errors.condicao}
                                            label="Condição de pagamento"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth={true}
                                            helperText={errors.condicao?.message}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start"   onClick={() => setModalCondicao(true)}>
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained"
                                        onClick={() => {orcamento.codigoTab ? setModalProdutos(true) : toast.error("Você precisa selecionar uma tabela de preços primeiro")}}><AddIcon/> Adicionar produto</Button>
                            </Grid>
                            <Grid item>
                                {!xs && <CardPedidoHeader/>}
                                {state.length > 0 && (
                                    state.map((produto, index) => (
                                        <CardPedido key={index} produto={produto} onChange={onChangeCardData} onDelete={removeProduto}/>
                                    ))
                                )}
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Valor Total"
                                    variant="outlined"
                                    disabled={true}
                                    value={Format.moeda(state.reduce((acc: number, produto) => acc + numeral(produto.valorFinal).value(), 0))}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{mt: 3, textAlign: 'right'}}>
                            <Button variant={'contained'} color={'primary'} type={'submit'}>
                                <SaveIcon/> Salvar
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
            {modalConsumidor && (
                <ModalConsumidor show={modalConsumidor} setShow={setModalConsumidor}
                                 handleSelect={handleSelectConsumidor}/>
            )}
            {modalTabelaPreco && (
                <ModalTabelaPreco
                    show={modalTabelaPreco}
                    setShow={setModalTabelaPreco}
                    handleSelect={handleSelectTabelaPreco}
                    cgcCliente={orcamento?.cgcConsumidor}
                    codigoCliente={orcamento.codigoConsumidor}
                />
            )}
            {modalTransportadora && (
                <ModalTransportadora
                    show={modalTransportadora}
                    setShow={setModalTransportadora}
                    handleSelect={handleSelectTransportador}
                    codigoCliente={orcamento.codigoConsumidor}
                />
            )}
            {modalCondicao && (
                <ModalCondicao
                    show={modalCondicao}
                    setShow={setModalCondicao}
                    handleSelect={handleSelectCondicao}
                    codigoCliente={orcamento.codigoConsumidor}
                />
            )}
            {modalProdutos && (
                <ModalProdutos
                    show={modalProdutos}
                    setShow={setModalProdutos}
                    handleSelect={handleAddProduto}
                    codigotab={orcamento?.codigoTab}
                    codigoCliente={orcamento.codigoConsumidor}
                />
            )}
            {modalCadastrar && (
                <ModalCadastrarConsumidor
                    show={modalCadastrar}
                    setShow={setModalCadastrar}
                    handleSelect={handleSelectConsumidor}
                />
            )}
            {modalHistorico && (
                <ModalHistorico
                    show={modalHistorico}
                    setShow={setModalHistorico}
                    handleSelect={handleSelectOrcamento}
                    codigoCliente={orcamento.codigoConsumidor}
                />
            )}
        </Box>
    );
};

export default AddOrcamentos;
