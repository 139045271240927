import {AxiosRequestConfig} from "axios";
import {API} from "services/Api";

const URL = "/configuracoes";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const ConfiguracoesService =  {
    findAll: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(URL, paramsGeral);
    },
    findByCodigoCompl: (codigo: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/${codigo}`, paramsGeral);
    },
    findByCodigo: (codigo: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/codigo/${codigo}`, paramsGeral);
    },
    update: (codigo: string, data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.put(`${URL}/${codigo}`, data, paramsGeral);
    },
    findAcessos: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/acessos`, paramsGeral);
    },
    findByDominio: (dominio: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        paramsGeral.params = {...paramsGeral.params, dominio};
        return API.get(`${URL}/dominio`, paramsGeral);
    },
    importXls: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/importar/xls`, data, paramsGeral);
    },
    importCsv: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/importar/csv`, data, paramsGeral);
    },
    importJson: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/importar/json`, data, paramsGeral);
    },

}