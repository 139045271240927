import {Step, StepLabel, Stepper} from "@mui/material";
import MyCard from "components/card";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import TableListItens from "components/agendamentos-entregas/table-list-itens";
import {AgendamentoService} from "services/AgendamentoService";
import {AgendamentoPedido} from "types/agendamentos";
import {toastError} from "utils/toastError";
import {AgendamentoUtils} from "utils/agendamentos";
import SelectData from "components/agendamentos-entregas/select-data";
import {toast} from "react-toastify";
import Agradecimento from "components/agendamentos-entregas/agradecimento";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

const steps = ['Itens', 'Selecionar Data', 'Confirmação'];


const AddAgendamentos = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [page, setPage] = useState<AgendamentoPedido[]>([]);
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();    const [state, dispatch] = useReducer(AgendamentoUtils.reducepedido, []);

    const handleNext = () => {
        if (state.length === 0) {
            toast.warn("Selecione ao menos um pedido")
            return;
        }
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleInicio = () => {
        setActiveStep(0);
        dispatch({type: "clear"});
        loadData();
    }

    const loadData = useCallback(() => {
        appDispatch(configActions.setLoading({
            loading: true,
        }));
        AgendamentoService.findAllPedidos(config.empresa.empresaCodigo)
            .then(res => {
                setPage(res.data.conteudo);
            })
            .catch(err => {
                toastError(err, "Erro ao buscar pedidos");
            })
            .finally(() => appDispatch(configActions.setLoading({
                loading: false,
            })));
    }, [appDispatch, config.empresa.empresaCodigo]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleAddPedido = (pedido: AgendamentoPedido) => {
        dispatch({type: "add", payload: pedido});
    }

    const handleRemovePedido = (pedido: AgendamentoPedido) => {
        dispatch({type: "remove", payload: pedido});
    }

    const updatePedido = (pedido: AgendamentoPedido) => {
        dispatch({type: "update", payload: pedido});
    }


    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <TableListItens
                    itens={page}
                    handleNext={handleNext}
                    pedidosSelecionados={state}
                    handleAddPedido={handleAddPedido}
                    handleRemovePedido={handleRemovePedido}
                />;
            case 1:
              return <SelectData
                handleBack={handleBack}
                handleNext={handleNext}
                pedidos={state}
                updatePedido={updatePedido}
              />;
            case 2:
              return <Agradecimento handleInicio={handleInicio}/>;
            default:
                throw new Error('Unknown step');
        }
    }

    return (
        <MyCard title={"Agendamento de entrega"}>
            <Stepper activeStep={activeStep} sx={{pt: 3, pb: 2}}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <>
                {getStepContent(activeStep)}
            </>
        </MyCard>
    );
}

export default AddAgendamentos;