import {cnpj, cpf} from "cpf-cnpj-validator";
import moment from "moment";
import numeral from "numeral";

const TIME_ZONE = "-03:00";

moment().locale('pt-br');

numeral.register('locale', 'custom', {
    delimiters: {
      thousands: '.',
      decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'o' : 'os';
    },
    currency: {
      symbol: 'R$'
    }
  });

numeral.locale('custom');


const Format = {
    data: (data: string) => {
        return moment(data).format('DD/MM/YYYY');
    },
    dataZ: (data: string) => {
        return moment(data).format('YYYY-MM-DD');
    },
    dataHora: (data: string) => {
        return moment(data).format('DD/MM/YYYY HH:mm');
    },
    dataUTC: (data: string, horario: string) => {
        let tempDate = Date.parse(data + " " + horario + TIME_ZONE);
        const date = moment(tempDate);
        return date.parseZone().utc().format();
    },
    moeda: (valor: number|string) => {
        if(typeof valor === 'string') {
            valor.replace(",", "");
            valor.replace(".", ",");
            valor = numeral(valor).value();
        }
        let number = numeral(valor);
        return number.format('$ 0,0.00');
    },
    inputMoneyFormat: (value:any) => {
        let number = numeral(value).format("$ 0.00");
        return number;
    },
    cgc: (valor: string) => {
        if (!valor) return "";
        valor = valor.replace(/\D/g, "");
        if (valor.length === 11) {
            return cpf.format(valor);
        } else {
            return cnpj.format(valor);
        }
    },
    cpf: (valor: string) => {
        return cpf.format(valor);
        // cnpj = cnpj.replace(/[^\d]/g, "");
        // return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    },
    cnpj: (valor: string) => {
        return cnpj.format(valor);
        // cnpj = cnpj.replace(/[^\d]/g, "");
        // return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    },
    telefone: (valor: string | null) => {
        if (!valor) return "";
        valor = valor.replace(/[^\d]/g, "");
        if (valor.length === 11) {
            return valor.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
        } else {
            return valor.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
        }
    },
    advplDouble: (valor: string) => {
        if (!valor) return "0.00";
        valor = valor.trim();
        return Intl.NumberFormat("en-US", {minimumFractionDigits: 2}).format((parseFloat(valor))).replace(",", "");
    },
    numeralSolver: (valor: string) => {
        if (!valor) return 0.00;
        valor = valor.trim();
        valor = valor.replace(",", "");
        valor = valor.replace(".", ",");
        return numeral(valor).value();
    },
}

export default Format;