import {createContext, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {ConfigAgendamento} from "types/configuracoes";
import {AgendamentoService} from "services/AgendamentoService";
import {toastError} from "utils/toastError";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";

type Props = {
    children: ReactNode;
}

export type AgendamentoEntregaContext ={
    parametros: ConfigAgendamento;
}

const AgendamentoEntregaContextType = createContext<AgendamentoEntregaContext>({} as AgendamentoEntregaContext);


const AgendamentoEntregaProvider = ({ children } : Props) => {
    const [parametros, setParametros] = useState<ConfigAgendamento>({} as ConfigAgendamento);
    const config = useAppSelector(useConfigState);

    const getConfig = useCallback(() => {
        if (!config.empresa) return;
        AgendamentoService.findConfig(config.empresa.empresaCodigo)
            .then(res => {
                setParametros(res.data);
            })
            .catch(err => {
                toastError(err, "Erro ao buscar horarios livres");
            })
    }, [config.empresa]);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    return (
        <AgendamentoEntregaContextType.Provider value={{parametros}}>
            {children}
        </AgendamentoEntregaContextType.Provider>
    )
}


export default AgendamentoEntregaProvider;

export const useAgendamentoEntrega = () => useContext(AgendamentoEntregaContextType);