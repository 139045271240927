import {Card, Grid, TextField, Typography} from "@mui/material";
import {NumericFormat} from 'react-number-format';
import Format from "utils/formatadores";
import {CotacaoProduto} from "types/cotacao";
import {CotacaoUtils} from "utils/cotacao";

type Props = {
    produto: CotacaoProduto;
    updateProduto: (produto: string, campo: "V" | "PE" | "ICMS" | "IPI" | "ST" | "OBS", valor: string) => void;
}

const totalizador = (produtos : CotacaoProduto) => {
    let quantidade = parseFloat(produtos.quantidade);
    let valor = Format.numeralSolver(produtos.valor);
    let total = quantidade * valor;
    return Format.moeda(total);
}


const CardCotacaoProduto = ({ produto, updateProduto }: Props) => {
    return (
        <Grid item xs={12} md={12} sx={{ mt: 2, p: 2, border: 1 }} component={Card} elevation={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={1}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <b>Item: </b> {produto.item}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <b>Descrição:</b>  {produto.descricao}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        <b>Quantidade: </b> {produto.quantidade2 === "0" || !produto.quantidade2 ? produto.quantidade : produto.quantidade + " Equivalente a " + produto.quantidade2}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    {produto.observacaoItem &&
                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                            <b>Observação: </b> {produto.observacaoItem}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} md={2}>
                    <NumericFormat
                        customInput={TextField}
                        decimalScale={2}
                        decimalSeparator=","
                        fixedDecimalScale
                        prefix="R$ "
                        thousandSeparator="."
                        fullWidth
                        required={true}
                        allowNegative={false}
                        value={produto.valor ? produto.valor : ''}
                        placeholder={'R$ 0,00'}
                        variant="outlined"
                        label={"Valor unitário"}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e: any) => {
                            updateProduto(produto.recno, "V", CotacaoUtils.limpaMoney(e.target.value));
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        fullWidth
                        label={"Prazo de entrega em dias"}
                        type={"number"}
                        value={produto.prazoent ? produto.prazoent : ''}
                        inputProps={{
                            step: 1,
                            min: 0
                        }}
                        InputLabelProps={{ shrink: true }}
                        required={true}
                        onKeyDown={(event) => {
                            if (event.key === '.' || event.key === ',') { event.preventDefault(); }
                        }}
                        onChange={(e) => {
                            updateProduto(produto.recno, "PE", e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        fullWidth
                        label={"IPI %"}
                        type={"number"}
                        inputProps={{
                            min: 0,
                            max: 100.0
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={produto.ipi ? produto.ipi : ''}
                        onChange={(e) => {
                            updateProduto(produto.recno, "IPI", e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        fullWidth
                        label={"ICMS %"}
                        type={"number"}
                        inputProps={{
                            min: 0,
                            max: 100.0
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={produto.icms ? produto.icms : ''}
                        onChange={(e) => {
                            updateProduto(produto.recno, "ICMS", e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <NumericFormat
                        customInput={TextField}
                        decimalScale={2}
                        decimalSeparator=","
                        fixedDecimalScale
                        prefix="R$ "
                        thousandSeparator="."
                        fullWidth
                        allowNegative={false}
                        value={produto.icmsSt ? produto.icmsSt : ''}
                        placeholder={'R$ 0,00'}
                        variant="outlined"
                        label={"ICMS Ret."}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e: any) => {
                            updateProduto(produto.recno, "ST", CotacaoUtils.limpaMoney(e.target.value));
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        label={"Valor total"}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        multiline
                        value={totalizador(produto)}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                        <TextField
                            label="Observação"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            multiline
                            rows={4}
                            value={produto.observacaoForn ? produto.observacaoForn : ''}
                            onChange={(e) => {
                                updateProduto(produto.recno, "OBS", e.target.value);
                            }}
                        />
                    </Grid>
            </Grid>
        </Grid>
    )
}


export default CardCotacaoProduto