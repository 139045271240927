import {Box, Button, Grid, Paper, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import {ReactNode} from "react";

type Props = {
    children: ReactNode;
    title?: string;
    action?: Function;
    buttonText?: string;
};

const MyCard = ({children, title, action, buttonText}: Props) => {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box
            component={Paper}
            elevation={md ? 0 : 3}
            square
            sx={{
                backgroundColor: (t) => md ? t.palette.background.paper : t.palette.grey[50],
                borderRadius: 1,
                color: "primary.main",
                p: 2,
            }}
        >
            {(title || action) && (
                <Grid container sx={{mb: 3}}>
                    {title && (
                        <Grid item xs={12} sm={6} md={6}>
                            <Box sx={{fontSize: 16, fontWeight: "h6.fontWeight", mb: 3}}>{title}</Box>
                        </Grid>
                    )}
                    {action && (
                        <Grid item xs={12} sm={6} md={6} sx={{textAlign: {xs: "left", sm: "right", md: "right"}}}>
                            <Tooltip title={buttonText ? buttonText : "Adicionar"} placement="top">
                                <Button variant={"contained"} onClick={() => action()}>
                                    {buttonText ? buttonText : "Adicionar"}
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            )}
            {children}
        </Box>
    );
};

export default MyCard;
