import { Box, Button, Grid, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import { useConfigState } from "store/config";

const Tela404 = () => {
    const config = useAppSelector(useConfigState);
    const navigate = useNavigate();
    const { palette } = useTheme();

    return (
        <>
            {/* <GlobalStyles styles={{body: {backgroundColor: palette.grey[100]}}} /> */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: palette.grey[100], height: '100vh' }}>
                <Grid container sx={{ justifyContent: 'center', py: 3, boxShadow: 4, backgroundColor: (t) => t.palette.background.paper, width: { xs: '100%', sm: '80%', md: '50%', lg: '45%' } }}>
                    <Grid
                        item
                        component={"img"}
                        src={config.configWhite.logo}
                        alt={config.configWhite.nome}
                        xs={4}
                        sx={{ mb: 2 }}
                    />
                    <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                        <Typography color={'primary'} fontSize={60} fontWeight={'bold'}>404</Typography>
                        <Typography color={'primary'} fontSize={30}>Página não encontrada</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                        <Typography fontSize={20} fontWeight={'bold'}>Você não possui acesso para a pagina está procurando, ou não existe.</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                        <Button variant="contained" onClick={() => navigate("/painel")}>Voltar para o painel</Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
export default Tela404