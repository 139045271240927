import MyCard from "components/card";
import Loader from "components/loader";
import {Button, Grid, Typography} from "@mui/material";
import download from "downloadjs";
import {toast} from "react-toastify";
import {RelatoriosService} from "services/RelatoriosService";
import {v4 as uuidv4} from 'uuid';
import {AxiosRequestConfig} from "axios";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store";
import {configActions} from "store/config";


const Relatorios = () => {
    const config = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();

    const handleDownloadLogs = () => {
        dispatch(configActions.setLoading({
            loading: true,
        }));  
        const configuracoes: AxiosRequestConfig = {
            params:{
                codigo: config.parametros.codigoUnico
            },
            responseType: 'blob',
        }
        RelatoriosService.findLogs(configuracoes)
            .then(res => {
                const file = new Blob([res.data], {type: 'application/octet-stream'});
                download(file, uuidv4().replace("-", "") + ".xls", "application/vnd.ms-excel");
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => {
                dispatch(configActions.setLoading({
                    loading: false,
                }));
            });
    }


    const handleDownloadUsuarios = () => {
       dispatch(configActions.setLoading({
            loading: true,
        }));
        const configuracoes: AxiosRequestConfig = {
            params:{
                codigo: config.parametros.codigoUnico
            },
            responseType: 'blob',
        }
        RelatoriosService.findUsuario(configuracoes)
            .then(res => {
                const file = new Blob([res.data], {type: 'application/octet-stream'});
                download(file, uuidv4().replace("-", "") + ".xls", "application/vnd.ms-excel");
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => {
                dispatch(configActions.setLoading({
                    loading: false,
                }));
            });
    }



    return (
        <MyCard title={`Baixar relatórios`}>
            {config.loading ? <Loader/> : (
                <>
                    <Grid item xs={12} sx={{mb: 3}} container>
                        <Grid item xs={12} md={3} border={1} borderRadius={2} padding={2}>
                            <Typography fontWeight={700} fontSize={16} sx={{mb:2}}>Relatório de logs</Typography>
                            <Button variant={"contained"} fullWidth onClick={handleDownloadLogs}>
                                Baixar
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ml: {xs:0, md:2}}} border={1} borderRadius={2} padding={2}>
                            <Typography fontWeight={700} fontSize={16} sx={{mb:2}}>Relatório de usuários</Typography>
                            <Button variant={"contained"} fullWidth onClick={handleDownloadUsuarios}>
                                Baixar
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </MyCard>
    )
}

export default Relatorios;