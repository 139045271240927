import {AxiosRequestConfig} from "axios";
import {createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useState} from "react";
import {toast} from "react-toastify";
import {CrmService} from "services/CrmService";
import {CampoFilter, CrmLead} from "types/crm";
import {ConsumidorOrcamento} from "types/orcamento";
import {RestPage} from "types/prtoheuspage";
import CrmUtils from "utils/crm";
import {toastError} from "utils/toastError";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";

type Props = {
  children: ReactNode;
}

type Screen = "Início" | "Atendimento" | "Funil" | "Orçamento";

type CrmContext = {
  setScreen: ( p: Screen ) => void;
  screen: Screen;
  loadLeads: () => void;
  leads: RestPage<CrmLead> | undefined;
  state: CrmLead[];
  dispatch: ( p: any ) => void;
  columns: string[];
  columnsName: string[];
  camposFilter: CampoFilter[];
  setScroll: ( p: boolean ) => void;
  scroll: boolean;
  vendedor: string;
  setDadosPedido: ( p: ConsumidorOrcamento ) => void;
  dadosPedido: ConsumidorOrcamento;
  isLoading: boolean;
  setIsLoading: ( p: boolean ) => void;
  setHideMenu: ( p: boolean ) => void;
  hideMenu: boolean;
}

const CrmContextType = createContext<CrmContext>( {} as CrmContext );

const CrmProvider = ( { children }: Props ) => {
  const [screen, setScreen] = useState<Screen>( "Início" );
  const [columns, setColumns] = useState<string[]>( [] );
  const config = useAppSelector(useConfigState);
  const [leads, setLeads] = useState<RestPage<CrmLead>>();
  const [state, dispatch] = useReducer( CrmUtils.reducerLeads, [] );
  const [columnsName, setColumnsName] = useState<string[]>( [] );
  const [scroll, setScroll] = useState( true );
  const [vendedor, setVendedor] = useState<string>( "" );
  const [dadosPedido, setDadosPedido] = useState<ConsumidorOrcamento>( {} as ConsumidorOrcamento );
  const [isLoading, setIsLoading] = useState( true );
  const [camposFilter, setCamposFilter] = useState<CampoFilter[]>( [] );
  const [hideMenu, setHideMenu] = useState( false );

  const loadColumns = useCallback( () => {
    setIsLoading( true );
    CrmService.findAllColumns( config.empresa.empresaCodigo )
      .then( res => {
        setCamposFilter( res.data );
      } )
      .catch( ( err ) => toastError( err, "Erro ao carregar colunas" ) )
      .finally( () => setIsLoading( false ) );
  }, [config.empresa.empresaCodigo] );

  useEffect( () => {
    loadColumns();
  }, [loadColumns] );

  useEffect( () => {
    let nColumns: string[] = [];
    let cColumns: string[] = [];
    camposFilter.forEach( d => {
      nColumns.push( d.campo );
      cColumns.push( d.descricao );
    } );
    setColumns( nColumns );
    setColumnsName( cColumns );
  }, [camposFilter] );

  //-- Carrega os leads
  const loadLeads = useCallback( () => {
    setIsLoading( true );
    const params: AxiosRequestConfig = {
      params: {
        codigo: config.empresa.empresaCodigo,
      }
    };
    dispatch( { type: 'clear' } );
    CrmService.listLeads( config.empresa.empresaCodigo, params )
      .then( ( res ) => {
        setLeads( res.data );
        res.data.conteudo.forEach( ( lead: CrmLead ) => {
          dispatch( {
            type: 'add',
            payload: lead
          } );
        } )
        setVendedor( res.data.conteudo[0].vendedor );
      } )
      .catch( ( err ) => {
        toast.error( err.response.data.message );
      } )
      .finally( () => setIsLoading( false ) )
  }, [config.empresa.empresaCodigo] );

  //-- Carrega a primeira pagina de leads ao iniciar a pagina
  useEffect(
    () => {
      loadLeads();
    }, [loadLeads]
  );

  const handleScroll = () => {
    if ( window.scrollY > 0 ) {
      setHideMenu( true );
    } else {
      setHideMenu( false );
    }
  }

  window.addEventListener( 'scroll', handleScroll );

  return (
    <CrmContextType.Provider value={{
      setScreen,
      screen,
      loadLeads,
      leads,
      state,
      dispatch,
      columns,
      columnsName,
      scroll,
      setScroll,
      vendedor,
      setDadosPedido,
      dadosPedido,
      isLoading,
      setIsLoading,
      camposFilter,
      hideMenu,
      setHideMenu
    }}
    >
      {children}
    </CrmContextType.Provider>
  )
}

export default CrmProvider;
export const useCrm = () => useContext( CrmContextType );