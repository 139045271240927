import BusinessIcon from "@mui/icons-material/Business";
import {Box, IconButton, Menu} from "@mui/material";
import {useState} from "react";
import ButtonExibeEmpresa from "components/buttons/exibeEmpresa";
import ButtonExibeFilial from "components/buttons/exibeFilial";
import {useAppSelector} from "store";
import {hasAnyRoles, useAuthState} from "store/auth";

const CompanyMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const appSelector = useAppSelector(useAuthState)
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

    const handleClose = () => {
        setAnchorEl(null);
    };

  return (
    <Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleClick}
        edge="start"
        sx={{
          marginRight: 5,
        }}
      >
        <BusinessIcon />
      </IconButton>
       <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          display: {
            lg: "none",
          },
        }}
      >
        <Box
          sx={{
            flexDirection: {
              xs: "Column",
              sm: "Column",
              md: "Column",
              lg: "row",
              xl: "row",
            },
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          {hasAnyRoles(appSelector, ["ROLE_ADMIN"]) && <ButtonExibeEmpresa />}
          <ButtonExibeFilial />
        </Box>
      </Menu>
    </Box>
  );
};
export default CompanyMenu;
