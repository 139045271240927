import {cnpj, cpf} from "cpf-cnpj-validator";

const numeros = /([0-9])/;
var alfabetoMin = /([a-z])/;
var alfabetoMai = /([A-Z])/;
var espacoBranco = / /g;
const chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

export const Validadores = {
    senha: (senha: string): string | boolean => {
        if (senha.match(espacoBranco)) return "A senha não pode conter espaços em branco";
        if (senha.length < 8) return "A senha deve ter no mínimo 8 caracteres";
        if (senha.length > 50) return "A senha deve ter no máximo 50 caracteres";
        if (!senha.match(numeros)) return "A senha deve conter pelo menos um número";
        if (!senha.match(alfabetoMin)) return "A senha deve conter pelo menos uma letra minúscula";
        if (!senha.match(alfabetoMai)) return "A senha deve conter pelo menos uma letra maiúscula";
        if (!senha.match(chEspeciais)) return "A senha deve conter pelo menos um caractere especial";
    },
    cgc: (cgc: string): string | boolean => {
        cgc = cgc.replace(/\D/g, '');
        // if (cgc.length !== 14 && cgc.length !== 11) return "O CPF/CNPJ informado é inválido";
        if (cgc.length < 11 || cgc.length > 14 || cgc.length === 12 || cgc.length === 13) {
            return "O CPF/CNPJ informado é inválido";
        }
        if (cgc.length === 11){
            if(!cpf.isValid(cgc)) return "O CPF informado é inválido";
        }
        if (cgc.length === 14){
            if(!cnpj.isValid(cgc)) return "O CNPJ informado é inválido";
        }
    },
    email: (email: string): string | boolean => {
        if (email.match(espacoBranco)) return "O e-mail não pode conter espaços em branco";
        if (email.length < 5) return "O e-mail deve ter no mínimo 5 caracteres";
        if (email.length > 50) return "O e-mail deve ter no máximo 50 caracteres";
        if (!email.includes("@")) return "O e-mail informado é inválido";
        if (!email.includes(".")) return "O e-mail informado é inválido";
    },
}