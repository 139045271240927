import {useCallback, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Autocomplete, Box, Button, FormControl, MenuItem, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import MyCard from "components/card";
import {EmpresaService} from "services/EmpresaService";
import {cnpj} from "cpf-cnpj-validator";
import {filterOptions} from "utils/constants";
import {Validadores} from "utils/validadores";
import {PermissaoService} from "services/PermissaoService";
import {AxiosRequestConfig} from "axios";
import {toastError} from "utils/toastError";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

type UsuarioForm = {
    id: number;
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    status: string;
    cgc: string;
    permissoes: string[];
}

const EditEmpresas = () => {
    const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
    const {handleSubmit, formState: {errors}, getValues, reset, register, control} = useForm<UsuarioForm>({
        mode: 'all',
        defaultValues: {
            id: 0,
            nome: '',
            password: '',
            cgc: '',
            confirm_senha: '',
            email: '',
            status: '1',
            permissoes: [],
        }
    });
    const [options, setOptions] = useState<{ id: string; label: string; }[]>([]);
    const [optionsSelected, setOptionsSelected] = useState<{ id: string; label: string; }[]>([]);
    const navigate = useNavigate();
    const {id} = useParams();
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();

    const loadUser = useCallback(() => {
        if (id) {
            appDispatch(configActions.setLoading({loading: true}))
            EmpresaService.findById(id as string)
                .then(res => {
                    res.data.status = res.data.status ? 1 : 0;
                    res.data.password = '';
                    res.data.cnpj = cnpj.format(res.data.cnpj)
                    setOptionsSelected(res.data.modulos.map((pr: any) => {
                        return {id: pr.nome, label: pr.descricao}
                    }));
                    reset(res.data);
                })
                .catch(err => {
                    toast.error(err.message);
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                });
        }
    }, [appDispatch, id, reset]);


    const onSubmit = (data: any) => {
        data.cgc = data.cgc.replace(/\D/g, '');
        if (cnpj.isValid(data.cgc)) {
            if (id) {
                const formData = {
                    ...data,
                    status: parseInt(data.status),
                    cnpj: data.cgc.replace(/\D/g, ''),
                    modulos: optionsSelected.map(pr => {
                        return {nome: pr.id, descricao: pr.label}
                    }),
                }
                appDispatch(configActions.setLoading({loading: true}))
                EmpresaService.update(id, formData, {params: {codigo: config.parametros.codigoUnico}})
                    .then(() => {
                        toast.success("Salvo com sucesso !");
                        navigate("/painel/empresas")
                    })
                    .catch(() => toast.error("Erro ao salvar !"))
                    .finally(() => appDispatch(configActions.setLoading({loading: false})));
            } else {
                navigate("/painel/empresas");
                toast.error("Id não encontrado !");
            }
        } else {
            toast.error("CNPJ inválido !");
        }
    }


    const getPermissoes = useCallback(() => {
        const configuracoes: AxiosRequestConfig = {
            params: {
                codigo: config.parametros.codigoUnico
            }
        }
        PermissaoService.findByGrupo(configuracoes)
            .then(res => {
                setOptions(res.data.map(pr => {
                    return {id: pr.nome, label: `${pr.descricao}`}
                }));
            })
            .catch(err => {
                toastError(err, 'Erro ao buscar permissões');
            })
    }, [config.parametros.codigoUnico]);

    useEffect(() => {
        getPermissoes();
        loadUser();
    }, [getPermissoes, loadUser]);

    const handleChange = (e: any) => {
        setOptionsSelected(e);
    };


    return (
        <MyCard title={'Editar empresa'}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                <TextField
                    {...register("nome")}
                    required
                    error={!!errors.nome}
                    label="Nome"
                    variant="outlined"
                    sx={{width: '100%'}}
                    helperText={errors.nome?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("email")}
                    required
                    error={!!errors.email}
                    type={'email'}
                    label="E-mail"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.email?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("password", {
                        validate: (value) => {
                            if (value) return Validadores.senha(value)
                        }
                    })}
                    error={!!errors.password}
                    type={'password'}
                    label="Senha"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.password?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("confirm_senha", {
                        validate: (value) => {
                            const {password} = getValues();
                            if (value && password && value !== password) return 'As senhas não são iguais.'
                        }
                    })}
                    error={!!errors.confirm_senha}
                    type={'password'}
                    label="Confirmar senha"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.confirm_senha?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register('cgc', {validate: (value) => Validadores.cgc(value),})}
                    error={!!errors.cgc}
                    required
                    type={'text'}
                    label="CNPJ"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.cgc?.message}
                    InputLabelProps={{shrink: true}}
                />
                <Controller
                    name="status"
                    control={control}
                    render={({field}) => (
                        <TextField
                            {...field}
                            error={!!errors.status}
                            required
                            select
                            label="Status"
                            variant="outlined"
                            sx={{width: '100%', mt: 3}}
                            helperText={errors.status?.message}
                            InputLabelProps={{shrink: true}}
                        >
                            {statusField.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>))}
                        </TextField>
                    )}
                />
                <FormControl fullWidth sx={{width: '100%', mt: 3}}>
                    <Autocomplete
                        disablePortal
                        value={optionsSelected}
                        multiple
                        filterOptions={filterOptions}
                        options={options}
                        fullWidth
                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Permissões"
                                                            InputLabelProps={{shrink: true}}/>}
                        onChange={(e, newValue) => {
                            handleChange(newValue);
                        }}
                    />
                </FormControl>
                <Box sx={{mt: 3, textAlign: 'right'}}>
                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                        <SaveIcon/> Salvar
                    </Button>
                </Box>
            </Box>
        </MyCard>
    );
}


export default EditEmpresas;