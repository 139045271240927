import {Box, Button, Grid, MenuItem, styled, TextField} from '@mui/material';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import Loader from 'components/loader';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';
import {useCrm} from 'contexts/CrmContext';
import {useCallback, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CrmService} from 'services/CrmService';
import {SpringPage} from 'types/springpage';
import Format from 'utils/formatadores';
import CardAtendimento from './cardAtendimento';

type SearchProps = {
  dataini: string;
  datafin: string;
  filtro: string;
  cPed:string;
}


const SendButton = styled(Button)({
  width: "100%",
  height: "100%",
});

const Atendimento = () => {
  const { setScroll, camposFilter, vendedor } = useCrm();
  const [isLoading, setIsLoading] = useState(true);
  const {handleSubmit, control} = useForm<SearchProps>({
    defaultValues: {
        cPed: "",
        dataini: "",
        datafin: "",
        filtro: "SELECIONE"
    }
  });
  const config = useAppSelector(useConfigState);
  const [dataIniT, setDataIniT] = useState("")
  const [dataFinT, setDataFinT] = useState("")
  const [filtroT, setFiltroT] = useState("Todos")
  const [cPed, setCped] = useState("")
  const [agenda, setAgenda] = useState<any[]>([]);

//   const handlePageCount = (v: any) => {
//   }

  useEffect(() => {
    setScroll(true);
  }, [setScroll]);


  const dataFilter = useCallback((d: any) => {
    let data = d;
    if (cPed) {
        data = data.filter((item: any) => item.codigo === cPed);
        if (filtroT !== "Todos" && filtroT !== "SELECIONE") {
            data = data.filter((item: any) => item.status === filtroT);
            if (dataIniT || dataFinT) {
                if (dataIniT && !dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT));
                } else if (!dataIniT && dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) <= Format.data(dataFinT));
                } else {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT) && Format.data(item.alerta) <= Format.data(dataFinT));
                }
            }
        } else {
            if (dataIniT || dataFinT) {
                if (dataIniT && !dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT));
                } else if (!dataIniT && dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) <= Format.data(dataFinT));
                } else {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT) && Format.data(item.alerta) <= Format.data(dataFinT));
                }
            }
        }
    } else {
        if (filtroT !== "Todos" && filtroT !== "SELECIONE") {
            data = data.filter((item: any) => item.status === filtroT);
            if (dataIniT || dataFinT) {
                if (dataIniT && !dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT));
                } else if (!dataIniT && dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) <= Format.data(dataFinT));
                } else {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT) && Format.data(item.alerta) <= Format.data(dataFinT));
                }
            }
        } else {
            if (dataIniT || dataFinT) {
                if (dataIniT && !dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT));
                } else if (!dataIniT && dataFinT) {
                    data = data.filter((item: any) => Format.data(item.alerta) <= Format.data(dataFinT));
                } else {
                    data = data.filter((item: any) => Format.data(item.alerta) >= Format.data(dataIniT) && Format.data(item.alerta) <= Format.data(dataFinT));
                }
            }
        }
    }
    data.sort((a: any, b: any) => {

        if (Format.data(a.alerta) > Format.data(b.alerta)) {
            if (a.contato && !b.contato) {
                return 1;
            } else if (!a.contato && b.contato) {
                return -1;
            } else {
                return 0;
            }
        } else if (Format.data(a.alerta) < Format.data(b.alerta)) {
            if (a.contato && !b.contato) {
                return 1;
            } else if (!a.contato && b.contato) {
                return -1;
            } else {
                return 0;
            }
        } else {
            if (a.contato && !b.contato) {
                return 1;
            } else if (!a.contato && b.contato) {
                return -1;
            } else {
                return 0;
            }
        }
    });
    return data;
}, [cPed, dataFinT, dataIniT, filtroT])

  const loadDados = useCallback((pageCount: number = 0) => {
    const params: AxiosRequestConfig = {
        params: {
            codigo: config.empresa.empresaCodigo,
            page: pageCount,
            size: 5,
            codigoVendedor: vendedor,
            cPed: cPed,
            dataini: dataIniT,
            datafin: dataFinT,
            filtro: filtroT
        }
      } 
    CrmService.lstAllAgendamentos(config.empresa.empresaCodigo, vendedor, params)
    .then((res:AxiosResponse<SpringPage<any[]>>) => {
        setAgenda(dataFilter(res.data));
    })
    .catch((err:any) => { 
        toast.error(err.message)
    })
    .finally(() => {
        setIsLoading(false);
    });
}, [cPed, dataFilter, dataFinT, dataIniT, config.empresa.empresaCodigo, filtroT, vendedor])

useEffect(() => {
    loadDados();
}, [loadDados]);

const onSubmit = () => {
    setIsLoading(true);
    loadDados();
}

return (
    <Grid container spacing={1.5} sx={{ mt: 0.5, ml: 0.5 }} >
      <Grid item xs={12}>
          <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{mb: 3}}
          >
              <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                      <Controller
                          name="filtro"
                          control={control}
                          render={({field}) => (
                              <TextField
                                  {...field}
                                  select
                                  label="Status de Cliente"
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  inputProps={{ readOnly: false}}
                                  onChange={(e) => {
                                      setFiltroT(e.target.value)
                                  }}
                                  value={filtroT}
                              >
                                  <MenuItem value={"Todos"}>
                                      Todos
                                  </MenuItem>
                                  {camposFilter.map(option => (
                                      <MenuItem key={option.campo} value={option.campo}>
                                          {option.descricao}
                                      </MenuItem>
                                  ))}
                              </TextField>
                          )}
                      />
                  </Grid>
                  <Grid item md={3} xs={12}>
                      <Controller
                          name="cPed"
                          control={control}
                          render={({field}) => (
                              <TextField
                                  {...field}
                                  type={"number"}
                                  label="Nº Cliente"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{ readOnly: false,
                                    min: "0"
                                  }}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  onChange={(e) => setCped(e.target.value)}
                                  value={cPed}
                              />
                          )}
                      />
                  </Grid>
                  <Grid item md={2} xs={12}>
                      <Controller
                          name="dataini"
                          control={control}
                          render={({field}) => (
                              <TextField
                                  {...field}
                                  type={"date"}
                                  label="Data inicial"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{ readOnly: false,
                                    max: "9999-12-31"
                                  }}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  onChange={(e) => setDataIniT(e.target.value)}
                                  value={dataIniT}
                              />
                          )}
                      />
                  </Grid>
                  <Grid item md={2} xs={12}>
                      <Controller
                          name="datafin"
                          control={control}
                          render={({field}) => (
                              <TextField
                                  {...field}
                                  type={"date"}
                                  label="Data final"
                                  variant="outlined"
                                  fullWidth
                                  inputProps={{ readOnly: false,
                                    max: "9999-12-31"
                                  }}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                  onChange={(e) => setDataFinT(e.target.value)}
                                  value={dataFinT}
                              />
                          )}
                      />
                  </Grid>
                  <Grid item md={2} xs={12}>
                      <SendButton type={"submit"} variant="contained" size="large">
                          Buscar
                      </SendButton>
                  </Grid>
              </Grid>
          </Box>
      </Grid>
      {isLoading && <Loader/>}
      {!isLoading && (<>
          {agenda && agenda.map((d, i) =>
              <Grid item xs={12} sx={{alignItems: "center", cursor: "pointer"}} key={i}>
                  <CardAtendimento d={d} loadDados={loadDados} />
              </Grid>
          )}
          {/* <Grid item xs={12}>
              <Pagination
                  count={1}
                  defaultPage={1}
                  page={1}
                  onChange={(event, value) => {
                      handlePageCount(value)
                  }}
              />
          </Grid> */}
      </>)}
  </Grid>
  );
}

export default Atendimento;
