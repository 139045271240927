import * as React from 'react';
import {forwardRef, useEffect, useState} from 'react';
import {Box, Button, Divider, Grid, IconButton, MenuItem, TextField, useMediaQuery, useTheme,} from '@mui/material';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import {toast} from 'react-toastify';
import moment from 'moment';
import Format from 'utils/formatadores';
import InputMask from 'react-input-mask';
import {Agenda, LstAgenda} from 'types/crm';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';
import {CrmService} from 'services/CrmService';
import {useCrm} from 'contexts/CrmContext';
import {Controller, useForm} from 'react-hook-form';
import {hasModuloPermission, useAuthState} from "store/auth";

type Props = {
  d: LstAgenda;
  loadDados: () => void;
  setModalDetail: (value: boolean) => void;
  modalDetail: boolean;
}

type CanAlt = {
  canAlt: boolean;
  canDelete: boolean;
  canAtt: boolean;
  canAltDia: boolean;
  canAltTem: boolean;
}

type Campos = {
  newData: string;
  timer: string;
}

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  mask: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props;
    return (
      <InputMask
        {...other}
        mask={mask}
        inputRef={ref}
      />
    );
  },
);

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const ModalDetails = ({ d, loadDados, setModalDetail, modalDetail }: Props) => {
  const theme = useTheme();
  const config = useAppSelector(useConfigState);
  const appSelector = useAppSelector(useAuthState)
  const [today, setToday] = useState("");
  const { setDadosPedido, setScreen } = useCrm();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [campos, setCampos] = useState<Campos>({
    newData: "",
    timer: "0"
  });
  const [alt, setAlt] = useState<CanAlt>({
    canAlt: false,
    canDelete: false,
    canAtt: false,
    canAltDia: false,
    canAltTem: false,
  });
  const { control, setValue } = useForm<LstAgenda>({
    defaultValues: {
      alerta: moment(d.alerta).format("DD/MM/YYYY"),
      contato: d.contato ? moment(d.contato).format("DD/MM/YYYY") : '',
      nome: d.nome,
      observa: d.observa,
      codigo: d.codigo,
      endereco: d.endereco,
      bairro: d.bairro,
      municipio: d.municipio,
      estado: d.estado,
      email: d.email,
      telefone: d.telefone,
      celular: d.celular,
      status: d.status,
      taviso: d.taviso ? d.taviso : '0',
      timer: campos.timer,
      newData: campos.newData
    }
  });

  useEffect(() => {
    setToday(moment(new Date()).format('YYYY-MM-DD'));
  }, [setToday])

  useEffect(() => {
    if (!d.contato && d.alerta) {
      setAlt({
        canAtt: true,
        canDelete: true,
        canAlt: false,
        canAltDia: false,
        canAltTem: false,
      });
    } else if (d.contato && d.alerta) {
      setAlt({
        canDelete: false,
        canAtt: false,
        canAlt: true,
        canAltDia: true,
        canAltTem: true
      });
    }
    setModalDetail(true)

  }, [d, setAlt, setModalDetail])

  const handleCloseDetail = () => {
    setModalDetail(false)
  }

  const handleDelete = () => {
    CrmService.deleteAgenda(config.empresa.empresaCodigo, d.id)
      .then((res) => {
        toast.success("Sucesso! Este agendamento foi deletado.");
      })
      .catch((err: any) => {
        toast.error(err.response.data.message || "Erro ao deletar agendamento!");
      })
      .finally(() => {
        setModalDetail(false)
        loadDados();
      })
    //toast.warning("Este agendamento não existe mais! Parece magicamente ter desaparecido!");
  }

  const handleSave = (orcamento: boolean) => {
    let salveAgenda: Agenda = {
      alerta: d.alerta,
      contato: d.contato,
      timer: campos.timer,
      status: d.status,
      valor: "",
      observa: d.observa,
      orcamento: "",
      pedido: "",
      newAlerta: campos.newData,
      taviso: d.taviso
    }
    if (!alt.canAtt) {
      if (d.contato !== "" && d.contato !== null && d.contato !== undefined) {
        toast.error("Atendimento ja realizado não é possivel alterar");
      } else {
        CrmService.createAgenda(config.empresa.empresaCodigo, d.id, "", "", salveAgenda)
          .then(() => {
            toast.success("Agendamento Criado com Sucesso!");
          })
          .catch((err: any) => {
            toast.error(err.response.data.message || "Campos obrigatorios não preenchidos");
          })
          .finally(() => {
            setModalDetail(false)
            loadDados();
          })
      }
    } else {
      salveAgenda.contato = Format.dataUTC(today, "12:00");
      if (salveAgenda.newAlerta) {
        salveAgenda.newAlerta = Format.dataUTC(salveAgenda.newAlerta, "12:00");
      }
      CrmService.createAgenda(config.empresa.empresaCodigo, d.id, "", "", salveAgenda)
        .then(() => {
          if (salveAgenda.newAlerta) {
            salveAgenda.alerta = salveAgenda.newAlerta;
            salveAgenda.newAlerta = null;
            salveAgenda.contato = null;
            CrmService.createAgenda(config.empresa.empresaCodigo, "", "", "", salveAgenda)
          }
          setDadosPedido({
            codigo: d.codigo,
            loja: "",
            nome: d.nome,
            cgc: "",
            endereco: d.endereco,
            bairro: d.bairro,
            codigoMunicipio: "",
            municipio: d.municipio,
            estado: d.estado,
            recno: ""
          });
          toast.success("Atendimento concluido com Sucesso!");
          if (orcamento) {
            setModalDetail(false);
            setScreen('Orçamento');
          }
        })
        .catch((err: any) => {
          toast.error(err.message);
        })
        .finally(() => {
          setAlt({
            canAlt: false,
            canDelete: false,
            canAtt: false,
            canAltDia: true,
            canAltTem: true
          });
          setModalDetail(false)
          loadDados();
        })
    }
  }

  const onChangeTimer = (value: string) => {
    if (value === "0") {
      setAlt({
        ...alt,
        canAltDia: false
      });
      setCampos({
        ...campos,
        newData: ''
      });
    } else {
      setAlt({
        ...alt,
        canAltDia: true
      });
      setCampos({
        ...campos,
        newData: moment(d.alerta).add(value, 'd').format('YYYY-MM-DD')
      });
    }
    setValue("timer", value);
  }

  return (
    <Grid>
      <BootstrapDialog
        onClose={handleCloseDetail}
        aria-labelledby="customized-dialog-title"
        open={modalDetail}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={fullScreen}
        keepMounted
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDetail} >
          Detalhes do Atendimento
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{ mt: 1 }}
            component="form"
            noValidate
          // onSubmit={handleSubmit(handleSave)} 
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Controller
                  name="nome"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Nome de Cliente"
                      variant="outlined"
                      value={d.nome}
                      fullWidth
                      inputProps={{
                        readOnly: true,
                        inputComponent: TextMaskCustom as any,
                      }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="codigo"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Codigo"
                      variant="outlined"
                      value={d.codigo}
                      inputProps={{
                        readOnly: true,
                        inputComponent: TextMaskCustom as any,
                        inputProps: {
                          mask: '99999999',
                        },
                      }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="alerta"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Data do Agendamento"
                      variant="outlined"
                      required
                      type={"string"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        readOnly: true,
                        inputComponent: TextMaskCustom as any,
                      }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="contato"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Data do Contato"
                      variant="outlined"
                      type={"string"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: true }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="timer"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Reagendar em:"
                      variant="outlined"
                      inputProps={{ readOnly: alt.canAltTem, inputComponent: TextMaskCustom as any }}
                      select
                      onChange={(e) => onChangeTimer(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value={"0"}>-</MenuItem>
                      <MenuItem value={"15"}>15 Dias</MenuItem>
                      <MenuItem value={"30"}>30 Dias</MenuItem>
                      <MenuItem value={"60"}>60 Dias</MenuItem>
                      <MenuItem value={"90"}>90 Dias</MenuItem>
                      <MenuItem value={"120"}>120 Dias</MenuItem>
                      <MenuItem value={"150"}>150 Dias</MenuItem>
                      <MenuItem value={"180"}>180 Dias</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="newData"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={"date"}
                      label="Reagendar no dia"
                      variant="outlined"
                      value={campos.newData}
                      inputProps={{
                        readOnly: alt.canAltDia,
                        max: "9999-12-31",
                        min: moment().format("YYYY-MM-DD"),
                      }}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setAlt({
                            ...alt,
                            canAltTem: false
                          });
                        } else {
                          setAlt({
                            ...alt,
                            canAltTem: true
                          });
                        }
                        setCampos({
                          ...campos,
                          newData: e.target.value
                        });
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="taviso"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Tempo para Alerta"
                      variant="outlined"
                      value={d.taviso}
                      required
                      inputProps={{
                        readOnly: alt.canAlt,
                        inputComponent: TextMaskCustom as any,
                        inputProps: {
                          mask: '999',
                        },
                        max: "365"
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="telefone"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Telefone"
                      variant="outlined"
                      value={d.telefone}
                      inputProps={{ readOnly: true }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="celular"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Celular"
                      variant="outlined"
                      value={(d.celular.length < 5) ? d.celular : "Não informado"}
                      inputProps={{ readOnly: true }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="estado"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Estado"
                      variant="outlined"
                      value={d.estado}
                      inputProps={{ readOnly: true }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="municipio"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Cidade"
                      variant="outlined"
                      value={d.municipio}
                      inputProps={{ readOnly: true }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="bairro"
                  control={control}
                  render={({ field }) => (
                    <TextField  {...field}
                      label="Bairro"
                      variant="outlined"
                      value={d.bairro}
                      inputProps={{ readOnly: true }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField    {...field}
                      label="Email"
                      variant="outlined"
                      value={d.email ? d.email : "Não informado"}
                      inputProps={{ readOnly: true }}
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  name="observa"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-multiline-flexible"
                      label="Observações de Cliente"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ readOnly: alt.canAlt }}
                      multiline
                      fullWidth
                      rows={5}
                    />
                  )}
                />
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid item xs={12} sx={{ justifyContent: 'end' }}>
                {(false) &&
                  <Button autoFocus sx={{ ml: 2 }} type={'submit'} variant={'contained'} onClick={() => { handleSave(false) }} >
                    Incluir Agendamento
                  </Button>
                }
                {(alt.canAtt) &&
                  <>
                    <Button autoFocus sx={{ ml: 2 }} type={'submit'} variant={'contained'} onClick={() => { handleSave(false) }} >
                      Confirmar
                    </Button>
                    {hasModuloPermission(appSelector, config ,"ORCAMENTO") &&
                      <Button autoFocus sx={{ ml: 2 }} type={'submit'} variant={'contained'} onClick={() => { handleSave(true) }} >
                        Confirmar e Gerar Orçamento
                      </Button>
                    }
                    {(alt.canDelete) &&
                      <Button autoFocus sx={{ ml: 2 }} variant={'contained'} onClick={() => { handleDelete() }} >
                        Excluir
                      </Button>
                    }
                  </>
                }
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Grid>
  );
}
