import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {EmpresaService} from "services/EmpresaService";
import {cnpj} from "cpf-cnpj-validator";
import {toast} from "react-toastify";
import MyCard from "components/card";
import Loader from "components/loader";
import {Autocomplete, Box, Button, FormControl, MenuItem, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {filterOptions} from "utils/constants";
import {PermissaoService} from "services/PermissaoService";
import {Validadores} from "utils/validadores";
import {Modulo} from "types/auth";
import {toastError} from "utils/toastError";
import {useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {useDispatch} from "react-redux";

type UsuarioForm = {
    id: number;
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    status: string;
    cgc: string;
    modulos: Modulo[];
}

const AddEmpresas = () => {
    const config = useAppSelector(useConfigState);
    const dispatch = useDispatch();
    const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
    const {handleSubmit, formState: {errors}, getValues, register} = useForm<UsuarioForm>({
        mode: 'all',
        defaultValues: {
            id: 0,
            nome: '',
            password: '',
            cgc: '',
            confirm_senha: '',
            email: '',
            status: '1',
            modulos: [],
        }
    });
    const [options, setOptions] = useState<{ id: string; label: string; }[]>([]);
    const [optionsSelected, setOptionsSelected] = useState<{ id: string; label: string; }[]>([]);
    const navigate = useNavigate();


    const onSubmit = (data: any) => {
        data.cgc = data.cgc.replace(/\D/g, '');
        if (cnpj.isValid(data.cgc)) {
            dispatch(configActions.setLoading({
                loading: true,
            }));
            const formData = {
                ...data,
                status: parseInt(data.status),
                cnpj: data.cgc.replace(/\D/g, ''),
                modulos: optionsSelected.map(pr => {
                    return {nome: pr.id, descricao: pr.label}
                }),
            }
            EmpresaService.create(formData, {params: {codigo: config.parametros.codigoUnico}})
                .then(() => {
                    toast.success("Salvo com sucesso !");
                    navigate("/painel/empresas")
                })
                .catch((err) => toastError(err, "Erro ao salvar !"))
                .finally(() => dispatch(configActions.setLoading({
                    loading: false,
                })));
        } else {
            toast.error("CNPJ inválido !");
        }
    }

    const getPermissoes = useCallback(() => {
        dispatch(configActions.setLoading({
            loading: true,
        }));
        PermissaoService.findByGrupo({params: {codigo: config.parametros.codigoUnico}})
            .then((response) => {
                setOptions(response.data.map(pr => {return {id: pr.nome, label: `${pr.descricao}`}}));
            })
            .catch(() => toast.error("Erro ao buscar as permissões !"))
            .finally(() => dispatch(configActions.setLoading({
                loading: false,
            })));
    }, [config.parametros.codigoUnico, dispatch]);

    useEffect(() => {
        getPermissoes();
    }, [getPermissoes]);

    const handleChange = (e: any) => {
        setOptionsSelected(e);
    };


    return (
        <MyCard title={'Adicionar empresa'}>
            {config.loading ? <Loader/> : (
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                    <TextField
                        {...register("nome")}
                        required
                        error={!!errors.nome}
                        label="Nome"
                        variant="outlined"
                        sx={{width: '100%'}}
                        helperText={errors.nome?.message}
                    />
                    <TextField
                        {...register("email")}
                        required
                        error={!!errors.email}
                        type={'email'}
                        label="E-mail"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.email?.message}
                    />
                    <TextField
                        {...register("password", {validate: (value) => Validadores.senha(value)})}
                        error={!!errors.password}
                        type={'password'}
                        label="Senha"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.password?.message}
                    />
                    <TextField
                        {...register("confirm_senha", {validate: (value) => {
                            const {password} = getValues();
                            if (value !== password) return 'As senhas não são iguais.'
                        }})}
                        error={!!errors.confirm_senha}
                        type={'password'}
                        label="Confirmar senha"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.confirm_senha?.message}
                    />
                    <TextField
                        {...register('cgc', {validate: (value) => Validadores.cgc(value),})}
                        error={!!errors.cgc}
                        required
                        type={'text'}
                        label="CNPJ"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.cgc?.message}
                    />
                    <TextField
                        {...register("status")}
                        error={!!errors.status}
                        required
                        select
                        defaultValue={1}
                        label="Status"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.status?.message}
                    >
                        {statusField.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>))}
                    </TextField>
                    <FormControl fullWidth sx={{width: '100%', mt: 3}}>
                        <Autocomplete
                            disablePortal
                            value={optionsSelected}
                            multiple
                            filterOptions={filterOptions}
                            options={options}
                            fullWidth
                            isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Permissões" InputLabelProps={{shrink: true}} />}
                            onChange={(e, newValue) => {
                                handleChange(newValue);
                            }}
                        />
                    </FormControl>
                    <Box sx={{mt: 3, textAlign: 'right'}}>
                        <Button variant={'contained'} color={'primary'} type={'submit'}>
                            <SaveIcon/> Salvar
                        </Button>
                    </Box>
                </Box>
            )}
        </MyCard>
    );
}


export default AddEmpresas;