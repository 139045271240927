import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton,
    Slide,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TransitionProps} from '@mui/material/transitions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {LinkPersonalizado} from "types/cadastroExterno";
import {SpringPage} from "types/springpage";
import {Acesso} from "types/auth";
import {sortModulos} from "utils/colaborador";
import {inputsTypes} from "utils/aprovacao-cadastro";

type Props = {
    showModal: boolean;
    link: LinkPersonalizado;
    page: SpringPage<LinkPersonalizado>;
    onClose: () => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const ModalPermissoesLink = ({showModal, link, page, onClose}: Props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={showModal}
            onClose={onClose}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'xl'}
            fullScreen={fullScreen}
            keepMounted
        >
            <DialogContent>
                <Box sx={{mb: 3}}>
                    <Typography color='primary' sx={{fontSize: 16, fontWeight: 'bold'}}>
                        Detalhes do Link - {link.descricao}
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={() => onClose()}
                        sx={{
                            position: 'absolute',
                            right: 30,
                            top: 10,
                            zIndex: 1,
                            color: theme => theme.palette.grey[500]
                        }}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                {link.campos.length > 0 &&
                    <Grid container sx={{mb:2}}>
                        <Grid item xs={12}>
                            <Typography color='primary' sx={{fontSize: 12, fontWeight: 'bold', mb:2}}>
                                Campos Extras
                            </Typography>
                        </Grid>
                        {link.campos.map((campo, i) => (
                            <Grid item xs={12} md={6} lg={4}
                                  sx={{border: 1, borderColor: (t) => t.palette.grey[200], p: 2}}
                                  key={i}>
                                <Typography sx={{fontSize: 16}}><b>Ordem:</b>    {campo.ordem}</Typography>
                                <Typography sx={{fontSize: 16}}><b>Descrição:</b>    {campo.descricao}</Typography>
                                <Typography sx={{fontSize: 16}}><b>Tipo:</b>     {inputsTypes.find(inp => inp.tipo === campo.tipo).label}</Typography>
                                <Typography sx={{fontSize: 16}}><b>Obrigatório:</b>  {campo.obrigatorio ? "Sim" : "Não"}</Typography>
                                {campo.valor && <Typography sx={{fontSize: 16}}><b>Valor:</b>    {campo.valor}</Typography>}
                            </Grid>
                        ))}
                    </Grid>
                }
                {link.acessos.map((acessoM: Acesso, i) => (
                    acessoM.modulos.length > 0 &&
                        <React.Fragment key={i}>
                            <Typography color='primary' sx={{fontSize: 12, fontWeight: 'bold', mb:2}}>
                                Permissões
                            </Typography>
                            <Accordion sx={{width: "100%"}} key={acessoM.empresaCodigo}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography sx={{fontSize: 16, fontWeight: 600}}>{acessoM.empresaNome}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        {acessoM.modulos.sort(sortModulos).map((mod) => (
                                            <Grid item xs={12} md={6} lg={4}
                                                  sx={{border: 1, borderColor: (t) => t.palette.grey[200], p: 2}}
                                                  key={mod.nome}>
                                                <FormControlLabel
                                                    key={mod.nome}
                                                    label={mod.descricao}
                                                    sx={{fontWeight: 600}}
                                                    control={
                                                        <Checkbox
                                                            readOnly
                                                            name={mod.nome}
                                                            checked={!!page.content?.find((link) => link.acessos?.find((acesso) => acesso.modulos.find((modulo) => modulo.nome === mod.nome)))}
                                                        />
                                                    }
                                                />
                                                {mod.permissoes.length > 0 && (
                                                    <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                                        {mod.permissoes.map((per, i) => (
                                                            <FormControlLabel
                                                                key={i}
                                                                label={per.nome}
                                                                control={
                                                                    <Checkbox
                                                                        readOnly
                                                                        name={per.nome}
                                                                        checked={!!page.content?.find((link) => link.acessos?.find((acesso) => acesso.modulos.find((modulo) => modulo.permissoes.find((permissao) => permissao === per))))}
                                                                    />
                                                                }
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </React.Fragment>
                ))}
            </DialogContent>
        </Dialog>
    )
}

export default ModalPermissoesLink