import {Box, Button, Grid, MenuItem, TextField} from "@mui/material";
import {useAgendamentoEntrega} from "contexts/AgendamentoEntregaContext";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import moment from "moment";
import {useCallback, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useEffectOnce} from "react-use";
import {AgendamentoService} from "services/AgendamentoService";
import Swal from "sweetalert2";
import {AgendamentoPedido} from "types/agendamentos";
import {toastError} from "utils/toastError";
import TableProdutos from "../table-produtos";

type FormState = {
    date: string;
    horario: string;
    placaPrevista: string;
}

type Props = {
    handleBack: () => void;
    handleNext: () => void;
    pedidos: AgendamentoPedido[];
    updatePedido: ( pedido: AgendamentoPedido ) => void;
}

const SelectData = ( { handleBack, handleNext, pedidos, updatePedido }: Props ) => {
    const [listDatas, setListDatas] = useState<string[]>();
    const [horarios, setHorarios] = useState<string[]>( [] );
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const { control, setValue, register, handleSubmit, formState: { errors } } = useForm<FormState>( {} );
    const { parametros } = useAgendamentoEntrega();

    const getHorarios = ( data: string ) => {
        appDispatch( configActions.setLoading( { loading: true } ) );
        AgendamentoService.findHorariosLivres( data, config.empresa.empresaCodigo )
            .then( res => {
                setHorarios( res.data.sort() );
            } )
            .catch( err => {
                toastError( err, "Erro ao buscar horarios livres" );
            } )
            .finally( () => appDispatch( configActions.setLoading( { loading: false } ) ) );

    }

    const getDatas = useCallback( () => {
        appDispatch( configActions.setLoading( { loading: true } ) );
        AgendamentoService.findDatasLivres( pedidos.map( p => p.dataNecessidade ), config.empresa.empresaCodigo )
            .then( res => {
                setListDatas( res.data.sort() );
            } )
            .catch( ( err ) => {
                Swal.fire( {
                    title: 'Tolerância de Datas!',
                    text: err.response.data.message,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                } )
            } )
            .finally( () => appDispatch( configActions.setLoading( { loading: false } ) ) );
    }, [config.empresa.empresaCodigo, pedidos, appDispatch] );

    useEffectOnce( () => {
        getDatas()
    });


    const onSubmit = ( data: FormState ) => {
        let isInvalid = true;
        pedidos.forEach( p => {
            if ( !p.quantidade || parseInt( p.quantidade ) === 0 ) {
                isInvalid = false;
            }
        } )
        if ( !isInvalid ) {
            toast.warn( "Quantidade de produtos não pode ser 0" );
            return;
        }
        if ( data.date === 'SELECIONAR' ) {
            toast.warn( 'Selecione uma data válida.' )
            return;
        }
        if ( data.horario === 'SELECIONAR' ) {
            toast.warn( 'Selecione um horário válido.' )
            return;
        }
        if ( data.placaPrevista === '' ) {
            toast.warn( 'Selecione uma placa válida.' )
            return;
        }
        appDispatch( configActions.setLoading( { loading: true } ) );
        let body = {
            id: '',
            dataEntrega: data.date,
            horaEntrega: data.horario,
            placaPrevista: data.placaPrevista,
            status: 'PENDENTE',
            itens: pedidos
        }

        const params = {
            params: {
                codigo: config.empresa.empresaCodigo,
            }
        }
        AgendamentoService.create( body, params )
            .then( res => {
                handleNext();
            } ).catch( err => {
                toastError( err, "Erro ao criar agendamento" );
            } ).finally( () => appDispatch( configActions.setLoading( { loading: false } ) ) );

    }



    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2>Preencha as informações para entrega</h2>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" onSubmit={handleSubmit( onSubmit )} autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={12} md={4}>
                                <Controller
                                    name="date"
                                    control={control}
                                    defaultValue="SELECIONAR"
                                    rules={{ required: { value: true, message: 'Campo obrigatório' }, validate: ( valor ) => { if ( valor === 'SELECIONAR' ) return 'Campo obrigatório'; } }}
                                    render={({field}) =>(
                                        <TextField
                                            error={!!errors.date}
                                            required
                                            select
                                            defaultValue={"SELECIONAR"}
                                            label="Data"
                                            variant="outlined"
                                            onChange={( e ) => {
                                                setValue( 'date', e.target.value );
                                                setValue( 'horario', 'SELECIONAR');
                                                getHorarios( e.target.value );
                                            }}
                                            fullWidth
                                            helperText={errors.date?.message}
                                        >
                                            <MenuItem value={"SELECIONAR"} disabled>Selecionar</MenuItem>
                                            {listDatas?.map( ( option ) => (
                                                <MenuItem key={option} value={option}>
                                                    {moment( option ).format( "DD/MM/YYYY" )}
                                                </MenuItem> )
                                            )}
                                        </TextField>
                                    ) }
                                />
                            </Grid>
                            <Grid item xs={4} sm={12} md={4}>
                                <Controller
                                    name="horario"
                                    control={control}
                                    defaultValue="SELECIONAR"
                                    rules={{ required: { value: true, message: 'Campo obrigatório' }, validate: ( valor ) => { if ( valor === 'SELECIONAR' ) return 'Campo obrigatório'; } }}
                                    render={({field}) =>(
                                        <TextField
                                            {...field}
                                            error={!!errors.horario}
                                            required
                                            select
                                            label="Horario"
                                            variant="outlined"
                                            fullWidth
                                            helperText={errors.horario?.message}
                                        >
                                            <MenuItem value={"SELECIONAR"} disabled>Selecionar</MenuItem>
                                            {horarios?.map( ( option ) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem> ) )}
                                        </TextField>
                                    ) }
                                />
                            </Grid>
                            <Grid item xs={4} sm={12} md={4}>
                                <TextField
                                    {...register( "placaPrevista" )}
                                    required
                                    error={!!errors.placaPrevista}
                                    label="Placa Prevista"
                                    type={"text"}
                                    placeholder={"AAAA000"}
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{
                                        maxLength: 7,
                                        minLength: 7,
                                    }}
                                    helperText={errors.placaPrevista?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <TableProdutos pedidos={pedidos} updatePedido={updatePedido} editavel={parametros?.agendamentoParcial} />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: "end" }}>
                                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                    Voltar
                                </Button>
                                <Button variant="contained" type={"submit"} sx={{ mt: 3, ml: 1 }}>
                                    Confirmar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid >
            </Grid >
        </>
    );
}

export default SelectData;