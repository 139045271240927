import {Orcamento, ProdutoOrcamento} from "types/orcamento";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {TransitionProps} from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "components/loader";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import {styled} from "@mui/material/styles";
import Format from "utils/formatadores";
import OrcamentoUtils from "utils/orcamento";
import {OrcamentosService} from "services/OrcamentosService";
import {AxiosResponse} from "axios";
import {RestPage} from "types/prtoheuspage";
import {toast} from "react-toastify";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";


type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    handleSelect: (data: Orcamento) => void;
    codigoCliente: string;
};


const MyAccordion = styled(Accordion)({
    backgroundColor: "#f5f5f5",
});

// nomeConsumidor
// descricaoTab
// enderecoConsumidor
// data

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalHistorico = ({show, setShow, handleSelect, codigoCliente}:Props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [data, setData] = useState<Orcamento[]>([]);
    const config = useAppSelector(useConfigState);
    const [loading, setLoading] = useState(false);


    const loadData = useCallback(() => {
        setLoading(true);
        let temp = codigoCliente.substring(0, codigoCliente.length - 4);
        OrcamentosService.findHistorico(config.empresa.empresaCodigo, temp)
            .then((res:AxiosResponse<RestPage<Orcamento>>) => {
                setData(res.data.conteudo);
            })
            .catch(err => {
                toast.error(err.response.data.message || "Erro ao carregar histórico");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [codigoCliente, config.empresa.empresaCodigo]);

    useEffect(() => {
        loadData();
    }, [loadData]);




    return (
        <div>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"xl"}
                fullScreen={fullScreen}
                keepMounted
                onClose={() => setShow(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShow(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>{"Historico de orçamentos"}</DialogTitle>
                <DialogContent>
                    <Box>
                        {loading ? (
                            <Loader />
                        ) : (
                            data.map((item: Orcamento) => (
                                <MyAccordion disableGutters={false} key={item.id}>
                                    <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                <Typography sx={{fontWeight: 900}}>Cliente</Typography>
                                                <Typography>{item.nomeConsumidor}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Typography sx={{fontWeight: 900}}>Data de Pedido</Typography>
                                                <Typography>{Format.data(item.data)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Typography sx={{fontWeight: 900}}>Valor</Typography>
                                                <Typography>{Format.moeda(OrcamentoUtils.valorTotal(item.produtos, item.valorFrete))}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Typography sx={{fontWeight: 900}}>Cond. Pag</Typography>
                                                <Typography>{item.descricaoCondicao}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Button onClick={() => handleSelect(item)} variant={"contained"}>Selecionar</Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <Typography sx={{fontWeight: 900}}>Endereço</Typography>
                                                <Typography>{item.enderecoConsumidor}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Typography sx={{fontWeight: 900}}>Bairro</Typography>
                                                <Typography>{item.bairroConsumidor}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Typography sx={{fontWeight: 900}}>Cidade</Typography>
                                                <Typography>{item.municipioConsumidor}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Typography sx={{fontWeight: 900}}>Estado</Typography>
                                                <Typography>{item.estadoConsumidor}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Typography sx={{fontWeight: 900}}>Transportadora</Typography>
                                                <Typography>{item.nomeTransportadora}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{mt: 2}}>
                                                <Grid item xs={12} md={12} sx={{mb: 2}}>
                                                    <hr />
                                                </Grid>
                                                <Grid container>
                                                    {item.produtos.map((produto: ProdutoOrcamento) => (
                                                        <Grid item xs={12} md={4} key={produto.codigo}>
                                                            <Typography sx={{fontWeight: 900}}>{produto.descricao}</Typography>
                                                            <Typography>{produto.quantidade} x {Format.moeda(produto.valor)}</Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </MyAccordion>
                            ))
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}


export default ModalHistorico;