import numeral from "numeral";

numeral.locale('custom');

export const CotacaoUtils = {
  limpaMoney: (valor: string) => {
    let newValue;
    if (valor || valor === "0") {
      newValue = numeral(valor).value()
    } else {
      return "0";
    }
    return newValue.toString();
  },
}