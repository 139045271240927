import {Materiais} from 'types/solicitacao-materiais';

const SolicitacaoMateriaisUtils = {
  reducerSelecionados: (state: Materiais[], action: any) => {
    switch (action.type) {
      case 'addAll':
        return [...action.payload];
      case 'add':
        return [
          ...state.filter(
            (item: Materiais, index: any) =>
              item.codigo !== action.payload.codigo
          ),
          action.payload,
        ];
      case 'remove':
        return [
          ...state.filter(
            (item: Materiais, index: any) =>
              item.codigo !== action.payload.codigo
          ),
        ];
      case 'updateQtdSolicitada':
        return [
          ...state.map((item: Materiais, index: any) => {
            if (item.codigo === action.payload.codigo) {
              item.qtdSolicitada = action.payload.qtdSolicitada;
            }
            return item;
          }),
        ];
      case 'updateCentroCusto':
        return [
          ...state.map((item: Materiais, index: any) => {
            if (item.codigo === action.payload.codigo) {
              item.centroCusto = action.payload.centroCusto;
            }
            return item;
          }),
        ];
      case 'updateNecessidade':
        return [
          ...state.map((item: Materiais, index: any) => {
            if (item.codigo === action.payload.codigo) {
              item.necessidade = action.payload.necessidade;
            }
            return item;
          }),
        ];
      case 'updateObservacao':
        return [
          ...state.map((item: Materiais, index: any) => {
            if (item.codigo === action.payload.codigo) {
              item.observacao = action.payload.observacao;
            }
            return item;
          }),
        ];
      case 'clear':
        return [];
      default:
        return state;
    }
  },
};

export default SolicitacaoMateriaisUtils;
