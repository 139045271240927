import MyCard from 'components/card';
import {CampoFilter, SolicitacaoMaterial} from 'types/solicitacao-materiais';
import {Autocomplete, Box, Button, Card, Grid, MenuItem, TextField, Typography} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import {toast} from 'react-toastify';
import {useCallback, useEffect, useReducer, useState} from 'react';
import {SolicitacaoMateriaisService} from 'services/SolicitacaoMateriaisService';
import moment from 'moment';
import ButtonDelete from 'components/buttons/delete';
import ModalAddMateriais from 'components/solicitacao-material/modal/addMateriais';
import SolicitacaoMateriaisUtils from 'utils/solicitacao-materiais';
import {AxiosResponse} from 'axios';
import {filterOptions} from 'utils/constants';
import {useAppDispatch, useAppSelector} from "store";
import {useAuthState} from "store/auth";
import {configActions, useConfigState} from "store/config";

type SolicitacaoMaterialForm = {
  numero: string;
  solicitante: string;
  dataEmissao: string;
  filialEntrega: string;
};

const EditSolicitacoesCompras = () => {
  const config = useAppSelector(useConfigState);
  const [materiais, dispatch] = useReducer( SolicitacaoMateriaisUtils.reducerSelecionados, [] );
  const appDispatch = useAppDispatch();
  const { userName } = useAppSelector(useAuthState)
  const [showModal, setShowModal] = useState( false );
  const [filiais, setFiliais] = useState<CampoFilter[]>();
  const [centroCusto, setCentroCusto] = useState<CampoFilter[]>();
  const [minDate, setMinDate] = useState( '' );

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control
  } = useForm<SolicitacaoMaterialForm>();

  const handleView = () => {
    setShowModal( true );
  };

  const handleClose = () => {
    setShowModal( false );
  };

  const getSolicitacaoById = useCallback( () => {
    appDispatch(configActions.setLoading({loading: true}))
    SolicitacaoMateriaisService.findById( config.empresa.empresaCodigo, id )
      .then( ( res: AxiosResponse<SolicitacaoMaterial> ) => {
        dispatch( { type: 'addAll', payload: res.data.materiais } );
        setValue( 'numero', res.data.numero );
        setValue( 'solicitante', userName );
        setValue( 'dataEmissao', moment(res.data.dataEmissao).format("YYYY-MM-DD") );
        setValue( 'filialEntrega', res.data.filialEntrega);
        setMinDate( moment( res.data.dataEmissao ).format( 'YYYY-MM-DD' ) );
      } )
      .catch( err => {
        toast.error( err.response.data.message || 'Erro ao carregar as solicitações', { autoClose: 1000 } );
      } )
      .finally( () => {
        appDispatch(configActions.setLoading({loading: false}))
      } );
  }, [appDispatch, config.empresa.empresaCodigo, id, setValue, userName] );


  const saveSolicitacao = ( data: any ) => {
    if ( id ) {
      appDispatch(configActions.setLoading({loading: true}))
      SolicitacaoMateriaisService.update( config.empresa.empresaCodigo, id, data )
        .then( ( res ) => {
          toast.success( `Solicitação nº ${res.data.numero} editada com sucesso!` );
          navigate( '/painel/solicitacao-materiais' );
        } )
        .catch( err => toast.error( err.response.data.message ) )
        .finally( () => appDispatch(configActions.setLoading({loading: false})) );
    } else {
      toast.error( 'Não foi possível salvar o orçamento' );
    }
  };

  const editarSolicitacao = ( data: SolicitacaoMaterial ) => {
    // let valorTotal = solicitacao.reduce((acc, material) => acc + parseFloat(material.valor) * material.qtdSolicitada, 0);

    let solicitacao = {
      numero: data.numero,
      dataEmissao: data.dataEmissao,
      codComprador: data.codComprador,
      filialEntrega: data.filialEntrega,
      materiais: materiais
    };
    if ( materiais.length === 0 ) {
      toast.error( 'Adicione pelo menos um material' );
      return;
    }

    let valid = true;

    for ( let i = 0; i < materiais.length; i++ ) {
      if ( materiais[i].qtdSolicitada === '0' ) valid = false;
    }

    if ( !valid ) {
      toast.error( 'O valor da quantidade deve ser maior que 0' );
      return;
    }
    saveSolicitacao( solicitacao );
  };

  const addMaterial = ( material: any ) => {
    material.qtdSolicitada = '1';
    material.necessidade = moment().format( 'YYYYMMDD' );
    material.observacao = 'Sem observação';
    dispatch( { type: 'add', payload: material } );
    toast.success( `Produto ${material.descricao} adicionado com sucesso !`, {
      autoClose: 1000
    } );
  };

  const removeMaterial = ( material: any ) => {
    dispatch( { type: 'remove', payload: material } );
  };

  const editCentroCusto = ( centroCusto: string, codigo: string ) => {
    dispatch( {
      type: 'updateCentroCusto',
      payload: {
        codigo,
        centroCusto
      }
    } );
  };

  const editNecessidade = ( necessidade: string, codigo: string ) => {
    dispatch( {
      type: 'updateNecessidade',
      payload: {
        codigo,
        necessidade
      }
    } );
  };

  const editQuantidade = ( qtdSolicitada: string, codigo: string ) => {
    dispatch( {
      type: 'updateQtdSolicitada',
      payload: {
        codigo,
        qtdSolicitada
      }
    } );
  };

  const editObservacao = ( observacao: string, codigo: string ) => {
    dispatch( {
      type: 'updateObservacao',
      payload: {
        codigo,
        observacao
      }
    } );
  };

  const buscarInfos = useCallback( async () => {
    try {
      appDispatch(configActions.setLoading({loading: true}))

      const centroCustoResponse = await SolicitacaoMateriaisService.findCentroCustoFiltro( config.empresa.empresaCodigo );
      setCentroCusto( centroCustoResponse.data.map(pr => {return {campo: pr.campo, descricao: pr.descricao}}));

      const filiaisResponse = await SolicitacaoMateriaisService.findFiliaisFiltro( config.empresa.empresaCodigo );
      setFiliais( filiaisResponse.data );

    } catch ( error ) {
      toast.error( 'Erro ao carregar campos de filtro' );
    } finally {
      getSolicitacaoById();
      appDispatch(configActions.setLoading({loading: false}))
    }
  }, [config.empresa.empresaCodigo, getSolicitacaoById, appDispatch ] );

  useEffect( () => {
    buscarInfos();
  }, [buscarInfos] );

  return (
    <div>
      <MyCard title={'Adicionar solicitação de materiais'}>
        <Box component='form' onSubmit={handleSubmit( editarSolicitacao )}>
            <Grid container spacing={2} sx={{ mb: 6 }}>
              <Grid item xs={4}>
                <TextField
                  label='Número'
                  variant='outlined'
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ readOnly: true }}
                  error={!!errors.solicitante}
                  helperText={errors.solicitante?.message}
                  {...register( 'numero', {
                    required: {
                      value: true,
                      message: 'Data de Emissão obrigatória'
                    }
                  } )}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type={'date'}
                  label='Data de Emissão'
                  variant='outlined'
                  fullWidth
                  inputProps={{
                    readOnly: true,
                    max: '9999-12-31',
                    min: minDate
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  {...register( 'dataEmissao', {
                    required: {
                      value: true,
                      message: 'Data de Emissão obrigatória'
                    }
                  } )}
                  error={!!errors.dataEmissao}
                  helperText={errors.dataEmissao?.message}
                />
              </Grid>

              <Grid item xs={4}>
                <Controller name='filialEntrega' control={control} render={({field}) =>
                   <TextField
                   {...field}
                   required
                   select
                   label='Local de Entrega'
                   variant='outlined'
                   defaultValue={''}
                   error={!!errors.filialEntrega}
                   helperText={errors.filialEntrega?.message}
                   fullWidth
                   InputLabelProps={{shrink: true}}
                 >
                   <MenuItem value={''} disabled>
                     Selecione um local de entrega
                   </MenuItem>
                   {filiais && filiais.map( ( campo ) => (
                     <MenuItem key={campo.campo} value={campo.campo}>
                       {campo.descricao}
                     </MenuItem>
                   ) )}
                 </TextField>
                }  
              />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ justifyContent: 'space-around', alignItems: 'center' }}>
              <Grid item xs={6}>
                <Box sx={{ fontSize: 14, fontWeight: 'h6.fontWeight', mb: 3 }}>Lista de materiais cadastrados</Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ mb: 3, textAlign: 'right' }}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                      handleView();
                    }}>
                    Adicionar Materiais
                  </Button>
                </Box>
              </Grid>
              {materiais &&
                materiais.map( ( material, i ) => (
                  <Grid item xs={12} key={i}>
                    <Card variant='elevation' sx={{ p: 2, backgroundColor: t => t.palette.grey[50] }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          justifyContent: 'space-around',
                          alignItems: 'center'
                        }}>
                        <Grid item xs={6} md={4} lg={1.5}>
                          <Typography sx={{ fontWeight: '900' }}>Código</Typography>
                          <Typography>{material.codigo}</Typography>
                        </Grid>
                        <Grid item xs={6} md={2} lg={0.5}>
                          <Typography sx={{ fontWeight: '900' }}>UM</Typography>
                          <Typography>{'UM'}</Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={3}>
                          <Typography sx={{ fontWeight: '900' }}>Descrição</Typography>
                          <Typography>{material.descricao}</Typography>
                        </Grid>

                        <Grid item xs={6} md={4.5} lg={2.5}>
                          <Typography sx={{ fontWeight: '900' }}>Centro Custo</Typography>
                            <Autocomplete
                              disablePortal
                              options={centroCusto}
                              fullWidth
                              size="small"
                              filterOptions={filterOptions}
                              getOptionLabel={(option: any) => option.descricao}
                              isOptionEqualToValue={(option:any, value:any) => option.campo === value.campo}
                              renderInput={(params) => 
                              <TextField 
                              {...params}
                              required
                              />}
                              onChange={( e, newValue ) => {
                                if (newValue) {
                                  editCentroCusto( newValue.campo, material.codigo );
                                }
                              }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4.5} lg={2.5}>
                          <Typography sx={{ fontWeight: '900' }}>Necessidade</Typography>
                          <TextField
                            type={'date'}
                            variant='outlined'
                            size='small'
                            required
                            defaultValue={moment( material.necessidade ).format( 'YYYY-MM-DD' )}
                            fullWidth
                            inputProps={{
                              readOnly: false,
                              max: '9999-12-31',
                              min: moment( material.necessidade ).format( 'YYYY-MM-DD' )
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            onChange={e => {
                              editNecessidade( e.target.value, material.codigo );
                            }}
                          />
                        </Grid>

                        <Grid item xs={6} md={3} lg={2}>
                          <Typography sx={{ fontWeight: '900' }}>Qtd.</Typography>
                          <TextField
                            type={'number'}
                            variant='outlined'
                            size='small'
                            required
                            fullWidth
                            defaultValue={material.qtdSolicitada}
                            inputProps={{
                              min: '0.00001',
                              step: '0.00001'
                            }}
                            onChange={e => {
                              editQuantidade( e.target.value, material.codigo );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Typography sx={{ fontWeight: '900' }}>Observação</Typography>
                          <TextField
                            multiline
                            rows={2}
                            variant='outlined'
                            size='small'
                            fullWidth
                            defaultValue={material.observacao}
                            InputLabelProps={{
                              shrink: true
                            }}
                            onChange={e => {
                              editObservacao( e.target.value, material.codigo );
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          &nbsp;
                          <ButtonDelete handleDelete={() => removeMaterial( material )} />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ) )}
            </Grid>

            <Box sx={{ mt: 3, textAlign: 'right' }}>
              <Button variant={'contained'} color={'primary'} type={'submit'}>
                <SaveIcon /> Salvar
              </Button>
            </Box>
          </Box>
      </MyCard>
      {/* {showModal && <ModalAddMateriais showModal={showModal} onClose={() => handleClose()} selecionadosReducer={selecionadosReducer} addMaterial={addMaterial} />} */}
      {showModal && <ModalAddMateriais showModal={showModal} onClose={() => handleClose()} addMaterial={addMaterial} />}
    </div>
  );
};

export default EditSolicitacoesCompras;
