import {AxiosRequestConfig} from "axios";
import request from "utils/axiosInterceptor";

let store;

export const injectStore = _store => {
    store = _store
}

export const API = {
    get: (url: string, config?: AxiosRequestConfig) => {
        let params: AxiosRequestConfig = {
            method: 'GET',
            url,
            ...config
        }
        let headers = {}
        if (config?.withCredentials && store.getState().auth.authData?.access_token) {
            headers = {...headers, 'Authorization': 'Bearer ' + store.getState().auth.authData.access_token}
        }
        // if (authData?.chave) {
        //     headers = {...headers, "Chave-Acesso": authData.chave}
        // }
        params = {...params, headers: {...headers}}
        return request(params)
    },
    post: (url: string, data: any, config?: AxiosRequestConfig) => {
        let params: AxiosRequestConfig = {
            method: 'POST',
            url,
            ...config,
            data,
        }
        let headers = {}
        if (config?.withCredentials && store.getState().auth.authData?.access_token) {
            headers = {...headers, 'Authorization': 'Bearer ' + store.getState().auth.authData.access_token}
        }
        // if (authData?.chave) {
        //     headers = {...headers, "Chave-Acesso": authData.chave}
        // }
        params = {...params, headers: {...headers}}
        return request(params)
    },
    put: (url: string, data: any, config?: AxiosRequestConfig) => {
        let params: AxiosRequestConfig = {
            method: 'PUT',
            url,
            ...config,
            data,
        }
        let headers = {}
        if (config?.withCredentials && store.getState().auth.authData?.access_token) {
            headers = {...headers, 'Authorization': 'Bearer ' + store.getState().auth.authData.access_token}
        }
        // if (authData?.chave) {
        //     headers = {...headers, "Chave-Acesso": authData.chave}
        // }
        params = {...params, headers: {...headers}}
        return request(params)
    },
    delete: (url: string, config?: AxiosRequestConfig) => {
        let params: AxiosRequestConfig = {
            method: 'DELETE',
            url,
            ...config
        }
        let headers = {}
        if (config?.withCredentials && store.getState().auth.authData?.access_token) {
            headers = {...headers, 'Authorization': 'Bearer ' + store.getState().auth.authData.access_token}
        }
        // if (authData?.chave) {
        //     headers = {...headers, "Chave-Acesso": authData.chave}
        // }
        params = {...params, headers: {...headers}}
        return request(params)
    }
}