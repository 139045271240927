import {Box, Divider, Grid, Typography} from "@mui/material"
import {useCrm} from "contexts/CrmContext"
import {useEffect, useState} from "react"
import {FunnelChart} from "react-funnel-pipeline"
import 'react-funnel-pipeline/dist/index.css'
import {CardDados} from "./cardDados"
import ReactApexChart from "react-apexcharts";
import {TFunil} from 'types/crm';
import {CrmService} from "services/CrmService"
import {toast} from "react-toastify"
import {AxiosRequestConfig} from "axios"
import Loader from "components/loader"
import {useAppSelector} from "store"
import {useConfigState} from "store/config"

const GraphStyle = {
  width: "80%",
  minHeight: "100%",
  margin: "0 auto",
}

export const Funil = () => {
  const { state, columns, columnsName, setScroll, vendedor, isLoading, setIsLoading } = useCrm();
  const config = useAppSelector(useConfigState);
  const [dataFunil, setDataFunil] = useState<any>();
  const [dadosGerais, setDadosGerais] = useState<any>();
  const [dadosFunil, setDadosFunil] = useState<TFunil>({} as TFunil);

  useEffect(() => {
    setScroll(false);
  }, [setScroll]);

  useEffect(
    () => {
      let itemFunil = [];
      let counter = 0;
      let series: number[] = [];
      for (let i = 0; i < columns.length; i++) {
        let count: number = state.filter((item) => item.status === columns[i]).length;
        let obj = { name: columnsName[i], value: count };
        itemFunil.push(obj);
        if (columns[i] === "1") {
          series.push(count);
        } else {
          counter = counter + count;
        }
      }
      setDataFunil(itemFunil);
      series.push(counter);

      let total = series[0] + series[1]
      let gerais1 = (series[0] / total) * 100;
      let gerais2 = (series[1] / total) * 100;
      
      setDadosGerais({
       numero: [series[0].toString(), series[1].toString()],
       porcentagem: [gerais1.toFixed(2).toString(), gerais2.toFixed(2).toString()]
      });

      const params : AxiosRequestConfig = {
        params: {
          codigo: config.empresa.empresaCodigo,
        }
      };
      CrmService.findFunil(vendedor, config.empresa.empresaCodigo, params)
      .then((res) => {
        setDadosFunil(res.data);
      })
      .catch((err) => { 
        toast.error(err.response.data.message); 
      })
      .finally(() => {
        setIsLoading(false);
      });
    },[columns, columnsName, config.empresa.empresaCodigo, setIsLoading, state, vendedor]
    );
        
  return (
    <Grid container item spacing={2}>
      { isLoading && <Loader/> }
      { !isLoading &&
        <>
          <Grid container item xs={12} md={6} sx={{ px: 2, height: "100%", minHeight: "73vh" }} >
            <Box sx={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      height: "100%",
                      width: "100%",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                      backgroundColor: "#fff",
                      p: 2
                      }}
                  component={Grid}
                  container item xs={12} spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h1" color={"black"} fontWeight={"900"} fontSize={20} sx={{ mb: 1 }} >
                  Tabela de Dados
                </Typography>
                <Divider/>
              </Grid>
              <Grid item xs={12} md={6} >
                <CardDados  title="Dados Do Vendedor" 
                            data1={"Ticket Médio: R$ " + dadosFunil.bigVenda} 
                            data2={"Prazo Médio: " + dadosFunil.medPrazo + " Dias"} 
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardDados  title="Contatos/Contatados" 
                            data1={dadosGerais && "Sem Contato: Total " + dadosGerais.numero[0] + " - " + dadosGerais.porcentagem[0] + "%"} 
                            data2={dadosGerais && "Com Contato: Total " + dadosGerais.numero[1] + " - " + dadosGerais.porcentagem[1] + "%"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardDados title="Agendamento / Atendimento">
                  {dadosFunil.qtdAgendamento && dadosFunil.qtdAtendimento &&
                    <ReactApexChart
                      options={{
                        labels: ["Agendamentos", "Atendimentos"],
                        legend: { show: true, 
                          position: "bottom", 
                          labels: { 
                            useSeriesColors: false
                          },
                          onItemHover: {
                            highlightDataSeries: false
                          }
                        },
                        dataLabels: { enabled: true },
                        tooltip: { enabled: true },
                        states: {
                          hover: { filter: { type: "none", value: 0 } },
                          active: { filter: { type: "none", value: 0 } }
                        },
                        stroke: { width: 0 },
                        plotOptions: {
                          pie: {
                            expandOnClick: false,
                            donut: {
                              size: "65%",
                              labels: {
                                show: true,
                                name: { show: true },
                                total: {
                                  show: true,
                                  showAlways: true,
                                  formatter: function (w: any) {
                                    const totals = w.globals.seriesTotals;
                                    const total = totals[0] + totals[1];
                                    return total
                                  }
                                }
                              }
                            }
                          }
                        }
                      }}
                      series={[dadosFunil.qtdAgendamento, dadosFunil.qtdAtendimento]}
                      type="donut"
                    />
                  }
                </CardDados>
              </Grid>
              <Grid item xs={12} md={6}>
              {dadosFunil.qtdProposta && dadosFunil.qtdFechamento &&
                <CardDados title="Proposta / Fechamento" >
                  <ReactApexChart
                    options={{
                      labels: ["Proposta", "Fechamento"],
                      legend: { show: true, 
                                position: "bottom", 
                                labels: { 
                                  useSeriesColors: false
                                },
                                onItemHover: {
                                  highlightDataSeries: false
                                }
                              },
                      dataLabels: { enabled: true },
                      tooltip: { enabled: true },
                      // fill: { colors: ["#f26684", "#7FD320"] },
                      states: {
                        hover: { filter: { type: "none", value: 0 } },
                        active: { filter: { type: "none", value: 0 } }
                      },
                      stroke: { width: 0 },
                      plotOptions: {
                        pie: {
                          expandOnClick: false,
                          donut: {
                            size: "65%",
                            labels: {
                              show: true,
                              name: { show: true },
                              total: {
                                show: true,
                                showAlways: true,
                                formatter: function (w: any) {
                                  const totals = w.globals.seriesTotals;
                                  const total = totals[0] + totals[1];
                                  return total
                                }
                              }
                            }
                          }
                        }
                      }
                    }}
                    series={[dadosFunil.qtdProposta, dadosFunil.qtdFechamento] as number[]}
                    type="donut"
                  />
                </CardDados>
              } 
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ px: 2, height: "100%", minHeight: "73vh" }} >
            <Box  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    height: "100%",
                    width: "100%",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
                    backgroundColor: "#fff",
                    p: 2
                  }}
                  component={Grid}
                  container item xs={12} spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h1" color={"black"} fontWeight={"900"} fontSize={20} sx={{ mb: 1 }} >
                  Grafico Pipeline
                </Typography>
              </Grid>
              <Divider sx={{ mb: 3 }}/>
              <Grid item xs={12} pt={5}>
                <FunnelChart 
                  data={dataFunil}
                  style={GraphStyle}
                />
              </Grid>
            </Box>
          </Grid>
        </>
      }
    </Grid>
  )
}
