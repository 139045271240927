import {useCallback, useEffect, useState} from 'react';
import {SpringPage} from 'types/springpage';
import {Colaborador} from 'types/user';
import {useNavigate, useSearchParams} from 'react-router-dom';
import ActionButtons from 'components/buttons';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
import MyCard from 'components/card';
import {Card, CardContent, Chip, Grid, MenuItem, Pagination, TextField, Typography} from '@mui/material';
import {ColaboradorService} from 'services/ColaboradorService';
import {PAGE_SIZE_SELECT} from 'utils/constants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {useForm} from 'react-hook-form';
import SeachForms from 'components/searchForms';
import {useAppDispatch, useAppSelector} from 'store';
import {configActions, useConfigState} from 'store/config';

const ListColaboradores = () => {
    const [page, setPage] = useState<SpringPage<Colaborador>>();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPageSize = '10';
    const defaultPage = '1';
    const config = useAppSelector(useConfigState);
    const sweetScreen = withReactContent(Swal);
    const forms = useForm({
        defaultValues: {
            codigo: searchParams.get('codigo') || '',
            nome: searchParams.get('nome') || '',
            cgc: searchParams.get('cgc') || '',
            modulo: searchParams.get('modulo') || ''
        }
    });
    const appDispatch = useAppDispatch();

    const loadColaboradores = useCallback(() => {
        if (!config.parametros || !config.parametros.codigoUnico) {
            toast.warning('Grupo não encontrado, selecione o grupo!');
            return;
        }
        if (!config.empresa || !config.empresa.empresaCodigo) {
            toast.warning('Empresa não encontrada, selecione a empresa!');
            return;
        }
      appDispatch(configActions.setLoading({loading: true}))
        const params: AxiosRequestConfig = {
            params: {
                page: parseInt(searchParams.get('page') || defaultPage) - 1,
                size: searchParams.get('size') || defaultPageSize,
                codigoUsuario: searchParams.get('codigo') || '',
                nome: searchParams.get('nome') || '',
                codigo: config.empresa.empresaCodigo,
                cgc: searchParams.get('cgc') || '',
                modulo: searchParams.get('modulo') || ''
            }
        };
        ColaboradorService.findAll(params)
            .then((res: AxiosResponse<SpringPage<Colaborador>>) => {
                setPage(res.data);
            })
            .catch(err => {
                toast.error(err);
            })
            .finally(() => {
              appDispatch(configActions.setLoading({loading: false}))
            });
    }, [config.parametros, config.empresa, appDispatch, searchParams]);

    useEffect(() => {
        loadColaboradores();
    }, [loadColaboradores]);

    const handleEdit = (id: string) => {
        navigate(`/painel/colaboradores/edit/${id}`);
    };

    const handleDelete = (id: string) => {
        sweetScreen
            .fire({
                title: `Deseja realmente excluir o colaborador:\n ${id} - ${page.content.find(pr => pr.id === id).nome} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Sim, deletar colaborador!`,
                cancelButtonText: `Não`,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            })
            .then(result => {
                if (result.isConfirmed) {
                  appDispatch(configActions.setLoading({loading: true}))
                    ColaboradorService.delete(id)
                        .then(res => {
                            toast.success('Colaborador deletado com sucesso!');
                            loadColaboradores();
                        })
                        .catch(err => {
                            toast.error(err.response.data.message);
                        })
                        .finally(() => {
                            appDispatch(configActions.setLoading({loading: false}))
                        });
                }
            });
    };

    const onSubmit = (d: any) => {
        setSearchParams({
            page: defaultPage,
            size: searchParams.get('size') || defaultPageSize,
            codigo: d.codigo,
            nome: d.nome,
            cgc: d.cgc,
            modulo: d.modulo
        });
    };

    return (
        <MyCard title={'Colaboradores'} action={() => navigate('/painel/colaboradores/add')}>
            <SeachForms forms={forms} onSubmit={onSubmit}/>
                <Grid item xs={12} sx={{mb: 3}}>
                    {page &&
                        page.content.map(colab => (
                            <Card sx={{width: '100%', mb: 2}} key={colab.id}>
                                <CardContent>
                                    <Grid container alignItems={'center'}>
                                        <Grid item xs={12} md={5}>
                                            <Typography sx={{fontSize: 16}} color='text.secondary' gutterBottom>
                                                <b>Codigo : </b> {colab.id.substring(4)}
                                            </Typography>
                                            <Typography sx={{fontSize: 16}} color='text.secondary' gutterBottom>
                                                <b>Nome: </b> {colab.nome}
                                            </Typography>
                                            <Typography sx={{fontSize: 16}} color='text.secondary' gutterBottom>
                                                <b>Email: </b> {colab.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <Typography sx={{fontSize: 16}} color='text.secondary' gutterBottom>
                                                <b>Empresa: </b> {colab.id.substring(0, 4)}
                                            </Typography>
                                            <Typography sx={{fontSize: 16}} color='text.secondary' gutterBottom
                                                        component={'div'}>
                                                <b>Status: </b>{' '}
                                                {colab.status ? (
                                                    <Chip label='Ativo' color='success' size={'small'} sx={{px: 1}}/>
                                                ) : (
                                                    <Chip label='Bloqueado' color='error' size={'small'} sx={{px: 1}}/>
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <ActionButtons
                                                handleEdit={() => handleEdit(colab.id)}
                                                handleDelete={() => handleDelete(colab.id)}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                </Grid>
            {page && page.totalElements > 0 && (
                <Grid container item xs={12}>
                    <Grid item md={6} xs={6}>
                        <Pagination
                            count={page?.totalPages}
                            defaultPage={parseInt(defaultPage)}
                            page={parseInt(searchParams.get('page') || defaultPage)}
                            onChange={(event, value) => {
                                setSearchParams({page: `${value}`, size: searchParams.get('size') || defaultPageSize});
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: 'right'}}>
                        <TextField
                            id='outlined-select-currency'
                            select
                            label='Registros por pagina'
                            onChange={(e: any) => {
                                setSearchParams({page: '1', size: e.target.value});
                            }}
                            size='small'
                            value={searchParams.get('size') || defaultPageSize}
                            sx={{minWidth: 150}}>
                            {PAGE_SIZE_SELECT.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            )}
        </MyCard>
    );
};

export default ListColaboradores;
