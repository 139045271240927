import React, {useCallback, useEffect, useState} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Slide,
    TextField,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Format from "utils/formatadores";
import {NumericFormat} from 'react-number-format';
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {toastError} from "utils/toastError";
import {CampoFilter} from "types/orcamento";
import CardCotacaoProduto from "components/cotacoes/cardProduto";
import {CotacaoService} from "services/CotacaoService";
import {Cotacao, CotacaoProduto} from "types/cotacao";
import {CotacaoUtils} from "utils/cotacao";
import numeral from "numeral";


type Props = {
    show: boolean;
    handleClose: () => void;
    cotacao: Cotacao;
    loadData: Function;
};


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const totalizadorItens = (produtos: CotacaoProduto[]): string => {
    if(!produtos) return Format.moeda(0);
    return Format.moeda(produtos.reduce((total, produto) => total + (parseFloat(produto.quantidade) * Format.numeralSolver(produto.valor)), 0));
}

const totalizadorIpi = (produtos: CotacaoProduto[]): string => {
    if(!produtos) return Format.moeda(0);
    let total = 0.0;
    for (let i = 0; i < produtos.length; i++) {
        if (produtos[i].valor && produtos[i].ipi) total = total + ((parseFloat(produtos[i].quantidade) * Format.numeralSolver(produtos[i].valor)) * (Format.numeralSolver(produtos[i].ipi) / 100));
    }
    return Format.moeda(total);
}

const totalizadorIcms = (produtos: CotacaoProduto[]): string => {
    if(!produtos) return Format.moeda(0);
    let total = 0.0;
    for (let i = 0; i < produtos.length; i++) {
        if (produtos[i].valor && produtos[i].icms) total = total + ((parseFloat(produtos[i].quantidade) * Format.numeralSolver(produtos[i].valor)) * (Format.numeralSolver(produtos[i].icms) / 100));
    }
    return Format.moeda(total);
}

const totalizadorIcmsSt = (produtos: CotacaoProduto[]): string => {
    if(!produtos) return Format.moeda(0);
    let total = 0.0;
    for (let i = 0; i < produtos.length; i++) {
        if (produtos[i].icmsSt) total = total + numeral(produtos[i].icmsSt).value() ;
    }
    return Format.moeda(total);
}

const totalizador = (cot: Cotacao): string => {
    if(!cot) return Format.moeda(0);
    let totalMerc = numeral(totalizadorItens(cot.produtos)).value() || 0;
    let totalIpi = numeral(totalizadorIpi(cot.produtos)).value() || 0;
    let totalIcmsSt = numeral(totalizadorIcmsSt(cot.produtos)).value() || 0;
    let despesas = parseFloat(cot.despesas) || 0;
    let desc = parseFloat(cot.desconto)|| 0;
    let frete = parseFloat(cot.valorFrete) || 0;
    let soma = totalMerc + totalIpi + totalIcmsSt + frete + despesas;
    let sub = desc;
    let total = soma - sub;
    return Format.moeda(total);
}

const ModalPreencherCotacao = ({ show, handleClose, cotacao, loadData }: Props) => {
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const [filtros, setFiltros] = useState<CampoFilter[]>([]);
    const [filtroT, setFiltroT] = useState("SELECIONE")
    const [tipoF, setTipoF] = useState("SELECIONE")
    const [cot, setCot] = useState<Cotacao>()

    useEffect(() => {
        setCot(cotacao);
    }, [cotacao]);

    const onClose = () => {
        handleClose();
        setFiltroT("SELECIONE")
        setTipoF("SELECIONE")
    }

    const onSubmit = () => {
        if (!cot.condpg) {
            toast.warn('Selecione a condição de pagamento!')
            return;
        }
        if (!cot.tipoFrete) {
            toast.warn('Selecione o tipo de frete!')
            return;
        }

        for (let i = 0; i < cot.produtos.length; i++) {
            if (!cot.produtos[i].valor) {
                toast.warn('Informe o valor do produto ' + cot.produtos[i].descricao + '!')
                return;
            }
            if (!cot.produtos[i].prazoent) {
                toast.warn('Informe o prazo de entrega do produto ' + cot.produtos[i].descricao + '!')
                return;
            }
        }

        appDispatch(configActions.setLoading({ loading: true }));
        CotacaoService.enviaCotacao(ajustaValores(cot), config.empresa.empresaCodigo)
            .then(() => {
                toast.success("Cotação enviada com sucesso!")
                onClose();
                loadData();
            })
            .catch(() => {
                toast.error("Ocorreu um erro ao enviar cotação!")
            })
            .finally(() => {
                appDispatch(configActions.setLoading({ loading: false }));
            })
    }

    const ajustaValores = (cotacao: Cotacao) => {
        let cotacaoTemp = cotacao;
        let total = totalizadorItens(cotacao.produtos);
        cotacaoTemp.valorTotal = Format.advplDouble(numeral(total).value().toString());
        cotacaoTemp.valorFrete = Format.advplDouble(cotacao.valorFrete);
        cotacaoTemp.despesas = Format.advplDouble(cotacao.despesas);
        cotacaoTemp.desconto = Format.advplDouble(cotacao.desconto);
        cotacaoTemp.produtos = cotacao.produtos.map(produto => {
            produto.valor = Format.advplDouble(produto.valor);
            produto.icms = Format.advplDouble(produto.icms);
            produto.icmsSt = Format.advplDouble(produto.icmsSt);
            produto.ipi = Format.advplDouble(produto.ipi);
            return produto;
        })
        return cotacaoTemp;
    }

    const updateProduto = (produto: string, campo: "V" | "PE" | "ICMS" | "IPI" | "ST" | "OBS", valor: string) => {
        let prodTemp = cot.produtos;
        for (let i = 0; i < prodTemp.length; i++) {
            if (prodTemp[i].recno === produto) {
                switch (campo) {
                    case "V":
                        prodTemp[i].valor = valor;
                        break;
                    case "PE":
                        prodTemp[i].prazoent = valor;
                        break;
                    case "ICMS":
                        prodTemp[i].icms = CotacaoUtils.limpaMoney(valor);
                        break;
                    case "IPI":
                        prodTemp[i].ipi = CotacaoUtils.limpaMoney(valor);
                        break;
                    case "ST":
                        prodTemp[i].icmsSt = CotacaoUtils.limpaMoney(valor);
                        break;
                    case "OBS":
                        prodTemp[i].observacaoForn = valor;
                        break;
                    default:
                }
            }
        }
        setCot({ ...cot, produtos: prodTemp });
    }

    const loadCamposFilter = useCallback(() => {
        appDispatch(configActions.setLoading({ loading: true }));
        CotacaoService.findAllFiltros(config.empresa.empresaCodigo)
            .then(res => {
                setFiltros(res.data)
            })
            .catch((err) => toastError(err, "Erro ao carregar campos de filtro"))
            .finally(() => appDispatch(configActions.setLoading({ loading: false })));
    }, [appDispatch, config.empresa.empresaCodigo]);


    useEffect(() => {
        loadCamposFilter();
    }, [loadCamposFilter])


    return (
        <div>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"xl"}
                fullScreen={true}
                keepMounted
                onClose={() => onClose()}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>{"Preencher Cotação"}</DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item sm={4} xs={12}>
                            <Typography sx={{ fontSize: 16 }} color="text.secondary">
                                <b>Numero: </b> {cot?.numero}
                            </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Typography sx={{ fontSize: 16 }} color="text.secondary">
                                <b>Proposta: </b> {cot?.numpro}
                            </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <Typography sx={{ fontSize: 16 }} color="text.secondary">
                                <b>Filial:</b> {cot?.filial}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label="Condição de pagamento"
                                variant="outlined"
                                fullWidth
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    setFiltroT(e.target.value)
                                    setCot({ ...cot, condpg: e.target.value })
                                }}
                                value={cot?.condpg ? cot.condpg : filtroT}
                            >
                                <MenuItem value={"SELECIONE"} disabled>
                                    Selecione a condição
                                </MenuItem>
                                {filtros.map(option => (
                                    <MenuItem key={option.campo} value={option.campo}>
                                        {option.descricao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <NumericFormat
                                customInput={TextField}
                                decimalScale={2}
                                decimalSeparator=","
                                fixedDecimalScale
                                prefix="R$ "
                                thousandSeparator="."
                                fullWidth
                                allowNegative={false}
                                placeholder={'R$ 0,00'}
                                label={"Desconto"}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                defaultValue={cot?.desconto ? cot.desconto : ""}
                                onChange={(e: any) => {
                                    setCot({ ...cot, desconto: CotacaoUtils.limpaMoney(e.target.value) });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                label="Tipo do frete"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                onChange={(e) => {
                                    setTipoF(e.target.value)
                                    setCot({ ...cot, tipoFrete: e.target.value });
                                }}
                                value={cot?.tipoFrete ? cot.tipoFrete : tipoF}
                            >
                                <MenuItem value={"SELECIONE"} disabled>
                                    Selecione o tipo de frete
                                </MenuItem>
                                <MenuItem value={"C"}>
                                    CIF
                                </MenuItem>
                                <MenuItem value={"F"}>
                                    FOB
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumericFormat
                                customInput={TextField}
                                decimalScale={2}
                                decimalSeparator=","
                                fixedDecimalScale
                                prefix="R$ "
                                thousandSeparator="."
                                fullWidth
                                allowNegative={false}
                                value={cot?.valorFrete ? cot.valorFrete : ""}
                                placeholder={'R$ 0,00'}
                                label={"Valor do frete"}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e: any) => {
                                    setCot({ ...cot, valorFrete: CotacaoUtils.limpaMoney(e.target.value) });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Valor dos itens"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ readOnly: true }}
                                value={
                                    Format.moeda(totalizadorItens(cot?.produtos))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Valor ICMS"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ readOnly: true }}
                                value={
                                    Format.moeda(totalizadorIcms(cot?.produtos))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Valor ICMS Ret."
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ readOnly: true }}
                                value={
                                    Format.moeda(totalizadorIcmsSt(cot?.produtos))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Valor IPI"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ readOnly: true }}
                                value={
                                    Format.moeda(totalizadorIpi(cot?.produtos))
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <NumericFormat
                                customInput={TextField}
                                decimalScale={2}
                                decimalSeparator=","
                                fixedDecimalScale
                                prefix="R$ "
                                thousandSeparator="."
                                fullWidth
                                allowNegative={false}
                                value={cot?.despesas ? cot.despesas : ""}
                                placeholder={'R$ 0,00'}
                                variant="outlined"
                                label={"Despesa por Cotação"}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e: any) => {
                                    setCot({ ...cot, despesas: CotacaoUtils.limpaMoney(e.target.value) });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Valor Total"
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ readOnly: true }}
                                value={
                                    Format.moeda(totalizador(cot))
                                }
                            />
                        </Grid>
                    </Grid>
                    {cotacao?.produtos.map(produto => <CardCotacaoProduto produto={produto} updateProduto={updateProduto} key={produto.recno} />)}
                    <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            type={"submit"}
                            sx={{ mr: 1 }}
                            onClick={onSubmit}
                        >
                            <SaveIcon /> Salvar
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ModalPreencherCotacao;