import {Stack} from "@mui/material";
import {BoardsList} from "components/crm/boardList";
import Loader from "components/loader";
import {useCrm} from "contexts/CrmContext";
import {useEffect} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const CrmLeads = () => {
  const { state, columns, setScroll, isLoading } = useCrm();

  useEffect(() => {
    setScroll(true);
  }, [setScroll]);
      
  return (
    <DndProvider backend={HTML5Backend}>
      <Stack
            direction="row"
            spacing={1}
            sx={{
                  display: 'flex',
                  flex: '1 1 auto',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  height: '100%'
                }}
      >
        {isLoading && <Loader/>}
        {!isLoading &&
          <>
            {columns.map((column, index) => (
              <BoardsList dt={state.filter(leads => leads.status === column)} id={column} key={index}/>
            ))}
          </>
        }
      </Stack>
    </DndProvider>
  );
}

export default CrmLeads;