import {Button, Tooltip} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

type Props = {
    handleConfirm: Function,
}

const ButtonConfirm = ({handleConfirm}: Props) => {
    return (
        <Tooltip title="Confirmar" placement="top">
            <Button variant={'contained'} onClick={() => handleConfirm()} sx={{marginRight: 1,}} color={'success'} fullWidth>
                <SendIcon/>
            </Button>
        </Tooltip>
    )
}

export default ButtonConfirm;