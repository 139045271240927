import {Link, useNavigate, useParams} from "react-router-dom";
import {useLayoutEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {AuthService} from "services/AuthService";
import {AxiosResponse} from "axios";
import {Avatar, Box, Button, Grid, TextField, Typography} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Copyright from "components/copyright";
import {Validadores} from "utils/validadores";
import { useAppDispatch } from "store";
import { configActions } from "store/config";

type FormState = {
    password: string;
    confirmPassword: string;
}

const TrocarSenha = () => {
    const {token} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {handleSubmit, getValues, formState: {errors}, register } = useForm<FormState>();
    const [isSend, setIsSend] = useState(false);


    useLayoutEffect(() => {
        if (!token) {
            toast.error("Token não informado");
            navigate('/auth/login');
        } else {
            dispatch(configActions.setLoading({loading: true}))
            AuthService.validToken(token)
                .then((res: AxiosResponse<{ valid: boolean }>) => {
                    if (!res.data.valid) {
                        toast.error("Token inválido");
                        navigate('/auth/login');
                    }
                })
                .catch(() => {
                    toast.error("Token inválido");
                    navigate('/auth/login');
                })
                .finally(() => {
                    dispatch(configActions.setLoading({loading: false}))
                });
        }
    }, [dispatch, navigate, token]);

    const onSubmit = ({password}: FormState) => {
        dispatch(configActions.setLoading({loading: true}))
        if(token) AuthService.changePassword(token, password)
            .then(() => {
                toast.success("Senha alterada com sucesso");
                setIsSend(true);
            })
            .catch((err) => toast.error(err.message))
            .finally(() => dispatch(configActions.setLoading({loading: false})));
    }


    return(
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "Center",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Avatar sx={{m: 1, bgcolor: "primary.main"}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5" sx={{mb: 3}}>
                Digite a nova senha
            </Typography>
            {!isSend && (
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 1, width: '100%'}}>

                    <TextField
                        {...register("password", {validate: (value) =>  Validadores.senha(value)})}
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        label="Senha"
                        type="password"
                        id="senha"
                        autoComplete="current-password"
                    />
                    <TextField
                        {...register("confirmPassword", {validate: (value) => {
                                const {password} = getValues();
                                if (value !== password) return 'As senhas não são iguais.'
                            }})}
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword?.message}
                        label="Confirmar senha"
                        type="password"
                        id="senha"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Enviar
                    </Button>
                </Box>
            )}
            {isSend && (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "Center",
                    alignItems: "center",
                    my: 5
                }}>
                    <Typography variant={"h3"}>
                        Senha alterada com sucesso!
                    </Typography>
                </Box>
            )}
            <Grid container>
                <Grid item xs>
                    <Link to="/auth/login">Voltar</Link>
                    <Copyright sx={{p: 5}}/>
                </Grid>
            </Grid>
        </Box>
    );
}


export default TrocarSenha;