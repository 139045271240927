import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogContent, DialogTitle, MenuItem, Select, Slide} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {TransitionProps} from "@mui/material/transitions";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {Acesso} from "types/auth";
import {ConfiguracoesService} from "services/ConfiguracoesService";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {hasAnyRoles, useAuthState} from "store/auth";
import { useNavigate } from "react-router-dom";

type Props = {
    title: string;
};

type FormConfig = {
    empresa: string;
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props}/>;
});

const MyModalChangeEmpresa = ({title}: Props) => {
    const {handleSubmit, register} = useForm<FormConfig>({});
    const appSelector = useAppSelector(useAuthState)
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const [empresasPage, setEmpresaPage] = useState<Acesso[]>();
    const {modalEmpresa} = useAppSelector(useConfigState);
    const navigate = useNavigate();

    const onClose = () => {
        appDispatch(configActions.setModalEmpresa({modalEmpresa: false}));
    }

    const loadEmpresas = useCallback(() => {
        const params: AxiosRequestConfig = {
            params: {
                page: 0,
                size: 1000,
                codigo: hasAnyRoles(appSelector, ["ROLE_ADMIN"]) ? config.parametros.codigoUnico : '',
            },
        };
        ConfiguracoesService.findAcessos(params)
            .then((res: AxiosResponse<Acesso[]>) => {
                setEmpresaPage(res.data);
            })
            .catch((err) => {
                toast.error(err);
            });
    }, [appSelector, config.parametros]);

    useEffect(() => {
        loadEmpresas();
    }, [loadEmpresas]);


    const onSubmit = (data: any) => {
        if (data.config !== "NONE") {
            let empresaTemp = empresasPage?.find((c) => c.empresaCodigo === data.empresa);
            if (empresaTemp) {
                appDispatch(configActions.setEmpresa({empresa: empresaTemp}));
            }
            appDispatch(configActions.setModalEmpresa({modalEmpresa: false}));
            onClose();
            navigate('/painel');
        }
    };

    return (
        <div>
            <Dialog
                open={modalEmpresa}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"sm"}
                keepMounted
                onClose={() => onClose()}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                        sx={{mt: 1}}
                    >
                        <Select
                            {...register("empresa")}
                            defaultValue="NONE"
                            sx={{maxHeight: 50, width: "100%"}}
                        >
                            <MenuItem disabled value="NONE">Selecione uma empresa</MenuItem>
                            {empresasPage && empresasPage.map((item: Acesso) => (
                                <MenuItem key={item.empresaCodigo} value={item.empresaCodigo}>
                                    {`${item.empresaCodigo} - ${item.empresaNome}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                        >
                            Salvar
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MyModalChangeEmpresa;
