import {AxiosRequestConfig} from "axios";
import {API} from "services/Api";

const URL = "/update-versao";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};


export const UpdateService =  {
    sistema: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}`, null, paramsGeral);
    },
}