import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from '@mui/icons-material/Done';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {Agenda, AgendaF, CrmLead} from 'types/crm';
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {RestPage} from "types/prtoheuspage";
import {toast} from "react-toastify";
import {CrmService} from "services/CrmService";
import {Controller, useForm} from 'react-hook-form';
import Format from 'utils/formatadores';
import PhoneIcon from '@mui/icons-material/Phone';
import moment from 'moment';
import {useCrm} from 'contexts/CrmContext';
import {SpringPage} from 'types/springpage';
import Loader from 'components/loader';
import {hasModuloPermission, useAuthState} from "store/auth";

type Props = {
  dt: CrmLead;
}

type Campos = {
  alterarData: Boolean;
  atende: Boolean;
  disableAll: Boolean;
  reagendarEm: Boolean;
  reagendarDia: Boolean;
  addbSet: Boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const sortHistorico = (a: Agenda, b: Agenda) => {
  if (a.contato && b.contato) {         // 1
    if (moment(a.alerta).format('YYYY-MM-DD') > moment(b.alerta).format('YYYY-MM-DD')) {
      return 1;
    } else if (moment(a.alerta).format('YYYY-MM-DD') < moment(b.alerta).format('YYYY-MM-DD')) {
      return -1;
    } else {
      return 0;
    }
  } else if (a.contato && !b.contato) { // 1
    return 1;
  } else if (!a.contato && b.contato) { // 1
    return -1;
  } else {                              // 1  
    if (moment(a.alerta).format('YYYY-MM-DD') > moment(b.alerta).format('YYYY-MM-DD')) {
      return 1;
    } else if (moment(a.alerta).format('YYYY-MM-DD') < moment(b.alerta).format('YYYY-MM-DD')) {
      return -1;
    } else {
      return 0;
    }
  }
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const Schedule = ({ dt }: Props) => {
  const [modalDetail, setModalDetail] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState<Agenda[]>([]);
  const config = useAppSelector(useConfigState);
  const [agenda, setAgenda] = useState<Agenda>();
  const [pageCount, setPageCount] = useState(1);
  const [today, setToday] = useState("");
  const [isOrcamento, setIsOrcamento] = useState(false);
  const [campos, setCampos] = useState<Campos>({} as Campos);
  const [pageT, setPageT] = useState<number>(0);
  const [canDelete, setCanDelete] = useState(false);
  const { loadLeads,
    setScreen,
    setDadosPedido,
    isLoading,
    setIsLoading
  } = useCrm();
  const {
    control,
    handleSubmit,
    setValue,
  } = useForm<AgendaF>({
    defaultValues: {
      alerta: "",
      contato: "",
      timer: "",
      orcamento: "",
      pedido: "",
      newAlerta: "",
      taviso: ""
    },
  });
  let pageSize = 6;
  const appSelector = useAppSelector(useAuthState)

  useEffect(() => {
    setToday(moment(new Date()).format('YYYY-MM-DD'));
  }, [setToday])

  const loadData = (pageCount: number = 0) => {
    const params: AxiosRequestConfig = {
      params: {
        page: pageCount,
        size: pageSize,
        sort: "contato,asc;alerta,asc",
        codigo: config.empresa.empresaCodigo,
      }
    }
    CrmService.findAgenda(config.empresa.empresaCodigo, pageCount, pageSize, dt.codigo, params)
      .then((res: AxiosResponse<SpringPage<Agenda>>) => {
        if (res.data.content.length > 0) {
          setPageT(res.data.totalPages);
          const sorting = [...res.data.content].sort(sortHistorico);
          setData(sorting);
        }
      })
      .catch((err: any) => {
        toast.error(err.message)
      })
      .finally(() => {
        setIsLoading(false);
        setCampos({
          alterarData: true,
          atende: false,
          disableAll: true,
          reagendarEm: true,
          reagendarDia: true,
          addbSet: false
        });
      });
  }

  const handleOpenDetail = () => {
    setModalDetail(true)
    loadData()
  }

  const handleCloseDetail = () => {
    setModalDetail(false);
    loadLeads();
  }

  const setter = (status: String, limpa: Boolean, dt?: AgendaF) => {
    if (limpa) {
      setValue('alerta', "");
      setValue('contato', "");
      setValue('timer', "");
      setValue('status', "");
      setValue('valor', "");
      setValue('observa', "");
      setValue('orcamento', "");
      setValue('pedido', "");
      setValue('newAlerta', "");
      setValue('taviso', "");
    } else if (dt) {
      setValue('alerta', dt.alerta);
      setValue('contato', dt.contato);
      setValue('timer', dt.timer);
      setValue('status', dt.status);
      setValue('valor', dt.valor);
      setValue('observa', dt.observa);
      setValue('orcamento', dt.orcamento);
      setValue('pedido', dt.pedido);
      setValue('newAlerta', dt.newAlerta);
      setValue('taviso', dt.taviso);
    }
  }

  const onChangeTimer = (value: string) => {
    let newData = moment().add(value, 'd').format('YYYY-MM-DD');
    if (value === "0") {
      setValue('newAlerta', '');
      setCampos({
        ...campos,
        reagendarDia: false
      });
    } else {
      setValue('newAlerta', Format.dataZ(newData));
      setCampos({
        ...campos,
        reagendarDia: true
      });
    }
  }

  const onChangeTAlerta = (value: string) => {
    value = value.replace(/\D/g, "");
  }

  const handleSave = (data: AgendaF) => {
    setAgenda(data)
    let salveAgenda: Agenda = {
      alerta: data.alerta ? Format.dataUTC(data.alerta, "12:00") : "null",
      contato: data.contato,
      timer: data.timer,
      status: dt.status,
      valor: "",
      observa: data.observa,
      orcamento: data.orcamento,
      pedido: data.pedido,
      newAlerta: data.newAlerta,
      taviso: data.taviso
    }
    if (!campos.atende) {
      if (data.contato !== "" && data.contato !== null && data.contato !== undefined) {
        toast.error("Atendimento ja realizado não é possivel alterar");
        setter(dt.status, true, data)
      } else {
        CrmService.createAgenda(config.empresa.empresaCodigo, "", dt.codigo, dt.vendedor, salveAgenda)
          .then((res: AxiosResponse<Agenda>) => {
            // setData(res.data.conteudo);
            toast.success("Agendamento Criado com Sucesso!");
            setter(dt.status, true, data)
            loadData();
          })
          .catch((err: any) => {
            toast.error(err.response.data.message || "Campos obrigatorios não preenchidos");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else {
      salveAgenda.contato = Format.dataUTC(today, "12:00");
      if (salveAgenda.newAlerta) {
        salveAgenda.newAlerta = Format.dataUTC(salveAgenda.newAlerta, "12:00");
      }
      CrmService.createAgenda(config.empresa.empresaCodigo, "", dt.codigo, dt.vendedor, salveAgenda)
        .then((res: AxiosResponse<RestPage<Agenda>>) => {
          if (salveAgenda.newAlerta) {
            salveAgenda.alerta = salveAgenda.newAlerta;
            salveAgenda.newAlerta = null;
            salveAgenda.contato = null;
            CrmService.createAgenda(config.empresa.empresaCodigo, "", dt.codigo, dt.vendedor, salveAgenda)
          }
          setData(res.data.conteudo);
          setDadosPedido({
            codigo: dt.codigo,
            loja: dt.loja,
            nome: dt.nome,
            cgc: dt.cgc,
            endereco: dt.endereco,
            bairro: dt.bairro,
            codigoMunicipio: dt.codMunicipio,
            municipio: dt.municipio,
            estado: dt.estado,
            recno: dt.recno
          });
          toast.success("Atendimento concluido com Sucesso!");
          if (isOrcamento) {
            setModalDetail(false);
            setScreen('Orçamento');
          } else {
            setter(dt.status, true, data)
            loadData();
          }
        })
        .catch((err: any) => {
          toast.error(err.message);
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  }

  const handleBoxClick = (a: Agenda) => {
    setCanDelete(false);
    if (!a.newAlerta) {
      a.newAlerta = "";
      a.timer = "0";
    } else {
      a.newAlerta = Format.dataZ(a.newAlerta);
    }
    a.alerta = Format.dataZ(a.alerta);
    if (a.contato) {
      a.contato = Format.dataZ(a.contato);
    }
    if (!a.contato) {
      a.contato = "";
      setCampos({
        ...campos,
        atende: true,
        disableAll: false,
        reagendarDia: false,
        reagendarEm: false,
        alterarData: true,
        addbSet: false,
      });
      setCanDelete(true);
    } else {
      setCampos({
        ...campos,
        atende: false,
        disableAll: true,
        reagendarDia: true,
        reagendarEm: true,
        alterarData: true,
        addbSet: false,
      });
    }
    setter(dt.status, false, a)
  }

  const handleAdd = () => {
    setter(dt.status, true)
    setCampos({
      ...campos,
      atende: false,
      disableAll: false,
      reagendarDia: true,
      reagendarEm: true,
      alterarData: false,
      addbSet: true
    });
  }

  const handleDelete = () => {
    setIsLoading(true);
    CrmService.deleteAgenda(config.empresa.empresaCodigo, "")
      .then(() => {
        toast.success("Sucesso! Este agendamento foi deletado.");
        loadData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message || "Erro ao deletar agendamento!");
      })
      .finally(() => {
        setModalDetail(false);
        setIsLoading(false);
      })
    //toast.warning("Este agendamento não existe mais! Parece magicamente ter desaparecido!");
  }

  return (
    <Grid>
      <IconButton onClick={handleOpenDetail}>
        <CalendarMonthIcon />
      </IconButton>
      <BootstrapDialog
        onClose={handleCloseDetail}
        aria-labelledby="customized-dialog-title"
        open={modalDetail}
        fullWidth={true}
        maxWidth={"xl"}
        fullScreen={fullScreen}
        keepMounted
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDetail} >
          {!campos.atende && "Historico de Agendamentos"}
          {campos.atende && "Atendimento por Cliente"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {isLoading && <Loader />}
          {!isLoading &&
            <Box sx={{ mt: 1 }} >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField label="Codigo e nome de cliente"
                    variant="outlined"
                    value={dt.codigo + " - " + dt.nome}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Telefone"
                    variant="outlined"
                    value={(dt.telefone.length < 10) ? dt.telefone : "Não informado"}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Celular"
                    variant="outlined"
                    value={(dt.celular.length < 10) ? dt.celular : "Não informado"}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField label="Email"
                    variant="outlined"
                    value={dt.email ? dt.email : "Não informado"}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6} >
                  <Paper elevation={2} sx={{
                    p: 2,
                    margin: 'auto',
                    maxWidth: '100%',
                    height: '100%',
                    flexGrow: 1
                  }}
                  >
                    <Grid>
                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <Typography>Data de Agendamento</Typography>
                        <Divider />
                        <Typography>Data de Contato</Typography>
                        <Divider />
                        <IconButton onClick={handleAdd}>
                          <AddIcon />
                        </IconButton>
                      </Stack>
                      <Divider sx={{ pt: 2 }} />
                      {data && data.map((item: Agenda, index) => (
                        <div key={index}>
                          <Grid container spacing={2}
                            justifyContent={'space-between'}
                            sx={{
                              py: 1,
                              "&:hover": {
                                backgroundColor: theme.palette.grey[200],

                                height: '100%',
                              },
                              cursor: 'pointer'
                            }}
                            onClick={() => handleBoxClick(item)}
                          >
                            <Grid item textAlign={'left'} xs={4} >
                              <Typography>{Format.data(item.alerta)}</Typography>
                            </Grid>
                            <Grid item textAlign={'right'} xs={4} >
                              <Typography>{item.contato && Format.data(item.contato)}</Typography>
                            </Grid>
                            <Grid item textAlign={'right'} xs={4} >
                              {item.contato ? <DoneIcon /> : <PhoneIcon />}
                            </Grid>
                          </Grid>
                          <Divider />
                        </div>
                      ))}
                      <Pagination
                        count={pageT}
                        defaultPage={0}
                        page={pageCount}
                        onChange={(event, value) => { loadData(value - 1); setPageCount(value) }}
                        sx={{
                          mt: 1,
                        }}
                      />
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper elevation={2} sx={{
                    p: 2,
                    margin: 'auto',
                    maxWidth: '100%',
                    minHeight: '100%',
                    flexGrow: 1
                  }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        {campos.atende && "Atendimento"}
                        {!campos.atende && "Cadastro de Agendamentos"}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit(handleSave)}
                        sx={{ mt: 1 }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="alerta"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  required
                                  type={"date"}
                                  label="Data do Agendamento"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    readOnly: campos.alterarData,
                                    max: "9999-12-31",
                                    min: moment().format("YYYY-MM-DD")
                                  }}
                                  defaultValue={agenda?.alerta}
                                  variant="outlined"
                                  fullWidth
                                  sx={{ width: "100%" }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="contato"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type={"date"}
                                  label="Data do Contato"
                                  variant="outlined"
                                  defaultValue={agenda?.contato}
                                  inputProps={{
                                    readOnly: true,
                                    max: "9999-12-31"
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  sx={{ width: "100%" }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="timer"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="Reagendar em:"
                                  variant="outlined"
                                  inputProps={{ readOnly: campos.reagendarEm }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    onChangeTimer(e.target.value);
                                  }}
                                  select
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  sx={{ width: "100%" }}
                                >
                                  <MenuItem value={"0"}>-</MenuItem>
                                  <MenuItem value={"15"}>15 Dias</MenuItem>
                                  <MenuItem value={"30"}>30 Dias</MenuItem>
                                  <MenuItem value={"60"}>60 Dias</MenuItem>
                                  <MenuItem value={"90"}>90 Dias</MenuItem>
                                  <MenuItem value={"120"}>120 Dias</MenuItem>
                                  <MenuItem value={"150"}>150 Dias</MenuItem>
                                  <MenuItem value={"180"}>180 Dias</MenuItem>
                                </TextField>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="newAlerta"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type={"date"}
                                  label="Reagendar no dia"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: campos.reagendarDia,
                                    max: "9999-12-31",
                                    min: moment().format("YYYY-MM-DD")
                                  }}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setCampos({
                                      ...campos,
                                      reagendarEm: true
                                    });
                                  }}
                                  defaultValue={agenda?.newAlerta}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  sx={{ width: "100%" }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Controller
                              name="taviso"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="Tempo para Alerta"
                                  variant="outlined"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    onChangeTAlerta(e.target.value);
                                  }}
                                  inputProps={{ readOnly: campos.disableAll }}
                                  defaultValue={agenda?.taviso}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  sx={{ width: "100%" }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Controller
                              name="observa"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="outlined-multiline-flexible"
                                  label="Observações de Cliente"
                                  defaultValue={agenda?.observa}
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{ readOnly: campos.disableAll }}
                                  multiline
                                  fullWidth
                                  rows={5}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Grid item xs={12} sx={{ justifyContent: 'end' }}>
                          {(campos.addbSet) &&
                            <Button autoFocus sx={{ ml: 2 }} type={'submit'} variant={'contained'} onClick={() => { setIsOrcamento(false) }} >
                              Incluir Agendamento
                            </Button>
                          }
                          {(campos.atende) &&
                            <>
                              <Button autoFocus sx={{ ml: 2 }} type={'submit'} variant={'contained'} onClick={() => { setIsOrcamento(false) }} >
                                Confirmar
                              </Button>
                              {hasModuloPermission(appSelector, config, "ORCAMENTO") &&
                                <Button autoFocus sx={{ ml: 2 }} type={'submit'} variant={'contained'} onClick={() => { setIsOrcamento(true) }} >
                                  Confirmar e Gerar Orçamento
                                </Button>
                              }
                              {(canDelete) &&
                                <Button autoFocus sx={{ ml: 2 }} variant={'contained'} onClick={() => { setIsOrcamento(false); handleDelete() }} >
                                  Excluir
                                </Button>
                              }
                            </>
                          }
                        </Grid>
                      </Box>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          }
        </DialogContent>
      </BootstrapDialog>
    </Grid>
  );
}