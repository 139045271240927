import {Button, Tooltip} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

type Props = {
    handleEdit: Function,
}

const ButtonEdit = ({handleEdit}: Props) => {
    return (
        <Tooltip title="Editar" placement="top">
            <Button variant={'contained'} onClick={() => handleEdit()} sx={{marginRight: 1,}} color={'warning'} fullWidth>
                <EditIcon />
            </Button>
        </Tooltip>
    )
}

export default ButtonEdit;