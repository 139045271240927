import {Button, Grid, MenuItem, TextField} from "@mui/material";
import {AxiosResponse} from "axios";
import Loader from "components/loader";
import {useCallback, useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {useMatch, useResolvedPath} from "react-router-dom";
import {toast} from "react-toastify";
import {PermissaoService} from "services/PermissaoService";
import {Modulo} from "types/auth";

type Props = {
    forms: any;
    onSubmit: (e: any) => void;
}

const SeachForms = ({forms: {register, handleSubmit, formState: {errors}, control}, onSubmit}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dados, setDados] = useState(null);
    let resolvedColaborador = useResolvedPath('/painel/colaboradores');
    let matchColaborador = useMatch({path: resolvedColaborador.pathname, end: true});
    let resolvedAdmin = useResolvedPath('/painel/admins');
    let matchAdmin = useMatch({path: resolvedAdmin.pathname, end: true});

    const getPermissoes = useCallback(() => {
        setIsLoading(true);
        PermissaoService.findAll({params: {page: 0, size: 100}})
            .then((res: AxiosResponse<Modulo[]>) => {
                setDados(res.data);
            })
            .catch((err) => {
                toast.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        getPermissoes();
    }, [getPermissoes]);

    return isLoading ? (
        <Loader/>
    ) : (
        <>
            <Grid container spacing={2} sx={{pb: 2}}>
                <Grid item xs={12} md={matchColaborador ? 2 : !matchAdmin ? 3 : 4}>
                    <TextField
                        {...register("codigo")}
                        error={!!errors.codigo}
                        variant="outlined"
                        sx={{width: "100%"}}
                        helperText={errors.codigo?.message}
                        label="Codigo"
                        type="number"
                        InputProps={{inputProps: {max: 8}}}
                        InputLabelProps={{shrink: true}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={matchColaborador ? 2.5 : !matchAdmin ? 3 : 4}>
                    <TextField
                        {...register("nome")}
                        label="Nome"
                        variant="outlined"
                        fullWidth
                        error={!!errors.nome}
                        helperText={errors.nome?.message}
                        InputLabelProps={{shrink: true}}
                    />
                </Grid>
                {!matchAdmin &&
                    <Grid item xs={12} md={matchColaborador ? 2.5 : 3}>
                        <TextField
                            {...register("cgc")}
                            label="CPF/CNPJ"
                            variant="outlined"
                            fullWidth
                            error={!!errors.cgc}
                            helperText={errors.cgc?.message}
                            type="number"
                            InputProps={{inputProps: {max: 14}}}
                            InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                }
                {matchColaborador &&
                    <Grid item xs={12} md={3}>
                        <Controller
                            name="modulo"
                            control={control}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    select
                                    label="Módulo"
                                    fullWidth error={!!errors.modulo}
                                    helperText={errors.modulo?.message}
                                    defaultValue={''}
                                    InputLabelProps={{shrink: true}}>
                                    <MenuItem value={' '} disabled>Selecione</MenuItem>
                                    <MenuItem value={''}>Todos</MenuItem>
                                    {dados?.map((modulo, i) => (
                                        <MenuItem value={modulo.nome} key={i}>{modulo.descricao}</MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    </Grid>
                }
                <Grid item xs={12} md={matchColaborador ? 2 :  !matchAdmin ? 3 : 4}>
                    <Button
                        onClick={handleSubmit((e: any) => {
                            onSubmit(e);
                        })}
                        sx={{width: "100%", height: "100%"}}
                        variant="contained"
                        color="primary"
                    >
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default SeachForms