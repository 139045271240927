/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogContent,
    Grid,
    Modal,
    Slide,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {SolicitacaoArmazem} from 'types/solicitacao-materiais';
import Format from 'utils/formatadores';
import {toast} from 'react-toastify';
import {SolicitacaoMateriaisService} from 'services/SolicitacaoMateriaisService';
import Loader from 'components/loader';
import {PLACEHOLDER_IMAGEM} from 'utils/constants';
import {useAppSelector} from 'store';
import {useConfigState} from 'store/config';

type Props = {
    open: boolean;
    onClose: Function;
    dadosSolicitacaoArmazem: SolicitacaoArmazem;
};

const Transition = React.forwardRef( function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='down' ref={ref} {...props} />;
} );

const ModalDetalhesMateriais = ( { open, onClose, dadosSolicitacaoArmazem }: Props ) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( 'sm' ) );
    const [openModal, setOpenModal] = useState( false );
    const [expandedImage, setExpandedImage] = useState( '' );
    const [isLoading, setIsLoading] = useState( true );
    const config = useAppSelector(useConfigState);

    const openImageModal = imageUrl => {
        setExpandedImage( imageUrl )
        setOpenModal( true );
    };

    const md = useMediaQuery( theme.breakpoints.down( 'md' ) );

    const buscarInfos = useCallback( async () => {
        try {
            setIsLoading( true );

            const filiaisResponse = await SolicitacaoMateriaisService.findFiliaisFiltro( config.empresa.empresaCodigo );
            filiaisResponse.data.forEach( item => {
                if ( item.campo === dadosSolicitacaoArmazem.filialEntrega ) {
                    dadosSolicitacaoArmazem.filialEntrega = item.descricao
                }
            } );

        } catch ( error ) {
            toast.error( 'Erro ao carregar campos de filtro' );
        } finally {
            setIsLoading( false );
        }
    }, [config.empresa.empresaCodigo] );

    useEffect( () => {
        buscarInfos();
    }, [] );

    return isLoading ? (
        <Loader />
    ) : (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose( false )}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={'xl'}
                fullScreen={fullScreen}
                keepMounted
                aria-describedby='alert-dialog-slide-description'>
                <DialogContent>
                    <Box sx={{ mb: 3 }}>
                        <Typography color='primary' sx={{ fontSize: 16, fontWeight: 'bold' }}>
                            Detalhes da Solicitação
                        </Typography>
                        <IconButton
                            aria-label='close'
                            onClick={() => onClose( false )}
                            sx={{
                                position: 'absolute',
                                right: 30,
                                top: 10,
                                zIndex: 1,
                                color: theme => theme.palette.grey[500]
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box>
                        {/* Dados da solicitação */}
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant={'h3'}
                                    sx={{ fontSize: 16, fontWeight: 600, mb: 1 }}
                                    color='text.secondary'></Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label='Número'
                                    variant='outlined'
                                    margin='normal'
                                    value={dadosSolicitacaoArmazem.numero}
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    type={'date'}
                                    label='Data de Emissão'
                                    variant='outlined'
                                    margin='normal'
                                    value={Format.dataZ( dadosSolicitacaoArmazem.dataEmissao )}
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label='Local de Entrega'
                                    variant='outlined'
                                    margin='normal'
                                    value={dadosSolicitacaoArmazem.filialEntrega}
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            {/* Listagem dos materiais */}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {dadosSolicitacaoArmazem.materiais.map( ( material, i ) => (
                                        <Grid item xs={12} key={i}>
                                            <Card
                                                variant='elevation'
                                                sx={{
                                                    p: 2,
                                                    backgroundColor: t => t.palette.grey[50]
                                                }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>

                                                        <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                                                            <Grid item>
                                                                <Typography sx={{ fontWeight: '900', mr: 0.5 }}>Status:</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography>{material.status}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <img
                                                            src={`data:image/jpeg;base64,${material.imagem ? material.imagem : PLACEHOLDER_IMAGEM}`}
                                                            alt={material.descricao}
                                                            width={md ? '75%' : 80}
                                                            height={md ? 'auto' : 80}
                                                            onClick={() =>
                                                                openImageModal(
                                                                    `data:image/jpeg;base64,${material.imagem ? material.imagem : PLACEHOLDER_IMAGEM}`
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={10} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6} md={4} lg={4}>
                                                                <Typography sx={{ fontWeight: '900' }}>Código</Typography>
                                                                <Typography>{material.codigo}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={4} lg={4}>
                                                                <Typography sx={{ fontWeight: '900' }}>Descrição</Typography>
                                                                <Typography>{material.descricao}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={4} lg={4}>
                                                                <Typography sx={{ fontWeight: '900' }}>Qtd.</Typography>
                                                                <Typography>{material.qtdSolicitada}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={4} lg={4}>
                                                                <Typography sx={{ fontWeight: '900' }}>UM</Typography>
                                                                <Typography>{material.unidadeMedida}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={4} lg={4}>
                                                                <Typography sx={{ fontWeight: '900' }}>Necessidade</Typography>
                                                                <Typography>{Format.data( material.necessidade )}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} md={4} lg={4}>
                                                                <Typography sx={{ fontWeight: '900' }}>Centro Custo</Typography>
                                                                <Typography>{material.centroCusto}</Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography sx={{ fontWeight: '900' }}>Observação</Typography>
                                                                <Typography>{material.observacao ? material.observacao : "Sem observação"}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Card>
                                        </Grid>
                                    ) )}
                                </Grid>
                            </Grid>

                            {/* Botões */}
                            <Grid item xs={12} sx={{ textAlign: { md: 'right', sm: 'center' }, mt: 2 }}>
                                <Tooltip title='Fechar' placement='top'>
                                    <Button variant={'contained'} color={'primary'} onClick={() => onClose()}>
                                        <HighlightOffIcon sx={{ mr: 1 }} /> Fechar
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent >
            </Dialog >
            <Modal
                open={openModal}
                onClose={() => setOpenModal( false )}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    src={expandedImage}
                    alt='Imagem ampliada'
                    style={{
                        width: '25%',
                        height: 'auto',
                        border: '1px solid #3b3b3b'
                    }}
                />
            </Modal>
        </div >
    );
};

export default ModalDetalhesMateriais;
