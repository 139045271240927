import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ConfiguracaoGrupo from "pages/painel/configuracoes/grupo";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useCallback, useEffect, useState} from "react";
import {Configuracoes, ConfiguracoesEmpresa} from "types/configuracoes";
import {ConfiguracoesEmpresaService} from "services/ConfiguracoesEmpresaService";
import {toastError} from "utils/toastError";
import ConfiguracaoEmpresa from "pages/painel/configuracoes/empresa";
import {toast} from "react-toastify";
import {ConfiguracoesService} from "services/ConfiguracoesService";
import {orange} from "@mui/material/colors";
import {useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {useDispatch} from "react-redux";


const EditConfiguracoes = () => {
    const config = useAppSelector(useConfigState);
    const dispatch = useDispatch();
    const [configuracoes, setConfiguracoes] = useState<ConfiguracoesEmpresa[]>([]);
    const [configGrupo, setConfigGrupo] = useState<Configuracoes>();


    const getConfigByEmpresa = useCallback(() => {
        if (!config.parametros || !config.parametros.codigoUnico) {
            toast.warning("É necessário selecionar um grupo!");
            return;
        }
        dispatch(configActions.setLoading({
            loading: true,
        }));
        ConfiguracoesService.findByCodigoCompl(config.parametros.codigoUnico)
            .then((res) => {
                setConfigGrupo(res.data)
            })
            .catch((err) => {
                toastError(err, "Erro ao buscar configurações!")
            })
            .finally(() => dispatch(configActions.setLoading({
                loading: false,
            })));
    }, [config.parametros, dispatch])

    const getAll = useCallback(() => {
        if (!config.parametros || !config.parametros.codigoUnico) {
            return;
        }
        dispatch(configActions.setLoading({
            loading: true,
        }));
        ConfiguracoesEmpresaService.findAll(config.parametros.codigoUnico)
            .then((response) => {
                setConfiguracoes(response.data);
            })
            .catch((error) => {
                toastError(error, "Erro ao buscar configurações");
            })
            .finally(() => {
                dispatch(configActions.setLoading({
                    loading: false,
                }));
            })
    },[config.parametros, dispatch]);

    useEffect(() => {
        getConfigByEmpresa();
        getAll();
    }, [getAll, getConfigByEmpresa]);



    return (
        <div>
            {configGrupo && (
                <Accordion elevation={2}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: orange[400]}}>
                        <Typography fontSize={16} fontWeight={"h6.fontWeight"}>{`Configurações ${config.parametros.grupo}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ConfiguracaoGrupo configuracao={configGrupo} update={getConfigByEmpresa}/>
                    </AccordionDetails>
                </Accordion>
            )}
            {configuracoes.map((configuracao, i) => <ConfiguracaoEmpresa configuracao={configuracao} key={i} update={getAll} />)}
        </div>
    );


}

export default EditConfiguracoes;