import {Epi} from "types/Epi";

const EpiUtils = {
  reducerEpis: (state: Epi[], action: any) => {
    switch (action.type) {
      case 'add':
        return [...state.filter((item: Epi, index: any) => item.numero !== action.payload.numero), action.payload];
      case 'remove':
        return state.filter((item: Epi, index: any) => item.numero !== action.payload.numero);
      case 'clear':
        return [];
      default:
        return state;
    }
  },
}

export default EpiUtils;