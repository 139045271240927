import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/aprovacao-cadastro";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const CadastroExternoService =  {
    findAllLink: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/formularios`, paramsGeral);
    },
    findByIdLink: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/formularios/${id}`, paramsGeral);
    },
    createLink: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/formularios`, data, paramsGeral);
    },
    deleteLink: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.delete(`${URL}/formularios/${id}`, paramsGeral);
    },
    findAllCadastro: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/cadastros-pendentes`, paramsGeral);
    },
    findByIdCadastro: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/cadastros-pendentes/${id}`, paramsGeral);
    },
    createCadastro: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/cadastros-pendentes`, data, paramsGeral);
    },
    updateStatus: (id: string, data?: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.put(`${URL}/cadastros-pendentes/${id}`,data, paramsGeral);
    },
    deleteCadastro: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.delete(`${URL}/cadastros-pendentes/${id}`, paramsGeral);
    }
}