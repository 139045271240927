import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    MenuItem,
    Pagination,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { AxiosRequestConfig, AxiosResponse } from "axios";
import MyCard from "components/card";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { CadastroExternoService } from "services/CadastroExternoService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Cadastros } from "types/cadastroExterno";
import { SpringPage } from "types/springpage";
import { PAGE_SIZE_SELECT } from "utils/constants";
import ModalDetalhesCadastro from "components/aprovacao-cadastro/modal/detalhesCadastro";
import Format from "utils/formatadores";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { configActions, useConfigState } from "store/config";

type SearchProps = {
    nome: string;
    cgc: string;
}

const ListAprovacaoCadastro = () => {
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const [page, setPage] = useState<SpringPage<Cadastros>>();
    const [cadastroSelecionado, setCadastroSelecionado] = useState<Cadastros>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showModal, setShowModal] = useState(false);
    const defaultPageSize = '10';
    const defaultPage = '1';
    const sweetScreen = withReactContent(Swal);
    const { register, handleSubmit, formState: { errors } } = useForm<SearchProps>();

    const findAllCadastros = useCallback(() => {

        if (!config.parametros || !config.parametros.codigoUnico) {
            toast.warning('Grupo não encontrado, selecione o grupo!');
            return;
        }

        if (!config.empresa || !config.empresa.empresaCodigo) {
            toast.warning('Empresa não encontrada, selecione a empresa!');
            return;
        }

        appDispatch(configActions.setLoading({
            loading: true,
        }));
        const params: AxiosRequestConfig = {
            params: {
                codigo: config.empresa.empresaCodigo,
                codigoUsuario: searchParams.get('codigo') || '',
                page: parseInt(searchParams.get('page') || defaultPage) - 1,
                nome: searchParams.get('nome') || '',
                cgc: searchParams.get('cgc') || '',
                size: searchParams.get('size') || defaultPageSize,
            }
        };
        CadastroExternoService.findAllCadastro(params)
            .then((res: AxiosResponse<SpringPage<Cadastros>>) => {
                setPage(res.data);
            })
            .catch(err => {
                toast.error(err);
            })
            .finally(() => {
                appDispatch(configActions.setLoading({
                    loading: false,
                }));
            });
    }, [config.parametros, config.empresa, appDispatch, searchParams]);

    useEffect(() => {
        findAllCadastros();
    }, [findAllCadastros]);


    const onSubmit = (d: any) => {
        setSearchParams({
            page: defaultPage,
            size: searchParams.get('size') || defaultPageSize,
            nome: d.nome,
            cgc: d.cgc,
        });
    };

    const handleRejeitar = (cadastro: Cadastros) => {
        sweetScreen
            .fire({
                title: `Deseja rejeitar o cadastro ?`,
                text: cadastro.nome,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Sim, rejeitar cadastro!`,
                cancelButtonText: `Não`,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            })
            .then(result => {
                if (result.isConfirmed) {
                    appDispatch(configActions.setLoading({
                        loading: true,
                    }));
                    const configuracoes: AxiosRequestConfig = {
                        params: {
                            codigo: config.empresa.empresaCodigo,
                            status: 'NEGADO',
                        }
                    }
                    CadastroExternoService.updateStatus(cadastro.id, {}, configuracoes)
                        .then(res => {
                            toast.success('Cadastro rejeitado com sucesso!');
                        })
                        .catch(err => {
                            toast.error(err.response.data.message);
                        })
                        .finally(() => {
                            appDispatch(configActions.setLoading({
                                loading: false,
                            }));
                            findAllCadastros();
                        });
                }
            });
    };

    const handleDelete = (cadastro: Cadastros) => {
        sweetScreen
            .fire({
                title: `Deseja excluir o cadastro ?`,
                text: cadastro.nome,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Sim, deletar cadastro!`,
                cancelButtonText: `Não`,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            })
            .then(result => {
                if (result.isConfirmed) {
                    appDispatch(configActions.setLoading({
                        loading: true,
                    }));
                    CadastroExternoService.deleteCadastro(cadastro.id)
                        .then(res => {
                            toast.success('Cadastro deletado com sucesso!');
                            findAllCadastros();
                        })
                        .catch(err => {
                            toast.error(err.response.data.message);
                        })
                        .finally(() => {
                            appDispatch(configActions.setLoading({
                                loading: false,
                            }));
                        });
                }
            });
    };

    const handleOpen = (cadastro: Cadastros) => {
        setCadastroSelecionado(cadastro);
        setShowModal(true);
    };


    const handleClose = () => {
        setCadastroSelecionado(undefined);
        setShowModal(false);
    };

    return (
        <div>
            <MyCard title={'Aprovação de Cadastros'}>
                <>
                    <Grid container spacing={2} sx={{ pb: 2 }}>
                        <Grid item xs={12} md={4.5}>
                            <TextField
                                {...register("nome")}
                                label="Nome"
                                variant="outlined"
                                fullWidth
                                error={!!errors.nome}
                                helperText={errors.nome?.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4.5}>
                            <TextField
                                {...register("cgc")}
                                label="CPF/CNPJ"
                                variant="outlined"
                                fullWidth
                                error={!!errors.cgc}
                                helperText={errors.cgc?.message}
                                type="number"
                                InputProps={{ inputProps: { max: 14 } }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                onClick={handleSubmit((e: any) => {
                                    onSubmit(e);
                                })}
                                sx={{ width: "100%", height: "100%" }}
                                variant="contained"
                                color="primary"
                            >
                                Buscar
                            </Button>
                        </Grid>
                    </Grid>
                    <Box sx={{ mb: 3 }}>
                        {page &&
                            page.content.map(cadastro => (
                                <Card sx={{ width: '100%', mb: 2 }} key={cadastro.id}>
                                    <CardContent>
                                        <Grid container alignItems='center' justifyContent='space-between' spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                                    <b>Descrição: </b> {cadastro.descricaoFormulario}
                                                </Typography>
                                            </Grid>
                                            {/* Status */}
                                            <Grid item xs={12} md={6}>
                                                <Typography component={'span'} sx={{ fontSize: 16 }} color='text.secondary'>
                                                    <b>Status: </b>{' '}
                                                    {cadastro.status === 'PENDENTE' && (<Chip label='Pendente' color='warning' size={'small'} />)}
                                                    {cadastro.status === 'APROVADO' && (<Chip label='Aprovado' color='success' size={'small'} />)}
                                                    {cadastro.status === 'NEGADO' && (<Chip label='Negado' color='error' size={'small'} />)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                                    <b>Nome: </b> {cadastro.nome}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                                    <b>CPF: </b> {Format.cpf(cadastro.cpf)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                                    <b>Nº Telefone: </b> {Format.telefone(cadastro.telefone)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                                    <b>Email: </b> {cadastro.email}
                                                </Typography>
                                            </Grid>
                                            {cadastro.razaoSocial && <Grid item xs={12} md={6}>
                                                <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                                    <b>Razão Social: </b> {cadastro.razaoSocial}
                                                </Typography>
                                            </Grid>}
                                            {cadastro.cnpj && <Grid item xs={12} md={6}>
                                                <Typography sx={{ fontSize: 16 }} color='text.secondary'>
                                                    {cadastro.cnpj && <>
                                                        <b>{cadastro.cnpj.length > 11 ? 'CNPJ Corporativo' : 'CPF Corporativo'}: </b> {Format.cgc(cadastro.cnpj)}
                                                    </>}
                                                </Typography>
                                            </Grid>}
                                            <Grid container item xs={12} md={12} spacing={3}>
                                                <Grid item xs={12} md={4}>
                                                    <Tooltip title='Excuir' placement='top'>
                                                        <Button variant={'contained'} color={'success'} fullWidth onClick={() => handleOpen(cadastro)} >
                                                            <CheckIcon sx={{ mr: 1 }} /> Aprovar
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Tooltip title="Rejeitar" placement="top">
                                                        <Button variant={'contained'} color={'warning'} fullWidth onClick={() => handleRejeitar(cadastro)}>
                                                            <DoDisturbOffIcon sx={{ mr: 1 }} /> Rejeitar
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Tooltip title='Excuir' placement='top'>
                                                        <Button variant={'contained'} color={'error'} fullWidth onClick={() => handleDelete(cadastro)} >
                                                            <DeleteIcon sx={{ mr: 1 }} /> Excluir
                                                        </Button>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            ))}
                    </Box>
                </>
                {page && page.totalElements > 0 && (
                    <Grid container item xs={12}>
                        <Grid item md={6} xs={6}>
                            <Pagination
                                count={page?.totalPages}
                                defaultPage={parseInt(defaultPage)}
                                page={parseInt(searchParams.get('page') || defaultPage)}
                                onChange={(event, value) => {
                                    setSearchParams({ page: `${value}`, size: searchParams.get('size') || defaultPageSize });
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} sx={{ textAlign: 'right' }}>
                            <TextField
                                id='outlined-select-currency'
                                select
                                label='Registros por pagina'
                                onChange={(e: any) => {
                                    setSearchParams({ page: '1', size: e.target.value });
                                }}
                                size='small'
                                value={searchParams.get('size') || defaultPageSize}
                                sx={{ minWidth: 150 }}>
                                {PAGE_SIZE_SELECT.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                )}
            </MyCard>
            {showModal &&
                <ModalDetalhesCadastro
                    onClose={handleClose}
                    cadastro={cadastroSelecionado}
                    showModal={showModal}
                    findAllCadastros={findAllCadastros}
                />
            }
        </div>
    )
}

export default ListAprovacaoCadastro