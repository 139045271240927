import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";
import {Cotacao} from "types/cotacao";

const URL = "/cotacoes";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const CotacaoService = {
  findAll: (codigo: string, params?: AxiosRequestConfig) => {
    if (params) paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = { ...paramsGeral.params, codigo };
    return API.get(URL, paramsGeral);
  },
  enviaCotacao: (cotacao: Cotacao, codigo: string, params?: AxiosRequestConfig) => {
    if (params) paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = { ...paramsGeral.params, codigo };
    return API.post(URL, cotacao, paramsGeral);
  },
  findAllFiltros(codigo: string, params?: AxiosRequestConfig) {
    if (params) params.params = { ...params?.params, codigo: codigo }
    else params = { params: { codigo: codigo } }
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(`${URL}/filtros`, paramsGeral);
  }
}