import {Navigate, Outlet} from "react-router-dom";
import {Role} from "types/auth";
import {useAppSelector} from "store";
import {hasAnyRoles, hasModuloPermission, useAuthState} from "store/auth";
import {useConfigState} from "store/config";

type Props = {
    role: Role[];
    modulo?: string;
    permissao?: string;
}

const HasPermissao = ({modulo = undefined, permissao = undefined, role} : Props) => {
    const appSelector = useAppSelector(useAuthState)
    const configSelector = useAppSelector(useConfigState)

    if(role && !modulo) {
        if(hasAnyRoles(appSelector, role)){
            return <Outlet/>
        }else{
            return <Navigate to="/404" replace />;
        }
    }else {
        if(modulo && !permissao){
            if (hasAnyRoles(appSelector, role) && hasModuloPermission(appSelector, configSelector, modulo)) {
                return <Outlet/>;
            } else {
                return <Navigate to="/404" replace/>;
            }
        }else{
            if (hasAnyRoles(appSelector, role) &&  hasModuloPermission(appSelector, configSelector,modulo, permissao)) {
                return <Outlet/>;
            } else {
                return <Navigate to="/404" replace/>;
            }
        }
    }
}


export default HasPermissao;