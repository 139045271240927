export const AgendamentoUtils = {
  reducepedido: (state: any, action: any) => {
    switch (action.type) {
        case 'add':
            return [...state.filter((item: any, index: any) => item.id !== action.payload.id), { ...action.payload, quantidade: action.payload.quantidadeDisponivel }];
        case 'remove':
            return state.filter((item: any, index: any) => item.id !== action.payload.id);
        case 'update':
            return state.map((item: any, index: any) => {
                if (item.id === action.payload.id) {
                    return { ...action.payload };
                }
                return item;
            });
        case 'clear':
            return [];
        default:
            return state;
    }
  }

}