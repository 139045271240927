import {Box, Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';


const TelaErro404Cadastro = () => {

    const navigate = useNavigate();

  return (
    <Box sx={{textAlign: 'center', my: 5}}>
        <Typography textAlign={'center'} sx={{fontSize: 20, fontWeight: 'bold', mb: 3}}>
            Página não encontrada
        </Typography>
        <Typography textAlign={'center'} sx={{fontSize: 16,  mb: 3}}>
            Entre em contato com o administrador do sistema
        </Typography>
        <Button variant='contained' onClick={() => navigate('/auth/login')}>
            Voltar
        </Button>
    </Box>
  )
}

export default TelaErro404Cadastro