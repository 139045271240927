import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "assets/theme";
import AuthProvider from "contexts/AuthContext";
import ConfigProvider from "contexts/ConfigContext";
import React from 'react';
import "assets/styles/app.css";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Rotas from "rotas";
import {AxiosInterceptor} from "utils/axiosInterceptor";
import {Provider} from "react-redux";
import {persistor, store} from "store";
import {PersistGate} from "redux-persist/integration/react";
import Loader from "components/loader";


const App = () => {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <ConfigProvider>
                            <AuthProvider>
                                <AxiosInterceptor>
                                    <CssBaseline/>
                                    <Rotas/>
                                    <ToastContainer
                                        position="top-right"
                                        autoClose={2000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                        theme={'colored'}
                                    />
                                    <Loader />
                                </AxiosInterceptor>
                            </AuthProvider>
                        </ConfigProvider>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    );
}


export default App;
