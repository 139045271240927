import {useCallback, useEffect, useState} from "react";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {toast} from "react-toastify";
import MyCard from "components/card";
import {Card, CardContent, Chip, Grid, MenuItem, Pagination, TextField, Typography} from "@mui/material";
import {SpringPage} from "types/springpage";
import {GrupoEmpresa} from "types/user";
import {GrupoEmpresaService} from "services/GrupoEmpresaService";
import ActionButtons from "components/buttons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PAGE_SIZE_SELECT} from "utils/constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useForm} from "react-hook-form";
import SeachForms from "components/searchForms";
import {useAppDispatch} from "store";
import {configActions} from "store/config";

const ListGruposEmpresa = () => {
    const [page, setPage] = useState<SpringPage<GrupoEmpresa>>();
    const appDispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPageSize = '10';
    const defaultPage = '1';
    const sweetScreen = withReactContent( Swal )
    const forms = useForm( {
        defaultValues: {
            nome: searchParams.get( 'nome' ) || '',
            codigo: searchParams.get( 'codigo' ) || '',
            cgc: searchParams.get( 'cgc' ) || ''
        },
    } );

    const loadGrupos = useCallback( () => {
        appDispatch(configActions.setLoading({loading: true}))
        const params: AxiosRequestConfig = {
            params: {
                page: parseInt( searchParams.get( 'page' ) || defaultPage ) - 1,
                size: searchParams.get( 'size' ) || defaultPageSize,
                nome: searchParams.get( 'nome' ) || '',
                codigoGrupo: searchParams.get( 'codigo' ) || '',
                cgc: searchParams.get( 'cgc' ) || ''
            }
        }
        GrupoEmpresaService.findAll( params )
            .then( ( res: AxiosResponse<SpringPage<GrupoEmpresa>> ) => {
                setPage( res.data );
            } )
            .catch( ( err ) => { toast.error( err ); } )
            .finally( () => { appDispatch(configActions.setLoading({loading: false})) } );
    }, [searchParams, appDispatch] );

    useEffect( () => {
        loadGrupos();
    }, [loadGrupos] );

    const handleEdit = ( id: string ) => {
        navigate( `/painel/grupos-empresas/edit/${id}` );
    }

    const handleDelete = ( id: string ) => {
        sweetScreen.fire( {
            title: `Deseja realmente excluir o grupo:\n ${id} - ${page.content.find( pr => pr.id === id ).nome} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, deletar grupo!`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        } ).then( ( result ) => {
            if ( result.isConfirmed ) {
                appDispatch(configActions.setLoading({loading: true}))
                GrupoEmpresaService.delete( id )
                    .then( ( res ) => {
                        toast.success( "Grupo deletado com sucesso!" );
                        loadGrupos();
                    } )
                    .catch( ( err ) => {
                        toast.error( err.response.data.message );
                    } )
                    .finally( () => {
                        appDispatch(configActions.setLoading({loading: false}))
                    } );
            }
        } )
    }

    const onSubmit = ( e: any ) => {
        setSearchParams( {
            page: defaultPage,
            size: searchParams.get( 'size' ) || defaultPageSize,
            nome: e.nome || '',
            codigo: e.codigo || '',
            cgc: e.cgc || ''
        } )
        loadGrupos();
    }

    return (
        <MyCard title={'Grupos de empresas'} action={() => navigate( '/painel/grupos-empresas/add' )}>
            <SeachForms forms={forms} onSubmit={onSubmit} />
            <Grid item xs={12} sx={{ mb: 3 }}>
                    {page && page.content.map( grupo => (
                        <Card sx={{ width: "100%", mb: 2 }} key={grupo.id}>
                            <CardContent>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} md={5}>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Grupo: </b> {grupo.id.substring( 0, 4 )}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Nome: </b> {grupo.nome}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
                                            <b>Email: </b> {grupo.email}
                                        </Typography>
                                        <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom component={'div'}>
                                            <b>Status: </b> {grupo.status ? <Chip label="Ativo" color="success" size={"small"} sx={{ px: 1 }} /> : <Chip label="Bloqueado" color="error" size={"small"} sx={{ px: 1 }} />}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <ActionButtons handleEdit={() => handleEdit( grupo.id )}
                                            handleDelete={() => handleDelete( grupo.id )} />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) )}
            </Grid>
            {page && page.totalElements > 0 &&
                <Grid container item xs={12}>
                    <Grid item md={6} xs={6}>
                        <Pagination
                            count={page?.totalPages}
                            defaultPage={parseInt( defaultPage )}
                            page={parseInt( searchParams.get( 'page' ) || defaultPage )}
                            onChange={( event, value ) => {
                                setSearchParams( { page: `${value}`, size: searchParams.get( 'size' ) || defaultPageSize } )
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Registros por pagina"
                            onChange={( e: any ) => {
                                setSearchParams( { page: "1", size: e.target.value } )
                            }}
                            size="small"
                            value={searchParams.get( 'size' ) || defaultPageSize}
                            sx={{ minWidth: 150 }}
                        >
                            {PAGE_SIZE_SELECT.map( ( option ) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ) )}
                        </TextField>
                    </Grid>
                </Grid>
            }
        </MyCard>
    )
}

export default ListGruposEmpresa;