import {Grid, Pagination} from "@mui/material";
import MyCard from "components/card"
import React, {useCallback, useEffect, useState} from "react";
import {RestPage} from "types/prtoheuspage";
import {toast} from "react-toastify";
import CardCotacao from "components/cotacoes/card";
import ModalPreencherCotacao from "components/cotacoes/modal";
import {CotacaoService} from "services/CotacaoService";
import {Cotacao} from "types/cotacao";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

const ListCotacoes = () => {
  const [pageData, setPageData] = useState<RestPage<Cotacao>>();
  const [page, setPage] = useState(1)
  const [pageSize,] = useState(10)
  const config = useAppSelector(useConfigState);
  const [show, setShow] = useState(false)
  const [cotacaoSelecionada, setCotacaoSelecionada] = useState<Cotacao>()
  const appDispatch = useAppDispatch();

  const loadData = useCallback(() => {
    let configuracoes = {
      params: {
        page,
        size: pageSize,
      }
    }
    appDispatch(configActions.setLoading({loading: true}))
    CotacaoService.findAll(config.empresa.empresaCodigo, configuracoes)
      .then((res) => {
        setPageData(res.data)
        if (res.data.quantidade === "0") {
          toast.warn("Não existe cotações disponiveis!");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => appDispatch(configActions.setLoading({loading: false})));
  }, [appDispatch, config.empresa.empresaCodigo, page, pageSize])

  useEffect(() => {
    loadData();
  }, [loadData])

  const handlePreencher = (cotacao: Cotacao) => {
    setCotacaoSelecionada(cotacao)
    setShow(true);
  }

  const handleClose = () => {
    setCotacaoSelecionada(undefined);
    setShow(false)
    loadData();
  }


  return (
    <MyCard title={"Listar cotações"}>
      <Grid container spacing={2}>
        {pageData &&
          <>
            {pageData.conteudo.map((cotacao, i) => <CardCotacao key={i} cotacao={cotacao} handlePreencher={handlePreencher} />)}
            <Grid item xs={12}>
              <Pagination
                count={Math.ceil(parseInt(pageData.quantidade) / pageSize)}
                defaultPage={1}
                page={page}
                onChange={(event, value) => setPage(value)}
              />
            </Grid>
          </>
        }
      </Grid>
      {cotacaoSelecionada &&
        <ModalPreencherCotacao cotacao={cotacaoSelecionada} handleClose={handleClose} show={show} loadData={loadData} />
      }
    </MyCard>
  )
}

export default ListCotacoes;