import ButtonEdit from "components/buttons/edit";
import ButtonDelete from "components/buttons/delete";
import ButtonPrint from "components/buttons/print";
import ButtonConfirm from "components/buttons/confirm";
import ButtonView from "components/buttons/view";
import {Grid} from "@mui/material";
import ButtonCancel from "./cancel";

type Props ={
    handleEdit?: Function,
    handleDelete?: Function,
    handleConfirm?: Function,
    handlePrint?: Function,
    handleView?: Function,
    handleCancel?: Function,
}

const ActionButtons = ({handleEdit, handleDelete, handleConfirm, handlePrint, handleView, handleCancel}:Props) => {
    return(
        <Grid container spacing={.5} sx={{display: 'flex', justifyContent:{xs: 'space-around', sm: 'space-evenly'}}}>
            {handleEdit &&
                <Grid item>
                    <ButtonEdit handleEdit={handleEdit}/>
                </Grid>
            }
            {handleView &&
                <Grid item>
                    <ButtonView handleView={handleView}/>
                </Grid>
            }
            {handleDelete &&
                <Grid item>
                    <ButtonDelete handleDelete={handleDelete}/>
                </Grid>
            }
            {handlePrint &&
                <Grid item>
                    <ButtonPrint handlePrint={handlePrint}/>
                </Grid>
            }
            {handleConfirm &&
                <Grid item>
                    <ButtonConfirm handleConfirm={handleConfirm}/>
                </Grid>
            }
            {handleCancel &&
                <Grid item>
                    <ButtonCancel handleCancel={handleCancel}/>
                </Grid>
            }
        </Grid>

    )
}

export default ActionButtons;
