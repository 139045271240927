import numeral from "numeral";
import {Orcamento, ProdutoFilter, ProdutoOrcamento} from "types/orcamento";

const OrcamentoUtils = {
    totalItens: (orcamento: Orcamento): number => {
        return orcamento.produtos.reduce((total, item) => total + numeral(item.quantidade).value(), 0);
    },
    valorTotal: (produtos: ProdutoOrcamento[], frete: string): number => {
        return produtos.reduce((acc: number, produto) => acc + numeral(produto.valorFinal).value(), 0) + numeral(frete).value()
    },
    updateFrete: (orcamento: Orcamento, tipoFrete: string): Orcamento => {
        return {...orcamento, tipoFrete: tipoFrete};
    },
    reducerProduto: (state: ProdutoOrcamento[], action: any) => {
        switch (action.type) {
            case 'add':
                return [...state.filter((item:ProdutoOrcamento, index:any) => item.codigo !== action.payload.codigo), action.payload];
            case 'remove':
                return state.filter((item:ProdutoOrcamento, index:any) => item.codigo !== action.payload.codigo);
            case 'update':
                return state.map((item:ProdutoOrcamento, index:any) => {
                    if (item.codigo === action.payload.codigo) {
                        var quantidade = numeral(parseFloat(action.payload.quantidade))
                        if (quantidade.value() <= 0) {
                            quantidade = numeral(1);
                        }
                        var valorVenda = numeral(parseFloat(action.payload.valorVenda))
                        return {...action.payload, valorFinal: quantidade.value() * valorVenda.value()};
                    }
                    return item;
                });
            case 'clear':
                return [];
            default:
                return state;
        }
    },
    reduceFilter: (state: ProdutoFilter[], action: any) => {
        switch (action.type) {
            case 'add':
                let isExist = state.find((item:ProdutoFilter, index:any) => item.campo === action.payload.campo && item.condicao === action.payload.condicao && item.valor === action.payload.valor);
                if (isExist) {
                    return state;
                }else{
                    return [...state, action.payload];
                }
            case 'update':
                return state.map((item:ProdutoFilter, index:any) => {
                    if (item.campo === action.payload.campo && item.condicao === action.payload.condicao && item.valor === action.payload.valor) {
                        return action.payload;
                    }
                    return item;
                });
            case 'remove':
                return state.filter((item:ProdutoFilter, index:any) => !(item.campo === action.payload.campo && item.condicao === action.payload.condicao && item.valor === action.payload.valor));
            case 'clear':
                return [];
            default:
                return state;
        }
    }
}

export default OrcamentoUtils;