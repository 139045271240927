import * as React from 'react';
import {useCallback, useState} from 'react';
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {CrmLead, HistProdutos} from 'types/crm';
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import Format from "utils/formatadores";
import {AxiosResponse} from "axios";
import {RestPage} from "types/prtoheuspage";
import {toast} from "react-toastify";
import {CrmService} from "services/CrmService";
import Loader from 'components/loader';


type Props = {
  dt: CrmLead;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const OpenDetails = ({ dt }: Props) => {
  const [modalDetail, setModalDetail] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState<HistProdutos[]>([]);
  const config = useAppSelector(useConfigState);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = useCallback(() => {
      CrmService.findHistorico(config.empresa.empresaCodigo, dt.codigo)
          .then((res:AxiosResponse<RestPage<HistProdutos>>) => {
              setData(res.data.conteudo);
          })
          .catch((err:any) => { toast.error(err.response.data.message);
          })
          .finally(() => { setIsLoading(false); })
  }, [dt.codigo, config.empresa.empresaCodigo]);
  
  const handleOpenDetail = () => {
    setModalDetail(true)
    loadData()
  }

  const handleCloseDetail = () => {
    setModalDetail(false)
  }

  return (
    <Grid>
      <IconButton onClick={handleOpenDetail}>
        <VisibilityIcon />
      </IconButton>
        <BootstrapDialog
          onClose={handleCloseDetail}
          aria-labelledby="customized-dialog-title"
          open={modalDetail}
          fullWidth={true}
          maxWidth={"xl"}
          fullScreen={fullScreen}
          keepMounted
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseDetail} >
            Detalhes do Cliente
          </BootstrapDialogTitle>
          {isLoading && <Loader/>}
          {!isLoading &&
            <DialogContent dividers>
              <Box sx={{ mt: 1 }} >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField  label="Nome de Cliente" 
                                variant="outlined" 
                                value={dt.nome} 
                                fullWidth 
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField  label="Telefone" 
                                variant="outlined" 
                                value={dt.telefone}  
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField  label="Celular" 
                                variant="outlined" 
                                value={(dt.celular.length < 5) ? dt.celular : "Não informado"} 
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField  label="Risco" 
                                variant="outlined" 
                                value={(dt.risco) ? dt.risco : "Não informado"} 
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField  label="Estado" 
                                variant="outlined" 
                                value={dt.estado}
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField  label="Cidade" 
                                variant="outlined" 
                                value={dt.municipio} 
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <TextField  label="Email" 
                                variant="outlined" 
                                value={dt.email} 
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField  label="Tipo" 
                                variant="outlined" 
                                value={(dt.tipo === 'J') ? 'Jurídica' : 'Física'}
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField  label="Ultima Compra" 
                                variant="outlined" 
                                value={(dt.ultiCom) ? dt.ultiCom : "Cliente sem compras"} 
                                inputProps={{readOnly: true}}
                                sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Observações de Cliente"
                      value={(dt.observa) ? dt.observa : "Não informado"}
                      inputProps={{readOnly: true}}
                      multiline
                      fullWidth
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={"700"} fontSize={12} sx={{ mb: 2 }} >
                      Historico de Pedidos
                    </Typography>
                    <Paper elevation={2} sx={{
                        p: 2,
                        margin: 'auto',
                        maxWidth: '100%',
                        flexGrow: 1
                      }}
                    >
                      <Grid>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                          <Typography>Codigo</Typography>
                          <Divider/>
                          <Typography>Data de Pedido</Typography>
                          <Divider/>
                          <Typography>Valor Total</Typography>
                          <Divider/>
                          <Typography>Cond. Pag</Typography>
                        </Stack>
                          <Divider sx={{pt: 2}}/>
                          {data.map((item: HistProdutos) => ( 
                            <div key={item.codigo}>
                              <Grid container spacing={2} justifyContent={'space-between'} sx={{ py: 1}}>
                                <Grid item>
                                  <Typography>{item.codigo}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{Format.data(item.emissao)}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{Format.moeda(item.valor)}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{item.condiPag}</Typography>
                                </Grid>
                              </Grid>
                              <Divider/>
                            </div>
                          ))}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          }
        </BootstrapDialog>
    </Grid>
  );
}