import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

type Props = {
    handleSelect: Function,
}

const ButtonSelect = ({handleSelect}: Props) => {
    return (
        <Button variant={'contained'} onClick={() => handleSelect()} sx={{marginRight: 1}} fullWidth>
            <AddIcon />
        </Button>
    )
}

export default ButtonSelect;