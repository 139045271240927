import {CLIENT_ID, CLIENT_SECRET} from "utils/constants";
import {AxiosRequestConfig} from "axios";
import request from "utils/axiosInterceptor";

type LoginData = {
    username: string;
    password: string;
}

const headers = {
    'Authorization': `Basic ${window.btoa(CLIENT_ID + ':' + CLIENT_SECRET)}`
}

export const AuthService = {
    login: (loginData: LoginData) => {
        const data = {...loginData, grant_type: 'password'};
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
        return request({method: 'POST', url: "/oauth2/token", data, headers})
    },
    resetPassword: (codigoEmpresa: string, codigoUsuario:string, config?:AxiosRequestConfig) => {
        let data = {
            codigoEmpresa,
            codigoUsuario
        }
        headers['Content-Type'] = 'application/json';
        return request({method: 'POST', url: '/auth/resetar-senha', data, headers, ...config})
    },
    validToken: (token:string, config?:AxiosRequestConfig) => {
        headers['Content-Type'] = 'application/json';
        return request({method: 'GET', url: `/auth/validar-token/${token}`,headers, ...config})
    },
    changePassword: (token:string, password:string, config?:AxiosRequestConfig) => {
        let data = {
            password
        }
        headers['Content-Type'] = 'application/json';
        return request({method: 'POST', url: `/auth/alterar-senha/${token}`, data,headers, ...config})
    },
    loginWithToken: (token:string, config?:AxiosRequestConfig) => {
        headers['Content-Type'] = 'application/json';
        return request({method: 'POST', url: `/auth/login/refreshToken`,data: {refreshToken: token} ,headers, ...config})
    }
}