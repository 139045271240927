import {Button, Grid} from "@mui/material";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

type Props = {
    handleInicio: () => void;
}

const Agradecimento = ( { handleInicio }: Props ) => {
    return (
        <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
                <AccessAlarmIcon sx={{ fontSize: 250 }} />
                <h1 className="mt-3"> Seu agendamento foi enviado!</h1>
                <Button variant="contained" onClick={handleInicio} sx={{ mt: 3, ml: 1 }}>
                    Inicio
                </Button>
            </Grid>
        </Grid>
    );
}

export default Agradecimento;