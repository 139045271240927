import {Card, Grid, TextField, Typography} from "@mui/material";
import Format from "utils/formatadores";
import React from "react";
import {Documento} from "types/documentos";

type Props = {
    documento: Documento;
    tipoExt?: string;
}

const InfoSolicitacaoArmazem = ({documento, tipoExt} : Props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={"h3"} sx={{fontSize: 16, fontWeight: 600, mb: 3}} color="text.secondary">
                    Detalhes Solicitação de Armazem {documento.codDocumento}</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
                <TextField
                    label="Numero"
                    value={documento.codDocumento}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <TextField
                    label="Data de Emissão"
                    value={Format.data(documento.dataCria)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <TextField
                    label="Tipo documento"
                    value={tipoExt ? tipoExt : documento.tipo}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <TextField
                    label="Solicitante"
                    value={documento.solicit}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <TextField
                    label="Tipo de frete"
                    value={documento.tipoFrete}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid item xs={6} md={6}>
                <TextField
                    label="Valor frete"
                    value={documento.vlrFrete}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            {documento.observa &&
                <Grid item xs={12} md={12}>
                    <TextField
                        label="Observação"
                        value={documento.observa}
                        variant="outlined"
                        margin="normal"
                        multiline
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {documento.produtos.map((produto) => (
                        <Grid item xs={12} key={produto.numeroItem}>
                            <Card variant="elevation" sx={{p: 2, backgroundColor: (t) => t.palette.grey[50]}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={1}>
                                        <Typography sx={{fontWeight: "900"}}>Item</Typography>
                                        <Typography>{produto.numeroItem}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={1}>
                                        <Typography sx={{fontWeight: "900"}}>Cod.</Typography>
                                        <Typography>{produto.produto}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Typography sx={{fontWeight: "900"}}>Descrição</Typography>
                                        <Typography>{produto.descricao}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={1}>
                                        <Typography sx={{fontWeight: "900"}}>Armazem</Typography>
                                        <Typography>{produto.armazem}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={1}>
                                        <Typography sx={{fontWeight: "900"}}>UM</Typography>
                                        <Typography>{produto.unMedida}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={1}>
                                        <Typography sx={{fontWeight: "900"}}>Qtd.</Typography>
                                        <Typography>{produto.quantidade}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Typography sx={{fontWeight: "900"}}>Data Necessidade</Typography>
                                        <Typography>{produto.dtNecess ? Format.data(produto.dtNecess) : ''}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Typography sx={{fontWeight: "900"}}>Custo Previsto</Typography>
                                        <Typography>{Format.moeda(produto.valUnidade)}</Typography>
                                    </Grid>
                                    {produto.observa &&
                                        <Grid item xs={12} md={12}>
                                            <Typography sx={{fontWeight: "900"}}>Observação</Typography>
                                            <Typography>{produto.observa}</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default InfoSolicitacaoArmazem;