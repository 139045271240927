import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/usuarios";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const PerfilService =  {
    getPerfil: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/perfil`, paramsGeral);
    },
    postPerfil: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.put(`${URL}/perfil`, data, paramsGeral);
    }
}