import {Box, Button, Grid, InputAdornment, MenuItem, TextField, Theme, useMediaQuery} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
    CondicaoOrcamento,
    ConsumidorOrcamento,
    Orcamento,
    ProdutoOrcamento,
    TransportadoraOrcamento
} from "types/orcamento";
import Format from "utils/formatadores";
import React, {ChangeEvent, useReducer, useState} from "react";
import SaveIcon from "@mui/icons-material/Save";
import MyCard from "components/card";
import {useNavigate} from "react-router-dom";
import OrcamentoUtils from "utils/orcamento";
import {OrcamentosService} from "services/OrcamentosService";
import {toast} from "react-toastify";
import {NumericFormat} from 'react-number-format';
import ModalConsumidor from "components/orcamento/modals/consumidor";
import ModalCondicao from "components/orcamento/modals/condicoes";
import ModalHistorico from "components/orcamento/modals/historico";
import ModalProdutos from "components/orcamento/modals/produtos";
import ModalTabelaPreco from "components/orcamento/modals/tabelaPreco";
import ModalTransportadora from "components/orcamento/modals/transportadora";
import ModalCadastrarConsumidor from "components/orcamento/modals/cadastrarConsumidor";
import MoneyIcon from '@mui/icons-material/Money';
import Swal from "sweetalert2";
import CardPedido from "components/orcamento/cardPedido";
import CardPedidoHeader from "components/orcamento/cardPedidoHeader";
import numeral from "numeral";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "store";
import {configActions} from "store/config";


const AddOrcamentos = () => {
    const [modalConsumidor, setModalConsumidor] = useState(false);
    const [modalTransportadora, setModalTransportadora] = useState(false);
    const [modalCondicao, setModalCondicao] = useState(false);
    const [modalProdutos, setModalProdutos] = useState(false);
    const [modalCadastrar, setModalCadastrar] = useState(false);
    const [modalTabelaPreco, setModalTabelaPreco] = useState(false);
    const [modalHistorico, setModalHistorico] = useState(false);
    const {handleSubmit, formState: {errors}, setValue, control} = useForm({
        defaultValues: {
            consumidor: '',
            tabelaPreco: '',
            transportadora: '',
            condicao: '',
            tipoFrete: ''
        }
    });
    const [orcamento, setOrcamento] = useState<Orcamento>({} as Orcamento);
    const navigate = useNavigate();
    const config = useSelector((state: RootState) => state.config);
    const [state, dispatch] = useReducer(OrcamentoUtils.reducerProduto, orcamento.produtos || []);
    const [frete, setFrete] = useState("");
    const xs = useMediaQuery((t: Theme) => t.breakpoints.down("md"));
    const appDispatch = useAppDispatch();

    const onSubmit = (data: any) => {
        if(!orcamento.codigoConsumidor){
            toast.warn("Você precisa selecionar um consumidor!");
            return;
        }

        if(!orcamento.codigoTab){
            toast.warn("Você precisa selecionar uma tabela de preço!");
            return;
        }

        if(!orcamento.codigoTransportadora){
            toast.warn("Você precisa selecionar uma transportadora!");
            return;
        }

        if(!orcamento.codigoCondicao){
            toast.warn("Você precisa selecionar uma condição de pagamento!");
            return;
        }


        if(!data.tipoFrete){
            toast.warn("Você precisa selecionar um tipo de frete!");
            return;
        }

        if (data.tipoFrete !== "SEM FRENTE" && !frete) {
            toast.warn("Você precisa informar o valor do frete!");
            return;
        }

        if (state.length === 0) {
            toast.warn("Você precisa adicionar pelo menos um produto!");
            return;
        }

        for(let i = 0; i < state.length; i++){
            if (parseInt(state[i].quantidade) === 0) {
                toast.warn("A quantidade dos produtos não pode ser 0!");
                return;
            }
            if (parseFloat(state[i].valorVenda) === 0.00) {
                toast.warn("O valor dos produtos não pode ser 0!");
                return;
            }
        }

        let orcamentoTemp = {...orcamento, valorFrete: `${frete}`, produtos: state, tipoFrete: data.tipoFrete};
        saveData(orcamentoTemp);

    }

    const saveData = (data: any) => {
        appDispatch(configActions.setLoading({loading: true}))
        OrcamentosService.create(config.empresa.empresaCodigo, data)
            .then((res) => {
                toast.success(<div>Salvo com sucesso ! <br /> Código: {res.data.codigoPedido}</div>);
                navigate("/painel/orcamentos");
            })
            .catch((err) => toast.error(err.response.data.message))
            .finally(() => appDispatch(configActions.setLoading({loading: false})));
    }

    const handleSelectConsumidor = (data: ConsumidorOrcamento) => {
        setOrcamento({
            ...orcamento,
            codigoConsumidor: data.codigo,
            nomeConsumidor: data.nome,
            cgcConsumidor: data.cgc,
            enderecoConsumidor: data.endereco,
            bairroConsumidor: data.bairro,
            municipioConsumidor: data.municipio,
            estadoConsumidor: data.estado,
        });
        setValue("consumidor", data.nome);
        setModalConsumidor(false);
    }

    const handleSelectTabelaPreco = (data: any) => {
        setOrcamento({
            ...orcamento,
            codigoTab: data.codigotab,
            descricaoTab: data.descricao,
        });
        setValue("tabelaPreco", data.descricao);
        setModalTabelaPreco(false);
    }

    const handleSelectTransportador = (data: TransportadoraOrcamento) => {
        setOrcamento({
            ...orcamento,
            codigoTransportadora: data.codigo,
            nomeTransportadora: data.nome,
        });
        setValue("transportadora", data.nome);
        setModalTransportadora(false);
    }

    const handleSelectCondicao = (data: CondicaoOrcamento) => {
        setOrcamento({
            ...orcamento,
            codigoCondicao: data.codigo,
            descricaoCondicao: data.descricao,
            formaCondicao: data.forma,
        });
        setValue("condicao", data.forma + " - " + data.descricao);
        setModalCondicao(false);
    }

    const handleSelectOrcamento = (data: Orcamento) => {
        setOrcamento(data);
        setValue("condicao",  data.descricaoCondicao + " - " + data.formaCondicao );
        setValue("transportadora", data.nomeTransportadora);
        setValue("tabelaPreco", data.descricaoTab);
        setValue("consumidor", data.nomeConsumidor);
        setValue("tipoFrete", data.tipoFrete);
        setFrete(numeral(data.valorFrete).format('$ 0,0.00'));
        data.produtos.forEach((item: ProdutoOrcamento, index: any) => {
            dispatch({type: "add", payload: {...item, valorVenda: parseFloat(item.valorVenda).toFixed(2)}});
        });
        setModalHistorico(false);
    }

    const handleAddProduto = (data: any) => {
        dispatch({
            type: 'add',
            payload: {...data, quantidade: data.qtd, valorFinal: parseFloat(data.qtd) * parseFloat(data.valor), valorVenda: parseFloat(data.valor).toFixed(2)}
        });
        toast.success(`Produto ${data.descricao} adicionado com sucesso !`, {autoClose: 1000,});
    }

    const removeProduto = (produto: any) => {
        dispatch({
            type: 'remove',
            payload: produto
        });
    }

    const onClickConsumidor = () => {
        if (orcamento && orcamento.produtos && orcamento.produtos.length > 0) {
            Swal.fire({
                title: 'Atenção',
                text: 'Ao alterar o consumidor, todos os produtos serão removidos. Deseja continuar ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({type: 'clear'});
                    setModalConsumidor(true);
                }
            });
        } else {
            setModalConsumidor(true);
        }
    }

    const onClickTabelaPreco = () => {
        if (orcamento && orcamento.produtos && orcamento.produtos.length > 0) {
            Swal.fire({
                title: 'Atenção',
                text: 'Ao alterar a tabela de preço, todos os produtos serão removidos. Deseja continuar ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({type: 'clear'});
                    setModalTabelaPreco(true);
                }
            });
        } else {
        setModalTabelaPreco(true);
        }
    }

    const onChangeCardData = (produto: ProdutoOrcamento) => {
        dispatch({
            type: 'update',
            payload: produto
        })
    }

    return(
        <MyCard title={'Adicionar orçamentos'}>
            <>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <Controller
                                            name="consumidor"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    required
                                                    error={!!errors.consumidor}
                                                    fullWidth={true}
                                                    label="Consumidor"
                                                    variant="outlined"
                                                    disabled={true}                                                
                                                    helperText={errors.consumidor?.message}
                                                    InputProps={{
                                                        startAdornment:                                                     
                                                            <InputAdornment position="start" onClick={() => onClickConsumidor()}>
                                                                <SearchIcon/>
                                                            </InputAdornment>,
                                                        endAdornment:
                                                            <InputAdornment position="end" onClick={() => setModalCadastrar(true)}>
                                                                <AddIcon/>
                                                            </InputAdornment>
                                                    }}
                                                />}
                                        />
                                    {orcamento.codigoConsumidor && (
                                        <Button onClick={() => setModalHistorico(true)}>Ver Histórico</Button>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Controller
                                            name="tabelaPreco"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    required
                                                    error={!!errors.tabelaPreco}
                                                    label="Tabela de preço"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth={true}                                                
                                                    helperText={errors.tabelaPreco?.message}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start" onClick={() => onClickTabelaPreco()}>
                                                                <SearchIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />}
                                        />
                                </Grid>
                                <Grid item>
                                    <Controller
                                            name="transportadora"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    required
                                                    error={!!errors.transportadora}
                                                    label="Transportadora"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth={true}
                                                    helperText={errors.transportadora?.message}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start" onClick={() => setModalTransportadora(true)}>
                                                                <SearchIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />}
                                        />
                                </Grid>
                                <Grid item>
                                    <Controller
                                            name="condicao"
                                            control={control}
                                            render={({field}) =>
                                                <TextField
                                                    {...field}
                                                    required
                                                    error={!!errors.condicao}
                                                    label="Condição de pagamento"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth={true}                                              
                                                    helperText={errors.condicao?.message}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start"   onClick={() => setModalCondicao(true)}>
                                                                <SearchIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />}
                                        />
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12} md= {6}>
                                        <Controller
                                            name="tipoFrete"
                                            control={control}
                                            render={({ field }) => (
                                            <TextField
                                                {...field}
                                                error={!!errors.tipoFrete}
                                                select
                                                label="Tipo de Frete"
                                                variant="outlined"
                                                sx={{ width: "100%" }}
                                                helperText={errors.tipoFrete?.message}
                                            >
                                                <MenuItem value={"SEM FRENTE"}>Sem Frete</MenuItem>
                                                <MenuItem value={"CIF"}>CIF</MenuItem>
                                                <MenuItem value={"FOB"}>FOB</MenuItem>
                                                <MenuItem value={"Cliente Retira"}>Cliente Retira</MenuItem>
                                            </TextField>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md= {6}>
                                        <NumericFormat
                                            customInput={TextField}
                                            variant="outlined"
                                            label="Valor do frete"
                                            decimalScale={2}
                                            decimalSeparator=","
                                            fixedDecimalScale
                                            prefix="R$ "
                                            thousandSeparator="."
                                            fullWidth={true}
                                            value={frete}
                                            placeholder={'R$ 0,00'}
                                            InputProps={{
                                                startAdornment:                                                     
                                                    <InputAdornment position="start">
                                                        <MoneyIcon/>
                                                    </InputAdornment>,
                                            }}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const value = numeral(e.target.value).format('$ 0,0.00');
                                                setFrete(value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained"
                                            onClick={() => {orcamento.codigoTab ? setModalProdutos(true) : toast.error("Você precisa selecionar uma tabela de preços primeiro")}}><AddIcon/> Adicionar produto</Button>
                                </Grid>
                                <Grid item>
                                    {!xs && <CardPedidoHeader/>}
                                    {state.length > 0 &&
                                        state.map((produto, index) => (
                                            <CardPedido key={index} produto={produto} onChange={onChangeCardData} onDelete={removeProduto}/>
                                        ))
                                    }
                                </Grid>
                                <Grid item>
                                    <TextField
                                            label="Valor Total"
                                            variant="outlined"
                                            disabled={true}
                                            value={Format.moeda(OrcamentoUtils.valorTotal(state, frete))}
                                            fullWidth={true}
                                        />
                                </Grid>
                            </Grid>
                        <Box sx={{mt: 3, textAlign: 'right'}}>
                            <Button variant={'contained'} color={'primary'} type={'submit'}>
                                <SaveIcon/> Salvar
                            </Button>
                        </Box>
                    </Box>
            </>
            {modalConsumidor && <ModalConsumidor show={modalConsumidor} setShow={setModalConsumidor} handleSelect={handleSelectConsumidor}/>}
            {modalTabelaPreco && <ModalTabelaPreco show={modalTabelaPreco} setShow={setModalTabelaPreco} handleSelect={handleSelectTabelaPreco} cgcCliente={orcamento?.cgcConsumidor} codigoCliente={orcamento.codigoConsumidor} />}
            {modalTransportadora && <ModalTransportadora show={modalTransportadora} setShow={setModalTransportadora} handleSelect={handleSelectTransportador} codigoCliente={orcamento.codigoConsumidor}/>}
            {modalCondicao && <ModalCondicao show={modalCondicao} setShow={setModalCondicao} handleSelect={handleSelectCondicao} codigoCliente={orcamento.codigoConsumidor}/>}
            {modalProdutos && <ModalProdutos show={modalProdutos} setShow={setModalProdutos} handleSelect={handleAddProduto} codigotab={orcamento?.codigoTab} codigoCliente={orcamento.codigoConsumidor}/>}
            {modalCadastrar && <ModalCadastrarConsumidor show={modalCadastrar} setShow={setModalCadastrar} handleSelect={handleSelectConsumidor}/>}
            {modalHistorico && <ModalHistorico show={modalHistorico} setShow={setModalHistorico} handleSelect={handleSelectOrcamento} codigoCliente={orcamento.codigoConsumidor}/>}
        </MyCard>
    )
}


export default AddOrcamentos;