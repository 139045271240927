import ButtonTopBar from "assets/styles/buttonTopBar";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

const ButtonExibeFilial = () => {
  const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
  return (
    <ButtonTopBar
      variant={"contained"}
      color={"inherit"}
      onClick={() => appDispatch(configActions.setModalEmpresa({modalEmpresa: true}))}
      sx={{
        m: 1,
        width: {
          lg: 400,
        },
        height: {
          lg: 35,
        },
      }}
    >
      {config.empresa && config.empresa.empresaCodigo ? `${config.empresa.empresaCodigo} - ${config.empresa.empresaNome}` : "Não selecionado"}
    </ButtonTopBar>
  );
};
export default ButtonExibeFilial;
