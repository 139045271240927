import {useForm} from "react-hook-form";
import {forwardRef, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {AuthService} from "services/AuthService";
import {Avatar, Box, Button, Grid, TextField, Typography} from "@mui/material";
import InputMask from "react-input-mask";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Copyright from "components/copyright";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";

type FormState = {
    codigoEmpresa: string;
    codigoUsuario: string;
}

type CustomProps = {
    onChange: (event: { target: { name: string; value: string } }) => void;
    mask: string;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, mask, ...other } = props;
        return (
            <InputMask
                {...other}
                mask={mask}
                inputRef={ref}
            />
        );
    },
);

const EsqueciASenha = () => {
    const {handleSubmit, reset, register, setValue} = useForm<FormState>({defaultValues:{codigoEmpresa: '', codigoUsuario: ''}});
    const dispatch = useAppDispatch();
    const [isSend, setIsSend] = useState(false);
    const config = useAppSelector(useConfigState);

    useEffect(() => {
        if(config.configWhite.codigo) setValue('codigoEmpresa', config.configWhite.codigo);
    } , [config.configWhite, setValue]);

    const onSubmit = ({codigoEmpresa, codigoUsuario}: FormState) => {
        dispatch(configActions.setLoading({loading: true}))
        AuthService.resetPassword(codigoEmpresa, codigoUsuario)
            .then(() => {
                toast.success("Email de recuperação enviado com sucesso. Verifique sua caixa de mensagens para continuar o processo.")
                setIsSend(true);
                reset();
            })
            .catch((err) => toast.error(err.message))
            .finally(() => dispatch(configActions.setLoading({loading: false})))
    }


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "Center",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Avatar sx={{m: 1, bgcolor: "primary.main"}}>
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5" sx={{mb: 3}}>
                Recuperar senha
            </Typography>
            {!isSend && (
                <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{mt: 1, width: '100%'}}>
                    <TextField
                        {...register("codigoUsuario", {required: true})}
                        label={"Codigo do usuário *"}
                        variant="outlined"
                        fullWidth
                        sx={{ mb: 2}}
                        InputProps={{
                            inputComponent: TextMaskCustom as any,
                            inputProps: {
                                mask: '999999',
                            },
                        }}
                        InputLabelProps={ {shrink: true} }
                    />
                    {
                        !config.configWhite.codigo && (
                            <TextField
                                {...register("codigoEmpresa", {required: true})}
                                label={"Código empresa*"}
                                variant="outlined"
                                fullWidth
                                sx={{ mb: 2}}
                                InputProps={{
                                    inputComponent: TextMaskCustom as any,
                                    inputProps: {
                                        mask: '9999',
                                    },
                                }}
                                InputLabelProps={ {shrink: true} }
                            />
                        )
                    }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Enviar
                </Button>
            </Box>
            )}
            {isSend && (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "Center",
                    alignItems: "center",
                    my: 5
                }}>
                    <Typography variant={"h3"}>
                        Email enviado com sucesso
                    </Typography>
                    <Typography variant={"h6"}>
                        Verifique sua caixa de mensagens para continuar o processo.
                    </Typography>
                </Box>
            )}
            <Grid container>
                <Grid item xs>
                    <Link to="/auth/login">Voltar</Link>
                    <Copyright sx={{p: 5}}/>
                </Grid>
            </Grid>
        </Box>

    );
}

export default EsqueciASenha;