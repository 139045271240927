import React, {useState} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {Button, Dialog, DialogContent, Grid, Slide, TextField, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Documento} from "types/documentos";
import {toast} from "react-toastify";
import {useAppDispatch, useAppSelector} from "store";
import {useConfigState} from "store/config";
import InfoItemPedidoCompra from "components/aprovacao-documento/modal-infos/item-pedido-compra";
import InfoSolicitacaoArmazem from "components/aprovacao-documento/modal-infos/solicitacao-armazem";
import InfoSolicitacaoCompra from "components/aprovacao-documento/modal-infos/solicitacao-compra";
import {toastError} from "utils/toastError";
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import BlockIcon from '@mui/icons-material/Block';
import InfoPedidoCompra from "components/aprovacao-documento/modal-infos/pedido-compra";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ComparaCotacao from "components/aprovacao-documento/modal-infos/compara-cotacao";
import Typography from "@mui/material/Typography";
import InfoNotaFiscal from "components/aprovacao-documento/modal-infos/nota-fiscal";
import {AprovacaoDocumentoService} from "services/AprovacaoDocumentosService";
import {configActions} from "store/config";
import Swal from "sweetalert2";

//-- Códigos de operações possíveis:
//--    "001" // Liberado
//--    "002" // Estornar
//--    "003" // Superior
//--    "004" // Transferir Superior
//--    "005" // Rejeitado
//--    "006" // Bloqueio
//--    "007" // Visualizacao


type Props = {
    open: boolean;
    onClose: Function;
    documento?: Documento;
    tipoExt?: string;
    loadData:Function;
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const ModalDetalhes = ({open, onClose, documento, tipoExt, loadData}:Props) => {
    const [motivo, setMotivo] = useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const config = useAppSelector(useConfigState);
    const [value, setValue] = useState(0);
    const appDispatch = useAppDispatch();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleConfirmar = () => {
        const status = "001";
        Swal.fire({
            title: `Deseja aprovar o documento?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        })
        .then(result => {
            if(!result.isConfirmed) return;
            const configuracoes = {
                params: {
                    status,
                    codigo: config.empresa.empresaCodigo,
                }
            }
            let doc = {
                numero: documento.codDocumento,
                tipo: documento.tipo,
                motivo: "",
            }
            appDispatch(configActions.setLoading({loading: true}))
            AprovacaoDocumentoService.send(doc, configuracoes)
                .then(() => {
                    toast.success("Documento aprovado com sucesso!");
                    loadData(1)
                    onClose();
                })
                .catch((err) => {
                    toastError(err, "Erro ao aprovar documento!");
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                })
        })
    }

    const handleBloquear = () => {
        const status = "006";
        Swal.fire({
            title: `Deseja bloquear o documento?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        })
        .then(result => {
            if(!result.isConfirmed) return;
            const configuracoes = {
                params: {
                    status,
                    codigo: config.empresa.empresaCodigo,
                }
            }
            let doc = {
                numero: documento.codDocumento,
                tipo: documento.tipo,
                motivo: "",
            }
            appDispatch(configActions.setLoading({loading: true}))
            AprovacaoDocumentoService.send(doc, configuracoes)
                .then(() => {
                    toast.success("Documento aprovado com sucesso!");
                    loadData(1)
                    onClose();
                })
                .catch((err) => {
                    toastError(err, "Erro ao aprovar documento!");
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                })
        })
    }

    const handleCancelar = () => {
        const status = "005";
        if(motivo.length > 0 && motivo.trim().length > 0) {
            Swal.fire({
                title: `Deseja rejeitar o documento?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: `Não`,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            })
            .then(result => {
                if(!result.isConfirmed) return;
                const configuracoes = {
                    params: {
                        status,
                        codigo: config.empresa.empresaCodigo,
                    }
                }
                let doc = {
                    numero: documento.codDocumento,
                    tipo: documento.tipo,
                    motivo,
                }
                appDispatch(configActions.setLoading({loading: true}))
                AprovacaoDocumentoService.send(doc, configuracoes)
                    .then(() => {
                        toast.success("Documento rejeitado com sucesso!");
                        loadData(1);
                        onClose();
                    })
                    .catch((err) => {
                        toastError(err, "Erro ao rejeitar documento!");
                    })
                    .finally(() => {
                        appDispatch(configActions.setLoading({loading: false}))
                    })
            })
        }
        else {
            toast.warn("Por favor, informe o motivo da rejeição!");
        }
    }

    if(!documento) return null;


    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"xl"}
                fullScreen={fullScreen}
                keepMounted
                onClose={() => onClose(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => onClose(false)}
                    sx={{
                        position: "absolute",
                        right: 30,
                        top: 10,
                        zIndex: 1,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid container >
                        <Box sx={{width: '100%'}}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} onChange={handleChange} aria-label="doc_tabs">
                                    <Tab label="Detalhes de Pedido"/>
                                    {documento.tipo === "PC" && <Tab label="Comparação de Cotações"/>}
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                       {documento.tipo === "PC" && <InfoPedidoCompra documento={documento} tipoExt={tipoExt}/>}
                                       {documento.tipo === "IP" && <InfoItemPedidoCompra documento={documento} tipoExt={tipoExt}/>}
                                       {documento.tipo === "SA" && <InfoSolicitacaoArmazem documento={documento} tipoExt={tipoExt}/>}
                                       {documento.tipo === "SC" && <InfoSolicitacaoCompra documento={documento} tipoExt={tipoExt}/>}
                                       {documento.tipo === "NF" && <InfoNotaFiscal documento={documento} tipoExt={tipoExt}/>}
                                        <Grid item xs={12} sx={{mt:2}}>
                                            <TextField
                                                label="Motivo"
                                                value={motivo}
                                                variant="outlined"
                                                margin="normal"
                                                multiline
                                                fullWidth
                                                rows={5}
                                                onChange={(e) => setMotivo(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{textAlign: {md: "right", sm: "center"},mt:2}}>
                                            <Tooltip title="Confirmar" placement="top">
                                                <Button variant={'contained'} onClick={() => handleConfirmar()} sx={{mr: {md: 1}}}
                                                        color={'success'}>
                                                    <CheckIcon sx={{mr: 1}}/> Aprovar
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Bloquear" placement="top">
                                                <Button variant={'contained'} color={'warning'} onClick={() => handleBloquear()} sx={{mr: {md: 1}}}>
                                                    <BlockIcon sx={{mr: 1}}/> Bloquear
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Rejeitar" placement="top">
                                                <Button variant={'contained'} color={'error'} onClick={() => handleCancelar()} sx={{mr: {md: 1}}}>
                                                    <DoDisturbOffIcon sx={{mr: 1}}/> Rejeitar
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Fechar" placement="top">
                                                <Button variant={'contained'} color={'primary'} onClick={() => onClose()}>
                                                    <HighlightOffIcon sx={{mr: 1}}/> Fechar
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                            </TabPanel>
                            {documento.tipo === "PC" &&
                                <TabPanel value={value} index={1}>
                                    <ComparaCotacao documento={documento} tipoExt={tipoExt}/>
                                </TabPanel>
                            }
                        </Box>

                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ModalDetalhes;