import {Link, Typography} from "@mui/material";

const Copyright = ({...props}) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" target={"_blank"} href="https://pontoini.com.br/">
                Ponto Ini Sistemas
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default Copyright;