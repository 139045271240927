import {Chip} from '@mui/material';
import moment from 'moment';
import {useCallback, useEffect, useState} from 'react';
import {CrmLead} from 'types/crm';

type Props = {
  dt: CrmLead;
}

export const ChipsAlerta = ({ dt }: Props) => {
  const [isClose, setIsClose] = useState(false);

  const handleAlert = useCallback(() => {
    // 20220929 <= 20220913 -15
      if (dt.taviso) {
        if (dt.alerta && moment(dt.alerta).format('YYYY-MM-DD') <= moment().add(dt.taviso, "days").format('YYYY-MM-DD')) {
          setIsClose(true);
      } else {
        setIsClose(false);
      }
    }
  }, [dt.alerta, dt.taviso])

  useEffect(() => {
    handleAlert();
  } , [handleAlert]);

  return (
    <Chip
      label={(!isClose) ? "Sem Alertas" : "Alerta: " + moment(dt.alerta).format('DD/MM/YYYY')}
      sx={{ background: (!isClose) ? '#d9d1d0' : '#eb8888' }}
    />
  )
}