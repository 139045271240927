import {Box, Button, Grid, MenuItem, Pagination, TextField, Tooltip} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {SolicitacaoMateriaisService} from 'services/SolicitacaoMateriaisService';
import {SolicitacaoMaterial} from 'types/solicitacao-materiais';
import {AxiosRequestConfig} from 'axios';
import {PAGE_SIZE_SELECT} from 'utils/constants';
import {SpringPage} from 'types/springpage';
import {toast} from 'react-toastify';
import ModalHistoricoSolicitacoes from 'components/solicitacao-material/modal/historicoSolicitacoes';
import CardSolicitacaoMateriais from 'components/solicitacao-material/cardSolicitacaoMaterial';
import MyCard from 'components/card';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from 'store';
import {configActions} from "store/config";

const ListSolicitacaoMateriais = () => {
  const config = useSelector((state: RootState) => state.config);
  const [solicitacoes, setSolicitacoes] = useState<SpringPage<SolicitacaoMaterial>>();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCount = '1'; //ESTADO DA PÁGINA ATUAL
  const pageSize = '10';// QUANTIDADE DE CONTEÚDO POR PÁGINA
  const appDispatch = useAppDispatch();
  const [showModal, setShowModal] = useState( false );

  const handleView = () => {
    setShowModal( true );
  };

  const handleClose = () => {
    setShowModal( false );
  };

  const navigate = useNavigate();

  const getSolicitacoes = useCallback( () => {
    appDispatch(configActions.setLoading({loading: true}))
    const params: AxiosRequestConfig = {
      params: {
        page: parseInt( searchParams.get( 'page' ) || pageCount )-1,
        size: parseInt(searchParams.get( 'size' ) || pageSize)
      }
    };
    SolicitacaoMateriaisService.findAllSolicitacoes( config.empresa.empresaCodigo, params )
      .then( ( res ) => {
        setSolicitacoes( res.data );
      } )
      .catch( err => {
        toast.error( err.response.data.message || 'Erro ao carregar as solicitações', { autoClose: 1000 } );
      } )
      .finally( () => {
        appDispatch(configActions.setLoading({loading: false}))
      } );

  }, [config.empresa.empresaCodigo, searchParams,appDispatch ] );

  useEffect( () => {
    getSolicitacoes();
  }, [getSolicitacoes] );

  return (
    <div>
      <MyCard title={'Solicitações de materiais'} action={() => navigate( '/painel/solicitacao-materiais/add' )}>

        {/* Botão Histórico de solicitações */}
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Tooltip title='Histórico de solicitações' placement='top'>
            <Button variant={'contained'} onClick={() => handleView()} color={'info'} >
              Histórico de solicitações
            </Button>
          </Tooltip>
        </Box>

        {/* Cards de solicitações */}
        {solicitacoes &&
          solicitacoes.content.map( ( dadosSolicitacao, i ) => (
            <Grid item xs={12} sx={{ alignItems: 'center', py: 1, my: 2 }} key={i}>
              <CardSolicitacaoMateriais
                dadosSolicitacao={dadosSolicitacao}
                getSolicitacoes={() => getSolicitacoes()}
                setSearchParams={setSearchParams}
                setIsLoading={(loading) => appDispatch(configActions.setLoading({loading}))}
              />
            </Grid>
          ) )}

        {/* Paginação */}
        {solicitacoes &&
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={10}>
              <Pagination
                count={solicitacoes && solicitacoes.totalPages} //Número total de solicitacoes / pageSize
                defaultPage={parseInt( pageCount )}
                page={parseInt( searchParams.get( 'page' ) || pageCount )}
                onChange={( value ) => {
                  setSearchParams( { page: `${value}`, size: searchParams.get( 'size' ) || pageSize } )
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                label='Registros por pagina'
                onChange={( e: any ) => {
                  setSearchParams( { page: "1", size: e.target.value } )
                }}
                size='small'
                value={searchParams.get( 'size' ) || pageSize}
                fullWidth>
                {PAGE_SIZE_SELECT.map( option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ) )}
              </TextField>
            </Grid>
          </Grid>
        }
      </MyCard>
      {showModal && (
        <ModalHistoricoSolicitacoes
          onClose={() => handleClose()}
          open={showModal}
        />
      )}
    </div>
  );
};

export default ListSolicitacaoMateriais;
