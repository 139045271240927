import {useForm} from "react-hook-form";
import {useCallback, useEffect} from "react";
import {AxiosResponse} from "axios";
import {UsuarioPerfil} from "types/user";
import {toastError} from "utils/toastError";
import {PerfilService} from "services/PerfilService";
import Loader from "components/loader";
import {Box, Button, TextField, useTheme} from "@mui/material";
import {Validadores} from "utils/validadores";
import SaveIcon from "@mui/icons-material/Save";
import MyCard from "components/card";
import {useAuthContext} from "contexts/AuthContext";
import {cnpj, cpf} from "cpf-cnpj-validator";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppSelector} from "store";
import {configActions} from "store/config";
import {hasAnyRoles, useAuthState} from "store/auth";


const Perfil = () => {
    const config = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();
    const {handleSubmit, formState: {errors}, getValues, reset, register} = useForm({mode: 'all'});
    const {updatePerfil} = useAuthContext();
    const appSelector = useAppSelector(useAuthState)
    const theme = useTheme();

    const loadUser = useCallback(() => {
        dispatch(configActions.setLoading({
            loading: true,
        }));
            PerfilService.getPerfil()
                .then((res : AxiosResponse<UsuarioPerfil>) => {
                    res.data.password = '';
                    if(res.data.cgc?.length === 11) res.data.cgc = cpf.format(res.data.cgc);
                    else if (res.data.cgc?.length === 14) res.data.cgc = cnpj.format(res.data.cgc);
                    reset(res.data);
                })
                .catch(err => {
                    toastError(err, 'Erro ao carregar perfil');
                })
                .finally(() => {
                    dispatch(configActions.setLoading({
                        loading: false,
                    }));
                });
    }, [dispatch, reset]);

    useEffect(() => {
        loadUser();
    }, [loadUser]);

    const onSubmit = (data: any) => {
        data.cgc = data.cgc.replace(/\D/g, '');
        dispatch(configActions.setLoading({
            loading: true,
        }));    
        updatePerfil(data).finally(() => {
            dispatch(configActions.setLoading({
                loading: false,
            }));
        });
    }

    return (
        <MyCard title={'Editar dados de perfil'}>
            {config.loading ? <Loader/> : (
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off" noValidate>
                    <TextField
                        {...register("nome")}
                        required
                        error={!!errors.nome}
                        label="Nome"
                        variant="outlined"
                        sx={{width: '100%'}}
                        helperText={errors.nome?.message.toString()}
                    />
                    <TextField
                        {...register("email")}
                        required
                        error={!!errors.email}
                        type={'email'}
                        label="E-mail"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.email?.message.toString()}
                    />
                    <TextField
                        {...register("cgc")}
                        required={!hasAnyRoles(appSelector, ['ROLE_ADMIN'])}
                        error={!!errors.cgc}
                        label={hasAnyRoles(appSelector,['ROLE_GRUPO_EMPRESA']) ? 'Raiz CNPJ' : "CPF/CNPJ"}
                        variant="outlined"
                        sx={{width: '100%', mt: 3, backgroundColor: hasAnyRoles(appSelector,['ROLE_ADMIN']) ? '' : theme.palette.grey[200]}}
                        inputProps={{ readOnly: !hasAnyRoles(appSelector,['ROLE_ADMIN'])}}
                        helperText={errors.cgc?.message.toString()}
                    />
                    <TextField
                        {...register("password", {validate: (value) => {
                                if(value) return Validadores.senha(value)
                            }})}
                        error={!!errors.password}
                        type={'password'}
                        label="Senha"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.password?.message.toString()}
                    />
                    <TextField
                        {...register("confirm_senha", {validate: (value) => {
                                const {password} = getValues();
                                if (value && password && value !== password) return 'As senhas não são iguais.'
                            }})}
                        error={!!errors.confirm_senha}
                        type={'password'}
                        label="Confirmar senha"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.confirm_senha?.message.toString()}
                    />


                    <Box sx={{mt: 3, textAlign: 'right'}}>
                        <Button variant={'contained'} color={'primary'} type={'submit'}>
                            <SaveIcon/> Salvar
                        </Button>
                    </Box>
                </Box>
            )}
        </MyCard>
    )
}

export default Perfil