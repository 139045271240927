import MyCard from "components/card";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Box, Button, Grid, Paper, TextField, Theme, Typography, useMediaQuery} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {useForm} from "react-hook-form";
import {ConfiguracoesService} from "services/ConfiguracoesService";
import DownloadIcon from "@mui/icons-material/Download";
import ExemploImportacao from "assets/files/exemplos.zip";
import ModalExemplos from "components/importacao/modal/exemplo";
import {useNavigate} from "react-router";
import {useAppSelector} from "store";
import {useDispatch} from "react-redux";
import {configActions, useConfigState} from "store/config";

type FormState = {
    file: FileList;
}

const Importacao = () => {
    const config = useAppSelector(useConfigState);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const formJson = useForm<FormState>();
    const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    // const formExcel = useForm<FormState>();
    // const formCsv = useForm<FormState>();

    useEffect(() => {
        if (downSM) {
            navigate('/painel')
            toast.warn("A importação só está disponível via desktop!")
        }
    }, [downSM, navigate]);


    const handleOpen = () => {
        setShowModal(true);
    };


    const handleClose = () => {
        setShowModal(false);
    };

    // const onSubmitExcel = ({file}: FormState) => {
    //     const formData = new FormData();
    //     formData.append("file", file[0]);
    //     const config = {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         },
    //         params: {
    //             codigo: parametros.codigoUnico,
    //         }
    //     }
    //     setLoading(true);
    //     ConfiguracoesService.importXls(formData, config)
    //         .then(() => {
    //             toast.success("Importação realizada com sucesso!");
    //         })
    //         .catch((err:any) => {
    //             toast.error(err.response?.data?.message ?? "Erro ao realizar a importação!");
    //         })
    //         .finally(() => setLoading(false))
    // }

    // const onSubmitCsv = ({file}: FormState) => {
    //     const formData = new FormData();
    //     formData.append("file", file[0]);
    //     const config = {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         },
    //         params: {
    //             codigo: parametros.codigoUnico,
    //         }
    //     }
    //     setLoading(true);
    //     ConfiguracoesService.importCsv(formData, config)
    //         .then(() => {
    //             toast.success("Importação realizada com sucesso!");
    //         })
    //         .catch((err:any) => {
    //             toast.error(err.response?.data?.message ?? "Erro ao realizar a importação!");
    //         })
    //         .finally(() => setLoading(false))
    // }

    const onSubmitJson = ({file}: FormState) => {
        if(!config.parametros || !config.parametros.codigoUnico) {
            toast.warn("Selecione o grupo para importar os dados!")
            return;
        }

        const formData = new FormData();
        formData.append("file", file[0]);
        const configuracoes = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
                codigo: config.parametros.codigoUnico,
            }
        }
       dispatch(configActions.setLoading({
            loading: true,
        }));
        ConfiguracoesService.importJson(formData, configuracoes)
            .then(() => {
                toast.success("Importação realizada com sucesso!");
            })
            .catch((err:any) => {
                toast.error(err.response?.data?.message ?? "Erro ao realizar a importação!");
            })
            .finally(() => dispatch(configActions.setLoading({
                loading: false,
            })))
    }

    return (
        <MyCard title={`Importação de usuários`}>
            <>
                    {/* <Grid item xs={12} sx={{mb: 3}}>
                        <Box component={Paper} sx={{width: "100%", mb: 2, p: 3}}>
                            <Box component="form" onSubmit={formExcel.handleSubmit(onSubmitExcel)} sx={{mt: 1}} autoComplete="off" encType={'multipart/form-data'}>
                                <Box sx={{mb: 3}}>
                                    <Typography fontWeight={700} fontSize={16}>Importar excel (.xls)</Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item md={10} xs={10}>
                                        <TextField
                                            {...formExcel.register("file", {required: "Campo obrigatório", validate: (value) => value[0].size < 1000000*100 || "O arquivo deve ter no máximo 100MB"})}
                                            required
                                            error={!!formExcel.formState.errors.file}
                                            type={"file"}
                                            label="Selecione o arquivo"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                accept: ".xlsx, .xls "
                                            }}
                                            helperText={formExcel.formState.errors.file?.message}
                                            InputLabelProps={{shrink: true}}
                                            // InputProps={{readOnly: !hasAnyRoles(["ROLE_ADMIN"])}}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={2}>
                                        <Button variant={'contained'} color={'primary'} type={'submit'} fullWidth sx={{height:"100%"}}>
                                            <FileUploadIcon/> Importar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={12} sx={{mb: 3}}>
                        <Box component={Paper} sx={{width: "100%", mb: 2, p: 3}}>
                            <Box component="form" onSubmit={formCsv.handleSubmit(onSubmitCsv)} sx={{mt: 1}} autoComplete="off" encType={'multipart/form-data'}>
                                <Box sx={{mb: 3}}>
                                    <Typography fontWeight={700} fontSize={16}>Importar excel (.csv)</Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item md={10} xs={10}>
                                        <TextField
                                            {...formCsv.register("file", {required: "Campo obrigatório", validate: (value) => value[0].size < 1000000*100 || "O arquivo deve ter no máximo 100MB"})}
                                            required
                                            error={!!formCsv.formState.errors.file}
                                            type={"file"}
                                            label="Selecione o arquivo"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                accept: ".csv "
                                            }}
                                            helperText={formCsv.formState.errors.file?.message}
                                            InputLabelProps={{shrink: true}}
                                            // InputProps={{readOnly: !hasAnyRoles(["ROLE_ADMIN"])}}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={2}>
                                        <Button variant={'contained'} color={'primary'} type={'submit'} fullWidth sx={{height:"100%"}}>
                                            <FileUploadIcon/> Importar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sx={{mb: 3}}>
                        <Box component={Paper} sx={{width: "100%", mb: 2, p: 3}}>
                            <Box component="form" onSubmit={formJson.handleSubmit(onSubmitJson)} sx={{mt: 1}} autoComplete="off" encType={'multipart/form-data'}>
                                <Box sx={{mb: 3}}>
                                    <Typography fontWeight={700} fontSize={16}>Importar json (.json)</Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item md={10} xs={10}>
                                        <TextField
                                            {...formJson.register("file", {required: "Campo obrigatório", validate: (value) => value[0].size < 1000000*100 || "O arquivo deve ter no máximo 100MB"})}
                                            required
                                            error={!!formJson.formState.errors.file}
                                            type={"file"}
                                            label="Selecione o arquivo"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                accept: ".json"
                                            }}
                                            helperText={formJson.formState.errors.file?.message}
                                            InputLabelProps={{shrink: true}}
                                            // InputProps={{readOnly: !hasAnyRoles(["ROLE_ADMIN"])}}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={2}>
                                        <Button variant={'contained'} color={'primary'} type={'submit'} fullWidth sx={{height:"100%"}}>
                                            <FileUploadIcon/> Importar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Button component={'a'} href={ExemploImportacao} download={"exemplos"} >
                            <DownloadIcon/> Download do modelo
                        </Button>
                        <Button variant={'contained'} color={'primary'} type={'submit'} onClick={() => handleOpen()}>
                            Exemplo
                        </Button>
                    </Grid>
                    
                    <ModalExemplos
                        onClose={handleClose}
                        showModal={showModal}
                    />
                    
                </>
        </MyCard>
    )
}

export default Importacao;