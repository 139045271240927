import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Slide,
    TextField, Theme,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {TransitionProps} from '@mui/material/transitions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Cadastros} from "types/cadastroExterno";
import {Acesso} from "types/auth";
import {sortModulos} from "utils/colaborador";
import Format from "utils/formatadores";
import {useForm} from "react-hook-form";
import {Validadores} from "utils/validadores";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {AxiosRequestConfig} from "axios";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {CadastroExternoService} from "services/CadastroExternoService";
import {toast} from "react-toastify";
import moment from "moment";
import {toastError} from 'utils/toastError';

type Props = {
    showModal: boolean;
    cadastro: Cadastros;
    onClose: () => void;
    findAllCadastros: () => void;
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const ModalDetalhesCadastro = ({showModal, cadastro, onClose, findAllCadastros}: Props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {handleSubmit, formState: {errors}, register} = useForm<Cadastros>({mode: 'all',});
    const sweetScreen = withReactContent(Swal);
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();


    const onSubmit = (data: Cadastros) => {
        const formData = {
            cpf: data.cpf.replace(/\D+/g, ''),
            cnpj: data.cnpj.replace(/\D+/g, ''),
            telefone: data.telefone.replace(/\D+/g, ''),
            email: data.email,
            nome: data.nome,
            razaoSocial: data.razaoSocial,
        }

        sweetScreen
            .fire({
                title: `Deseja aprovar o cadastro ?`,
                text: formData.nome,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Sim, aprovar cadastro!`,
                cancelButtonText: `Não`,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            })
            .then(result => {
                if (result.isConfirmed) {
                    appDispatch(configActions.setLoading({loading: true,}));
                    const configuracoes: AxiosRequestConfig = {
                        params: {
                            codigo: config.empresa.empresaCodigo,
                            status: 'APROVADO',
                        }
                    }
                    CadastroExternoService.updateStatus(cadastro.id, formData, configuracoes)
                        .then(res => {
                            if (res.data.aprovado) {
                                toast.success('Cadastro enviado com sucesso!');
                            } else {
                                toast.error('Erro ao aprovar cadastro! \n Entre em contato com o suporte!');
                            }
                        })
                        .catch(err => {
                            if(err.response.status === 503){
                                toast.warn(`${err.response.data.message || 'Erro ao enviar email !'}`);
                            }else{
                                toastError(err, "Erro ao salvar !")
                            }
                        })
                        .finally(() => {
                            appDispatch(configActions.setLoading({
                                loading: false,
                            }));
                            onClose();
                            findAllCadastros();
                        });
                }
            });
    }

    return (
        <Dialog
            open={showModal}
            onClose={onClose}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth={'xl'}
            fullScreen={fullScreen}
            keepMounted
        >
            <DialogContent>
                <Box sx={{mb: 3}}>
                    <Typography color='primary' sx={{fontSize: 16, fontWeight: 'bold'}}>
                        Permissões do usuário - {cadastro.nome}
                    </Typography>
                    <IconButton
                        aria-label='close'
                        onClick={() => onClose()}
                        sx={{
                            position: 'absolute',
                            right: 30,
                            top: 10,
                            zIndex: 1,
                            color: theme => theme.palette.grey[500]
                        }}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                    <Box sx={{mb: 2, mt: 3}}>
                        <Typography color={'primary'}>Informações obrigatórias</Typography>
                        <Divider/>
                    </Box>
                    <Grid container alignItems={'center'} spacing={2} sx={{mb: 2}}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register('nome')}
                                error={!!errors.nome}
                                helperText={errors.nome?.message}
                                required
                                label='Nome'
                                variant='outlined'
                                margin='normal'
                                defaultValue={cadastro.nome}
                                fullWidth
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register('email', {validate: (value) => Validadores.email(value)})}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                required
                                defaultValue={cadastro.email}
                                label='Email'
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register('cpf', {validate: (value) => Validadores.cgc(value)})}
                                error={!!errors.cpf}
                                helperText={errors.cpf?.message}
                                required
                                defaultValue={Format.cpf(cadastro.cpf)}
                                label='CPF'
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                {...register('telefone')}
                                error={!!errors.telefone}
                                helperText={errors.telefone?.message}
                                required
                                defaultValue={Format.telefone(cadastro.telefone)}
                                label='Nº Telefone'
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                InputLabelProps={{shrink: true}}
                            />
                        </Grid>
                        {cadastro.razaoSocial &&
                            <Grid item xs={12} md={6}>
                                <TextField
                                    {...register('razaoSocial')}
                                    error={!!errors.razaoSocial}
                                    helperText={errors.razaoSocial?.message}
                                    defaultValue={cadastro.razaoSocial}
                                    label='Razão Social'
                                    variant='outlined'
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    required={true}
                                />
                            </Grid>
                        }
                        {cadastro.cnpj &&
                            <Grid item xs={12} md={6}>
                                <TextField
                                    {...register('cnpj', {validate: (value) => Validadores.cgc(value)})}
                                    error={!!errors.cnpj}
                                    helperText={errors.cnpj?.message}
                                    defaultValue={Format.cgc(cadastro.cnpj)}
                                    label={cadastro.cnpj.length > 11 ? 'CNPJ' : 'CPF'}
                                    variant='outlined'
                                    margin='normal'
                                    fullWidth
                                    required={true}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                        }
                    </Grid>
                    {cadastro.campos.length > 0 &&
                        <>
                            <Box sx={{mb: 2, mt: 3}}>
                                <Typography color={'primary'}>Informações adicionais</Typography>
                                <Divider/>
                            </Box>
                            <Grid container spacing={1}>
                                {cadastro.campos.map((campo, i) => (
                                    <Grid item xs={4} key={i}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            label={campo.descricao}
                                            variant='outlined'
                                            value={campo.tipo === "datetime-local" ? moment(campo.resposta).format("DD/MM/YYYY") : campo.resposta}
                                            margin='normal'
                                            fullWidth
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    }
                    <Box sx={{mb: 2, mt: 3}}>
                        <Typography color={'primary'}>Permissões do usuário</Typography>
                        <Divider/>
                    </Box>
                    {cadastro.acessos.map((acessoM: Acesso, i) => (
                        acessoM.modulos.length > 0 &&
                        <Accordion sx={{width: "100%"}} key={i}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography sx={{fontSize: 16, fontWeight: 600}}>{acessoM.empresaNome}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {acessoM.modulos.sort(sortModulos).map((mod, i) => (
                                        <Grid item xs={12} md={6} lg={4}
                                              sx={{border: 1, borderColor: (t) => t.palette.grey[200], p: 2}}
                                              key={i}>
                                            <FormControlLabel
                                                key={mod.nome}
                                                label={mod.descricao}
                                                sx={{fontWeight: 600}}
                                                control={
                                                    <Checkbox
                                                        readOnly
                                                        name={mod.nome}
                                                        checked={!!cadastro.acessos?.find((acesso) => acesso.modulos.find((modulo) => modulo.nome === mod.nome))}
                                                    />
                                                }
                                            />
                                            {mod.permissoes.length > 0 && (
                                                <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                                    {mod.permissoes.map((per, i) => (
                                                        <FormControlLabel
                                                            key={i}
                                                            label={per.nome}
                                                            control={
                                                                <Checkbox
                                                                    readOnly
                                                                    name={per.nome}
                                                                    checked={!!cadastro.acessos?.find((acesso) => acesso.modulos.find((modulo) => modulo.permissoes.find((permissao) => permissao === per)))}
                                                                />
                                                            }
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        </Grid>
                                    ))}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Grid container spacing={2} sx={{mt: 2}}>
                        <Grid item xs={6} sx={{textAlign: 'start'}}>
                            <Typography sx={{color: (theme: Theme) => theme.palette.error.main}} >Obs. 1: Apenas as informações obrigatórias podem ser editadas.</Typography>
                            <Typography sx={{color: (theme: Theme) => theme.palette.error.main}} >Obs. 2: Informações adicionais não serão salvas na criação de usuário, servindo somente para avaliação do aprovador.</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'end'}}>
                            <Tooltip title="Confirmar" placement="top">
                                <Button variant={'contained'} color={'success'} type={'submit'}>
                                    <CheckIcon sx={{mr: 1}}/> Aprovar
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default ModalDetalhesCadastro