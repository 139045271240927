import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/usuarios";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};

export const GrupoEmpresaService =  {
    findAll: (params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/grupos`, paramsGeral);
    },
    findById: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/grupos/${id}`, paramsGeral);
    },
    create: (data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/grupos`, data, paramsGeral);
    },
    update: (id: string, data: any, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.put(`${URL}/grupos/${id}`, data, paramsGeral);
    },
    delete: (id: string, params?: AxiosRequestConfig) => {
        paramsGeral = {...paramsGeral, ...params};
        return API.delete(`${URL}/grupos/${id}`, paramsGeral);
    }
}