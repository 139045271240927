import {Box, Card, CardContent, Grid, Typography, useTheme} from "@mui/material";
import {ChipsAlerta} from 'components/crm/chipsAlerta';
import {OpenDetails} from 'components/crm/openDetails';
import {Schedule} from 'components/crm/schedule';
import {useDrag} from "react-dnd";
import {CrmLead} from 'types/crm';

type Props = {
  dt: CrmLead;
}

export const Board = ({ dt }: Props) => {
  const theme = useTheme()
  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: 'card',
    item: { status: dt.status, codigo: dt.codigo },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(), 
    })
  });

  return (
    <div ref={drag}>
      <Card
        ref={dragPreview}
        role={"handle"}
        sx={{
          opacity: isDragging ? 0.5 : 1,
          margin: theme.spacing(2),
          marginBottom: 2,
          borderLeft: (dt.status === '1') ? '5px solid #000000' : 
                      (dt.status === '2') ? '5px solid #dede73' :
                      (dt.status === '3') ? '5px solid #dede73' :
                      (dt.status === '4') ? '5px solid #dede73' :
                      (dt.status === '5') ? '5px solid #4ecf5d' : 
                                            '5px solid #cf4e4e' }
        }
        variant="outlined"
      >
        <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
          }}
        >
          <CardContent sx={{
                flex: '1 0 auto',
                paddingBottom: theme.spacing(1)
            }}
          >
            <Typography component="h5" variant="h6" fontWeight={900}>
              {dt.nome}
            </Typography>
              
            <Grid item xs={12}>
              <Box mt={1}>
                <Typography variant='body2' fontWeight={500}>Codigo:  {dt.codigo}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' fontWeight={500}>Email:  {dt.email}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' fontWeight={500}>Telefone:  {dt.telefone}</Typography>
              </Box>
              <Box>
                <Typography variant='body2' fontWeight={500}>Ultima Compra:  {dt.ultiCom}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: theme.spacing(1)
              }}
            >
              <ChipsAlerta dt={dt}/>
              <OpenDetails dt={dt} />
              <Schedule dt={dt} />
            </Grid>
          </CardContent>
        </div>
      </Card>
    </div>
  )
}