import jwtDecode from "jwt-decode";
import {createContext, ReactNode, useContext, useEffect} from "react";
import {toast} from "react-toastify";
import {Acesso, TokenData} from "types/auth";
import {AuthService} from "services/AuthService";
import {toastError} from "utils/toastError";
import {PerfilService} from "services/PerfilService";
import Swal from "sweetalert2";
import {useTheme} from "@mui/material";
import {useAppDispatch, useAppSelector} from "store";
import {authActions, useAuthState, verifyAuthentication} from "store/auth";
import {useConfigContext} from "contexts/ConfigContext";

type Props = {
    children: ReactNode;
}

type LoginState = {
    username: string;
    empresa: string;
    password: string;
}


export type AuthContext ={
    signIn: (data: LoginState) => Promise<void>;
    updatePerfil: (data: any) => Promise<void>;
}


const AuthContextType = createContext<AuthContext>({} as AuthContext);


const AuthProvider = ({ children } : Props) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const selector = useAppSelector(useAuthState);
    const {getConfig} = useConfigContext();

    useEffect(() => {
        if(!verifyAuthentication(selector)){
            dispatch(authActions.signOut());
        }
    }, [dispatch, selector]);


    const signIn = async ({username, password, empresa}:LoginState) => {
        username = `${empresa}${username}`;
        await AuthService.login({username, password})
            .then(async (res:any)=>{
                dispatch(authActions.signIn({authData: res.data}));
                const jwtDecoded = jwtDecode(res.data.access_token) as TokenData;
                if(jwtDecoded.authorities.includes('ROLE_GRUPO_EMPRESA')){
                    await getConfig(jwtDecoded.username.substring(0,4));
                }
                if(jwtDecoded.authorities.includes('ROLE_COLABORADOR')){
                    let status = false;
                    jwtDecoded.acessos.forEach((acesso: Acesso) => {
                        if (acesso.empresaCodigo.substring(0,4) === empresa) {
                            if (acesso.status) {
                                status = true;
                            }
                        }
                    })
                    if(status){
                        await getConfig(empresa);
                    }else{
                        dispatch(authActions.signOut());
                        toast.error('Codigo incorreto ou acesso bloqueado!');
                    }
                }
            })
            .catch((e) => {
                toastError(e, "Erro ao realizar login");
            });
    }





    const updatePerfil = async (data: any) => {
        PerfilService.postPerfil(data)
            .then(async (res) => {
                dispatch(authActions.updateName({name: res.data.nome}));
                await Swal.fire({
                    title: 'Salvo com sucesso !',
                    icon: 'success',
                    confirmButtonColor: theme.palette.primary.main,
                })
            })
            .catch(async (err) => {
                await Swal.fire({
                    title: 'Erro ao salvar !',
                    icon: 'error',
                    html: err?.response?.data?.message || 'Erro desocnhecido ao salvar !',
                    confirmButtonColor: theme.palette.primary.main,
                })
            })
    }


    return (
        <AuthContextType.Provider value={{updatePerfil, signIn}}>
            {children}
        </AuthContextType.Provider>
    )
}

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContextType);