import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {ReactNode} from "react";
import {useMatch, useNavigate, useResolvedPath} from "react-router-dom";
import {Role} from "types/auth";
import {useMediaQuery, useTheme} from "@mui/material";
import {useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {hasAnyRoles, hasModuloPermission, useAuthState} from "store/auth";
import {useDispatch} from "react-redux";

type Props = {
    icon: ReactNode;
    text: string;
    link: string;
    modulo?: string;
    permissao?: string;
    role: Role[];
}


const MenuItem = ({icon, text, link, role, modulo, permissao} : Props) => {
    const config = useAppSelector(useConfigState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let resolved = useResolvedPath(link);
    let match = useMatch({ path: resolved.pathname, end: true});
    const appSelector = useAppSelector(useAuthState);
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down("sm"));

    if(role){
        if(!hasAnyRoles(appSelector, role)){
            return <></>;
        }
    }

    if(modulo){
        if(!hasModuloPermission(appSelector, config, modulo)){
            return <></>;
        }
    }

    if(permissao){
        if(!hasModuloPermission(appSelector, config,  modulo, permissao)){
            return <></>;
        }
    }



    return (
        <ListItem disablePadding sx={{display: "block", textDecoration: 'ImageListItem'}} onClick={() => {
            if(sm) dispatch(configActions.setOpenMenu({
                openMenu: false,
            }));
            navigate(link)
        }} >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: config.openMenu ? "initial" : "center",
                    px: 2.5,
                }}
                selected={!!match}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: config.openMenu ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} sx={{opacity: config.openMenu ? 1 : 0}} />
            </ListItemButton>
        </ListItem>
    )
}


export default MenuItem;