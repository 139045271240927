import ProfileDashboard from "components/dashboard/profileDashboard";
import AdmDashboard from "components/dashboard/admDashboard";
import {useAppSelector} from "store";
import {hasAnyRoles, useAuthState} from "store/auth";

const Home = () => {
    const appSelector = useAppSelector(useAuthState)

  return (
    <>
      {/*  ---------------------------------------------- PROFILE ------------------------------------------------------- */}
      <ProfileDashboard />

      {/*  ------------------------------------------- ADMINISTRADOR ---------------------------------------------------- */}
      {hasAnyRoles(appSelector, ["ROLE_ADMIN", "ROLE_GRUPO_EMPRESA", "ROLE_EMPRESA"] ) && (
        <AdmDashboard />
      )}
    </>
  );
};

export default Home;
