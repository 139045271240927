import {Button, styled} from "@mui/material";

const ButtonTopBar = styled(Button)(({ theme }) => ({
  flexGrow: 1,
  color: "#000000",
  marginRight: 5,
  fontSize: "0.8rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.6rem",
  },
}));

export default ButtonTopBar;

