import {AxiosRequestConfig} from "axios"
import {API} from "services/Api";

const URL = "/danfes";

let paramsGeral: AxiosRequestConfig = {
  withCredentials: true,
};

export const DanfesService = {
  findAll: (codigo: string, codigoEmpresa: string, params?: AxiosRequestConfig) => {
    paramsGeral = { ...paramsGeral, ...params };
    paramsGeral.params = { ...paramsGeral.params, codigo, codigoEmpresa };
    paramsGeral = { ...paramsGeral, ...params };
    return API.get(URL, paramsGeral);
  },
  getFile: (id: string, codigo: string, tipo: number, params?: AxiosRequestConfig) => {
    if (params) {
      params = { ...params, responseType: 'blob', withCredentials: true }
      params.params = { ...params?.params, codigo, tipo }
    }
    else {
      params = { params: { codigo, tipo }, responseType: 'blob', withCredentials: true }
    }
    return API.get(`${URL}/file/${id}`, params);
  },
}