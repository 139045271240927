import RefreshIcon from '@mui/icons-material/Refresh';
import {Button, Chip, Grid, MenuItem, Pagination, Paper, TextField, Typography} from "@mui/material";
import {yellow} from "@mui/material/colors";
import {AxiosRequestConfig} from "axios";
import ModalAgendamentoDetails from "components/agendamentos-entregas/modals/details";
import ButtonCancel from "components/buttons/cancel";
import ButtonView from "components/buttons/view";
import MyCard from "components/card";
import {useAgendamentoEntrega} from "contexts/AgendamentoEntregaContext";
import moment from "moment";
import {useCallback, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {AgendamentoService} from "services/AgendamentoService";
import {useAppDispatch, useAppSelector} from 'store';
import {configActions, useConfigState} from 'store/config';
import Swal from "sweetalert2";
import {Agendamento} from "types/agendamentos";
import {SpringPage} from "types/springpage";
import {PAGE_SIZE_SELECT} from "utils/constants";
import {toastError} from "utils/toastError";
import {hasModuloPermission, useAuthState} from "store/auth";

const ListAgendamentos = () => {
    const [page, setPage] = useState<SpringPage<Agendamento>>();
    const navigate = useNavigate();
    const config = useAppSelector(useConfigState);
    const [show, setShow] = useState( false );
    const [agendamento, setAgendamento] = useState<Agendamento>();
    const { parametros: { diasToCancel } } = useAgendamentoEntrega();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultPageSize = '10';
    const defaultPage = '1';
    const appSelector = useAppSelector(useAuthState);
    const appDispatch = useAppDispatch();

    const loadAgendamentos = useCallback( () => {
        const configuracoes: AxiosRequestConfig = {
            params: {
                page: parseInt( searchParams.get( 'page' ) || defaultPage ) - 1,
                size: searchParams.get( 'size' ) || defaultPageSize,
            }
        }
        appDispatch(configActions.setLoading({loading: true}))
        AgendamentoService.findAllAgendamentos( config.empresa.empresaCodigo, configuracoes )
            .then( ( res ) => {
                setPage( res.data );
            } ).catch( ( err ) => {
                toastError( err, "Erro ao buscar agendamentos" )
            } )
            .finally( () => appDispatch(configActions.setLoading({loading: false})) );
    }, [appDispatch, config.empresa.empresaCodigo, searchParams] );

    useEffect( () => {
        loadAgendamentos();
    }, [loadAgendamentos] );

    const handleDelete = ( id: any ) => {
        Swal.fire( {
            title: `Deseja realmente cancelar este agendamento?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, cancelar agendamento!`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        } ).then( ( result ) => {
            if ( result.isConfirmed ) {
                appDispatch(configActions.setLoading({loading: true}))
                AgendamentoService.delete( id, config.empresa.empresaCodigo )
                    .then( ( res ) => {
                        toast.success( "Agendamenmto cancelado com sucesso!" );
                        loadAgendamentos();
                    } )
                    .catch( ( err ) => {
                        toast.error( err.response.data.message );
                    } )
                    .finally( () => {
                        appDispatch(configActions.setLoading({loading: false}))
                    } );
            }
        } )
    }

    const reenviarPedidos =  () => {
            Swal.fire( {
            title: `Deseja realmente reenviar os pedidos?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: `Sim, reenviar pedidos!`,
            cancelButtonText: `Não`,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        } ).then( ( result ) => {
            if ( result.isConfirmed ) {
                appDispatch(configActions.setLoading({loading: true}))
                AgendamentoService.reenviarPedidos( config.empresa.empresaCodigo )
                    .then( ( res ) => {
                        toast.success( "Pedidos reenviados com sucesso!" );
                        loadAgendamentos();
                    } )
                    .catch( ( err ) => {
                        toast.warn( err.response.data.message );
                    } )
                    .finally( () => {
                        appDispatch(configActions.setLoading({loading: false}))
                    } );
            }
        } )
    }

    const handleView = ( agendamento: Agendamento ) => {
        setAgendamento( agendamento );
        setShow( true );
    }

    const cancelamentoIsPosible = ( agendamento: Agendamento ) => {
        const dataAtual = moment();
        const dataCarregamento = moment( agendamento.dataEntrega );
        const dias = moment.duration( dataCarregamento.diff( dataAtual ) ).asDays();
        if ( agendamento.status === 'CANCELADO' ) return false;
        return dias >= diasToCancel;
    };


    return (
        <MyCard
            title={'Lista de agendamentos'}
            action={
                hasModuloPermission(appSelector, config, "AGENDAMENTO_ENTREGA", "FORNECEDOR") ? () => {
                    navigate( '/painel/agendamentos-de-entregas/add' )
                } : undefined
            }
            buttonText={hasModuloPermission(appSelector, config, "AGENDAMENTO_ENTREGA", "FORNECEDOR") ? "Agendar" : ""}
        >
            {
                hasModuloPermission(appSelector, config, "AGENDAMENTO_ENTREGA", "FORNECEDOR") && (
                    <Grid container sx={{mb:2, justifyContent: "space-around"}}>
                        <Grid item xs={12}>
                            <Button fullWidth variant={"contained"} onClick={reenviarPedidos}>
                                <RefreshIcon sx={{ mr: 1 }} />
                                Reenviar pedidos pendentes
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
            {page && page.content.map( ( agendamento, i ) => (
                <Grid key={i} component={Paper} container sx={{ mb: 2, p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item container xs={12} sm={10} md={10} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item xs={6} sm={6} md={4.5}>
                            <Typography sx={{ fontSize: 14, lineHeight: 2 }}><b>Data agendada:</b> {moment( agendamento.dataEntrega ).format( "DD/MM/YYYY" )} {agendamento.horaEntrega}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <Typography sx={{ fontSize: 14, lineHeight: 2 }}><b>Placa prevista:</b> {agendamento.placaPrevista}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3.5}>
                            <Typography sx={{ fontSize: 14, lineHeight: 2 }}><b>Status: </b>
                                {agendamento.status === "CONFIRMADO" && <Chip component={"span"} label="Confirmado" color="success" size={"small"} />}
                                {agendamento.status === "PENDENTE" && <Chip label="Pendente" component={"span"} sx={{ backgroundColor: yellow[500] }} size={"small"} />}
                                {agendamento.status === "CANCELADO" && <Chip component={"span"} label="Cancelado" color="error" size={"small"} />}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sm={2} md={2} sx={{ mt: { xs: 1, md: 0 }, gap: 1 }}>
                        <Grid item>
                            <ButtonView handleView={() => handleView( agendamento )} />
                        </Grid>
                        {cancelamentoIsPosible( agendamento ) &&
                            <Grid item>
                                <ButtonCancel handleCancel={() => handleDelete( agendamento.id )} />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            ) )}
            {page && (
                <Grid container item xs={12}>
                    <Grid item md={6} xs={6}>
                        <Pagination
                            count={page?.totalPages}
                            defaultPage={parseInt( defaultPage )}
                            page={parseInt( searchParams.get( 'page' ) || defaultPage )}
                            onChange={( event, value ) => {
                                setSearchParams( { page: `${value}`, size: searchParams.get( 'size' ) || defaultPageSize } )
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Registros por pagina"
                            onChange={( e: any ) => {
                                setSearchParams( { page: "1", size: e.target.value } )
                            }}
                            size="small"
                            value={searchParams.get( 'size' ) || defaultPageSize}
                            sx={{ minWidth: 150 }}
                        >
                            {PAGE_SIZE_SELECT.map( ( option ) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ) )}
                        </TextField>
                    </Grid>
                </Grid>
            )}
            {show && agendamento && <ModalAgendamentoDetails setShow={setShow} show={show} agendamento={agendamento} />}
        </MyCard>
    )
}


export default ListAgendamentos;