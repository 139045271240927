import AuthLayout from 'components/authLayout';
import CadastroLayout from 'components/cadastroLayout';
import PainelLayout from 'components/painelLayout';
import AgendamentoEntregaProvider from 'contexts/AgendamentoEntregaContext';
import CrmProvider from 'contexts/CrmContext';
import EsqueciASenha from 'pages/auth/esqueciSenha';
import Login from 'pages/auth/login';
import TrocarSenha from 'pages/auth/trocarSenha';
import HasPermissao from 'pages/painel';
import AddAdministradores from 'pages/painel/admins/add';
import EditAdministradores from 'pages/painel/admins/edit';
import ListAdmins from 'pages/painel/admins/list';
import AddAgendamentos from 'pages/painel/agendamentos/add';
import AddExcecoesDatas from 'pages/painel/agendamentos/excecoes-datas/add';
import ListExcecoesDatas from 'pages/painel/agendamentos/excecoes-datas/list';
import ListAgendamentos from 'pages/painel/agendamentos/list';
import ListAprovacaoCadastro from 'pages/painel/aprovacao-cadastro/list';
import ListDocumentos from 'pages/painel/aprovacao-documento/list';
import ListBoletos from 'pages/painel/boletos/list';
import AddFormularios from 'pages/painel/formularios/add';
import Formularios from 'pages/painel/formularios/list';
import AddColaboradores from 'pages/painel/colaboradores/add';
import EditColaboradores from 'pages/painel/colaboradores/edit';
import ListColaboradores from 'pages/painel/colaboradores/list';
import EditConfiguracoes from 'pages/painel/configuracoes';
import ListCotacoes from 'pages/painel/cotacoes/list';
import Crm from 'pages/painel/crm';
import ImprimirDanfe from 'pages/painel/danfes/imprimir';
import ListDanfes from 'pages/painel/danfes/list';
import AddEmpresas from 'pages/painel/empresas/add';
import EditEmpresas from 'pages/painel/empresas/edit';
import ListEmpresas from 'pages/painel/empresas/list';
import FuncionarioEpi from 'pages/painel/funcionario-epi';
import AddGrupoEmpresa from 'pages/painel/gruposEmpresa/add';
import EditGrupoEmpresa from 'pages/painel/gruposEmpresa/edit';
import ListGruposEmpresa from 'pages/painel/gruposEmpresa/list';
import Home from 'pages/painel/home';
import Importacao from 'pages/painel/importacao';
import AddOrcamentos from 'pages/painel/orcamentos/add';
import EditOrcamentos from 'pages/painel/orcamentos/edit';
import ImprimirOrcamento from 'pages/painel/orcamentos/imprimir';
import ListOrcamentos from 'pages/painel/orcamentos/list';
import Perfil from "pages/painel/perfil";
import ListPermissoes from 'pages/painel/permissoes/list';
import Relatorios from "pages/painel/relatorios";
import AddMateriais from 'pages/painel/solicitacao-materiais/add';
import EditSolicitacoesCompras from 'pages/painel/solicitacao-materiais/edit';
import ImprimirSolicitacaoMateriais from 'pages/painel/solicitacao-materiais/imprimir';
import ListSolicitacaoMateriais from 'pages/painel/solicitacao-materiais/list';
import TelaCadastroExterno from 'pages/telaCadastroExterno';
import TelaErro404Cadastro from 'pages/telaErro404Cadastro';
import {Navigate, Route, Routes} from 'react-router-dom';
import Tela404 from "pages/painel/tela404";

const Rotas = () => {
    return (
        <Routes>
            <Route path="cadastro" element={<CadastroLayout/>}>
                <Route index element={<Navigate to={"/cadastro/nao-encontrada"}/>}/>
                <Route path=":id" element={<TelaCadastroExterno/>}/>
                <Route path="nao-encontrada" element={<TelaErro404Cadastro />}/>
            </Route>
            <Route index element={<Navigate to={'/auth/login'}/>}/>
            <Route path="login" element={<Navigate to={'/auth/login'}/>}/>
            <Route path="auth" element={<AuthLayout/>}>
                <Route index element={<Navigate to={'/auth/login'}/>}/>
                <Route path="login">
                    <Route index element={<Login/>}/>
                    <Route path=":empresa" element={<Login/>}/>
                </Route>
                <Route path="esqueci-a-senha" element={<EsqueciASenha/>}/>
                <Route path="trocar-senha">
                    <Route index element={<Navigate to="/login" replace/>}/>
                    <Route path=":token" element={<TrocarSenha/>}/>
                </Route>
            </Route>
            <Route path="painel" element={<PainelLayout/>}>
                <Route index element={<Home/>}/>
                <Route path="perfil" element={<Perfil/>}/>
                <Route path="admins" element={<HasPermissao role={['ROLE_ADMIN']}/>}>
                    <Route index element={<ListAdmins/>}/>
                    <Route path="add" element={<AddAdministradores/>}/>
                    <Route path="edit" >
                        <Route index element={<Navigate to={'/painel/admins'}/>}/>
                        <Route path=":id" element={<EditAdministradores/>}/>
                    </Route>
                </Route>
                <Route path="permissoes" element={<HasPermissao role={['ROLE_ADMIN']}/>}>
                    <Route index element={<ListPermissoes/>}/>
                </Route>
                <Route path="grupos-empresas" element={<HasPermissao role={['ROLE_ADMIN']}/>}>
                    <Route index element={<ListGruposEmpresa/>}/>
                    <Route path="add" element={<AddGrupoEmpresa/>}/>
                    <Route path="edit" >
                        <Route index element={<Navigate to={'/painel/grupos-empresas'}/>}/>
                        <Route path=":id" element={<EditGrupoEmpresa/>}/>
                    </Route>
                </Route>
                <Route path="empresas" element={<HasPermissao role={['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']}/>}>
                    <Route index element={<ListEmpresas/>}/>
                    <Route path="add" element={<HasPermissao role={['ROLE_ADMIN']}/>}>
                        <Route index element={<AddEmpresas/>}/>
                    </Route>
                    <Route path="edit" element={<HasPermissao role={['ROLE_ADMIN']}/>}>
                        <Route index element={<Navigate to={'/painel/empresas'}/>}/>
                        <Route path=":id" element={<EditEmpresas/>}/>
                    </Route>
                </Route>
                <Route path="colaboradores" element={<HasPermissao role={['ROLE_ADMIN', 'ROLE_EMPRESA', 'ROLE_GRUPO_EMPRESA']}/>}>
                    <Route index element={<ListColaboradores/>}/>
                    <Route path="add" element={<AddColaboradores/>}/>
                    <Route path="edit" >
                      <Route index element={<Navigate to={'/painel/colaboradores'}/>}/>
                      <Route path=":id" element={<EditColaboradores/>}/>
                    </Route>
                </Route>
                <Route path="configuracoes" element={<HasPermissao role={['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']}/>}>
                    <Route index element={<EditConfiguracoes/>}/>
                </Route>
                <Route path="relatorios" element={<HasPermissao role={['ROLE_ADMIN']}/>} >
                    <Route index element={<Relatorios/>}/>
                </Route>
                <Route path="importacao" element={<HasPermissao role={['ROLE_ADMIN', 'ROLE_GRUPO_EMPRESA']}/>} >
                    <Route index element={<Importacao/>}/>
                </Route>
                <Route path="orcamentos" element={<HasPermissao role={['ROLE_COLABORADOR']} modulo={'ORCAMENTO'}/>}>
                    <Route index element={<ListOrcamentos/>}/>
                    <Route path="add" element={<AddOrcamentos/>}/>
                    <Route path="edit" >
                        <Route index element={<Navigate to={'/painel/orcamentos'}/>}/>
                        <Route path=":id" element={<EditOrcamentos/>}/>
                    </Route>
                    <Route path="imprimir" >
                      <Route index element={<Navigate to={'/painel/orcamentos'}/>}/>
                      <Route path=":id" element={<ImprimirOrcamento/>}/>
                    </Route>
                </Route>
                <Route path="aprovacao-documentos" element={<HasPermissao role={['ROLE_COLABORADOR']} modulo={'APROVACAO_DOCUMENTO'} />}>
                    <Route index element={<ListDocumentos/>}/>
                </Route>
                <Route path="boletos" element={<HasPermissao role={['ROLE_COLABORADOR']}  modulo={'FATURAMENTO'} permissao='FATURAS'/>}>
                    <Route index element={<ListBoletos/>}/>
                </Route>
                <Route path="funcionario-epi" element={<HasPermissao role={['ROLE_COLABORADOR']} modulo={'FUNCIONARIO_EPI'} permissao='GERENTE,FUNCIONARIO' />}>
                    <Route index element={<FuncionarioEpi/>}/>
                </Route>
                <Route path="danfes" element={<HasPermissao role={['ROLE_COLABORADOR']} modulo={'FATURAMENTO'} permissao='DANFES'/>}>
                    <Route index element={<ListDanfes/>}/>
                    <Route path="imprimir" >
                        <Route index element={<Navigate to={'/painel/danfes'}/>}/>
                        <Route path=":id" element={<ImprimirDanfe/>}/>
                    </Route>
                </Route>
                <Route path="cotacoes" element={<HasPermissao role={['ROLE_COLABORADOR']} modulo={'COTACAO'}/>}>
                    <Route index element={<ListCotacoes/>}/>
                </Route>
                <Route path="formularios" element={<HasPermissao role={['ROLE_GRUPO_EMPRESA']} modulo={'APROVACAO_CADASTRO'}/>}>
                    <Route index element={<Formularios/>}/>
                    <Route path="add" element={<AddFormularios/>}/>
                </Route>
                <Route path="aprovacao-cadastro" element={<HasPermissao role={['ROLE_GRUPO_EMPRESA', 'ROLE_COLABORADOR']} modulo={'APROVACAO_CADASTRO'}/>}>
                    <Route index element={<ListAprovacaoCadastro/>}/>
                </Route>      
                <Route path="agendamentos-de-entregas" element={<AgendamentoEntregaProvider><HasPermissao role={['ROLE_GRUPO_EMPRESA','ROLE_COLABORADOR']} modulo={'AGENDAMENTO_ENTREGA'}/></AgendamentoEntregaProvider>}>
                    <Route index element={<ListAgendamentos/>}/>
                    <Route path="add" element={<HasPermissao role={['ROLE_COLABORADOR']} modulo={'AGENDAMENTO_ENTREGA'} permissao={"FORNECEDOR"}/>}>
                        <Route index element={<AddAgendamentos/>}/>
                    </Route>
                    <Route path="excecoes-datas" element={<HasPermissao role={['ROLE_GRUPO_EMPRESA', 'ROLE_EMPRESA']} modulo={'AGENDAMENTO_ENTREGA'} />}>
                        <Route index element={<ListExcecoesDatas/>}/>
                        <Route path="add" element={<AddExcecoesDatas/>}/>
                    </Route>
                </Route>
                <Route path="crm" element={<CrmProvider><HasPermissao role={['ROLE_COLABORADOR']} modulo={'CRM'}/></CrmProvider>}>
                    <Route index element={<Crm/>}/>
                </Route>
                <Route path="solicitacao-materiais" element={<HasPermissao role={['ROLE_COLABORADOR']} modulo={'SOLICITACAO_COMPRA'}/>}>
                    <Route index element={<ListSolicitacaoMateriais/>}/>
                    <Route path="add" element={<AddMateriais/>}/>
                    <Route path="edit" >
                        <Route index element={<Navigate to={'/painel/solicitacao-materiais'}/>}/>
                        <Route path=":id" element={<EditSolicitacoesCompras/>}/>
                    </Route>
                    <Route path="imprimir" >
                        <Route index element={<Navigate to={'/painel/solicitacao-materiais'}/>}/>
                        <Route path=":id" element={<ImprimirSolicitacaoMateriais/>}/>
                    </Route>
                </Route>
            </Route>
            <Route path="404" element={<Tela404 />}/>
            <Route path="*" element={<Navigate to={"404"}/>}/>
        </Routes>
    );
};

export default Rotas;
