import {Grid, Paper, styled, TextField, Theme, Typography, useMediaQuery} from "@mui/material";
import React from "react";
import {AgendamentoPedido} from "types/agendamentos";
import moment from "moment/moment";
import {NumericFormat} from 'react-number-format';
import numeral from "numeral";


const CustomHeader = styled( Typography )( ( { theme } ) => ( {
    fontWeight: "bold",
    fontSize: ".9rem",
    margin: 0,
    padding: 0
} ) );

type Props = {
    pedidos: AgendamentoPedido[];
    updatePedido?: ( pedido: AgendamentoPedido ) => void;
    editavel?: boolean;
}
const TableProdutos = ( { pedidos, updatePedido, editavel = false }: Props ) => {

    const md = useMediaQuery( ( t: Theme ) => t.breakpoints.down( "md" ) );

    return (
        <>
            {!md && <Grid container component={Paper} item sx={{ mb: 2, px: 1, py: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid item md={editavel ? 1.71 : 2}>
                    <CustomHeader>Nº Pedido</CustomHeader>
                </Grid>
                <Grid item md={editavel ? 1.71 : 2}>
                    <CustomHeader>Data Nesc.</CustomHeader>
                </Grid>
                <Grid item md={editavel ? 1.71 : 2}>
                    <CustomHeader>Produto</CustomHeader>
                </Grid>
                <Grid item md={editavel ? 1.71 : 2}>
                    <CustomHeader>Descrição</CustomHeader>
                </Grid>
                {editavel && (
                    <Grid item md={1.71}>
                        <CustomHeader>Qtd.</CustomHeader>
                    </Grid>
                )}
                <Grid item md={editavel ? 1.71 : 2}>
                    <CustomHeader>Qtd. Pedida</CustomHeader>
                </Grid>
                <Grid item md={editavel ? 1.71 : 2}>
                    <CustomHeader>Un. Medida</CustomHeader>
                </Grid>
            </Grid>}
            {pedidos.map( ( pedido, index ) => (
                <Grid key={index} container component={Paper} sx={{ mb: 2, px: 1, py: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item xs={6} sm={6} md={editavel ? 1.71 : 2} sx={{ mb: { xs: 1, md: 0 } }}>
                        <Typography variant="body1">{md && <b>Nº Pedido:</b>} {pedido.pedido}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={editavel ? 1.71 : 2} sx={{ mb: { xs: 1, md: 0 } }}>
                        <Typography variant="body1">{md && <b>Data Nesc.:</b>} {moment( pedido.dataNecessidade ).format( "DD/MM/YYYY" )}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={editavel ? 1.71 : 2} sx={{ mb: { xs: 1, md: 0 } }}>
                        <Typography variant="body1">{md && <b>Produto:</b>} {pedido.produto}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={editavel ? 1.71 : 2} sx={{ mb: { xs: 1, md: 0 } }}>
                        <Typography variant="body1">{md && <b>Descrição:</b>} {pedido.descricao}</Typography>
                    </Grid>
                    {editavel && (
                        <Grid item xs={6} sm={6} md={2}>
                            {updatePedido && (
                                <>
                                    <Typography variant="body1">{md && <b>Qtd. a entregar:</b>}</Typography>
                                    <NumericFormat
                                        customInput={TextField}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        InputProps={{
                                            style: { fontSize: 12 },
                                        }}
                                        allowNegative={false}
                                        min={0.0001}
                                        max={pedido.quantidadeDisponivel}
                                        variant="standard"
                                        defaultValue={pedido.quantidade}
                                        onChange={( e: any ) => {
                                            let value = e.target.value || "0";
                                            let quant = numeral( value ).value();
                                            updatePedido( { ...pedido, quantidade: quant.toString() } );
                                        }}
                                    />
                                </>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={6} sm={6} md={editavel ? 1.71 : 2} sx={{ mb: { xs: 1, md: 0 } }}>
                        <Typography variant="body1">{md && <b>Qtd.:</b>} {!editavel ? pedido.quantidade : pedido.quantidadeDisponivel}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={editavel ? 1.71 : 2} sx={{ mb: { xs: 1, md: 0 } }}>
                        <Typography variant="body1">{md && <b>Un. Medida :</b>}{pedido.unidadeMedida}</Typography>
                    </Grid>
                </Grid>
            ) )}
        </>
    );
}

export default TableProdutos;