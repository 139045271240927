import {
    Checkbox, Divider,
    FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    Grid, InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Slider,
    TextField, Theme
} from "@mui/material";
import {FCampo} from "types/formulariosCampos";
import {Controller} from "react-hook-form";

type Props = {
    campo: FCampo;
    register: any;
    errors: any;
    control: any;
}

const SEPARADOR = ";";

const Campos = ({campo, register, errors, control}: Props) => {
    switch (campo.tipo) {
        case "hidden":
            return (
                <TextField
                    {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                    type={'hidden'}
                    // defaultValue={campo.valor ? campo.valor : ''}
                    required={campo.obrigatorio}
                    InputLabelProps={{shrink: true,}}
                    size='small'
                    error={!!errors[campo.campo]}
                    helperText={errors[campo.campo]?.message}
                />
            );
        case "text":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        type={'text'}
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        size='small'
                        required={campo.obrigatorio}
                    />
                </Grid>
            );
        case "select":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        select
                        size='small'
                        defaultValue={"NONE"}
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        required={campo.obrigatorio}
                    >
                        <MenuItem value={"NONE"} disabled>Selecionar</MenuItem>
                        {campo.valor && campo.valor.split(SEPARADOR).map((vl, i) =>
                            <MenuItem key={i} value={vl}>
                                {vl}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
            );
        case "checkbox":
            return (
                <Grid item xs={12}>
                    <InputLabel>{campo.descricao}</InputLabel>
                    <Controller
                        control={control}
                        name={campo.campo}
                        rules={{required: {value: campo.obrigatorio, message: 'Campo obrigatório'}}}
                        render={({field}) => (
                            <FormGroup
                                sx={{display: 'flex', flexDirection: 'row'}}
                            >
                                {campo.valor && campo.valor.split(SEPARADOR).map((vl) =>
                                    <FormControlLabel control={<Checkbox/>} label={vl} key={vl} onChange={(e, checked) => {
                                        if(checked) {
                                            if(!field.value) {
                                                field.onChange(vl)
                                            }else{
                                                let temp = field.value+SEPARADOR+vl;
                                                field.onChange(temp);
                                            }
                                        }else{
                                            let temp = field.value.split(SEPARADOR);
                                            temp.splice(temp.indexOf(vl), 1);
                                            field.onChange(temp.join(SEPARADOR));
                                        }
                                    }} />
                                )}
                            </FormGroup>
                        )}
                    />
                    {errors[campo.campo]?.message && <FormHelperText
                        sx={{color: (theme: Theme) => theme.palette.error.main}}>{errors[campo.campo]?.message}</FormHelperText>}
                    <Divider/>
                </Grid>
            );
        case "radio":
            return (
                <Grid item xs={12}>
                    <FormLabel>{campo.descricao}</FormLabel>
                    <Controller
                        control={control}
                        name={campo.campo}
                        rules={{required: {value: campo.obrigatorio, message: 'Campo obrigatório'}}}
                        render={({field}) => (
                            <RadioGroup
                                // defaultValue={campo.valor.split(";")[0]}
                                sx={{display: 'flex', flexDirection: 'row'}}
                                // error={fieldState.error.message ? fieldState.error.message : "false"}
                                // required={campo.obrigatorio}
                                // size='small'
                            >
                                {campo.valor && campo.valor.split(";").map((vl, i) =>
                                    <FormControlLabel control={<Radio/>} label={vl} value={vl}
                                                      onChange={(e, checked) => {
                                                          if(checked) {
                                                              field.onChange(vl);
                                                          }
                                                      }} key={i}/>
                                )}
                            </RadioGroup>
                        )}
                    />
                    {errors[campo.campo]?.message && <FormHelperText
                        sx={{color: (theme: Theme) => theme.palette.error.main}}>{errors[campo.campo]?.message}</FormHelperText>}
                    <Divider/>
                </Grid>
            );
        case "number":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        type={'number'}
                        size='small'
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        required={campo.obrigatorio}
                    />
                </Grid>
            );
        case "textarea":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        multiline
                        rows={5}
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        size='small'
                        required={campo.obrigatorio}
                    />
                </Grid>
            );
        case "email":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        type={"email"}
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        size='small'
                        required={campo.obrigatorio}
                    />
                </Grid>
            );
        case "range":
            return (
                <Grid item xs={12}>
                    <InputLabel>{campo.descricao}</InputLabel>
                    <Slider
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        valueLabelDisplay="auto"
                        min={parseInt(campo.valor.split(SEPARADOR)[0])}
                        max={parseInt(campo.valor.split(SEPARADOR)[1])}
                        error={errors[campo.campo] ? errors[campo.campo] : "false"}
                        required={campo.obrigatorio}
                        size='small'
                    />
                    {errors[campo.campo]?.message && <FormHelperText
                        sx={{color: (theme: Theme) => theme.palette.error.main}}>{errors[campo.campo]?.message}</FormHelperText>}
                    <Divider/>
                </Grid>
            );
        case "url":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        type={"url"}
                        size='small'
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        required={campo.obrigatorio}
                    />
                </Grid>
            );
        case "time":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        type={"time"}
                        size='small'
                        InputLabelProps={{shrink: true,}}
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        required={campo.obrigatorio}
                    />
                </Grid>
            );
        case "datetime-local":
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={campo.descricao}
                        fullWidth
                        type={"date"}
                        InputLabelProps={{shrink: true,}}
                        size='small'
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        required={campo.obrigatorio}
                    />
                </Grid>
            );

        default:
            return (
                <Grid item xs={12}>
                    <TextField
                        {...register(campo.campo, {required: {value: campo.obrigatorio, message: 'Campo obrigatório'}})}
                        label={"Campo"}
                        fullWidth
                        required={campo.obrigatorio}
                        error={!!errors[campo.campo]}
                        helperText={errors[campo.campo]?.message}
                        size='small'
                    />
                </Grid>
            )
    }
}

export default Campos;