import {AxiosRequestConfig} from "axios"
import {API} from "./Api";

const URL = "/orcamentos";

let paramsGeral: AxiosRequestConfig = {
    withCredentials: true,
};


export const OrcamentosService =  {
    findAll: (codigo:string, params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(URL, paramsGeral);
    },
    findTransportadoras: (codigo:string,params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/transportadoras`, paramsGeral);
    },
    findProdutos: (codigo:string,codigotab: string,params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo, codigotab}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/produtos`, paramsGeral);
    },
    findConsumidores: (codigo:string,params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/consumidores`, paramsGeral);
    },
    findCondicoes: (codigo:string,params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/condicoes`, paramsGeral);
    },
    findById: (codigo:string,id: string, params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/${id}`, paramsGeral);
    },
    confirmarPedido: (codigo:string,id: string, params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/${id}/confirmar`,{}, paramsGeral);
    },
    getPdf: (codigo:string,id: string, params?: AxiosRequestConfig) => {
        if(params) {
            params = {...params, responseType: 'blob', withCredentials: true}
            params.params = {...params?.params, codigo}
        }
        else {
            params = {params: {codigo}, responseType: 'blob', withCredentials: true}
        }
        return API.get(`${URL}/${id}/pdf`, params);
    },
    create: (codigo:string,data: any, params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.post(URL, data, paramsGeral);
    },
    createConsumidor: (codigo:string, data: any , params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.post(`${URL}/consumidores`, data, paramsGeral);
    },
    update: (codigo:string,id: string, data: any, params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.put(`${URL}/${id}`, data, paramsGeral);
    },
    delete: (codigo:string,id: string, params?: AxiosRequestConfig) => {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.delete(`${URL}/${id}`, paramsGeral);
    },
    findTabelaProdutos(codigo: string, params?: AxiosRequestConfig) {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/tabelaPrecos`, paramsGeral);
    },
    findAllCamposFiltroProdutos(codigo: string, params?: AxiosRequestConfig) {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/filtros/produtos`, paramsGeral);
    },
    findHistorico(codigo: string, codigoCliente:string, params?: AxiosRequestConfig) {
        if(params) params.params = {...params?.params, codigo: codigo}
        else params = {params: {codigo: codigo}}
        paramsGeral = {...paramsGeral, ...params};
        return API.get(`${URL}/historico/${codigoCliente}`, paramsGeral);
    }
}