import {Button, Card, Grid, Tooltip, Typography} from "@mui/material";
import React from "react";
import PrintIcon from "@mui/icons-material/Print";
import {Boleto} from "types/boletos";
import Formatadores from "utils/formatadores";
import {useAppSelector} from "store";
import {useConfigState} from "store/config";
import {toast} from "react-toastify";
import download from "downloadjs";
import {BoletoService} from "services/BoletoService";

type Props = {
  boleto: Boleto;
  setLoading: (st: boolean) => void;
}

const CardBoleto = ({ boleto, setLoading }: Props) => {
  const config = useAppSelector(useConfigState);

  const getFile = () => {
    setLoading(true);
    BoletoService.getFile(boleto.recno, config.empresa.empresaCodigo)
      .then(res => {
        const file = new Blob([res.data], { type: 'application/pdf' });
        download(file, boleto.numero.toString().replace(/\D/g, '') + ".pdf", "application/pdf");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

//   const handlePrint = (recno: any) => {
//     setLoading(true);
//     BoletoService.getFile(recno, empresa.empresaCodigo)
//         .then(res => {
//             const file = new Blob([res.data], {type: 'application/pdf'});
//             const url = URL.createObjectURL(file);
//             window.open(url, "_blank");
//         })
//         .catch((err) => {
//             toast.error(err.response.data.message);
//         })
//         .finally(()=> setLoading(false));
// }

  return (
    <Grid item xs={12} sx={{ alignItems: "center" }}>
      <Card variant="elevation" sx={{ p: 2 }}>
        <Grid container alignItems="center">
          <Grid item lg={3} md={5} xs={12}>
            <Typography sx={{ fontSize: 16 }} color="text.secondary">
              <b>Numero: </b> {boleto.numero} {boleto.prefixo && <> - {boleto.prefixo}</>}
            </Typography>
            <Typography sx={{ fontSize: 16 }} color="text.secondary">
              <b>Emissão:</b> {Formatadores.data(boleto.emissao)}
            </Typography>
            <Typography sx={{ fontSize: 16 }} color="text.secondary">
              <b>Vencimento: </b> {Formatadores.data(boleto.vencimento)}
            </Typography>
            <Typography sx={{ fontSize: 16 }} color="text.secondary">
              <b>Tipo:</b> {boleto.tipo}
            </Typography>
          </Grid>
          <Grid item lg={7} md={5} xs={12}>
            <Typography sx={{ fontSize: 16 }} color="text.secondary">
              <b>Origem:</b> {boleto.filial}
            </Typography>
            {boleto.parcela &&
              <Typography sx={{ fontSize: 16 }} color="text.secondary">
                <b>Parcela: </b> {boleto.parcela}
              </Typography>
            }
            <Typography sx={{ fontSize: 16 }} color="text.secondary">
              <b>Valor:</b> {Formatadores.moeda(boleto.saldo)}
            </Typography>
            <Typography sx={{ fontSize: 16 }} color="text.secondary">
              <b>Observação:</b> {boleto.obs}
            </Typography>

          </Grid>
          <Grid item container sm={2} xs={12}>
            <Grid item xs={12} md={12}>
            <Tooltip title="Imprimir" placement="top">
                <Button variant={'contained'} onClick={() => getFile()}
                        sx={{marginRight: 1,}}>
                    <PrintIcon sx={{mr: .5}}/> PDF
                </Button>
            </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}


export default CardBoleto;