import {useCallback, useEffect, useReducer, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {cnpj, cpf} from "cpf-cnpj-validator";
import {toast} from "react-toastify";
import {ColaboradorService} from "services/ColaboradorService";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {PermissaoService} from "services/PermissaoService";
import MyCard from "components/card";
import {Box, Button, Divider, FormControlLabel, Grid, MenuItem, TextField, Typography} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {Acesso, Modulo, Permissao} from "types/auth";
import AcessoUtils, {sortModulos} from "utils/colaborador";
import Checkbox from "@mui/material/Checkbox";
import {Validadores} from "utils/validadores";
import {toastError} from "utils/toastError";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import {useAppDispatch, useAppSelector} from "store";
import {configActions, useConfigState} from "store/config";
import {hasAnyRoles, useAuthState} from "store/auth";

type UsuarioForm = {
    id: number;
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    status: string;
    cgc: string;
    permissoes: string[];
}


const isPermission = (state: Acesso[], acessoM: Acesso, mod: Modulo, per: Permissao): boolean => {
    let acesso = state.find((acesso: Acesso) => acesso.empresaCodigo === acessoM.empresaCodigo);
    if (acesso === undefined) return false;
    let modulo = acesso.modulos.find((modu: Modulo) => modu.nome === mod.nome);
    if (modulo === undefined) return false;
    let permissao = modulo.permissoes.find((perm: Permissao) => perm.nome === per.nome);
    return permissao !== undefined;
}


const EditColaboradores = () => {
    const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
    const {
        handleSubmit,
        formState: {errors},
        getValues,
        register,
        reset,
        control
    } = useForm<UsuarioForm>({mode: 'all', defaultValues: {id: 0, nome: '', password: '', cgc: '', confirm_senha: '', email: '', status: '1', permissoes: [],}});
    const [state, dispatch] = useReducer(AcessoUtils.reducerAcessos, []);
    const [listAcessos, setListAcessos] = useState<Acesso[]>([])
    const navigate = useNavigate();
    const appSelector = useAppSelector(useAuthState)
    const config = useAppSelector(useConfigState);
    const appDispatch = useAppDispatch();
    const {id} = useParams();

    const getAcessos = useCallback(() => {
        let params: AxiosRequestConfig = {
            params: {
                codigo: config.parametros.codigoUnico,
                full: true
            }
        };
        PermissaoService.findAllAcessos(params)
            .then((res: AxiosResponse<Acesso[]>) => {
                setListAcessos(res.data);
            })
            .catch((err) => {
                toastError(err, "Erro ao buscar acessos !");
            });
    }, [config.parametros.codigoUnico]);

    useEffect(() => {
        getAcessos();
    }, [getAcessos]);

    const loadColaborador = useCallback(() => {
        if (id) {
            appDispatch(configActions.setLoading({loading: true}));
            ColaboradorService.findById(id)
                .then(res => {
                    if (res.data.acessos.length === 0){
                        for (let i = 0; i < listAcessos.length; i++) {
                            dispatch({type: 'add', payload: {acs: listAcessos[i]}})
                        }
                    }else{
                        for (let i = 0; i < res.data.acessos.length; i++) {
                            dispatch({type: 'add', payload: {acs: res.data.acessos[i]}})
                        }
                    }
                    res.data.status = res.data.status ? "1" : "0";
                    res.data.password = '';
                    if (res.data.cgc.length === 14) {
                        res.data.cgc = cnpj.format(res.data.cgc);
                    } else {
                        res.data.cgc = cpf.format(res.data.cgc);
                    }
                    reset(res.data);
                })
                .catch(err => {
                    toast.error(err.message);
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}));
                });
        }
    }, [appDispatch, id, listAcessos, reset]);

    useEffect(() => {
        loadColaborador();
    }, [loadColaborador]);

    const onSubmit = (data: any) => {
        if (id) {
            data.cgc = data.cgc.replace(/\D/g, '');
            if (data.cgc.length === 14 && !cnpj.isValid(data.cgc)) {
                toast.error('CNPJ inválido');
                return;
            } else if (data.cgc.length === 11 && !cpf.isValid(data.cgc)) {
                toast.error('CPF inválido');
                return;
            } else {
                appDispatch(configActions.setLoading({loading: true}));
                const formData = {
                    id,
                    password: data.password,
                    nome: data.nome,
                    email: data.email,
                    cgc: data.cgc,
                    status: data.status === "1",
                    acessos: state
                } 
                
                ColaboradorService.update(formData, {params: {codigo: config.parametros.codigoUnico}})
                    .then(() => {
                        toast.success("Salvo com sucesso !");
                        navigate("/painel/colaboradores")
                    })
                    .catch((err) => toastError(err, "Erro ao salvar !"))
                    .finally(() => appDispatch(configActions.setLoading({loading: false})));
            }
        } else {
            navigate("/painel/admins");
            toast.error("Id não encontrado !");
        }
    }

    const handleChangeMod = (checked: any, acs: Acesso, mod: Modulo) => {
        if (checked) {
            dispatch({type: 'addMod', payload: {acs, mod}})
        } else {
            dispatch({type: 'removeMod', payload: {acs, mod}})
        }
    }

    const handleChangePerm = (checked: any, acs: Acesso, mod: Modulo, permissao: Permissao) => {
        if (checked) {
            dispatch({type: 'addPer', payload: {acs, mod, permissao}})
        } else {
            dispatch({type: 'removePer', payload: {acs, mod, permissao}})
        }
    }

    const isCheckedMod = (acs: Acesso, mod: Modulo): boolean => {
        let acessos = state.find((acesso: Acesso) => acesso.empresaCodigo === acs.empresaCodigo)
        if (acessos === undefined) return false;
        let modulo = acessos.modulos.find((modu: Modulo) => modu.nome === mod.nome);
        return modulo !== undefined;
    }

    return (
        <MyCard title={'Editar colaborador'}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off">
                <TextField
                    {...register("nome")}
                    required
                    error={!!errors.nome}
                    label="Nome"
                    variant="outlined"
                    sx={{width: '100%'}}
                    helperText={errors.nome?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("email")}
                    required
                    error={!!errors.email}
                    type={'email'}
                    label="E-mail"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.email?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("password", {
                        validate: (value) => {
                            if (value) return Validadores.senha(value)
                        }
                    })}
                    error={!!errors.password}
                    type={'password'}
                    label="Senha"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.password?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("confirm_senha", {
                        validate: (value) => {
                            const {password} = getValues();
                            if (value && password && value !== password) return 'As senhas não são iguais.'
                        }
                    })}
                    error={!!errors.confirm_senha}
                    type={'password'}
                    label="Confirmar senha"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.confirm_senha?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register('cgc', {validate: (value) => Validadores.cgc(value),})}
                    error={!!errors.cgc}
                    required
                    type={'text'}
                    label="CPF/CNPJ"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.cgc?.message}
                    InputLabelProps={{shrink: true}}
                />
                {
                    hasAnyRoles(appSelector, ['ROLE_ADMIN']) && (
                        <Controller 
                        name="status"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                error={!!errors.status}
                                required
                                select
                                label="Status"
                                variant="outlined"
                                fullWidth
                                helperText={errors.status?.message}
                                sx={{ mt: 3}}
                            >
                                {statusField.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>))}
                            </TextField>
                        )}

                    />
                    )
                }
                <Box sx={{mb: 2, mt: 3}}>
                    <Typography>Permissões do Colaborador</Typography>
                    <Divider/>
                </Box>
                {listAcessos.map((acessoM: Acesso, i) => (
                    <Accordion sx={{width: "100%"}} key={i}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography sx={{fontSize: 16, fontWeight: 600}}>{acessoM.empresaNome}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} sx={{mb: 2}}>
                                    <TextField
                                        required
                                        select
                                        size={'small'}
                                        value={state.filter((acesso: Acesso) => acesso.empresaCodigo === acessoM.empresaCodigo)[0]?.status ? "1" : "0"}
                                        onChange={(e) => {
                                            let temp = acessoM;
                                            temp.status = e.target.value === "1";
                                            dispatch({type: 'updateStatus', payload: {acs: temp}})
                                        }}
                                        label="Status"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ mt: 3}}
                                        inputProps={{
                                            readOnly: false
                                        }}
                                    >
                                        <MenuItem value={"1"}>Ativo</MenuItem>
                                        <MenuItem value={"0"}>Bloqueado</MenuItem>
                                    </TextField>
                                </Grid>

                                {acessoM.modulos.sort(sortModulos).map((mod, i) => (
                                    <Grid item xs={12} md={6} lg={4}
                                          sx={{border: 1, borderColor: (t) => t.palette.grey[200], p: 2}}
                                          key={i}>
                                        <FormControlLabel
                                            key={mod.nome}
                                            label={mod.descricao}
                                            sx={{fontWeight: 600}}
                                            control={
                                                <Checkbox
                                                    disabled={false}
                                                    name={mod.nome}
                                                    onChange={(e) => handleChangeMod(e.target.checked, acessoM, mod)}
                                                    // defaultChecked={false}
                                                    checked={isCheckedMod(acessoM, mod)}
                                                />
                                            }
                                        />
                                        {mod.permissoes.length > 0 && (
                                            <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>
                                                {mod.permissoes.map((per) => (
                                                    <FormControlLabel
                                                        // defaultChecked={false}
                                                        key={per.nome}
                                                        label={per.descricao ? per.descricao : per.nome}
                                                        control={
                                                            <Checkbox
                                                                disabled={false}
                                                                name={per.descricao ? per.descricao : per.nome}
                                                                onChange={(e) => handleChangePerm(e.target.checked, acessoM, mod, per)}
                                                                // defaultChecked={false}
                                                                checked={isPermission(state, acessoM, mod, per)}
                                                            />
                                                        }
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{mt: 3, textAlign: 'right'}}>
                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                        <SaveIcon/> Salvar
                    </Button>
                </Box>
            </Box>
        </MyCard>
    );
}

export default EditColaboradores;