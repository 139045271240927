import {Card, Typography} from "@mui/material";
import React from "react";

type Props = {
  title: string;
  data1?: string;
  data2?: string;
  children?: React.ReactNode;
};

export const CardDados = ({ title, data1, data2, children }: Props) => {
  
  return (
      <Card>
        <Card>
          <Typography sx={{ fontSize: 18, pl: 1, pt: 1 }} color="text.primary" gutterBottom>
            {title}
          </Typography>
          { data1 &&
            <Typography variant="h5" component="div" fontSize={15} pl={2}>
              {data1}
            </Typography>
          }
          { data2 &&
            <Typography sx={{ mb: 1.5, pl: 2 }} variant="h5" component="div" fontSize={15} pl={2} >
              {data2}
            </Typography>
          }
          {children}
        </Card>
      </Card>
  );
}