import MyCard from "components/card";
import {useCallback, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {AdminService} from "services/AdminService";
import {toast} from "react-toastify";
import {Box, Button, MenuItem, TextField} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {useNavigate, useParams} from "react-router-dom";
import {Validadores} from "utils/validadores";
import {useAppDispatch} from "store";
import {configActions} from "store/config";

type UsuarioForm = {
    id: number;
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    status: string;
}

const EditAdministradores = () => {
    const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
    const {handleSubmit, formState: {errors}, getValues, reset, control, register} = useForm<UsuarioForm>(
        {
            mode: 'all',
            defaultValues: {
                id: 0,
                nome: '',
                password: '',
                confirm_senha: '',
                email: '',
                status: '',
            }
        }
    );
    const navigate = useNavigate();
    const {id} = useParams();
    const appDispatch = useAppDispatch();

    const loadUser = useCallback(() => {
        if (id) {
            appDispatch(configActions.setLoading({loading: true}))
            AdminService.findById(id as string)
                .then(res => {
                    res.data.status = res.data.status ? 1 : 0;
                    res.data.password = '';
                    reset(res.data);
                })
                .catch(err => {
                    toast.error(err.message);
                })
                .finally(() => {
                    appDispatch(configActions.setLoading({loading: false}))
                });
        }
    }, [appDispatch, id, reset]);

    const onSubmit = (data: any) => {
        if (id) {
            appDispatch(configActions.setLoading({loading: true}))
            const formData = {
                ...data,
                status: parseInt(data.status),
            }
            AdminService.update(id as string, formData)
                .then(() => {
                    toast.success("Salvo com sucesso !");
                    navigate("/painel/admins")
                })
                .catch(() => toast.error("Erro ao salvar !"))
                .finally(() => appDispatch(configActions.setLoading({loading: false})));
        } else {
            navigate("/painel/admins");
            toast.error("Id não encontrado !");
        }
    }

    useEffect(() => {
        loadUser();
    }, [loadUser]);

    return (
        <MyCard title={'Editar administrador'}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off" noValidate>
                <TextField
                    {...register("nome")}
                    required
                    error={!!errors.nome}
                    label="Nome"
                    variant="outlined"
                    sx={{width: '100%'}}
                    helperText={errors.nome?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("email")}
                    required
                    error={!!errors.email}
                    type={'email'}
                    label="E-mail"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.email?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("password", {
                        validate: (value) => {
                            if (value) return Validadores.senha(value)
                        }
                    })}
                    error={!!errors.password}
                    type={'password'}
                    label="Senha"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.password?.message}
                    InputLabelProps={{shrink: true}}
                />
                <TextField
                    {...register("confirm_senha", {
                        validate: (value) => {
                            const {password} = getValues();
                            if (value && password && value !== password) return 'As senhas não são iguais.'
                        }
                    })}
                    error={!!errors.confirm_senha}
                    type={'password'}
                    label="Confirmar senha"
                    variant="outlined"
                    sx={{width: '100%', mt: 3}}
                    helperText={errors.confirm_senha?.message}
                    InputLabelProps={{shrink: true}}
                />

                <Controller
                    name="status"
                    control={control}
                    render={({field}) => (
                        <TextField
                            {...field}
                            error={!!errors.status}
                            required
                            select
                            label="Status"
                            variant="outlined"
                            sx={{width: '100%', mt: 3}}
                            helperText={errors.status?.message}
                            InputLabelProps={{shrink: true}}
                        >
                            {statusField.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>))}
                        </TextField>
                    )}
                />
                <Box sx={{mt: 3, textAlign: 'right'}}>
                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                        <SaveIcon/> Salvar
                    </Button>
                </Box>
            </Box>
        </MyCard>
    )
}

export default EditAdministradores;



