import MyCard from "components/card";
import {useForm} from "react-hook-form";
import {AdminService} from "services/AdminService";
import {toast} from "react-toastify";
import {Box, Button, MenuItem, TextField} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {useNavigate} from "react-router-dom";
import {Validadores} from "utils/validadores";
import {useAppDispatch} from "store";
import {configActions} from "store/config";

type UsuarioForm = {
    id: number;
    nome: string;
    password: string;
    confirm_senha: string;
    email: string;
    status: string;
}

const AddAdministradores = () => {
    const statusField = [{value: "0", label: 'Bloqueado'}, {value: "1", label: 'Ativo'}];
    const {handleSubmit, formState: {errors}, getValues, register} = useForm<UsuarioForm>(
        {
            mode: 'all',
            defaultValues: {
                id: 0,
                nome: '',
                password: '',
                confirm_senha: '',
                email: '',
                status: '',
            }
        }
    );
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();


    const onSubmit = (data: any) => {
        appDispatch(configActions.setLoading({loading: true}))
        const formData = {
            ...data,
            status: parseInt(data.status),
        }
        AdminService.create(formData)
            .then(() => {
                toast.success("Salvo com sucesso !");
                navigate("/painel/admins")
            })
            .catch(() => toast.error("Erro ao salvar !"))
            .finally(() => appDispatch(configActions.setLoading({loading: false})));
    }

    return (
        <MyCard title={'Adicionar administrador'}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}} autoComplete="off" >
                    <TextField
                        {...register("nome")}
                        required
                        error={!!errors.nome}
                        label="Nome"
                        variant="outlined"
                        sx={{width: '100%'}}
                        helperText={errors.nome?.message}
                    />
                    <TextField
                        {...register("email")}
                        required
                        error={!!errors.email}
                        type={'email'}
                        label="E-mail"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.email?.message}
                    />
                    <TextField
                        {...register("password", {validate: (value) =>  Validadores.senha(value)})}
                        error={!!errors.password}
                        required
                        type={'password'}
                        label="Senha"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.password?.message}
                    />
                    <TextField
                        {...register("confirm_senha", {validate: (value) => {
                                const {password} = getValues();
                                if (value !== password) return 'As senhas não são iguais.'
                        }})}
                        error={!!errors.confirm_senha}
                        required
                        type={'password'}
                        label="Confirmar senha"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.confirm_senha?.message}
                    />
                    <TextField
                        {...register("status")}
                        error={!!errors.status}
                        required
                        select
                        label="Status"
                        variant="outlined"
                        sx={{width: '100%', mt: 3}}
                        helperText={errors.status?.message}
                    >
                        {statusField.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>))}
                    </TextField>
                    <Box sx={{mt: 3, textAlign: 'right'}}>
                        <Button variant={'contained'} color={'primary'} type={'submit'}>
                            <SaveIcon/> Salvar
                        </Button>
                    </Box>
                </Box>
        </MyCard>
    )
}

export default AddAdministradores;


