import CloseIcon from "@mui/icons-material/Close";
import {
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import React from "react";
import {Agendamento} from "types/agendamentos";
import moment from "moment";
import TableProdutos from "components/agendamentos-entregas/table-produtos";
import {yellow} from "@mui/material/colors";

type Props = {
    show: boolean;
    setShow: ( show: boolean ) => void;
    agendamento: Agendamento;
};

const Transition = React.forwardRef( function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
} );

const ModalAgendamentoDetails = ( { show, setShow, agendamento }: Props ) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery( theme.breakpoints.down( "sm" ) );

    return (
        <div>
            <Dialog
                open={show}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={"md"}
                fullScreen={fullScreen}
                keepMounted
                onClose={() => setShow( false )}
                aria-describedby="alert-dialog-slide-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={() => setShow( false )}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: ( theme ) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle><Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{"Detalhes do agendamento"}</Typography></DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={4}>
                            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Agendado para:</Typography>
                            <Typography sx={{ fontSize: 16 }}>{`${moment( agendamento.dataEntrega ).format( "DD/MM/YYYY" )} ${agendamento.horaEntrega}`}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Placa prevista:</Typography>
                            <Typography sx={{ fontSize: 16 }}>{agendamento.placaPrevista}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>Status:</Typography>
                            {agendamento.status === "CONFIRMADO" && <Chip label="Confirmado" color="success" size={"small"} />} {agendamento.status === "PENDENTE" && <Chip label="Pendente" sx={{ backgroundColor: yellow[500] }} size={"small"} />}{agendamento.status === "CANCELADO" && <Chip label="Cancelado" color="error" size={"small"} />}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ fontSize: 12, fontWeight: 'bold', mb: 2 }}>Detalhes dos produtos:</Typography>
                            <TableProdutos pedidos={agendamento.itens} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ModalAgendamentoDetails;
